import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import dayjs from "dayjs";

import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { styles } from "../../../helpers";
import { gridColumns, localizedTextsMap } from "./constants";
import Spinner from "../../spinners/Spinner";
import { getScheduleList } from "./services";
import { Context } from "../../../context/utilsContext";
import ModalAlert from "../../floats/ModalAlert";
import CustomToolbar from "./molecules/CustomToolbar";

const ManageSchedule = () => {
    const {
        session: {
            login: { user },
        },
        users: { status: requestStatusUsersList, message: resquestUserListMsg },
        contract: {
            tramites: { status, message },
        },
    } = useSelector((state) => state);
    const { loading, setLoading, reload } = useContext(Context);

    const [key, setKey] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const getList = async () => {
        try {
            setTextSpinner("Cargando listado de Agendamiento...");

            setLoading(true);

            const { token } = user;

            const response = await getScheduleList(
                dayjs().format("YYYY-MM"),
                token
            );

            if (response["status"] === "Success") {
                setDataGrid(
                    response["data"].filter(
                        (item) => item["sProcess"] === "NORMAL"
                    )
                );
            } else {
                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: "Consulta con un administrador del sistema",
                    buttonText: "Cerrar",
                    modalIcon: "warnIcon"
                });
                setShowModal(true);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: "Consulta con un administrador del sistema",
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }
    };

    useEffect(() => {
        if (![null, undefined, "success", ""].includes(status)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        } else if (
            ![null, undefined, "success", ""].includes(requestStatusUsersList)
        ) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: resquestUserListMsg,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);

    useEffect(() => {
        getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (reload === true) getList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const renderList = React.useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) =>
                                `${row.sContractID}-${row.sContractTypeName}`
                            }
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading}
                            sx={[
                                styles.gridContainerStyles,
                                {
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                        fontSize: "8px",
                                    },
                                },
                            ]}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                        updateData={() => {
                                            setTextSpinner(
                                                "Actualizando listado de agendamiento..."
                                            );
                                            getList();
                                        }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        {
                                            field: "contractAutoId",
                                            sort: "desc",
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, gridColumns]);

    return (
        <div className="content-detail__container" key={key}>
            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManageSchedule;
