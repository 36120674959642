import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';

import { limpiarEspacios, textErrorAsync } from "../../../../../utils/validations";
import Spinner from "../../../../spinners/Spinner";
import { /* addChalleRestValidacion, */ /* addChalleRestValidacion */ addESingnerCertPinFirma, addRaWsIngresSolicitud } from "../../../../../store/fvavanzada/fvavanzada";
import { raWSIngresoSolicitud, wSDescargaCertificadoConfPinFirma } from "../../../../../utils/api-fva";
import { api as Api } from "../../../../../utils/enums";
import GetFromXmlRestWSIngresoSolicitud from "../../hooks/get-from-xml";
import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import ModalAlert from "../../../../floats/ModalAlert";


const Step3 = () => {
    const styles = FirmaVirtualAvanzadaStyles();
    const screenSizeHook = ScreenSizeHook();
    const {
        fvavanzada: {
            challeRespuestaValidacion,
            clvUnicadAtosUsuario,
            /* clvUnicaAccessToken */
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [textLoading, setTextLoading] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [modalStatus, setModalStatus] = React.useState({});
    const statusRestXml = GetFromXmlRestWSIngresoSolicitud();
    // eslint-disable-next-line
    const [succesCert, setSuccesCert] = React.useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const getCert = async () => {

        try {

            setLoading(true);

            setTextLoading("Preparando solicitud.");

            const xmlCertPinFirma = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="http://ws.signserver.esign.com/">
                    <soapenv:Header/>
                    <soapenv:Body>
                    <ws:descargaCertCreaConfPinFirma>
                        <!--Optional:-->
                        <requestDescargaConf>
                        <encabezado>
                                <user>${Api.usuarioDescargaCertificado}</user>
                                <password>${Api.claveDescargaCertificado}</password>
                                <pinFirma>${clvUnicadAtosUsuario['formData']['pinFirma']}</pinFirma>
                            </encabezado>
                            <datosDescarga>
                                <pin>${statusRestXml['restPin']}</pin>
                                <nroSolicitud>${statusRestXml['restNumSolicitud']}</nroSolicitud>
                                <clave>${clvUnicadAtosUsuario['formData']['pinFirma']}</clave>
                                <tipoCertificado>${Api.PRODUCCION === "true" ? 'FEA' : 'FES'}</tipoCertificado>
                            </datosDescarga>
                            <parametro>
                                <rutEmpresa>${Api.rutEmpresa}</rutEmpresa>
                                <usuarioLogin>${Api.X_API_APP}</usuarioLogin>
                                <posicionImagenXInferiorIzquierda></posicionImagenXInferiorIzquierda>
                                <posicionImagenYInferiorIzquierda></posicionImagenYInferiorIzquierda>
                                <posicionImagenXSuperiorDerecha></posicionImagenXSuperiorDerecha>
                                <posicionImagenYSuperiorDerecha></posicionImagenYSuperiorDerecha>
                                <paginaImagen></paginaImagen>
                                <codigoQR>true</codigoQR>
                                <posicionXCodigoQR></posicionXCodigoQR>
                                <posicionYCodigoQR></posicionYCodigoQR>
                                <urlVerificacion>0</urlVerificacion>
                                <posicionXUrlVerificacion></posicionXUrlVerificacion>
                                <posicionYUrlVerificacion></posicionYUrlVerificacion>
                                <agregarSello></agregarSello>
                                <posicionXSello></posicionXSello>
                                <posicionYSello></posicionYSello>
                                <selloDefault></selloDefault>
                                <imagenPDFB64></imagenPDFB64>
                            </parametro>
                        </requestDescargaConf>
                    </ws:descargaCertCreaConfPinFirma>
                    </soapenv:Body>
                </soapenv:Envelope>`;

            const certPingFirma = await wSDescargaCertificadoConfPinFirma(xmlCertPinFirma);

            console.log('certPingFirma', certPingFirma);

            if (certPingFirma['status'] !== 'FAIL') {

                dispatch(addESingnerCertPinFirma(certPingFirma));

                setSuccesCert(true);

                setModalStatus({
                    title: "Solicitud preparada exitosamente!.",
                    message: "Su solicitud ha sido procesada correctamente",
                    buttonText: "Cerrar",
                    modalIcon: 'successIcon'
                });
                setShowModal(true);
            } else {

                // dispatch(addESingnerCertPinFirma(certPingFirma));

                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: certPingFirma['message'],
                    buttonText: "Cerrar",
                    modalIcon: 'warnIcon'
                });
                setShowModal(true);

            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: textErrorAsync,
                buttonText: "Cerrar",
                modalIcon: 'warnIcon'
            });
            setShowModal(true);
            console.log('error', error);
        }

    };

    React.useEffect(() => {
        console.log('statusRestXml', statusRestXml);

        dispatch(addRaWsIngresSolicitud(`<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
            <soap:Body>
                <ns2:ingresarSolicitudResponse xmlns:ns2="http://ws.backend.esign.com/">
                    <responseIngreso>
                        <salida>
                            <estado>OK</estado>
                            <descripcion>Solicitud FES ingresada correctamente</descripcion>
                            <estadoSolicitud>PENDIENTE</estadoSolicitud>
                            <numeroSolicitud>83029658486852</numeroSolicitud>
                            <pin>5B7B2I</pin>
                        </salida>
                    </responseIngreso>
                </ns2:ingresarSolicitudResponse>
            </soap:Body>
        </soap:Envelope>`));

        /* dispatch(addChalleRestValidacion({
            "status": "OK",
            "message": "Respuestas validadas con exito",
            "respuestasCorrectas": true,
            "idValidacion": "Zt2iCrKcGtd",
            "created": "2022-07-21 11:19:18"
        })); */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusRestXml, succesCert]);

    React.useEffect(() => {

        (async () => {

            try {// ${clvUnicadAtosUsuario['RolUnico']['numero'] + '-' + clvUnicadAtosUsuario['RolUnico']['DV']}

                if (statusRestXml !== undefined && statusRestXml['restEstado'] !== "OK") {

                    setTextLoading("Preparando solicitud.");

                    var xmlBodyStr = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="http://ws.backend.esign.com/">
                                <soapenv:Header/>
                                <soapenv:Body>
                                    <ws:ingresarSolicitud>
                                        <!--Optional:-->
                                        <requestIngreso>
                                            <encabezado>
                                                <usuario>${Api.X_API_APP}</usuario>
                                                <clave>${Api.X_API_KEY}</clave>
                                            </encabezado>
                                            <parametro>
                                                <nombres>${limpiarEspacios(clvUnicadAtosUsuario['name']['nombres'][0])}</nombres>
                                                <apellidoPaterno>${limpiarEspacios(clvUnicadAtosUsuario['name']['apellidos'][0])}</apellidoPaterno>
                                                <apellidoMaterno>${limpiarEspacios(clvUnicadAtosUsuario['name']['apellidos'][1])}</apellidoMaterno>
                                                <email>${clvUnicadAtosUsuario['formData']['email']}</email>
                                                <rut>${clvUnicadAtosUsuario['RolUnico']['numero'] + '-' + clvUnicadAtosUsuario['RolUnico']['DV']}</rut>
                                                <telefonoMovil>${clvUnicadAtosUsuario['formData']['telefono']}</telefonoMovil>
                                                <telefonoFijo></telefonoFijo>
                                                <direccion></direccion>
                                                <nroSerieCedula>${clvUnicadAtosUsuario['formData']['numeroDocumento']}</nroSerieCedula>
                                                <origen>FirmaVirtual</origen>
                                                <vigencia>365</vigencia>
                                                <clave>${clvUnicadAtosUsuario['formData']['pinFirma']}</clave>
                                                <idValidacion>${challeRespuestaValidacion['idValidacion']}</idValidacion>
                                                <tipoCertificado>${Api.PRODUCCION === "true" ? 'FEA' : 'FES'}</tipoCertificado>
                                                <!--Optional:-->
                                                <estado></estado>
                                            </parametro>
                                        </requestIngreso>
                                    </ws:ingresarSolicitud>
                                </soapenv:Body>
                            </soapenv:Envelope>`;

                    setLoading(true);

                    const restRaIngresSolicit = await raWSIngresoSolicitud(xmlBodyStr);

                    console.log('restRaIngresSolicit', restRaIngresSolicit);

                    if (restRaIngresSolicit['status'] !== 'FAIL') {

                        dispatch(addRaWsIngresSolicitud(restRaIngresSolicit));

                        setModalStatus({
                            title: "Solicitud preparada exitosamente!.",
                            message: "Su solicitud ha sido procesada correctamente",
                            buttonText: "Cerrar",
                            modalIcon: 'successIcon'
                        });
                        setShowModal(true);

                    } else {

                        setModalStatus({
                            title: "¡Ups!, Algo ha pasado",
                            message: restRaIngresSolicit['message'],
                            buttonText: "Cerrar",
                            modalIcon: 'warnIcon'
                        });
                        setShowModal(true);

                    }

                    setLoading(false);

                }

            } catch (error) {
                setLoading(false);
                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: textErrorAsync,
                    buttonText: "Cerrar",
                    modalIcon: 'warnIcon'
                });
                setShowModal(true);
                console.log('error', error);
            }

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* React.useEffect(() => {

        (async () => {

            

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusRestXml]); */


    React.useEffect(() => {

        /* console.log('challeRespuestaValidacion', challeRespuestaValidacion);
        console.log('clvUnicadAtosUsuario', clvUnicadAtosUsuario);
        console.log('X_API_KEY', Api.X_API_KEY);
        console.log('X_API_APP', Api.X_API_APP); */

        /* dispatch(addChalleRestValidacion({
            "status": "OK",
            "message": "Respuestas validadas con exito",
            "respuestasCorrectas": true,
            "idValidacion": "Zt2iCrKcGtd",
            "created": "2022-07-21 11:19:18"
        })); */


        /* setModalTitle("Solicitud preparada exitosamente!");
        setModalMsg('Solicitud procesada correctamente');
        setModalIcon(<SquareFace className="icon--cover" />);
        setModalButtonTitle("Cerrar");
        setShowModal(true); */


        /* setModalTitle("¡Ups!, Algo ha pasado");
        setModalMsg('textErrorAsync');
        setModalButtonTitle("Cerrar");
        setShowModal(true); */

    }, []);

    return (
        <Grid container xs={12} className={styles.mainContentFEA}>

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textLoading}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {statusRestXml !== undefined && (<Grid xs={12} sm={7} xl={4} className={styles.contentLoginFVA} mt={5}>

                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: "bold",
                        color: "#646b6f",
                        fontSize: ['xs', 'sm'].includes(screenSizeHook) ? '1.7rem' : '3rem'
                    }}
                    gutterBottom
                    component="strong"
                >
                    {statusRestXml['restDescrip']}
                </Typography>

                <Typography variant="body1" gutterBottom>
                    Estado de la solicitud: {statusRestXml['restEstadoSolicitud']}, Numero de la solicitud: {statusRestXml['restNumSolicitud']}, Condigo pin: {statusRestXml['restPin']}
                </Typography>

                <br />

                {statusRestXml['restEstado'] !== "OK" && (<Button
                    variant="contained"
                    color="primary"
                // onClick={() => getTokenAndUrlAuth()}
                >
                    Re Intentar
                </Button>)}

                {statusRestXml['restEstado'] === "OK" && (<LoadingButton
                    color="primary"
                    loading={loading}
                    loadingIndicator="Cargando…"
                    variant="contained"
                    onClick={() => getCert()}
                >
                    Obtener Certificado
                </LoadingButton>)}

            </Grid>)}

        </Grid>
    );
}

export default Step3;