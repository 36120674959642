import { forwardRef, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Btn from '../../../../buttons/Button';
import { PositionFirmStyles } from '../../index.css';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SignatoryAlert = ({ missingSigners, agree }) => {
    const styles = PositionFirmStyles();
    const [open, setOpen] = useState(false);
    let nombres = '';


    if (missingSigners.length === 1) {

        nombres += missingSigners[0];

    } else if (missingSigners.length > 1) {

        const lastName = missingSigners[missingSigners.length - 1];

        const listName = missingSigners.filter((name) => name !== lastName);

        nombres += (listName.toString().replace(/,/g, ", ") + ' y ' + lastName);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (missingSigners.length !== 0) handleClickOpen();
    }, [missingSigners]);

    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            maxWidth='xs'
            className={styles.mainContainerDialog}
        >

            <DialogTitle sx={{ textAlign: 'center' }}>
                Faltan firmas en el documento.
            </DialogTitle>

            <DialogContent>

                <DialogContentText>

                    <Typography>{missingSigners.length === 1 ? 'El participante ' : 'Los participantes '} <strong> {nombres} </strong> {missingSigners.length === 1 ? 'aún no posee' : 'aun no poseen'} al menos una firma en el documento. </Typography>

                </DialogContentText>

            </DialogContent>

            <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                <Btn
                    variant="outlined"
                    text="Cancelar"
                    color="error"
                    onClick={handleClose}
                    sx={{
                        height: '39px',
                        borderRadius: '20px'
                    }}
                />

                <Btn
                    variant="outlined"
                    text="Aceptar"
                    color="primary"
                    onClick={() => agree()}
                    sx={{
                        height: '39px',
                        borderRadius: '20px',
                        marginLeft: '30px !important'
                    }}
                />

            </DialogActions>

        </Dialog>
    );
}

export default SignatoryAlert;