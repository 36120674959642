import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styles } from "../../../helpers";
import { Spinner } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { getAllAgreementsStorage } from "../../../store/organization";
import CustomToolBar from "./molecules/CustomToolbar";
import { gridColumns } from "./constants";
import ModalAlert from "../../floats/ModalAlert";

const ManagerAgreements = () => {
    const {
        session: {
            login: { user },
        },
        organizations: {
            agreements,
            loading,
            messageOrganizations,
            statusOrganizations
        },
        contract: {
            tramites: {
                loadingTramits,
                loadingTypeTramits,
                status,
                message
            }
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [textSpinner, setTextSpinner] = React.useState("");
    const [dataGrid, setDataGrid] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});
    const [key, setKey] = useState("");

    const genKey = () => {
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );
    };

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const getData = async () => {

        setTextSpinner("Cargando Convenios...");

        const { token } = user;

        dispatch(getAllAgreementsStorage(token));

    }

    useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: 'warnIcon'
            });
            setShowModal(true);
        } else if (![null, undefined, 'success', ''].includes(statusOrganizations)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: messageOrganizations,
                buttonText: "Cerrar",
                modalIcon: 'warnIcon'
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, statusOrganizations]);

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDataGrid(agreements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreements]);

    const renderList = useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.agreementId}
                            getCellClassName={null}
                            rowHeight={56}
                            sx={styles.gridContainerStyles}
                            localeText={styles.localizedTextsMap}
                            disableSelectionOnClick={true}
                            loading={loading || loadingTramits || loadingTypeTramits}
                            components={{
                                Toolbar: () => (
                                    <CustomToolBar
                                        genKey={genKey}
                                        updateData={() => getData()}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "agreementId", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits]);

    return (
        <div className="content-detail__container" key={key}>
            {(loading || loadingTramits, loadingTypeTramits) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManagerAgreements;
