import React from 'react'
import { ModalContractCreatedAndContractStepsStyles } from '../../index.css';

const Images = ({ src, alt, id, active, nextInactive, prevInactive }) => {

    const styles = ModalContractCreatedAndContractStepsStyles();

    let sliderClass;

    switch (true) {
        case (active === id && id === 0):
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Center} ${styles.slider_Img_firstActive}`;
            break;
        case (active === id):
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Center}`;
            break;
        case (nextInactive === id && id === 1):
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Right} ${styles.slider_Img_nextInactive}`;
            break;
        case (nextInactive === id):
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Right}`;
            break;
        case (prevInactive === id):
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Left}`;
            break;
        default:
            sliderClass = `${styles.slider_Img} ${styles.slider_Img_Hidde}`;
    }

    return (
        <img src={src} alt={alt} className={sliderClass} />
    )
}

export default Images