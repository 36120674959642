import axios from "axios";
import { api } from "../utils/enums";

const uri = api.BASE_URI_INFO_CHECK;
const uriAnfBase = api.BASE_ANF_URI;
const apiKey = process.env.REACT_APP_API_KEY_INFO_CHECK;


export const updateSignerStatus = async (data, contractID, signerID, token) => {

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${uriAnfBase}/api/updateLogin/${contractID}/${signerID}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        data: JSON.stringify(data)
    };

    return axios.request(config);

}

// eslint-disable-next-line
export const checkClvUnicaFV = async (data, contractID, signerID, token) => {

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://claveunica.firmavirtual.com/claveUnica',
        // url: `${uriAnfBase}/api/v1/contract/login/claveunica/${contractID}/${signerID}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        data: JSON.stringify(data)
    };

    return axios.request(config);

}

export const checkClvUnica = async (data) => {

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${uri}/partner/validacion/clave_unica`,
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
    };

    return axios.request(config);

    /*try {
        const response = await axios.post(
            `${uri}/partner/validacion/clave_unica`,
            JSON.stringify(data),
            {
                headers: {
                    'x-api-key': apiKey,
                    'Content-Type': 'application/json'
                },
            }
        );

        return response;
    } catch (error) {
        return error;
    }*/
};

/* export const getAllNotarys = async (userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_ALL_NOTARYS}`,
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
}; */
