import { Box } from "@mui/material";
import StatusHook from "../../hooks/StatusHook";

const RenderStatus = ({ status, paymentStatus }) => {
    const restStatus = StatusHook(status, paymentStatus);

    return (
        <Box className={restStatus !== undefined ? restStatus.class : null} sx={{
            padding: {
                xs: '2rem 1rem !important',
                lg: '0 !important'
            }
        }}>
            {restStatus !== undefined ? restStatus.text : ""}
        </Box>
    );
};

export default RenderStatus;
