import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { ReactComponent as IconWSClosed } from '../../assets/icons/whatsApp-icon-green.svg';

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
//import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { styled } from "@mui/material";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    '& .css-15074u6-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab': {
        color: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        opacity: '.7',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: '1'
        }
    },
    '& .css-kt22eg': {
        color: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        opacity: '.7',
        transition: 'opacity 0.3s ease',
        '&:hover': {
            opacity: '1'
        }
    }
}));

const actions = [
    /* {
        name: "Comercial",
        icon: <ContactPhoneIcon />,
        link: "https://api.whatsapp.com/send/?phone=56957105453&text&type=phone_number&app_absent=0",
    }, */
    {
        name: "Soporte",
        icon: <SupportAgentIcon />,
        link: "https://api.whatsapp.com/send/?phone=56975635393&text&type=phone_number&app_absent=0",
    },
];

const WhatsappIconSpeedDial = ({ screen }) => {
    const [open, setOpen] = useState(false);

    const [mediaQuery, setMediaQuery] = useState({});

    useEffect(() => {

        let css = {
            position: "fixed",
            left: "2vw",
            display: "flex"
        };

        if (screen === "sm") {
            css = Object.assign(css, { bottom: "35px" });
        } else if (screen === "xs") {
            css = Object.assign(css, { bottom: "40px" });
        } else if (screen === "md") {
            css = Object.assign(css, { bottom: "25px" });
        } else {
            css = Object.assign(css, { bottom: "30px" });
        }

        setMediaQuery(css);

    }, [screen]);

    return (
        <StyledSpeedDial
            ariaLabel="SpeedDial tooltip example"
            direction="up"
            sx={mediaQuery}
            //color="#dee0df"
            icon={<IconWSClosed style={{ fontSize: 28 }} />}
            onClick={() => setOpen((open) => !open)}
            open={open}
            openIcon={<FaWhatsapp style={{ fontSize: 28 }} />}
        /* FabProps={{
            sx: {
                bgcolor: "success.main",
                "&:hover": {
                    bgcolor: "success.main",
                },
            },
        }} */
        >
            {actions.map((action) => (
                <SpeedDialAction
                    tooltipPlacement="right"
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => window.open(action.link, "_blank")}
                />
            ))}
        </StyledSpeedDial>
    );
};

export default WhatsappIconSpeedDial;
