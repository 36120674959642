import { createStyles, makeStyles } from "@mui/styles";

export const InfoMessageStyles = makeStyles(() => {
    return createStyles({
        boxContainer: {
            display: "flex",
            alignItems: "center",
            borderRadius: "0px 0.438rem 0.438rem 0px",
            margin: '1rem 0',
            '& *': {
                padding: '.25rem',
            }
        }
    })
});