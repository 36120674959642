import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const ManagerAgreementsStyles = makeStyles((theme) => {

    return createStyles({
        titleInputAgreementStep: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'

        },
        titleBoxStepThree: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'

        },
        boxIndetify: {
            display: 'flex',
            flexDirection: ' !important',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
            marginTop: '3px !important',
            paddingRight: '10px',
            zoom: 1.5,
            '& svg': {
            }

        },
        stepsperContent: {
            display: 'flex',
            flexDirection: 'column !important',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            // paddingLeft: '20px',
            // paddingRight: '20px',
            // height: '100vh'
        },
        buttomBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'flex-start',
            color: 'white !important',
            '& button': {
                fontWeight: '900 !important',
                fontSize: '1.2rem !important',
                padding: '8px 22px',
                color: 'white',
                backgroundColor: 'rgb(245, 162, 38)',
                borderWidth: '0px !important',
                borderRadius: '5px',
                zoom: '1.1',
                '& span': {
                    color: 'white !important'
                }
            },
            '& svg': {
                marginBottom: '9px'
            },
            '& #textPulsar': {
                marginLeft: '10px',

                '& strong': {
                    fontWeight: '500'
                }
            }
        },
        formBox: {
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                margin: theme.spacing(1)
            }
        },
        stepBox: {
            width: "100%",
            "& > *": {
                margin: "4rem auto 0rem"
            }
        }
    });

});