import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";
//import { store, persistor } from "../storeConfig";
//import { path, apiMessage } from "../../utils/enums";

const slice = createSlice({
    name: 'couponsType',

    initialState: {
        allCouponsTypes: '',
        couponType: '',
        loading: false,
        message: {},
        status: ''
    },

    reducers: {
        PURGE_COUPONSTYPE_STATE: (state, action) => {
            state.allCouponsTypes = '';
            state.couponType = '';
            state.loading = false;
            state.message = {};
            state.status = ''
        },

        //create
        COUPONTYPE_CREATE_REQUESTED: (state, action) => {
            //state.couponType = '';
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONTYPE_CREATE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.couponType = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        //update
        COUPONTYPE_UPDATE_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONTYPE_UPDATE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.couponType = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        //get One
        COUPONTYPE_GETONE_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONTYPE_GETONE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.couponType = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        //get All
        COUPONTYPE_GETALL_REQUESTED: (state, action) => {
            state.allCouponsTypes = '';
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONTYPE_GETALL_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.allCouponsTypes = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        //request fail
        COUPONTYPE_REQUESTED_FAILED: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.status = action.payload.status
        },

        //edit state info
        COUPONTYPE_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },
    }
});

export const {
    PURGE_COUPONSTYPE_STATE,
    COUPONTYPE_CREATE_REQUESTED,
    COUPONTYPE_CREATE_REQUESTED_SUCCESS,
    COUPONTYPE_UPDATE_REQUESTED,
    COUPONTYPE_UPDATE_REQUESTED_SUCCESS,
    COUPONTYPE_GETONE_REQUESTED,
    COUPONTYPE_GETONE_REQUESTED_SUCCESS,
    COUPONTYPE_GETALL_REQUESTED,
    COUPONTYPE_GETALL_REQUESTED_SUCCESS,
    COUPONTYPE_REQUESTED_FAILED,
    COUPONTYPE_SAVE_INFO

} = slice.actions;

export const cleanCouponsTypeForm = () => {
    return { type: PURGE_COUPONSTYPE_STATE.type };
};

export const couponsTypeSaveInfo = (data) => {
    return { type: COUPONTYPE_SAVE_INFO, payload: data }
}

export const createCouponType = (data, token) => {
    return apiCallStart({
        url: `/api/v2/coupon-type/create`,
        method: 'POST',
        data,
        onStart: COUPONTYPE_CREATE_REQUESTED.type,
        onSuccess: COUPONTYPE_CREATE_REQUESTED_SUCCESS.type,
        onError: COUPONTYPE_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const updateCouponType = (data, token) => {
    return apiCallStart({
        url: `/api/v2/coupon-type/update`,
        method: 'POST',
        data,
        onStart: COUPONTYPE_UPDATE_REQUESTED.type,
        onSuccess: COUPONTYPE_CREATE_REQUESTED_SUCCESS.type,
        onError: COUPONTYPE_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const getCouponType = (id, token) => {
    return apiCallStart({
        url: `/api/v2/coupon-type/getone/${id}`,
        method: 'GET',
        onStart: COUPONTYPE_GETONE_REQUESTED.type,
        onSuccess: COUPONTYPE_GETONE_REQUESTED_SUCCESS.type,
        onError: COUPONTYPE_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const getAllCouponsType = (token) => {
    return apiCallStart({
        url: `/api/v2/coupon-type/getall`,
        method: 'GET',
        onStart: COUPONTYPE_GETALL_REQUESTED.type,
        onSuccess: COUPONTYPE_GETALL_REQUESTED_SUCCESS.type,
        onError: COUPONTYPE_REQUESTED_FAILED.type,
        authorization: token
    });
};

export default slice.reducer;