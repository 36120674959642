import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Container, Typography } from '@mui/material'
import { Grid } from '@material-ui/core';

import img1 from "../../assets/img/img_paymenNotification.png";
import img2 from "../../assets/img/img_receipt.png";
import img3 from "../../assets/img/img_signatoryDocument.png";
import img4 from "../../assets/img/img_userMailSign.png";
import img5 from "../../assets/img/img_signedDocument.png";
import img6 from "../../assets/img/img_documentReview.png";
import img7 from "../../assets/img/img_userIDVeirfyMail.svg";
import img8 from "../../assets/img/img_claveUnica.png";
import img9 from "../../assets/img/img_userID.jpeg";
import img10 from "../../assets/img/img_userPhoto.jpeg";

import { ContractsCreatedSuccessStyles } from './index.css';
import { ReactComponent as SuccessLogo } from '../../assets/icons/check-icon-circular.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/icon-info-outlined.svg';
import { getDetailTramit as getDetalExpress } from '../../store/formExpressTramit';
import { getDetailTramit as getDetalANF } from '../../store/formANFTramit';
import ImgSlider from './molecules/imgSlider/ImgSlider';
import { to } from '../../utils/enums';
import { getContractDetail } from '../../store/contract/contractDetail';

const ContractsCreatedSuccess = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { token } = useSelector(state => state.session.login.user);
    const expressID = useSelector(state => state?.formExpressTramit?.detailContract?.contrato?.[0]?.autoId);
    const anfID = useSelector(state => state?.formANFTramit?.detailContract?.contrato?.numberID);
    const approversID = useSelector(state => state?.contract?.contractCreator?.contractCreatedDetails?.contrato?.[0].autoId);
    const styles = ContractsCreatedSuccessStyles();
    const navigate = useNavigate();
    const [contractID, setContractID] = useState('');

    const modalInfo = {
        express: {
            images: [
                { src: img1, alt: "imagen-paso1-tramiteExpress-notificacionDePago" },
                { src: img2, alt: "imagen-paso2-tramiteExpress-reciboDePago" },
                { src: img3, alt: "imagen-paso3-tramiteExpress-notificacionFirmaDelDocumento" },
                { src: img4, alt: "imagen-paso4-tramiteExpress-firmaDocumento" },
                { src: img5, alt: "imagen-paso5-tramiteExpress-documentoFirmado" }
            ],
            text: [
                "Los participantes asignados como pagadores, recibirán un correo con el botón de pago.",
                "Tras el pago, el participante recibirá un correo con su Boleta o Factura.",
                "Al estar pagado 100% el servicio, los participantes asignados como firmantes recibirán un correo con el enlace de firma.",
                "Al hacer clic en el enlace de firma del correo, accederá a la plataforma para firmar en las áreas indicadas.",
                "Al firmar, recibirá correo de confimación. Trámites con notaria: todos deben firmar para recibir el documento notariado."
            ],
            stepTitle: [
                "Realizar pago",
                "Documento tributario",
                "Documento para firmar",
                "Firma en plataforma",
                "Confirmación y entrega",
            ]
        },
        anf: {
            images: [
                { src: img7, alt: "imagen-paso1-tramiteANF-notificacionValidacionDeIdentidad" },
                { src: img8, alt: "imagen-paso2-tramiteANF-paginaClaveUnica" },
                { src: img9, alt: "imagen-paso3-tramiteANF-subirFotosDelCarnet" },
                { src: img10, alt: "imagen-paso4-tramiteANF-subirVideoDeAutorizacion" },
                { src: img3, alt: "imagen-paso5-tramiteANF-NofitifacionDePago" }
            ],
            text: [
                "Recibirá un correo electrónico para iniciar el proceso de validación en la aplicación web.",
                "Inicie sesión con su Clave Única.",
                "Suba su documento de identidad en la aplicación.",
                "Grabe un video verificando su identidad.",
                "Recibirá un correo con el enlace para firmar una vez que todos los participantes hayan validado su identidad"
            ],
            stepTitle: [
                "Enlace para validación",
                "Inicio de sesión",
                "Documento de identidad",
                "Verificación en Video",
                "Enlace para Firma",
            ]
        },
        approvers: {
            images: [
                { src: img6, alt: "imagen-paso1-tramiteAprobadores-revisionDocumento" },
                { src: img1, alt: "imagen-paso2-tramiteAprobadores-notificacionDePago" },
                { src: img2, alt: "imagen-paso3-tramiteAprobadores-reciboDePago" },
                { src: img3, alt: "imagen-paso4-tramiteAprobadores-notificacionFirmaDocumento" },
                { src: img5, alt: "imagen-paso5-tramiteAprobadores-documentoFirmadoSatisfactoriamente" }
            ],
            text: [
                "Descargar y revisar el documento para aprobarlo.",
                "Luego de aprobado, llegará el botón de pago.",
                "Podrá descargar el documento tributario.",
                "El documento estará listo para firmar.",
                "Confirmación de firma y espera de documento."
            ],
            stepTitle: [
                "Revisión de documento",
                "Realizar pago",
                "Documento tributario",
                "Documento para firmar",
                "Confirmación y entrega",
            ]
        }
    };

    const getContractId = () => {

        if (location.state.tramitType === 'express') {
            setContractID(expressID);
        };

        if (location.state.tramitType === 'anf') {
            setContractID(anfID);
        };

        if (location.state.tramitType === 'approvers') {
            setContractID(approversID);
        };

    };

    const navegateBack = () => {

        if (['express', 'anf'].includes(location.state.tramitType)) {
            window.history.back();
        };

        if (location.state.tramitType === 'approvers') {
            navigate(to.CONTRACT_CREATION)
        };

    };

    const navegateNext = () => {

        if (location.state.tramitType === 'express') {
            navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                state: { sContractID: location.state.sContractID },
            });
        };

        if (location.state.tramitType === 'anf') {
            navigate(to.ANF_DETAIL, {
                state: { sContractID: location.state.sContractID },
            });
        };

        if (location.state.tramitType === 'approvers') {
            navigate(to.CONTRACT_DETAIL, {
                state: { sContractID: location.state.sContractID },
            });
        };

    };


    useEffect(() => {
        console.log(location.state, modalInfo[location.state.tramitType])
        if (location.state.tramitType === 'express') {
            dispatch(getDetalExpress(token, location.state.sContractID));
        };

        if (location.state.tramitType === 'anf') {
            dispatch(getDetalANF(token, location.state.sContractID));
        };

        if (location.state.tramitType === 'approvers') {
            dispatch(getContractDetail(location.state.sContractID, token));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state.tramitType]);

    useEffect(() => {
        getContractId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expressID, anfID, approversID])

    return (
        <Container>
            <Grid className={styles.container}>
                <Box className={styles.header}>
                    <SuccessLogo style={{ width: '3.467rem', height: '3.467rem' }} />
                    <Box className={styles.headerText}>
                        <Typography>Trámite creado con AP: {contractID || 'Cargando'}</Typography>
                    </Box>
                </Box>

                <Box className={styles.infoBox}>
                    <IconInfo style={{ width: '4rem', height: '2rem', margin: '0 .8rem 0 0' }} />
                    <Box >
                        <Typography>
                            Nota: Para nuestros clientes con un convenio mensual, la notificación de pago
                            no será enviada a sus participantes; en su lugar, pasará directamente a firma.
                        </Typography>
                    </Box>
                </Box>

                <Box className={styles.bodyText_Box}>
                    <Typography>
                        Pasos de validación y notificación de documento en correo electrónico:
                    </Typography>
                </Box>

                <Box className={styles.sliderContent}>
                    <ImgSlider
                        images={modalInfo[location.state.tramitType].images}
                        stepsNumbers={modalInfo[location.state.tramitType].text.length}
                        text={modalInfo[location.state.tramitType].text}
                        stepsTitle={modalInfo[location.state.tramitType].stepTitle}
                    />
                </Box>
            </Grid>

            <Grid className={styles.buttonsBox}>
                <button
                    className={styles.button_back}
                    onClick={navegateBack}
                    disableRipple
                >
                    Volver
                </button>

                <button className={styles.button_next} onClick={navegateNext}>
                    Siguiente
                </button>
            </Grid>
        </Container>
    )
}

export default ContractsCreatedSuccess