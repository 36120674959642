import { forwardRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import Tooltip from '@mui/material/Tooltip';
import { IoIosCopy } from "react-icons/io";
import IconButton from '@mui/material/IconButton';
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import { api } from "../../../../../utils/enums";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContractDetailStyles = makeStyles(() => {
    return createStyles({
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        muiAlert: {
            width: "100%",
            /* '& svg, div': {
                color: 'white !important',
            } */
        }
    });
});

const PagarTramite = ({ contractDetail, member }) => {
    const styles = ContractDetailStyles();
    const url = `${api.BASE_URI}/webpay/create/${member["token_payment"]}/${contractDetail[0]["sContractID"]}`;
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        contractDetail[0]['sStatus'] === "APPROVED" && contractDetail[0]['withDocument'] === 1 && ['PENDING', 'PARTIALLY_PAID'].includes(contractDetail[0]['sPaymentStatus']) && member['payment'] === "PENDING" && (
            <>
                <Tooltip title="Copiar link de pago." placement="top">

                    <IconButton onClick={handleClick}>

                        <CopyToClipboard text={url}>

                            <IoIosCopy className="icon--xxs icon--blue-light" />

                        </CopyToClipboard>

                    </IconButton>

                </Tooltip>

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                    }}
                >
                    <Alert
                        className={styles.muiAlert}
                        onClose={handleClose}
                        severity="success"
                    >
                        El link de pago ha sido copiado, puedes enviárselo a
                        el firmante.
                    </Alert>
                </Snackbar>

                <a
                    style={{
                        padding: "5px 10px",
                        background: "#00adea",
                        borderRadius: "11px",
                        color: "white",
                        fontSize: "12px",
                    }}
                    href={url}
                    rel="noreferrer noopener"
                    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium css-1yxmbwk"
                    target="_blank"
                >
                    PAGAR
                </a>
            </>
        )
    );
}

export default PagarTramite;