import { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

import { ReactComponent as IconsErrorVideo } from "../../icons/icons-error-video.svg";

import Btn from "../../../../buttons/Button";
import { SignAuthorizationStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorAlert = ({ errors, title, msn, showIcon, onClose }) => {
    const styles = SignAuthorizationStyles();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        dispatch(
            saveTramitANFInfo({
                indexe: "statusMergeDoc",
                value: null,
            })
        );

        setOpen(false);

        if (!verifiVariable(onClose)) onClose();
    };

    useEffect(() => {
        if (errors > 0) handleClickOpen();
    }, [errors]);

    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            className={styles.mainContainerDialog}
        >
            <DialogContent>
                {!verifiVariable(showIcon) && <IconsErrorVideo />}

                {verifiVariable(title) && (
                    <Typography variant="h6" gutterBottom>
                        Ha ocurrido un error, por favor grabar video nuevamente
                    </Typography>
                )}

                {!verifiVariable(title) && (
                    <Typography
                        variant="h6"
                        gutterBottom
                        color={["secondary.main"]}
                    >
                        {title}
                    </Typography>
                )}

                {verifiVariable(msn) && (
                    <Typography variant="subtitle1" gutterBottom>
                        Intente nuevamente y si el problema persiste,
                        comuníquese con nuestro{" "}
                        <span> Servicio de Atención al Cliente </span>
                    </Typography>
                )}

                {!verifiVariable(msn) && (
                    msn.map(message => (
                        <DialogContentText key={message}>{message}</DialogContentText>
                    ))
                )}
            </DialogContent>

            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    paddingBottom: "20px"
                }}
            >
                <Btn
                    variant="outlined"
                    text="OK"
                    color="primary"
                    onClick={handleClose}
                    sx={{
                        height: "39px",
                        borderRadius: "20px",
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ErrorAlert;
