const ContractType = () => {
    const typeSignature = (type, tramList) => {
        const signature = tramList.filter(item => item.iContractTypeFeeID === type)[0];

        return `${procedureType(signature.sContractType)} - ${signature.sContractTypeName} - CANT. FIRMANTES ${signature.iSignCount} - ${signatureType(signature.sModal)}`;
    }

    const signatureType = (signature) => {
        switch (signature) {
            case "SIGNATURE_PROT_NOTARIAL":
                return "Firma con Protocolización";

            case "SIGNATURE_CERT_NOTARIAL":
                return "Firma certificada";

            default:
                return "Firma Simple";
        }
    };

    const procedureType = (procedure) => {
        switch (procedure) {
            case "LABOR":
                return "Laboral";

            case "PERSONAL":
                return "Personal";

            case "POWER":
                return "Poder";

            case "COMPANY":
                return "Compañia";

            case "SOCIETY":
                return "Social";

            case "VEHICLE":
                return "Vehiculo";

            default:
                return "Inmobiliario";
        }
    };

    return {
        typeSignature,
        signatureType,
        procedureType
    }
}

export default ContractType;