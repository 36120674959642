import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";
import { apiCallStart as apiExpressCallStart } from "../actions/apiExpress";
import { apiCallStart as apiAnfCallStart } from "../actions/apiAnf";
import { apiCallStart as apiCallStartMerge } from "../actions/apiMerge";

const userToken = process.env.REACT_APP_USUARIO_DEMO;
const clvToken = process.env.REACT_APP_PASSWORD_DEMO;

const slice = createSlice({
    name: "formANFTramit",
    initialState: {
        agrement: null,
        allAnfTramit: [],
        attachedCDV: null,
        code: null,
        contractName: '',
        detailContract: { firmantes: null, owner: null, contrato: null, archivos: null },
        detailContractFromExpress: {},
        dniInfo: [],
        document: null,
        editSigner: undefined,
        fileName: null,
        firms: {},
        formParticipants: null,
        loading: false,
        message: null,
        participants: [],
        requestTramit: undefined,
        restClvUnica: null,
        screenshot: null,
        signatureType: null,
        signatureTypeList: [],
        status: null,
        statusMergeDoc: null,
        token: null,
        tramitANFCreated: null,
        uploadProgress: 0
    },
    reducers: {
        TRAMIT_ANF_DETAIL_FROM_EXPRESS_SUCCESS: (state, action) => {
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.detailContractFromExpress = action.payload["message"];
            state.loading = false;
        },

        TRAMIT_ALL_ANF_SUCCESS: (state, action) => {
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.allAnfTramit = action.payload["data"]["contracts"];
            state.loading = false;
        },

        TRAMIT_ANF_DETAIL_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.detailContract = { ...action.payload["data"] };
            state.loading = false;
        },

        TRAMIT_ANF_CLEANED_STATUS: (state, action) => {
            state.status = null;
        },

        TRAMIT_ANF_CLEANED_DETAIL_CONTRACT: (state, action) => {
            state.detailContract = {};
            state.detailContractFromExpress = {};
        },

        TRAMIT_ANF_MERGE_SUCCESS: (state, action) => {
            state.loading = false;
            state.uploadProgress = 0;

            if (action.payload["status"] === "fail") {
                state.message = null;
                state.statusMergeDoc = action.payload["status"];
            } else {
                state.document =
                    "data:application/pdf;base64," +
                    action.payload["data"]["file"];
                state.message = null;
                state.statusMergeDoc = null;
            }
        },

        TRAMIT_ANF_UPLOAD_PROGRESS: (state, action) => {
            state.uploadProgress = Math.floor(action.payload);
        },

        TRAMIT_ANF_CLEAR_VERIFY_CLV_UNICA: (state, action) => {
            state.restClvUnica = null;
            state.dniInfo = [];
            state.token = null;
            state.screenshot = null;
        },

        TRAMIT_ANF_SAVE_INFO: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },

        TRAMIT_ANF_PURGE_STATE: (state, action) => {
            state.signatureType = null;
            state.signatureTypeList = [];
            state.participants = [];
            state.document = null;
            state.editSigner = null;
            state.fileName = null;
            state.requestTramit = null;
            state.status = null;
            state.statusMergeDoc = null;
            state.code = null;
            state.message = null;
            state.loading = false;
            state.detailContract = {};
            state.detailContractFromExpress = {};
            state.allAnfTramit = [];
            state.token = null;
            state.agrement = null;
            state.formParticipants = null;
            state.attachedCDV = null;
            state.contractName = ''
        },

        TRAMIT_ANF_REQUEST: (state, action) => {
            state.uploadProgress = 0;
            state.loading = true;
            state.status = null;
            state.code = null;
            state.statusMergeDoc = null;
        },

        TRAMIT_ANF_GET_TOKEN_SUCCESS: (state, action) => {
            state.token = action.payload["message"]["Authorization"];
        },

        TRAMIT_ANF_UPLOAD_VIDEO_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.message = action.payload["message"];
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.loading = false;
        },

        TRAMIT_ANF_CREATE_SUCCESS: (state, action) => {
            state.attachedCDV = null;
            state.code = action.payload["code"];
            state.firms = {};
            state.loading = false;
            state.message = action.payload["data"]["contract"];
            state.status = action.payload["status"];
            state.tramitANFCreated = true
        },

        TRAMIT_ANF_DELETE_SUCCESS: (state, action) => {
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.loading = false;
            state.message = null;
        },

        TRAMIT_ANF_FAIL: (state, action) => {
            state.uploadProgress = 0;
            state.message = action.payload["error"];
            state.status = action.payload["status"] || 'fail';
            state.code = action.payload["code"] || '400';
            state.loading = false;
        },

        TRAMIT_ANF_DEMO: (state, action) => { },
    },
});

export const {
    TRAMIT_ANF_DELETE_SUCCESS,
    TRAMIT_ANF_DETAIL_FROM_EXPRESS_SUCCESS,
    TRAMIT_ANF_DEMO,
    TRAMIT_ANF_SAVE_INFO,
    TRAMIT_ANF_PURGE_STATE,
    TRAMIT_ANF_REQUEST,
    TRAMIT_ANF_CREATE_SUCCESS,
    TRAMIT_ANF_UPLOAD_VIDEO_SUCCESS,
    TRAMIT_ANF_GET_TOKEN_SUCCESS,
    TRAMIT_ALL_ANF_SUCCESS,
    TRAMIT_ANF_UPLOAD_PROGRESS,
    TRAMIT_ANF_FAIL,
    TRAMIT_ANF_CLEAR_VERIFY_CLV_UNICA,
    TRAMIT_ANF_MERGE_SUCCESS,
    TRAMIT_ANF_CLEANED_DETAIL_CONTRACT,
    TRAMIT_ANF_CLEANED_STATUS,
    TRAMIT_ANF_DETAIL_SUCCESS,
} = slice.actions;

export const clearStatusAnf = () => {
    return { type: TRAMIT_ANF_CLEANED_STATUS.type, payload: null };
};

export const clearContractsDetail = () => {
    return { type: TRAMIT_ANF_CLEANED_DETAIL_CONTRACT.type, payload: null };
};

export const emailClaveUnica = (data, token) => {
    return apiAnfCallStart({
        url: `/api/signer/sendLinkAuth`,
        method: "POST",
        data,
        onStart: TRAMIT_ANF_DEMO.type,
        onSuccess: TRAMIT_ANF_DEMO.type,
        onError: TRAMIT_ANF_DEMO.type,
        authorization: token,
    });
};

export const getDetailTramitFromExpress = (token, sContractID) => {
    return apiExpressCallStart({
        url: `/api/v1/contract/detail/${sContractID}`,
        method: "GET",
        data: {},
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_DETAIL_FROM_EXPRESS_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const deleteExpressTramit = (token, sContractID) => {
    return apiExpressCallStart({
        url: `/api/contract/${sContractID}`,
        method: "DELETE",
        data: {},
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_DELETE_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const deleteAnfTramit = (token, sContractID) => {
    return apiAnfCallStart({
        url: `/api/contract/${sContractID}`,
        method: "DELETE",
        data: {},
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_DELETE_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const getAllAnfTramit = (token) => {
    return apiAnfCallStart({
        url: `/api/contract/getAll`,
        method: "GET",
        data: {},
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ALL_ANF_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const getDetailTramit = (token, sContractID) => {
    return apiAnfCallStart({
        url: `/api/contract/${sContractID}`,
        method: "GET",
        data: {},
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_DETAIL_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const mergeDoc = (data, token) => {
    return apiCallStartMerge({
        url: `/pdf/repair`,
        method: "POST",
        data,
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_MERGE_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        onUploadProgress: TRAMIT_ANF_UPLOAD_PROGRESS.type,
        authorization: token,
    });
};

export const createANFTramit = (token, data) => {
    return apiAnfCallStart({
        url: `/api/contract`,
        method: "POST",
        data,
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_CREATE_SUCCESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const getToken = () => {
    return apiCallStart({
        url: `/logindata`,
        method: "POST",
        data: {
            login: userToken,
            password: clvToken,
        },
        onStart: TRAMIT_ANF_DEMO.type,
        onSuccess: TRAMIT_ANF_GET_TOKEN_SUCCESS.type,
        onError: TRAMIT_ANF_DEMO.type,
    });
};

export const uploadVideo = (token, data) => {
    return apiAnfCallStart({
        url: `/api/uploadVideo`,
        method: "POST",
        data,
        onStart: TRAMIT_ANF_REQUEST.type,
        onSuccess: TRAMIT_ANF_UPLOAD_VIDEO_SUCCESS.type,
        onUploadProgress: TRAMIT_ANF_UPLOAD_PROGRESS.type,
        onError: TRAMIT_ANF_FAIL.type,
        authorization: token,
    });
};

export const clearResClvUnica = () => {
    return { type: TRAMIT_ANF_CLEAR_VERIFY_CLV_UNICA.type, payload: null };
};

export const saveTramitANFInfo = (data) => {
    return { type: TRAMIT_ANF_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: TRAMIT_ANF_PURGE_STATE.type, payload: null };
};

export default slice.reducer;