import React from "react";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import Text from "../heading/Text";

const Steptracker = (props) => {
    const { current, step, title, subtitle, onClick, cursor } = props;
    const screenSizeHook = ScreenSizeHook();

    const stepNumberStyles = {
        // color: current ? "white.main" : "primary.light",
        color: "white.main",
        fontSize: "1.3rem",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
    };

    const titleStyles = {
        color: current ? "black.main" : "gray.dark",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
        fontSize: screenSizeHook === 'xs' ? '0.8rem' : '15px'
    };

    const subTitleStyles = {
        color: "quinary.dark",
        fontWeight: "300",
        marginBottom: "0",
    };

    const trackItem = {
        paddingTop: '15px',
        width: screenSizeHook === 'xs' ? '15px' : '150px',
        flexDirection: 'column',
        justifyContent: screenSizeHook === 'xs' ? 'start' : 'center',
        alignContent: 'center',
        cursor
    };

    return (
        <div className="procedure-creation__track-item" onClick={onClick} style={trackItem}>
            <div
                className={`procedure-creation-step ${current ? "step--active" : ""
                    }`}
            >
                <Text sx={stepNumberStyles} align="left" mgButton variant="h7">
                    {step}
                </Text>
            </div>
            <div className="procedure-creation-description">
                <Text sx={titleStyles} align="center" mgButton variant="h7">
                    {title}
                </Text>
                <Text sx={subTitleStyles}>{subtitle}</Text>
            </div>
        </div>
    );
};

export default Steptracker;
