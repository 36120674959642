import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import { ManagerAgreementsStyles } from "../../index.css";
import { errorStepFormAgreement } from "../../../../../store/formAgreement";


const StepTwoAgreementName = ({ control, watch, trigger, formState }) => {
    const styles = ManagerAgreementsStyles();
    const dispatch = useDispatch();

    const watchAgreementName = watch('agreementName');
    const watchModalPayment = watch('modalPayment');

    useEffect(() => {
        if ([typeof formState.errors['agreementName'], typeof formState.errors['modalPayment']].includes('object')) {
            dispatch(errorStepFormAgreement(true));
        } else {
            dispatch(errorStepFormAgreement(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        watchAgreementName,
        watchModalPayment
    ]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        2<BiRightArrowAlt color="rgb(0, 79, 159)" />

                    </Grid>

                    <Grid item xs={11} sx={{
                        justifyContent: "center",
                        alignItems: "center"
                    }}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            Datos del convenio
                        </Typography>

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto1} gutterBottom>
                            Nombre de Convenio
                        </Typography>

                        <Controller
                            name="agreementName"
                            control={control}
                            rules={{
                                required:
                                    "El nombre del convenio es requerido",
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <TextField
                                        error={
                                            fieldState.error
                                                ? true
                                                : false
                                        }
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        value={value}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error
                                                    .message
                                                : null
                                        }
                                        placeholder={
                                            "Ingrese el nombre del convenio"
                                        }
                                        color={
                                            fieldState.error
                                                ? "error"
                                                : "success"
                                        }
                                        focused={
                                            fieldState.isTouched ||
                                            fieldState.isDirty
                                        }
                                        fullWidth
                                    />
                                );
                            }}
                        />

                    </Grid>

                </Grid>

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h5" className={styles.cssTexto1} gutterBottom>
                            Metodo de Pago
                        </Typography>

                        <Controller
                            name='modalPayment'
                            control={control}
                            rules={{
                                required: 'Debe Seleccionar al menos un metodo de pago'
                            }}
                            render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                return (
                                    <FormControl fullWidth sx={{ m: 1, width: '100%' }} error={fieldState.error ? true : false}>

                                        <RadioGroup
                                            row
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="AFTER_PAYMENT" control={<Radio />} label="Pago mensual" />
                                            <FormControlLabel value="DEFAULT_PAYMENT" control={<Radio />} label="Pago por contrato" />
                                        </RadioGroup>

                                        <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepTwoAgreementName;