import { forwardRef, Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaWhatsapp } from "react-icons/fa";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BiTrash } from "react-icons/bi";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadOutlined from '@mui/icons-material/CloudDownloadOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';

import { docStatus, typeUser, verifiVariable } from '../../../utils/enums';
import StatusPortion from './molecules/statusPortion';
import PagarTramite from './molecules/pagarTramite';
import ContractMembersSignatureStatus from './hooks/contractMembersSignatureStatus';
import ResendUserContract from './molecules/resendUserContract';
import StatuSign from './hooks/statuSign';
import StatusPayment from './hooks/statusPayment';
import { theme } from '../../../theme/theme';
import { DetailSignatoriesStyles } from './index.css';
import Btn from '../../buttons/Button';
import { aprovedMemberContract, downloadMemberContractDoc, unAprovedMemberContract } from './services';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const buttonxs = {
    fontSize: "0.9rem",
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: "500",
    color: "black.main",
    "&:hover": {
        color: "primary.light",
        backgroundColor: "transparent",
        textDecoration: "underline",
    },
};

const DetailSignatories = ({ member, index, contractDetail, contractMembers, contractFiles, removeSignner, deleteSigner, updateContract }) => {
    const styles = DetailSignatoriesStyles();
    const {
        session: {
            login: {
                userInfo,
                user
            }
        }
    } = useSelector((state) => state);
    const contractMembersSignatureStatus = ContractMembersSignatureStatus(member);
    const statusPayment = StatusPayment(member);
    const statuSign = StatuSign(contractDetail[0].sStatus, member);
    const [statusContract, setStatusContract] = useState(undefined);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [textBox, setTextBox] = useState('');
    const [open, setOpen] = useState(false);

    const checkSuccessAgrrement = () => {
        let success = false;

        if (contractMembers) {
            const memberAgreed = contractMembers.filter(
                (member) => member.agree_draft === docStatus.SUCCESS
            );
            if (memberAgreed.length === contractMembers.length) success = true;
        }

        return success;
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }


    const unAprovedDoc = async () => {

        try {

            setLoading(true);

            const { token } = user;

            const response = await unAprovedMemberContract(member['token_disagree'], token);

            response && setLoading(false);

            updateContract();

        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }

    };

    const aprovedDoc = async () => {

        try {

            setLoading(true);

            const { token } = user;

            const response = await aprovedMemberContract(member['token_agree'], token);

            response && setLoading(false);

            updateContract();

        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }

    };

    const downloadContract = async () => {

        try {

            setLoading(true);

            const { token } = user;

            const response = await downloadMemberContractDoc(member['token_download'], token, contractDetail[0]['sContractID']);

            response && setLoading(false);

            updateContract();

        } catch (error) {
            setLoading(false);
            handleClickOpen();
            console.log(error);
        }

    };


    useEffect(() => {

        if (contractDetail) setStatusContract(contractDetail[0].sStatus);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractDetail]);

    useEffect(() => {

        const splitText = member['full_name'].split(' ');

        let newText = "";

        splitText.forEach((texto, index) => {
            if (index !== 3) newText += `${texto.substring(0, 1).toUpperCase()}`;
        });

        setTextBox(newText);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    return (

        <Fragment>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center', color: 'red' }}>
                    Ah ocurrido un error!
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>
                        Lo sentimos, ah ocurrido un error.
                        Le pedimos disculpas por las molestias causadas y lo invitamos a
                        reportar el error a nuestro equipo de soporte desde el siguiente enlace.

                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0", "_blank");
                                handleClose();
                            }}
                        >
                            <FaWhatsapp className="icon--white" style={{ fontSize: 28, fill: '#5cc858' }} />
                        </IconButton>


                    </DialogContentText>

                </DialogContent>

            </Dialog>


            <Card sx={{ width: 300 }} className={styles.cardContainer}>

                <CardHeader

                    className={styles.cardHeader}
                    title={<div className={styles.statusHeader}>
                        {[contractMembersSignatureStatus, statuSign, statusPayment].map((item) => item)}
                    </div>}
                />

                <CardHeader
                    className={styles.cardHeader}
                    title={
                        <Typography
                            component="span"
                            variant="h5"
                            className='font-color--primary-light'
                        >
                            firmante {index + 1}
                        </Typography>
                    }
                />

                {!checkSuccessAgrrement() && removeSignner && (<CardHeader
                    className={styles.cardHeader}
                    title={
                        <Btn
                            variant="text"
                            onClick={() => deleteSigner()}
                            startIcon={
                                <BiTrash className="icon--xxs" />
                            }
                            text="Eliminar firmante"
                            size="xs"
                            sx={buttonxs}
                        />
                    }
                />)}

                <CardHeader

                    className={styles.cardHeader}

                    avatar={
                        <Avatar sx={{ bgcolor: theme.palette.primary.dark }} aria-label="recipe">
                            {textBox}
                        </Avatar>
                    }

                    action={<StatusPortion member={member} />}

                    title={!verifiVariable(member) ? member['full_name'] : ''}

                    subheader={<ResendUserContract
                        contractMembers={contractMembers}
                        contractFiles={contractFiles}
                        member={member}
                    />}

                />

                <CardActions disableSpacing>

                    {loading && (<CircularProgress />)}

                    {!loading && ['REVIEW', 'CREATED'].includes(statusContract) && userInfo.iProfileID === typeUser.ADMIN && (<Stack direction="row" spacing={1}>

                        {contractDetail[0]['withDocument'] === 1 && (<IconButton onClick={() => downloadContract()}>

                            <Tooltip title="Descargar" arrow>

                                <CloudDownloadOutlined className='icon--grey-dark-light' />

                            </Tooltip>

                        </IconButton>)}

                        {[docStatus.SUCCESS].includes(member['download_draft']) && [docStatus.ACTIVE].includes(member['disagree_draft']) && [docStatus.ACTIVE].includes(member['agree_draft']) && (
                            <Fragment>

                                <IconButton onClick={() => unAprovedDoc()}>

                                    <Tooltip title="Rechazar" arrow>

                                        <CancelOutlinedIcon className='icon--red' />

                                    </Tooltip>

                                </IconButton>

                                <IconButton onClick={() => aprovedDoc()}>

                                    <Tooltip title="Aceptar" arrow>

                                        <CheckCircleOutline className='icon--green' />

                                    </Tooltip>

                                </IconButton>

                            </Fragment>
                        )}

                    </Stack>)}

                    {member["payment"] === "PENDING" && member["portion"] !== "0.00" && (
                        <PagarTramite
                            contractDetail={contractDetail}
                            member={member}
                        />
                    )}

                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />

                    </ExpandMore>

                </CardActions>

                <Collapse in={expanded} timeout="auto" unmountOnExit>

                    <CardContent>

                        <List sx={{ width: '100%', /* maxWidth: 360, */ bgcolor: 'background.paper' }}>

                            <ListItem alignItems="flex-start">

                                <ListItemText
                                    primary="RUT"
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {member['RUT']}
                                            </Typography>

                                            <Divider />

                                        </Fragment>
                                    }
                                />

                            </ListItem>

                            <ListItem alignItems="flex-start">

                                <ListItemText
                                    primary="Correo electrónico"
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {member['email']}
                                            </Typography>

                                            <Divider />

                                        </Fragment>
                                    }
                                />

                            </ListItem>

                            <ListItem alignItems="flex-start">

                                <ListItemText
                                    primary="Número de celular"
                                    secondary={
                                        <Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {member['phone']}
                                            </Typography>

                                            <Divider />

                                        </Fragment>
                                    }
                                />

                            </ListItem>

                        </List>

                    </CardContent>

                </Collapse>

            </Card>


        </Fragment>
    );
}

export default DetailSignatories;