import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";
import { apiMessage, typeUser as typesUser } from "../../utils/enums";
import moment from "moment";

export const urlContractOwner = "/api/v1/contract/owner/";

const slice = createSlice({
    name: "contract",
    initialState: {
        loading: false,
        lastFetch: null,
        applicant: {},
        contract: {},
        contractListReceived: false,
        contractCreated: null,
        contractCreatedDetails: null,
        contractList: [],
        contractListActive: [],
        contractTypeMembers: [],
        esignaturesListReceived: false,
        esignaturesList: [],
        message: "",
        tramitAproversCreated: null,
    },
    reducers: {
        CONTRACT_SAVE_DETAIL_CONTRACT: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },
        PURGE_STATE: (state, action) => {
            state.loading = false;
            state.lastFetch = null;
            state.applicant = {};
            state.contract = {};
            state.contractListReceived = false;
            state.contractCreated = null;
            state.contractList = [];
            state.contractListActive = [];
            state.contractTypeMembers = [];
            state.esignaturesListReceived = false;
            state.esignaturesList = [];
            state.message = "";
        },
        CONTRACT_DETAIL_REQUEST: (state, action) => {
            state.loading = true;
            state.contractCreated = null;
            state.contract = {};
            state.message = "";
        },
        CONTRACT_DETAIL_CLEAR: (state, action) => {
            state.loading = false;
            state.contractCreated = null;
            state.contract = {};
            state.message = "";
        },
        CONTRACT_DETAIL_REQUESTED_FAIL: (state, action) => {
            state.loading = false;
            state.contractCreated = false;
            state.message = "";
        },
        CONTRACT_DETAIL_REQUESTED_RECIVED: (state, action) => {
            state.loading = false;
            state.lastFetch = Date.now();
            state.message = "";
        },
        CONTRACT_DETAIL_SUCCESS: (state, action) => {
            if (action.payload.status === apiMessage.SUCCESS) {
                state.loading = false;
                state.message = "";
                state.contractCreatedDetails = action.payload.message;
            } else {
                state.loading = false;
                state.message = action.payload.message;
            }
        },
        CONTRACT_DETAIL_FAIL: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.contractCreatedDetails = null;
        },
        CONTRACT_CREATION_REQUESTED: (state, action) => {
            state.loading = true;
            state.contractCreated = null;
            state.contract = {};
            state.message = "";
        },
        CONTRACT_CREATION_REQUESTED_FAIL: (state, action) => {
            state.loading = false;
            state.contractCreated = false;
            state.message = "";
        },
        CONTRACT_CREATION_RECIVED: (state, action) => {
            state.loading = false;
            state.lastFetch = Date.now();
            state.message = "";
        },
        CONTRACT_CREATION_SUCCESS: (state, action) => {
            if (action.payload.status === apiMessage.SUCCESS) {
                state.loading = false;
                state.contractCreated = true;
                state.message = "";
                state.contract = {
                    // TODO aqui
                    status: action.payload.status,
                    detail: action.payload.message[0],
                };
                state.tramitAproversCreated = true
            } else {
                state.loading = false;
                state.contractCreated = false;
                state.message = action.payload.message;
            }
        },
        CONTRACT_CREATION_FAIL: (state, action) => {
            state.message = action.payload;
            state.contractCreated = false;
            state.contract = {};
            state.loading = false;
        },
        CONTRACT_CREATION_RESET: (state, action) => {
            state.contractCreated = null;
            state.loading = false;
            state.message = "";
            state.contract = {};
        },
        CONTRACT_INFO_STATE: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },
        CONTRACT_LIST_REQUESTED: (state, action) => {
            state.loading = true;
            state.contractList = [];
            state.message = "";
        },
        CONTRACT_LIST_REQUESTED_FAIL: (state, action) => {
            state.loading = false;
            state.contractListReceived = false;
            state.message = "";
        },
        CONTRACT_LIST_RECIVED: (state, action) => {
            state.loading = false;
            state.contractListReceived = true;
            state.lastFetch = Date.now();
            state.message = "";
        },
        CONTRACT_LIST_SUCCESS: (state, action) => {
            state.loading = false;
            state.contractList = action.payload;
            state.message = "";
        },
        CONTRACT_LIST_FAIL: (state, action) => {
            state.contractListReceived = false;
            state.contractList = [];
            state.message = action.payload;
            state.loading = false;
        },
        CONTRACT_APPLICANT: (state, action) => {
            state.applicant = action.payload;
            state.loading = false;
            state.message = "";
        },
        CONTRACT_MEMBERS: (state, action) => {
            state.contractTypeMembers = action.payload;
            state.loading = false;
            state.message = "";
        },
        CONTRACT_TYPE: (state, action) => {
            state.contract = action.payload;
            state.loading = false;
            state.message = "";
        },
        ESIGNATURE_TYPES_REQUESTED: (state, action) => {
            state.loading = true;
            state.esignaturesList = [];
            state.message = "";
        },
        ESIGNATURE_TYPES_SUCCESS: (state, action) => {
            state.esignaturesList = action.payload;
            state.loading = false;
            state.message = "";
        },
        ESIGNATURE_TYPES_FAIL: (state, action) => {
            state.esignaturesListReceived = false;
            state.esignaturesList = [];
            state.message = action.payload;
            state.loading = false;
        },
        CONTRACT_GET_CREATED_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = "";
        },
        CONTRACT_GET_CREATED_SUCCESS: (state, action) => {
            state.loading = false;
            state.contractListActive = action.payload;
            state.message = "";
        },
        CONTRACT_GET_CREATED_FAIL: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
        CONTRACT_FILES_LIST_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = "";
        },
        CONTRACT_FILES_LIST_REQUESTED_FAIL: (state, action) => {
            state.loading = false;
            state.message = "";
        },
        CONTRACT_FILES_LIST_RECIVED: (state, action) => {
            state.loading = false;
            state.lastFetch = Date.now();
            state.message = "";
        },
        CONTRACT_FILES_LIST_SUCCESS: (state, action) => {
            if (action.payload.status === apiMessage.SUCCESS) {
                state.loading = false;
                state.message = "";
                state.contractCreatedDetails.files = action.payload.message;
            } else {
                state.loading = false;
                state.message = action.payload.message;
            }
        },
        CONTRACT_FILES_LIST_FAIL: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
    },
});

export const {
    PURGE_STATE,
    CONTRACT_SAVE_DETAIL_CONTRACT,
    CONTRACT_GET_CREATED_REQUESTED,
    CONTRACT_GET_CREATED_SUCCESS,
    CONTRACT_GET_CREATED_FAIL,
    CONTRACT_CREATION_REQUESTED,
    CONTRACT_CREATION_REQUESTED_FAIL,
    CONTRACT_CREATION_RECIVED,
    CONTRACT_CREATION_SUCCESS,
    CONTRACT_CREATION_FAIL,
    CONTRACT_CREATION_RESET,
    CONTRACT_LIST_REQUESTED,
    CONTRACT_LIST_REQUESTED_FAIL,
    CONTRACT_LIST_RECIVED,
    CONTRACT_LIST_SUCCESS,
    CONTRACT_LIST_FAIL,
    CONTRACT_APPLICANT,
    CONTRACT_MEMBERS,
    CONTRACT_TYPE,
    ESIGNATURE_TYPES_REQUESTED,
    ESIGNATURE_TYPES_SUCCESS,
    ESIGNATURE_TYPES_FAIL,
    CONTRACT_DETAIL_REQUEST,
    CONTRACT_DETAIL_REQUESTED_FAIL,
    CONTRACT_DETAIL_REQUESTED_RECIVED,
    CONTRACT_DETAIL_SUCCESS,
    CONTRACT_DETAIL_FAIL,
    CONTRACT_INFO_STATE,
    CONTRACT_FILES_LIST_REQUESTED,
    CONTRACT_FILES_LIST_REQUESTED_FAIL,
    CONTRACT_FILES_LIST_RECIVED,
    CONTRACT_FILES_LIST_SUCCESS,
    CONTRACT_FILES_LIST_FAIL,
    CONTRACT_DETAIL_CLEAR,
} = slice.actions;

export const clearCreateContract = () => {
    return { type: CONTRACT_DETAIL_CLEAR.type, payload: undefined };
};

export const addApplicant = (applicant) => {
    return { type: CONTRACT_APPLICANT.type, payload: applicant };
};

export const addMemeber = (member) => {
    return { type: CONTRACT_MEMBERS.type, payload: member };
};

export const addContract = (contract) => {
    return { type: CONTRACT_TYPE.type, payload: contract };
};

export const resetContract = () => {
    return { type: CONTRACT_CREATION_RESET.type };
};

export const saveContractInfo = (data) => {
    return { type: CONTRACT_INFO_STATE.type, payload: data }
}

export const getContractDetail = (contractID, token) => {
    return apiCallStart({
        url: `/api/v1/contract/detail/${contractID}`,
        method: "GET",
        onStart: CONTRACT_DETAIL_REQUEST.type,
        onSuccess: CONTRACT_DETAIL_SUCCESS.type,
        onError: CONTRACT_DETAIL_FAIL.type,
        authorization: token,
    });
};

export const getContractsTypes = (token) => {
    return apiCallStart({
        url: "/api/v1/contract-type/all/0",
        method: "GET",
        onStart: CONTRACT_LIST_REQUESTED.type,
        onSuccess: CONTRACT_LIST_SUCCESS.type,
        onError: CONTRACT_LIST_FAIL.type,
        authorization: token,
    });
};

export const getESignatureTypes = (token, contractID, counter) => {
    return apiCallStart({
        url: `/api/v1/signers/${contractID}/${counter}`,
        method: "GET",
        onStart: ESIGNATURE_TYPES_REQUESTED.type,
        onSuccess: ESIGNATURE_TYPES_SUCCESS.type,
        onError: ESIGNATURE_TYPES_FAIL.type,
        authorization: token,
    });
};

export const createContract = (contract, token, agrementId) => {
    if (contract !== undefined && token !== undefined) {
        return apiCallStart({
            url:
                agrementId === false
                    ? "/api/v1/contract/create-contract-role"
                    : `/api/v1/contract/create-contract-role/${agrementId}`,
            method: "POST",
            data: contract,
            onStart: CONTRACT_CREATION_REQUESTED.type,
            onSuccess: CONTRACT_CREATION_SUCCESS.type,
            onError: CONTRACT_CREATION_FAIL.type,
            authorization: token,
        });
    }
};

export const getContractsCreated = (userID, token, typeUser) => {
    return apiCallStart({
        url:
            typeUser === typesUser.ADMIN
                ? "/api/v1/contract"
                : `${urlContractOwner}${userID}`,
        method: "GET",
        onStart: CONTRACT_GET_CREATED_REQUESTED.type,
        onSuccess: CONTRACT_GET_CREATED_SUCCESS.type,
        onError: CONTRACT_GET_CREATED_FAIL.type,
        authorization: token,
    });
};

export const getContractFilesList = (contractID, token) => {
    return apiCallStart({
        url: `/api/v1/filelist/${contractID}`,
        method: "GET",
        onStart: CONTRACT_FILES_LIST_REQUESTED.type,
        onSuccess: CONTRACT_FILES_LIST_SUCCESS.type,
        onError: CONTRACT_FILES_LIST_FAIL.type,
        authorization: token,
    });
};

export const loadContractList = () => (dispatch, getState) => {
    const { lastFetch } = getState().contract.contract_creactor;

    const diffMinutes = moment().diff(moment(lastFetch), "minutes");

    if (diffMinutes < 10) return;
    dispatch(
        apiCallStart({
            url: "/api/v1/contract",
            onStart: CONTRACT_LIST_REQUESTED.type,
            onSuccess: CONTRACT_LIST_RECIVED.type,
            onError: CONTRACT_LIST_REQUESTED_FAIL.type,
        })
    );
};

export const saveDetailsContract = (data) => {
    return { type: CONTRACT_SAVE_DETAIL_CONTRACT.type, payload: data };
};

export default slice.reducer;
