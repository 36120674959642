import React from "react";
import { BiDownload } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { styles } from "../../../../../helpers";
import Btn from "../../../../buttons/Button";
import Text from "../../../../heading/Text";

const CustomToolbar = ({ updateData }) => {

    const cvsExportFields = ["id", "sTipoContrato", "iSignCount", "sUserName"];

    return (
        <div className="contracts-list__top-menu">
            <div className="title-menu__status">
                <Text sx={styles.titleStyless} variant="h6">
                    Historial de pagos
                </Text>
                <Text sx={styles.subTitleStyles}>
                    Resumen de historial de pagos
                </Text>
            </div>
            <GridToolbarContainer className="buttons-menu__status">
                <GridToolbarFilterButton
                    title="Filtros"
                    // translate={true}
                    lang="es"
                    arrow={false}
                    className="grid-toolbar"
                    sx={styles.buttonGridStatusMenu}
                />
                <GridToolbarExport
                    title="Descargar"
                    // translate={true}
                    lang="es"
                    sx={styles.buttonGridStatusMenu}
                    startIcon={
                        <BiDownload className="icon--xs icon--blue-light" />
                    }
                    csvOptions={{
                        allColumns: true,
                        fields: cvsExportFields,
                    }}
                />
                <Btn
                    variant="contained"
                    text="Actualizar"
                    sx={styles.buttonStatusMenu}
                    onClick={updateData}
                    startIcon={
                        <AiOutlineReload className="icon--xs icon--blue-light" />
                    }
                />
            </GridToolbarContainer>
        </div>
    );
};

export default CustomToolbar;
