import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import * as PdfjsLib from "pdfjs-dist";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import { ExpressProcessStyles } from "../index.css";
import AddDocuments from "../molecules/addDocuments";

import {
    asyncFileToBase64,
    to,
    verifiVariable,
} from "../../../../utils/enums";
import PreviewDocument from "../molecules/previewDocument";
import { saveTramitExpressInfo } from "../../../../store/formExpressTramit";
import Spinner from "../../../spinners/Spinner";
import ErrorAlert from "../molecules/errorAlert";
import { Typography } from "@mui/material";
import UserManuals from "../../../userManuals";
//import AttachedCDV from "../../../attachedCDV";
import ModalContractCreatedAndContractSteps from "../../../modalContractCreatedAndContractSteps";
import { replaceDocument } from "../../../../utils/api";
import { theme } from "../../../../theme/theme";


const ActionsButton = styled(Button)((props) => ({
    width: "47%",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "1rem",
    padding: "9px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    // backgroundColor: '#0063cc',
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        // backgroundColor: '#0069d9',
        backgroundColor: props.theme.palette.primary.xlight,
        // borderColor: '#0062cc',
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
}));

const ReplaceExpressDocument = () => {
    const styles = ExpressProcessStyles();
    const {
        document,
        firms,
        detailContract: { firmantes, contrato }
    } = useSelector((state) => state.formExpressTramit);
    const {
        login: { user },
    } = useSelector((state) => state.session);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [textError, setTextError] = useState("");
    const [titleError, setTitleError] = useState("");
    const [showError, setShowError] = useState(0);
    const [openModalSteps, setOpenModalSteps] = useState(false);

    const ButtonInfo = styled(Button)((props) => ({
        width: "100%",
        fontStyle: "normal",
        boxShadow: "none",
        fontWeight: "bolder",
        textTransform: "none",
        fontSize: "1rem",
        padding: "9px 26px",
        border: "1px solid",
        borderRadius: "7px",
        lineHeight: 1.5,
        fontFamily: ['"PoppinsBold"'].join(","),
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    }));


    const asyncPdfForSignrequest = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if ([null, undefined, ""].includes(document)) return;

                const dataFirms = { ...firms };

                const pdfDoc = await PDFDocument.load(document);

                // Embed the Helvetica font
                const helveticaFont = await pdfDoc.embedFont(
                    StandardFonts.Helvetica
                );

                // Get the first page of the document
                const pages = pdfDoc.getPages();

                Object.values(dataFirms).forEach((item) => {
                    const signedPage = pages[item["page"] - 1];

                    // Draw a string of text diagonally across the first page
                    signedPage.drawText(
                        item["text"],
                        Object.assign(
                            { ...item },
                            {
                                font: helveticaFont,
                                color: rgb(1, 1, 1),
                                y: signedPage.getHeight() - item["y"],
                            }
                        )
                    );
                });

                const pdfBytes = await pdfDoc.save();

                const file = new File([pdfBytes], "document", {
                    type: "application/pdf",
                });

                resolve(file);
            } catch (error) {
                reject(error);
            }
        });
    };


    const handleDataClick = async () => {
        try {

            setTextLoading("Reemplazando documento por favor espere");

            setLoading(true);

            const { token } = user;

            const fileSigReqest = await asyncPdfForSignrequest();

            const fileBase64 = await asyncFileToBase64(fileSigReqest);

            const data = {
                document: {
                    sContractID: contrato[0]['sContractID'],
                    file_content: fileBase64.replace("data:application/pdf;base64,", "")
                }
            }

            const { status, message } = await replaceDocument(token, data);

            if (status === 'success') {

                navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                    state: { sContractID: contrato[0]['sContractID'] },
                });

            } else {
                setTitleError("Problemas al reemplazar el documento.");
                setTextError(JSON.stringify(message));
                setShowError((num) => num + 1);
            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
            setTitleError("Problemas al reemplazar el documento.");
            setTextError(JSON.stringify(error));
            setShowError((num) => num + 1);
        }
    }

    useEffect(() => {

        return () => {
            dispatch(
                saveTramitExpressInfo({
                    indexe: "firms",
                    value: {},
                })
            );

            dispatch(
                saveTramitExpressInfo({
                    indexe: "document",
                    value: null,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        (async () => {

            if (verifiVariable(document)) return;

            const loadPdfDocumentTask = PdfjsLib.getDocument(document);

            const pdf = await loadPdfDocumentTask.promise;

            const searchInPdf = async () => {
                const textMatches = [];
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const textContent = await page.getTextContent();
                    const textItems = textContent.items.map(item => item.str).join(' ');
                    const match = textItems.includes("[[s");
                    if (match) {
                        textMatches.push({
                            page: i,
                            text: textItems
                        });
                    }
                }

                if (textMatches.length > 0) {

                    setTitleError("Error en documento.");
                    setTextError("El documento ya posee firmas asignadas.");
                    setShowError((num) => num + 1);

                    setTimeout(() => {
                        dispatch(
                            saveTramitExpressInfo({
                                indexe: "document",
                                value: null,
                            })
                        );

                    }, 500);

                }
            };

            searchInPdf();

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);


    return (
        <Box className={styles.mainContent}>

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textLoading}
                    opacity={true}
                />
            )}

            <ErrorAlert msn={textError} title={titleError} errors={showError} />

            <ModalContractCreatedAndContractSteps
                openModal={openModalSteps}
                setOpenModal={setOpenModalSteps}
                tramitType={'express'}
            />

            <Grid container>

                {!verifiVariable(document) && (<Grid item xs={12}>

                    <Item
                        className={`${styles.contenItem} ${styles.infoItem} ${styles.document}`}
                    >
                        <PreviewDocument firmantes={firmantes} />
                    </Item>

                </Grid>)}

                <Grid item xs={12} sx={{ zIndex: 99 }}>
                    <Item className={`${styles.contenItem} ${styles.formItem}`}>
                        <UserManuals download={'https://drive.google.com/file/d/1A-lXOk75MuHMH35S1cvc8rRK3Nzxr12Q/view?usp=sharing'} />

                        <AddDocuments
                            loadingModaldocs={(e) => setLoading(e)}
                            textLoadingModaldocs={(e) => setTextLoading(e)}
                        />

                        <Box className={styles.stepContainerActionsButtoms}>
                            <ActionsButton
                                onClick={() => navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                                    state: { sContractID: contrato[0]['sContractID'] },
                                })}
                                variant="contained"
                                color="primary"
                            >
                                Atrás
                            </ActionsButton>

                            <ActionsButton
                                disabled={verifiVariable(document) || Object.values(firms).length === 0}
                                onClick={() => handleDataClick()}
                                variant="contained"
                                color="primary"
                            >
                                Guardar
                            </ActionsButton>
                        </Box>

                        <Item
                            className={`${styles.contenItem} ${styles.formItem}`}
                        >
                            <Box
                                className={`${styles.stepContainerActionsButtoms} ${styles.managProc}`}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                // className={styles.titleFV}
                                >
                                    Conoce los pasos de validación y
                                    notificación que llegan al correo de
                                    los participantes
                                </Typography>

                                <ButtonInfo
                                    onClick={() =>
                                        setOpenModalSteps(true)
                                    }
                                    variant="outlined"
                                    color="primary"
                                >
                                    Ingresa aquí
                                </ButtonInfo>
                            </Box>
                        </Item>
                    </Item>
                </Grid>

            </Grid>

        </Box>
    );
};

export default ReplaceExpressDocument;
