import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import { BiCloudUpload } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";

import { ModalUploadNotaryDocStyles } from "./ModalUploadNotaryDoc.css";
import Spinner from "../spinners/Spinner";
import { upaloadContractNotaryDoc } from "../../utils/api";
import { to } from "../../utils/enums";
import { Box } from "@mui/material";

const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    fontSize: "0.78rem",
    border: `1.5px solid ${props.theme.palette.primary.light}`,
    margin: 5
    // marginRight: "15px",
}));

const ModalUploadNotaryDoc = ({
    styledButton,
    contract,
    userToken,
    inList,
    setOpenModalNotary,
    openModalNotary,
    hideButoomsModal,
    tramitType
}) => {
    const styles = ModalUploadNotaryDocStyles();
    const navigate = useNavigate();

    const { control, setValue, handleSubmit, reset } = useForm({
        defaultValues: {
            fileUp: "",
            nameFile: "",
            repertoire: "",
            contractid: "",
        },
    });

    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        const contractId = inList
            ? contract
            : contract["contrato"][0]["sContractID"];
        setValue("contractid", contractId);
        setModalTitle("Subir documento");
        setDescription(
            "Suba el documento notariado y asócielo con un numero de repertorio."
        );
        setTexButtonGoback("Volver");
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        setOpenModalNotary(false);
        if ([true].includes(restStatus)) {
            tramitType === 'express' && navigate(to.EXPRESS_DOCUMENT_MANAGE);
            tramitType === 'anf' && navigate(to.ANF_MANAGE);
            !tramitType && navigate(to.MANAGE_DELIVERY);
        };
    };

    const handleClickNavegate = () => {
        if ([true].includes(restStatus)) {
            tramitType === 'express' && navigate(to.EXPRESS_DOCUMENT_MANAGE);
            tramitType === 'anf' && navigate(to.ANF_MANAGE);
            !tramitType && navigate(to.MANAGE_DELIVERY);
        };
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("fileUp", data["fileUp"]);
            formData.append("repertoire", data["repertoire"]);
            formData.append("contractid", data["contractid"]);
            await upaloadContractNotaryDoc(formData, userToken);
            setRestStatus(true);
            setTexButtonGoback("Ir a documentos de entrega");
            setModalTitle("Notificación Exitosa");
            setDescription(
                "La notificación a sido enviada a los destinatarios correspondientes"
            );
            reset();
            setRestStatus(true);
            setLoading(false);
        } catch (error) {
            setTexButtonGoback("Volver");
            setModalTitle("Notificación Fallida");
            setDescription(
                "La notificación no a sido enviada a los destinatarios correspondientes"
            );
            setRestStatus(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (openModalNotary) {
            handleClickOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModalNotary]);

    return (
        <React.Fragment>
            {loading && <Spinner loading={true} type="bar" opacity={true} />}

            {!hideButoomsModal && (
                <Fragment>
                    {inList ? (
                        <Box
                            sx={{ padding: "6px" }}
                            onClick={() => handleClickOpen()}
                        >
                            <BiCloudUpload className="icon--xs icon-pointer icon--blue-light" />
                        </Box>
                    ) : (
                        <DeleteAndUpdateCustomButtom
                            variant="outlined"
                            startIcon={<BiCloudUpload className="icon--blue" />}
                            onClick={() => handleClickOpen()}
                        >
                            Subir Documento Notariado
                        </DeleteAndUpdateCustomButtom>
                    )}
                </Fragment>
            )}

            <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
                {restStatus === undefined && (
                    <Stack className={styles.buttomsTopContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <AiOutlineCloseCircle
                                fontSize="inherit"
                                size={20}
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === true && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegGrinWink
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === false && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegSadCry
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                >
                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (
                            <Grid
                                container
                                spacing={1}
                                className={styles.gridContainer}
                            >
                                <Grid item xs={10}>
                                    <Typography variant={""}>
                                        Num. de folio
                                    </Typography>
                                </Grid>

                                <Grid item xs={10}>
                                    <Controller
                                        name="repertoire"
                                        control={control}
                                        rules={{
                                            required:
                                                "El Repertorio es requerido",
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <TextField
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    placeholder={
                                                        "Num. Repertorio"
                                                    }
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={10} mt={1}>
                                    <Typography variant={""}>
                                        Subir Archivo
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={10}
                                    className={styles.uploadContent}
                                >
                                    <Controller
                                        name="nameFile"
                                        control={control}
                                        rules={{
                                            required:
                                                "Debe selecionar un archivo",
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <TextField
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    disabled
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    placeholder={
                                                        "Agregar archivo"
                                                    }
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                />
                                            );
                                        }}
                                    />

                                    <label htmlFor="contained-button-file">
                                        <input
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                if (
                                                    e.target.files[0] !==
                                                    undefined
                                                ) {
                                                    setValue(
                                                        "fileUp",
                                                        e.target.files[0]
                                                    );
                                                    setValue(
                                                        "nameFile",
                                                        e.target.files[0][
                                                        "name"
                                                        ]
                                                    );
                                                } else {
                                                    setValue("nameFile", "");
                                                    setValue("fileUp", "");
                                                }
                                            }}
                                            accept="application/pdf"
                                            id="contained-button-file"
                                            type="file"
                                        />

                                        <Button
                                            variant="contained"
                                            size="large"
                                            className={styles.uploadButton}
                                            component="span"
                                        >
                                            Adjuntar
                                        </Button>
                                    </label>
                                </Grid>
                            </Grid>
                        )}
                    </DialogContent>

                    <DialogActions>
                        {restStatus === undefined && (
                            <Stack className={styles.constentDialogActionSend}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={styles.goBackButton}
                                >
                                    Enviar
                                </Button>
                            </Stack>
                        )}

                        {restStatus !== undefined && (
                            <Stack
                                className={styles.constentDialogActionGoBack}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleClickNavegate();
                                    }}
                                    className={styles.goBackButton}
                                >
                                    {texButtonGoback}
                                </Button>
                            </Stack>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
};

export default ModalUploadNotaryDoc;
