import React from "react";
import { Box } from "@mui/material";
import { styles } from "../../../../../helpers";
import ModalEditUser from "../../molecules/modalEditUser";
import ModalUserDelete from "../../molecules/modalUserDelete";

const RenderActions = ({ user }) => {

    return (
        <Box
            sx={styles.actionButtonsContainerStyles}
            className="flex--1 flex--center"
        >
            <ModalEditUser userData={user} />
            <ModalUserDelete userData={user} />
        </Box>
    );
};

export default RenderActions;
