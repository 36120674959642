import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { InfoMessageStyles } from './index.css';
import { ReactComponent as IconSuccess } from '../../assets/icons/icon-check-outlined.svg';
import { ReactComponent as IconInfo } from '../../assets/icons/icon-info-outlined.svg';
import { ReactComponent as IconWarningOrange } from '../../assets/icons/icon-warning-orange-outlined.svg';
import { ReactComponent as IconWarningError } from '../../assets/icons/icon-warning-red-outlined.svg';
import { theme } from '../../theme/theme';

const InfoMessage = ({ type, title, message, iconSize }) => {

    const CustomBox = styled(Grid)((props) => ({
        ...(type === 'info' && {
            borderLeft: `2px solid ${theme.palette.primary.main} !important`,
            border: `1px solid ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.xxlight,
        }),
        ...(type === 'success' && {
            border: `1px solid ${theme.palette.success.main}`,
            borderLeft: `2px solid ${theme.palette.success.main}`,
            backgroundColor: theme.palette.success.xlight,
        }),
        ...(type === 'warning' && {
            border: `1px solid ${theme.palette.warning.dark}`,
            borderLeft: `2px solid ${theme.palette.warning.dark}`,
            backgroundColor: theme.palette.warning.xxlight,
        }),
        ...(type === 'error' && {
            border: `1px solid ${theme.palette.error.xlight}`,
            borderLeft: `2px solid ${theme.palette.error.xlight}`,
            backgroundColor: theme.palette.error.xxlight,
        }),
    }));

    const CustomTitleText = styled(Typography)((props) => ({
        fontFamily: 'PoppinsBold',
        fontSize: '1rem',
        ...(type === 'info' && {
            color: theme.palette.primary.main
        }),
        ...(type === 'success' && {
            color: theme.palette.success.main
        }),
        ...(type === 'warning' && {
            color: theme.palette.warning.dark
        }),
        ...(type === 'error' && {
            color: theme.palette.error.xlight
        }),
    }));

    const CustomMessageText = styled(Typography)((props) => ({
        fontFamily: 'PoppinsBold',
        fontSize: '0.8rem',
        ...(type === 'info' && {
            color: theme.palette.primary.main
        }),
        ...(type === 'success' && {
            color: theme.palette.success.main
        }),
        ...(type === 'warning' && {
            color: theme.palette.warning.dark
        }),
        ...(type === 'error' && {
            color: theme.palette.error.xlight
        }),
    }));

    // eslint-disable-next-line
    const iconSizeStyle = {
        sm: iconSize === 'sm' && { width: '2.5rem', height: '2.5rem' },
        md: iconSize === 'md' && { width: '5rem', height: '5rem' },
        lg: iconSize === 'lg' && { width: '7.5rem', height: '7.5rem' },
    };

    const styles = InfoMessageStyles();
    const [icon, setIcon] = useState("");

    const iconType = () => {
        type === 'info' && setIcon(<IconInfo style={iconSizeStyle[iconSize]} />);
        type === 'success' && setIcon(<IconSuccess style={{ width: '5rem', height: '5rem' }} />);
        type === 'warning' && setIcon(<IconWarningOrange style={{ width: '5rem', height: '5rem' }} />);
        type === 'error' && setIcon(<IconWarningError style={{ width: '5rem', height: '5rem' }} />);
    };

    useEffect(() => {
        iconType();
        // eslint-disable-next-line
    }, [type])

    return (
        <CustomBox className={styles.boxContainer}>
            {icon}
            <Box>
                <CustomTitleText>
                    {title}
                </CustomTitleText>
                <CustomMessageText>
                    {message}
                </CustomMessageText>
            </Box>
        </CustomBox>
    )
}

export default InfoMessage