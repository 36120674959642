import { useContext, Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import Grid from "@mui/material/Grid";

import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg";
import { to, verifiVariable } from "../../../utils/enums";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import ContainerIndicator from "./molecules/containerIndicator";
import { Context } from "../../../context/utilsContext";
import Steptracker from "./molecules/stepTracker";
import StepsButtom from "../../../utils/stepsButtom";

const styledIcon = {
    marginLeft: "29px",
    marginRight: "29px",
};

const PaperworkHeader = () => {
    const { setToResumen } = useContext(Context);
    const screenSizeHook = ScreenSizeHook();
    const {
        formTramit: {
            firms,
            fourthStep,
            thirdStep: { fileName },
            firstStep: { contractTypeFee },
            secondStep,
        },
    } = useSelector((state) => state);
    const { pathname } = useLocation();
    const step1 = to.CONTRACT_CREATION;
    const step2 = to.CONTRACT_CREATION_SIGNATORIES;
    const step3 = to.CONTRACT_CREATION_DOCUMENTS;
    const step4 = to.CONTRACT_CREATION_ADD_SIGNATURES;
    const step5 = to.CONTRACT_CREATION_DATA_PREVIEW;
    const replacePage = to.CONTRACT_REPLACE_DOCUMENT;


    return (
        <div className="content-detail__container">
            <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={replacePage === pathname ? 12 : 8} xl={replacePage === pathname ? 12 : 9}>

                    {replacePage !== pathname && (<Fragment> <br />

                        <div
                            style={{
                                justifyContent: ["sm", "lg", "md"].includes(
                                    screenSizeHook
                                )
                                    ? "center"
                                    : "center", //TODO Verificar css
                                alignItems: "baseline",
                            }}
                            className="procedure-creation__track-container"
                        >
                            <Steptracker
                                marginText={
                                    screenSizeHook !== "xs" ? "-34px" : "0px"
                                }
                                cursor={
                                    !verifiVariable(contractTypeFee) &&
                                        pathname !== step1
                                        ? "pointer"
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        !verifiVariable(contractTypeFee) &&
                                        pathname !== step1
                                    )
                                        setToResumen(step1);
                                }}
                                step="1"
                                thereIsData={
                                    !verifiVariable(contractTypeFee) ? true : false
                                }
                                current={pathname === step1 ? true : false}
                                title={
                                    screenSizeHook === "xs"
                                        ? "Datos"
                                        : "Datos del trámite"
                                }
                            // subtitle="Tipo de documento de trámite"
                            />

                            {screenSizeHook === "xs" && (
                                <ArrowRight style={styledIcon} />
                            )}

                            <Steptracker
                                thereIsData={secondStep.length}
                                marginText={
                                    screenSizeHook !== "xs" ? "-22px" : "0px"
                                }
                                cursor={
                                    secondStep.length !== 0 && pathname !== step2
                                        ? "pointer"
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        secondStep.length !== 0 &&
                                        pathname !== step2
                                    )
                                        setToResumen(step2);
                                }}
                                step="2"
                                current={pathname === step2 ? true : false}
                                title="Participantes"
                            // subtitle="Datos de los solicitantes"
                            />

                            {screenSizeHook === "xs" && (
                                <ArrowRight style={styledIcon} />
                            )}

                            <Steptracker
                                thereIsData={
                                    !verifiVariable(fileName) ? true : false
                                }
                                marginText={
                                    screenSizeHook !== "xs" ? "-22px" : "0px"
                                }
                                cursor={
                                    !verifiVariable(fileName) && pathname !== step3
                                        ? "pointer"
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        !verifiVariable(fileName) &&
                                        pathname !== step3
                                    )
                                        setToResumen(step3);
                                }}
                                step="3"
                                current={pathname === step3 ? true : false}
                                title="Documentos"
                            // subtitle="Documentos a firmar"
                            />

                            {screenSizeHook === "xs" && (
                                <ArrowRight style={styledIcon} />
                            )}

                            <Steptracker
                                thereIsData={
                                    !verifiVariable(fourthStep) ? true : false
                                }
                                marginText={
                                    screenSizeHook !== "xs" ? "-41px" : "0px"
                                }
                                cursor={
                                    !verifiVariable(fileName) &&
                                        !verifiVariable(fourthStep) &&
                                        pathname !== step4
                                        ? "pointer"
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        !verifiVariable(fileName) &&
                                        !verifiVariable(fourthStep) &&
                                        pathname !== step4
                                    )
                                        setToResumen(step4);
                                }}
                                step="4"
                                current={pathname === step4 ? true : false}
                                title={
                                    screenSizeHook === "xs"
                                        ? "Firmas"
                                        : "Asignación de firmas"
                                }
                            // subtitle="Asignación de firmas"
                            />

                            {screenSizeHook === "xs" && (
                                <ArrowRight style={styledIcon} />
                            )}

                            <Steptracker
                                marginText={
                                    screenSizeHook !== "xs" ? "-7px" : "0px"
                                }
                                lastTrack={true}
                                step="5"
                                cursor={
                                    Object.values(firms).length !== 0 &&
                                        !verifiVariable(fourthStep) &&
                                        !verifiVariable(fileName) &&
                                        secondStep.length !== 0 &&
                                        !verifiVariable(contractTypeFee) &&
                                        pathname !== step5
                                        ? "pointer"
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        Object.values(firms).length !== 0 &&
                                        !verifiVariable(fourthStep) &&
                                        !verifiVariable(fileName) &&
                                        secondStep.length !== 0 &&
                                        !verifiVariable(contractTypeFee) &&
                                        pathname !== step5
                                    )
                                        setToResumen(step5);
                                }}
                                current={pathname === step5 ? true : false}
                                title="Resumen"
                            // subtitle="Confirmación de datos"
                            />
                        </div>

                        {!["xl", "lg"].includes(screenSizeHook) && (
                            <Grid item xs={12} md={12} lg={12} xl={3}>
                                <br />
                                <br />

                                <ContainerIndicator />
                                <StepsButtom />
                            </Grid>
                        )}

                        <br />
                        <br />
                    </Fragment>)}

                    <div className="procedure-creation__container">
                        <Outlet />
                    </div>
                </Grid>

                {replacePage !== pathname && ["xl", "lg"].includes(screenSizeHook) && (
                    <Grid item xs={12} md={12} lg={4} xl={3}>
                        <br />
                        <br />

                        <ContainerIndicator />
                        <StepsButtom />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default PaperworkHeader;
