import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const PositionFirmStyles = makeStyles(() => {

    return createStyles({
        textIcon: ({ iconColor }) => ({
            fill: iconColor
        }),
        signatoriesList: {
            backgroundColor: 'background.paper !important'
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        inputControl: {
            '& div:first-child': {
                height: '34px'
            }
        },
        actionButtoms: {
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        mainContainer: {
            width: 'auto'
        },
        navigationControls: {
            display: 'flex'
        },
        boxIcon: {
            position: 'absolute',
            bottom: '43px',
            fill: 'red',
            left: '60px',
            zIndex: 999,
            cursor: 'pointer'
        },
        results: {
            textAlign: 'center'
        },
        dragableBox: ({ layerX, layerY }) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'baseline',
            position: 'absolute',
            left: `${layerX}px`,
            bottom: `${layerY}px`,
            /* width: width + 'px',
            height: height + 'px', */
            background: 'transparent',
            cursor: 'move',
            '& p': {
                fontSize: '2rem'
            }
        }),
        canvasContainer: {
            width: '100%',
            height: 'auto',
            overflow: 'auto',
            background: '#333',
            textAlign: 'center',
            border: 'solid 3px'
        },
        pdfViewer: {
            background: '#ccc',
            position: 'relative',
            margin: '2em auto'
        }
    });

});