import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Grid, Popover } from "@mui/material";

import { ReactComponent as ProfileCheck } from "../../../../../../../assets/icons/icon-profile-checked.svg";
import { ReactComponent as InfoIconContained } from "../../../../../../../assets/icons/icon-info-contained.svg";
import { ReactComponent as InfoIconOutlined } from "../../../../../../../assets/icons/icon-info-outlined.svg";
import { ReactComponent as IconCheckContained } from "../../../../../../../assets/icons/icon-check-contained-blue.svg";
import { theme } from "../../../../../../../theme/theme";
import Btn from "../../../../../../buttons/Button";
import { verifiVariable } from "../../../../../../../utils/enums";
import { styles } from "../../../../../../../helpers";
import { useSelector } from "react-redux";

const CustomText = styled(Typography)((props) => ({
    color: props.theme.palette.primary.main,
    fontWeight: 900,
}));

const CardTypeFirm = ({
    icon,
    title,
    UrlButtom,
    step,
    cardMenuActive,
    setCardMenuActive,
    infoActive,
    setInfoActive,
    cardMenu,
    tramitType
}) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [tramitTypeList, setTramitTypeList] = useState([]);
    const [tramitTypeFeatures, setTramitTypeFeatures] = useState([]);
    const [price, setPrice] = useState(null);
    const navegate = useNavigate();
    const boxRef = useRef(null);
    const { agreementList, standarAgreementList } = useSelector((state) => state.agreement);
    const { allTramitAgreements } = useSelector(state => state.contract.tramites);

    const getTramitTypeFeatures = () => {
        if (title === "Autorización notarial de firmas (ANF)") {
            setTramitTypeFeatures([
                'Clave única',
                'Grabación del video de voluntad de firmas'
            ]);
        } else if (title === "Protocolización notarial") {
            setTramitTypeFeatures([
                'Registro público en físico',
                'Número de repertorio y fecha'
            ]);
        } else if (title === "Certificación notarial") {
            setTramitTypeFeatures([
                'Certificación de copia fiel',
                'Registro digital en fojas.cl o cbrchile.cl'
            ]);
        } else if (title === "Firma electrónica (Sin notaría)") {
            setTramitTypeFeatures([
                'Cumplimiento a la ley 19.799',
                'Rúbrica digital de la firma manuscrita'
            ]);
        };
    };

    const getTramitTypeData = () => {
        const tramitTypeData = allTramitAgreements.filter(tramitAgreement => tramitAgreement.sModal === tramitType && tramitAgreement.sContractTypeName === 'CONTRATO DE ARRIENDO');
        const tramitTypeIsOneSigner = tramitTypeData.find(tramitType => tramitType.iSignCount === 1);
        const tramitTypeIsNotOneSigner = tramitTypeData.find(tramitType => tramitType.iSignCount === 2);
        setTramitTypeList([tramitTypeIsOneSigner, tramitTypeIsNotOneSigner]);
    };

    const getAgrementStandars = () => {

        if (([...standarAgreementList].find((item) => item['agrement']['agreementId'] === 1))) {

            const agreements = [...standarAgreementList].find((item) => item['agrement']['agreementId'] === 1)['agrementDetail'];
            return [...tramitTypeList].map((item) => {

                const getAgrement = agreements.find((agreementItem) => agreementItem['iContractTypeFeeID'] === item['iContractTypeFeeID']);

                return Object.assign({ ...item }, {
                    agreementPrice: {
                        ...getAgrement,
                        total: [parseFloat(getAgrement['price']), parseFloat(getAgrement['notaryPrice'])].reduce((a, b) => a + b, 0)
                    }
                });

            });

        } else {
            return {};
        }

    }

    const getPrice = useCallback(() => {

        let getAgrement;
        let agreements = [];
        let agreementsStandar = [];

        if (!verifiVariable(agreementList) && agreementList.length > 0 && [...agreementList].find((item) => item['agreement']['is_default'] === 1)) {

            agreements = [...agreementList].find((item) => item['agreement']['is_default'] === 1)['agreementList'];

            agreementsStandar = [...standarAgreementList].find((item) => item['agrement']['agreementId'] === 1)?.['agrementDetail'];
            return [...tramitTypeList].map((item) => {

                getAgrement = agreements.find((agreementItem) => agreementItem['iContractTypeFeeID'] === item['iContractTypeFeeID']);

                if (verifiVariable(getAgrement)) getAgrement = agreementsStandar.find((agreementItem) => agreementItem['iContractTypeFeeID'] === item['iContractTypeFeeID']);

                return Object.assign({ ...item }, {
                    agreementPrice: {
                        ...getAgrement,
                        total: [parseFloat(getAgrement['price']), parseFloat(getAgrement['notaryPrice'])].reduce((a, b) => a + b, 0)
                    }
                });

            });

        } else {
            return getAgrementStandars();
        }
        // eslint-disable-next-line
    }, [tramitTypeList, agreementList, standarAgreementList]);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        getTramitTypeData();
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCardMenuActive(cardMenuActive === step ? undefined : step);
        setInfoActive(infoActive !== step ? step : undefined);
    };

    const handleInfoClick = (event) => {
        setCardMenuActive(cardMenuActive === step ? undefined : step);
        setInfoActive(infoActive !== step ? step : undefined);
        handlePopoverOpen(event);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const cardStyles = {
        ...styles.card_item,
        ...(title === "Autorización notarial de firmas (ANF)" && { ...styles.card_item_ANF }),
        ...(title === "Protocolización notarial" && { ...styles.card_item_Prn }),
        ...(title === "Certificación notarial" && { ...styles.card_item_Crn }),
        ...(title === "Firma electrónica (Sin notaría)" && { ...styles.card_item_Fes }),
        ...(cardMenuActive === step && {
            backgroundColor: theme.palette.primary.xxxxxlight,
            border: `1px solid ${theme.palette.primary.main}`
        })
    }

    const handleClickNavegate = () => {
        if (cardMenu === 'approvers') {
            navegate(UrlButtom?.approvers);
        } else if (cardMenu === 'express') {
            navegate(UrlButtom?.express || UrlButtom);
        };
    };

    useEffect(() => {

        if (boxRef.current) {
            const svg = boxRef.current.querySelectorAll('svg');
            svg.forEach((svg) => {
                svg.style.height = '2rem';
                svg.style.width = '2rem';
                svg.style.margin = "0 0 0 0"
            })
        };

    }, []);

    useEffect(() => {
        if (open) {
            setPrice(getPrice());
            getTramitTypeFeatures();
        };
        // eslint-disable-next-line
    }, [open])

    return (
        <Grid item sx={cardStyles}>

            <Box ref={boxRef}>
                {icon}
            </Box>

            <Box sx={styles.card_item_title}>
                <CustomText variant="p" style={styles.card_item_title_text}>{title}</CustomText>
            </Box>

            <Box
                sx={styles.card_item_info_icon}
                onClick={(event) => handleInfoClick(event)}
            >
                <Typography>
                    Más detalles
                </Typography>
                {
                    infoActive === step
                        ? <InfoIconOutlined style={{marginLeft: 9}} />
                        : <InfoIconContained style={{marginLeft: 9}} />
                }
            </Box>

            <Box sx={styles.card_item_info}>
                {cardMenu === 'approvers'
                    ? (
                        <>
                            <ProfileCheck />
                            <Typography variant="p" sx={styles.card_item_info_text}>Con aprobador</Typography>
                        </>
                    )
                    : (
                        <Typography variant="p" sx={styles.card_item_info_text}>Trámite express</Typography>
                    )}
            </Box>

            {!verifiVariable(UrlButtom) && (
                <Btn
                    variant="contained"
                    text="Iniciar trámite"
                    sx={styles.buttonBlueStatusMenu}
                    onClick={handleClickNavegate}
                />
            )}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: { ...styles.card_item_popover_container }
                }}
                elevation={0}
            >
                <Typography sx={styles.card_item_popover_title}>
                    {title}
                </Typography>

                <Box sx={styles.card_item_popover_textBox}>
                    <Box>
                        {price?.[1]?.iSignCount === 2 && (
                            <Typography sx={styles.card_item_popover_mainText}>
                                ${price?.[1]?.agreementPrice?.total.toLocaleString('es')}
                            </Typography>
                        )}

                        <Typography className="secondaryText-info">
                            (valor dos firmantes)*
                        </Typography>
                    </Box>

                    <Box>
                        {price?.[0]?.iSignCount === 1 ? (
                            <>
                                <Typography sx={styles.card_item_popover_secondaryText}>
                                    ${price?.[0]?.agreementPrice?.total.toLocaleString('es')}
                                </Typography>

                                <Typography className="secondaryText-info">
                                    (valor un firmante)*
                                </Typography>
                            </>
                        ) : (
                            <CircularProgress size={20} />
                        )}
                    </Box>
                </Box>

                <Box sx={styles.card_item_popover_textBox}>
                    {tramitTypeFeatures?.length > 0 && (
                        tramitTypeFeatures.map((feature, index) => (
                            <Box key={index}>
                                <IconCheckContained />

                                <Typography sx={styles.card_item_popover_textBox_featureText}>
                                    {feature}
                                </Typography>
                            </Box>
                        ))
                    )}
                </Box>

                <Button
                    sx={styles.card_item_popover_button}
                    onClick={handleClickNavegate}
                >
                    Seleccionar
                </Button>

            </Popover>
        </Grid>
    );
};

export default CardTypeFirm;