import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

// fontFamily: "PoppinsRegular",

// borderColor: theme.palette.primary.light,

// fontFamily: "PoppinsBold",

export const GeneralListProcedStyles = makeStyles((theme) => {
    return createStyles({
        tableHead: {
            backgroundColor: "#f7f7f7",
        },
        rowStatus: {
            '& svg': {
                position: "relative",
                top: "4px",
                zoom: ".6",
                marginRight: "10px"
            }
        },
        rowText: {
            fontFamily: "PoppinsRegular",
            "& span": {
                color: "#919191",
            },
            "& svg": {
                marginLeft: "10px",
            },
        },
        cellTitle: {
            fontFamily: "PoppinsBold",
        },
        descriptionText: {
            fontFamily: "PoppinsBold",
        },
    });
});
