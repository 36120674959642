import React from 'react';
import StatusHook from '../../hooks/StatusHook';

const RenderStatus = ({ status }) => {

    const restStatus = StatusHook(status);

    return (
        <div className={ restStatus !== undefined ? restStatus.class : null }>{ restStatus !== undefined ? restStatus.text : '' }</div>
    );

};

export default RenderStatus;