import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "formAgreement",
    initialState: {
        errorStep: true,
        loading: false,
        message: null,
        code: null,
        status: null
    },
    reducers: {

        CLEAR: (state, action) => {
            state.status = null;
            state.code = null;
            state.message = null;
            state.loading = false;
        },

        CREATE_AGREMENT_SUCCESS: (state, action) => {
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.message = null;
            state.loading = false;
        },

        CREATE_AGREMENT_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
        },

        CREATE_AGREMENT_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        FORM_AGREEMENT_ERROR_STEP: (state, action) => {
            state.errorStep = action.payload;
        }

    }
});

export const {
    CREATE_AGREMENT_SUCCESS,
    CREATE_AGREMENT_FAIL,
    CREATE_AGREMENT_REQUEST,
    FORM_AGREEMENT_ERROR_STEP,
    CLEAR
} = slice.actions;

export const clearAgreementStatus = () => {
    return { type: CLEAR.type, payload: null };
}

export const createAgreement = (token, data) => {
    return apiCallStart({
        url: `/api/v1/agreement/create`,
        method: "POST",
        data,
        onStart: CREATE_AGREMENT_REQUEST.type,
        onSuccess: CREATE_AGREMENT_SUCCESS.type,
        onError: CREATE_AGREMENT_FAIL.type,
        authorization: token
    });
};

export const errorStepFormAgreement = (data) => {
    return { type: FORM_AGREEMENT_ERROR_STEP.type, payload: data };
};

export default slice.reducer;
