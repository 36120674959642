import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "firmaDocs",
    initialState: {
        pdfBase64: null,
        signatureBoxes: null
    },
    reducers: {

        GESTION_SIGNATURE_BOXES: (state, action) => {
            state.signatureBoxes = action.payload;
        },

        LOAD_DOC_BASE_64: (state, action) => {
            state.pdfBase64 = action.payload;
        },

        FIRMA_DOC_PURGE_STATE: (state, action) => {
            state.pdfBase64 = null;
        }

    },
});

export const {
    GESTION_SIGNATURE_BOXES,
    LOAD_DOC_BASE_64,
    FIRMA_DOC_PURGE_STATE,
} = slice.actions;

export const gestionSignatureBoxes = (data) => {
    return { type: GESTION_SIGNATURE_BOXES.type, payload: data };
};

export const loadDocBase64 = (fileBase64) => {
    return { type: LOAD_DOC_BASE_64.type, payload: fileBase64 };
};

export default slice.reducer;
