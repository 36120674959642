import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const MenuasideStyles = makeStyles((theme) => {
    return createStyles({
        toggleButton: {
            "& span.menu-aside__item-text": {
                fontFamily: "PoppinsBold",
                fontSize: "0.95rem",
                color: theme.palette.black.main,
            },
        },
        accordion: {
            "& span.menu-aside__item-text": {
                fontFamily: "PoppinsBold",
                fontSize: "0.85rem",
                color: theme.palette.black.main,
                // paddingLeft: "0px !important",
            },
            "& ul.menu-aside__list a": {
                fontFamily: "PoppinsRegular",
            },
        },
        buttom: {
            fontFamily: "PoppinsBold",
            fontSize: "0.85rem",
            color: theme.palette.black.main,
        },
        buttonGroup: {
            paddingLeft: "21px",
        },
    });
});
