import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../../../theme/theme";

export const VerifiedClaveUnicaStyles = makeStyles(() => {
    return createStyles({
        mainContent: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            [theme.breakpoints.down('md')]: {
                marginTop: '40px',
                width: '90%',
            },

            [theme.breakpoints.up('sm')]: {
                width: '50vw',
            },

            '& div.content-grid': {
                marginTop: '0px !important',
                marginLeft: '0px !important'
            }
        },
        headerGrid: {
            marginTop: '30px !important',            
            paddingLeft: '0px !important',
            marginBottom: '30px !important',
            display: 'flex',


            [theme.breakpoints.down('md')]: {
                flexDirection: 'column !important',
                justifyContent: 'flex-start !important',                
            },

            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row !important',
                justifyContent: 'space-between !important',                
            },

            '& div.info': {

                [theme.breakpoints.up('sm')]: {
                    marginLeft: '40px',
                },

                '& div.text': {
                    display: 'flex',

                    '& svg': {
                        marginLeft: '10px',
                        margin: '0px'
                    }
                },

                '& div.barra': {
                    width: '100px',
                    height: '4px',
                    // background: 'rgb(0,79,159)',
                    background: 'linear-gradient(90deg, rgba(0,79,159,1) 30%, rgba(255,89,89,1) 30%)'
                }

            },

            '& svg': {

                [theme.breakpoints.up('sm')]: {
                    marginRight: '40px'
                },

                [theme.breakpoints.down('md')]: {
                    marginTop: '20px'
                },
            }

        },
        bodyGrid: {
            display: 'flex',
            flexDirection: 'column !important',
            alignItems: 'center',
            paddingLeft: '20px !important',
            paddingRight: '20px !important',
            paddingTop: '40px !important',
            borderRadius: '7px 7px 0px 0px',
            borderStyle: 'solid',
            borderColor: theme.palette.gray.light,
            borderBottom: 'white 1px solid',
            // backgroundColor: '#F1F4F7',
            '& div.content-check-term': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',

                '& label': {
                    marginBottom: '0px',
                    marginLeft: '0px',
                    marginRight: '0px'
                },

                '& h6.text-term': {
                    fontSize: '.95rem',
                    cursor: 'pointer',
                    marginBottom: '0px',
                    fontFamily: 'PoppinsRegular',
                    textDecoration: 'underline'
                }
            },

            '& div.content-power-by': {
                marginBottom: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                '& h6': {
                    fontFamily: 'PoppinsRegular',
                    color: theme.palette.black.secondary,
                    marginRight: '20px',
                    fontSize: '1.1rem',
                },

                '& svg': {
                    zoom: '.5'
                }
            }
        },
        footerGrid: {
            paddingLeft: '0px !important',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '0px !important',
            borderRadius: '0px 0px 7px 7px',
            background: theme.palette.primary.main
        },
        tabButtomsGrid: {
            
        }

    });
});
