import { useFieldArray, useForm, useWatch } from "react-hook-form";

import Grid from '@mui/material/Grid';

import TypeFormAgreements from "../typeFormAgreements";
import { ManagerAgreementsStyles } from "../../index.css";
import StepOneOrganizationData from "../../atoms/stepOneOrganizationData";
import StepTwoAgreementName from "../../atoms/stepTwoAgreementName";
import StepThreeUploadPriceList from "../../atoms/stepThreeUploadPriceList";

const StepsperAddAgreements = () => {
    const styles = ManagerAgreementsStyles();
    const { control, watch, trigger, setValue, getValues, formState, setError, clearErrors } = useForm({
        defaultValues: {
            rut: "",
            desc: "",
            fullName: "",
            domain: "",
            phone: "",
            email: "",
            agreementName: "",
            contractTypeList: "",
            modalPayment: ""
        }
    });

    const { append, remove, fields } = useFieldArray({
        control,
        name: "agreements"
    });

    return (

        <div className="content-detail__container">

            <div className="procedure-creation__container">

                <Grid className={styles.stepsperContent} container>

                    <TypeFormAgreements
                        lastPage={(e) => console.log('e: ', e)}
                        getValues={getValues}
                    >

                        <StepOneOrganizationData
                            control={control}
                            watch={watch}
                            trigger={trigger}
                            setValue={setValue}
                        />

                        <StepTwoAgreementName
                            control={control}
                            watch={watch}
                            trigger={trigger}
                            formState={formState}
                        />

                        <StepThreeUploadPriceList
                            control={control}
                            useWatch={useWatch}
                            trigger={trigger}
                            append={append}
                            remove={remove}
                            fields={fields}
                            formState={formState}
                            setValue={setValue}
                            getValues={getValues}
                            setError={setError}
                            clearErrors={clearErrors}
                        />

                    </TypeFormAgreements>

                </Grid>

            </div>

        </div>

    );
}

export default StepsperAddAgreements;