import { createStyles, makeStyles } from "@mui/styles";

export const ProceduresHistoryStyles = makeStyles((theme) => {
    return createStyles({
        container: {
            width: '100%',
            margin: '0 0 1rem 0',
            padding: '0 .5rem 2.5rem .5rem',
            backgroundColor: theme.palette.white.main,
            [theme.breakpoints.up('lg')]:{
                padding: '0 .5rem',
            }
        },
        tap_container: {
            display: 'flex'
        },
        tap_box: {
            backgroundColor: theme.palette.primary.xxxxxlight,
            width: '13rem',
            textAlign: 'center',
            borderRadius: '1rem',
            margin: '1.25rem 1.188rem 1.25rem 0',
            cursor: 'pointer',
            placeContent: 'center',
            '&:hover': {
                backgroundColor: theme.palette.primary.xlight,
                '& > p': {
                    color: theme.palette.white.main
                }
            }
        },
        tap_box_inactive: {
            backgroundColor: theme.palette.gray.xxxlight,
            opacity: '50%',
            '& > p': {
                color: theme.palette.gray.dark
            }
        },
        tap_text: {
            color: theme.palette.primary.xdark,
            fontSize: '.688rem',
            padding: '.5rem 1.438rem',
            fontWeight: 500,
            fontFamily: 'PoppinsRegular',
        },
        mainText: {
            fontSize: '1.25rem',
            fontFamily: 'PoppinsBold',
            margin: '0.5rem 0'
        },
        secondaryText: {
            fontSize: '1.2rem',
            fontFamily: 'PoppinsRegular',
            margin: '0.5rem 0'
        },
        frequentProcedures_Box: {
            width: '16.9rem',
            margin: '0 1.588rem 1rem 0',
            padding: '0.5rem',
            transition: '0.3s ease',
            boxShadow: '0px 4px 10px 0px rgba(0, 79, 159, 0.05)',
            "&:hover": {
                transform: 'translateY(-2.5px)'
            }
        },
        frequentProcedures_Box_tramitType_Box: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            "& svg": {
                width: "15.05px",
                height: "15.05px"
            }
        },
        frequentProcedures_Box_tramitType: {
            display: 'flex',
            alignItems: 'center',
            '& .text': {
                fontSize: '.875rem',
                marginLeft: '.25rem',
                fontFamily: 'PoppinsRegular'
            }
        },
        frequentProcedures_Box_tramitType_Text: {
            fontSize: '0.813rem',
            fontFamily: 'PoppinsBold',
            margin: '0.5rem 0 0 0',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        frequentProcedures_Box_tramitType_link: {
            margin: '0.5rem 0 0 0',
            fontSize: '0.813rem',
            fontFamily: 'PoppinsRegular'
        },
        tramitInfo_Box: {
            display: 'flex',
            marginRight: '0.2rem'
        },
        tramitInfo_Box_Role: {
            display: 'flex',
            margin: '0 0.5rem',
            alignItems: 'center'
        },
        tramitInfo_Box_Text: {
            fontSize: '.8rem'
        }
    });
});