import { useEffect, useState } from "react";

const StatusHook = (status, paymentStatus) => {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (status === "CREATED")
            setData({
                class: "status__container created",
                text: "Contrato creado",
            });

        if (status === "REVIEW")
            setData({
                class: "status__container pending",
                text: "Contrato en revisión",
            });

        if (status === "REJECTED")
            setData({
                class: "status__container rejected",
                text: "Contratos(s) rechazado(s)",
            });

        if (
            status === "APPROVED" &&
            ["PARTIALLY_PAID", "PENDING"].includes(paymentStatus)
        )
            setData({
                class: "status__container pending",
                text: "Pendiente de pago",
            });

        if (status === "IN_NOTARY")
            setData({ class: "status__container pending", text: "En notaría" });

        if (status === "SIGNED_PENDING")
            setData({
                class: "status__container pending",
                text: "Pendiente de firma",
            });

        if (status === "FINISHED")
            setData({ class: "status__container approved", text: "Entregado" });
    }, [status, paymentStatus]);

    return data;
};

export default StatusHook;
