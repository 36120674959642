import React, { Component } from 'react';
import update from 'immutability-helper';
import { DropTarget } from 'react-dnd';
import PropTypes from 'prop-types';

import { itemTypes } from '../../../../../utils/enums';
import DraggableBox from '../draggableBox';

class DraggableContainer extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            /* boxes: {
        a: { top: 20, left: 80, title: 'Drag me around' },
        b: { top: 180, left: 20, title: 'Drag me too' }
      }, */
            boxes: {},
            styles: {
                width: 300,
                height: 300,
                position: 'relative'
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        const stylesUpdated = { ...state.styles };
        stylesUpdated.width = props.draggableContainerDimensions.width;
        stylesUpdated.height = props.draggableContainerDimensions.height;

        let boxesUpdated = {};
        if (JSON.stringify(props.boxes) !== JSON.stringify(state.boxes) && Object.keys(props.boxes).length === Object.keys(state.boxes).length) {
            boxesUpdated = { ...state.boxes };
        } else if (JSON.stringify(props.boxes) !== JSON.stringify(state.boxes) && Object.keys(props.boxes).length !== Object.keys(state.boxes).length) {
            boxesUpdated = { ...props.boxes };
        }

        if (Object.keys(props.boxes).length > 0 && Object.keys(boxesUpdated).length === 0) {
            boxesUpdated = { ...props.boxes };
        }

        const boxesUpdatedToDisplay = {};
        Object.keys(boxesUpdated).filter((signatureKey) => {
            const currentDocumentPageData = props.currentDocumentPage || 1;

            if (boxesUpdated[signatureKey].pageLocation === currentDocumentPageData) {
                boxesUpdatedToDisplay[signatureKey] = boxesUpdated[signatureKey];
            }            

            return signatureKey;
        });

        boxesUpdated = { ...boxesUpdatedToDisplay };

        return {
            boxes: boxesUpdated,
            styles: stylesUpdated
        };
    }

    render() {
        const { hideSourceOnDrag, connectDropTarget, draggableContainerScale, componentMarginTop } = this.props;
        const { boxes, styles } = this.state;

        const arrayBoxes = Object.values(boxes);

        const lastItem = arrayBoxes[arrayBoxes.length -1];

        return connectDropTarget(
            <div style={styles}>
                {Object.keys(boxes).map((key) => {
                    const { left, top, title, pageLocation, backgroundColor, color } = boxes[key];

                    return (
                        <div key={key}>
                            <DraggableBox
                                id={key}
                                key={key}
                                left={left}
                                top={top}
                                title={title}
                                backgroundColor={backgroundColor}
                                pageLocation={pageLocation}
                                color={color}
                                canDrag={lastItem['title'] === title ? true : false}
                                draggableContainerScale={draggableContainerScale}
                                hideSourceOnDrag={hideSourceOnDrag}
                                componentMarginTop={componentMarginTop}
                            >
                                {title}
                            </DraggableBox>
                        </div>
                    );
                })}
            </div>
        );
    }

    moveBox(id, left, top) {
        this.setState(
            update(this.state, {
                boxes: {
                    [id]: {
                        $merge: { left, top }
                    }
                }
            })
        );
    }
}

DraggableContainer.propTypes = {
    boxes: PropTypes.any,
    connectDropTarget: PropTypes.any,
    hideSourceOnDrag: PropTypes.bool,
    componentMarginTop: PropTypes.number,
    currentDocumentPage: PropTypes.number,
    draggableContainerScale: PropTypes.number,
    draggableContainerDimensions: PropTypes.object
};

export default DropTarget(
    itemTypes.SIGNATURE_CONTAINER,
    {
        drop(props, monitor, component) {

            if (!component) {
                return;
            }

            const draggableContainerWidth = Number(props.draggableContainerDimensions.width.substring(0, props.draggableContainerDimensions.width.length - 2));
            const draggableContainerHeight = Number(props.draggableContainerDimensions.height.substring(0, props.draggableContainerDimensions.height.length - 2));
            const signatureContainerWidth = 205; // Esto se puede cambiar y es el width de DraggableBox que está harcoded
            const signatureContainerHeight = 80; // Esto se puede cambiar y es el width de DraggableBox que está harcoded

            const item = monitor.getItem();
            const delta = monitor.getDifferenceFromInitialOffset();
            /* eslint-disable */
      const left =
        Math.round(item.left + delta.x / item.draggableContainerScale) < 0
          ? 0
          : Math.round(signatureContainerWidth + item.left + delta.x / item.draggableContainerScale) > draggableContainerWidth
          ? Math.round(draggableContainerWidth - signatureContainerWidth)
          : Math.round(item.left + delta.x / item.draggableContainerScale)
      const top =
        Math.round(item.top + delta.y / item.draggableContainerScale) < 0
          ? 0
          : Math.round(signatureContainerHeight + item.top + delta.y / item.draggableContainerScale) > draggableContainerHeight
          ? Math.round(draggableContainerHeight - signatureContainerHeight)
          : Math.round(item.top + delta.y / item.draggableContainerScale)
      /* eslint-disable */

      props.handleUpdateBoxesContext(item, left, top, delta);
      component.moveBox(item.id, left, top);
    }
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget()
  })
)(DraggableContainer)

/* style={{ transform: 'scale(0.5)', transformOrigin: 'top center' }} */
