import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Text, Btn } from "../../components";
import { to } from "../../utils/enums";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";

const NotFound = () => {
    const navigate = useNavigate();

    const titleStyles = {
        fontWeight: "500",
        fontSize: "5rem",
        marginBottom: "10px",
        marginTop: "30px"
    };

    const subTitleStyles = {
        color: "quinary.dark",
        fontWeight: "500",
        fontSize: "1.2rem",
        marginBottom: "50px"
    };

    const buttonStyle = {
        color: "white.main",
        fontWeight: "500",
        textTransform: "none",
        fontSize: "1.2rem",
    };

    const handleBackButtonStyle = () => {
        navigate(to.HOME);
    }

    return (
        <div className="page__cover-container">
            <Link to={to.HOME}>
                <Logo className="logo-size--md" />
            </Link>
            <Text sx={titleStyles} align="center">404</Text>
            <Text sx={subTitleStyles} align="center">Esta página no existe, por favor vuelve al inicio</Text>
            <Btn
                variant="contained"
                text="Regresar al inicio"
                sx={buttonStyle}
                onClick={handleBackButtonStyle}
            />
        </div>
    );
}

export default NotFound;
