import { useContext } from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Context } from "../../../../../context/utilsContext";

import { ReactComponent as PlusIcon } from "../../../../../assets/icons/icon-plus-outlined-blue.svg";
import { theme } from "../../../../../theme/theme";


/* const ActionsWhiteButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.white.main,
    color: props.theme.palette.primary.light,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.white.main,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.white.main,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
})); */

const ActionsButton = styled(Button)((props) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    lineHeight: 1.5,
    margin: '0 0 0 1rem',
    color: props.theme.palette.primary.main,
    backgroundColor: props.theme.palette.white.main,
    border: `1px solid ${theme.palette.primary.light}`,
    fontFamily: ["PoppinsBold"].join(","),
    "&:hover": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: props.theme.palette.primary.light,
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        backgroundColor: props.theme.palette.primary.light,
    },
}));

const CustomToobarCuponsList = ({ onUpdate, onCreate, title, subTitle, onDisableCreate }) => {
    // eslint-disable-next-line
    const { setShow } = useContext(Context);

    return (
        <Grid container /* spacing={2} */>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        flexDirection: "column",
                    }}
                >
                    <Typography fontFamily={"PoppinsBold"}>{title}</Typography>
                    <Typography
                        variant={"caption"}
                        fontFamily={"PoppinsRegular"}
                    >
                        {subTitle}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: "1",
                        justifyContent: "flex-end",
                    }}
                >

                    <ActionsButton
                        disabled={onDisableCreate}
                        onClick={() => onCreate()}
                    >

                        Asignar cupon
                    </ActionsButton>

                    <ActionsButton
                        disabled={onDisableCreate}
                        onClick={() => onCreate()}
                        startIcon={<PlusIcon />}
                    >

                        Agregar nuevo
                    </ActionsButton>
                </Box>
            </Grid>
        </Grid >
    );
};

export default CustomToobarCuponsList;