import React, { Fragment, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { SessionForm } from "../../components";
import { to } from "../../utils/enums";
import { loadUserFromLocalStorage } from "../../store/auth/userLogin";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";

export default function Login() {
    const screenSizeHook = ScreenSizeHook();
    const navigate = useNavigate();

    useEffect(() => {
        let run = true;
        if (run) {
            if (localStorage.getItem("session") !== null) {
                const payload = JSON.parse(localStorage.getItem("session"));

                loadUserFromLocalStorage(payload);
                navigate(to.DASHBOARD_WELCOME);
            }
        }
        return () => run = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <div className="login-wrapper">
                <div className="start-session__container">
                    <div className="start-session-content__container flex--center">

                        <Grid container>

                            <Grid item xs={12} sx={{ marginRight: 2, marginLeft: 2 }}>

                                <Link to="/">
                                    <Logo className="logo-size--md" style={{ width: '100%' }} />
                                </Link>

                            </Grid>

                        </Grid>

                        <h1 className="title--head text-align--center title">
                            Iniciar sesión
                        </h1>
                        <SessionForm />
                    </div>
                </div>

                {!['xs', 'sm'].includes(screenSizeHook) && (<div className="showcase__container login-bg" />)}

            </div>

            {/* <WhatsappIconSpeedDial screen={screenSizeHook} /> */}

            {/* <HelpIconSpeedDial screen={screenSizeHook} /> */}
        </Fragment>
    );
}
