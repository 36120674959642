import { forwardRef, Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegTrashAlt } from "react-icons/fa";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { ContractDetailReviewStyles } from '../../index.css';
import Btn from '../../../../buttons/Button';
import { Context } from '../../../../../context/utilsContext';
import { deleteContract } from '../../../../../utils/api';
import { to, verifiVariable } from '../../../../../utils/enums';
import { saveDetailsContract } from '../../../../../store/contract/contractDetail';
import { saveTramitANFInfo } from '../../../../../store/formANFTramit';


const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    margin: 5,
    fontSize: '0.78rem',
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteContractAlert = () => {
    const styles = ContractDetailReviewStyles();
    const {
        login: { user }
    } = useSelector((state) => state.session);
    const {
        contractCreator: { contractListActive }
    } = useSelector((state) => state.contract);
    const { allAnfTramit } = useSelector((state) => state.formANFTramit);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigation = useNavigate();
    const { setLoading } = useContext(Context);
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const deleteCurrentContract = async () => {
        try {
            const { token } = user;
            const { message } = contractListActive;

            const contractID = typeof state === "object" ? state["sContractID"] : state;


            const findContract2 = [...allAnfTramit].find((item) => item["contractID"] === contractID);

            setLoading(true);
            await deleteContract(contractID, token);

            if (!verifiVariable(message) && typeof message === "object" && [...message].length !== 0) {
                const findContract1 = [...message].find((item) => item["sContractID"] === contractID);
                if (!verifiVariable(findContract1)) dispatch(saveDetailsContract({ indexe: "contractListActive", value: { ...contractListActive, message: [...message].filter((item) => item["sContractID"] !== contractID) } }));
            }

            if (!verifiVariable(findContract2)) dispatch(saveTramitANFInfo({ indexe: "allAnfTramit", value: [...allAnfTramit].filter((item) => item["contractID"] !== contractID) }));

            setLoading(false);
            handleClose();
            navigation(to.CONTRACT_STATUS);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (

        <Fragment>

            <Tooltip
                title={
                    <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                        Elimina el trámite definitivamente. Este no podrá ser recuperado
                    </Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >

                <DeleteAndUpdateCustomButtom onClick={handleClickOpen} variant="outlined" /* style={{ marginRight: '20px' }} */ startIcon={<FaRegTrashAlt className="icon--blue" />}>
                    Eliminar trámite
                </DeleteAndUpdateCustomButtom>

            </Tooltip>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    ¿Deseas eliminar este contrato?
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>

                        <Typography>
                            Si eliminas este contrato, no podrás recuperarlo.
                        </Typography>

                    </DialogContentText>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: '39px',
                            borderRadius: '20px'
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() => deleteCurrentContract()}
                        sx={{
                            height: '39px',
                            borderRadius: '20px',
                            marginLeft: '30px !important'
                        }}
                    />

                </DialogActions>

            </Dialog>

        </Fragment>

    );
}

export default DeleteContractAlert;