import { Fragment } from "react";

import Typography from "@mui/material/Typography";
import { verifiVariable } from "../../../../../utils/enums";

const Contact = ({ showMsnInfo }) => {
    return (
        <Fragment>
            {!verifiVariable(showMsnInfo) && (
                <Typography variant="subtitle1" gutterBottom>
                    Para más información, comunícate con nuestro equipo de
                    soporte al cliente:
                </Typography>
            )}

            <Typography variant="caption" gutterBottom>
                Operaciones: +56 2 3210 3824 &nbsp; &nbsp; / &nbsp; &nbsp; Soporte API: +56 2 3210
                1199 &nbsp; &nbsp; / &nbsp; &nbsp; Ventas: +56 2 3210 3927 <br />{" "}
                contacto@firmavirtual.legal
            </Typography>
        </Fragment>
    );
};

export default Contact;
