import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const fontSize = 'calc(1.5rem + 1vw) !important';

export const FormularioUachtyles = makeStyles((theme) => {

    return createStyles({
        containerShowCancel: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly'
        },
        fileFormContainer: {
            display: "flex",
            flexWrap: "wrap"

        },
        titleHintStyle: {
            color: "quinary.xdark",
            fontWeight: "500",
            fontSize: "1.1rem",
            marginTop: "21px",
        },
        hintBodyButton: {
            backgroundColor: "primary.light",
            px: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            marginTop: "10px"
        },
        hintBodyButtonText: {
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            marginTop: "10px",
            "&:hover": {
                color: "black.main",
                backgroundColor: "transparent",
            }
        },
        hintBodyLinkText: {
            mx: "3px"
        },
        hintListItem: {
            display: "block"
        },
        hintListContainer: {
            my: "10px"
        },
        titleStyles: {
            color: "black.main",
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        hintContainer: {
            py: "15px",
            px: "25px",
            marginRight: "10px",
            paddingBottom: "35px",
            backgroundColor: "primary.xlight",
            borderRadius: "5px",
            flex: "1"
        },
        cssTexto1: {
            fontSize: 'calc(10px + 1vw) !important',
            color: 'black',
            '& strong, em': {
                fontSize: 'calc(10px + 1vw)',
            },
            '& a': {
                fontSize: 'calc(10px + 1vw)',
                color: 'black',
                fontWeight: 'normal',
                textDecoration: 'underline'
            }
        },
        stepBox: {
            width: "100%",
            "& > *": {
                margin: "4rem auto 0rem"
            }
        },
        formBox: {
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                margin: theme.spacing(1)
            }
        },
        stepsperContent: {
            display: 'flex',
            flexDirection: 'column !important',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '100vh'
        },
        texto2: {
            fontSize,
            textAlign: 'center',
            fontStyle: 'italic',

            '& strong': {
                fontSize
            },

            '& a': {
                fontSize,
                color: 'black',
                fontWeight: 'normal',
                textDecoration: 'underline'
            }
        },
        texto1: {
            fontSize,
            textAlign: 'center'
        },
        texto3: {
            textAlign: 'center',
            '& a': {
                fontSize,
                color: 'black',
                fontWeight: 'normal',
                textAlign: 'center',
                textDecoration: 'underline'
            }
        },
        buttomBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            color: 'white !important',
            '& button': {
                fontWeight: '900 !important',
                fontSize: '1.2rem !important',
                '& span': {
                    color: 'white !important'
                }
            },
            '& svg': {
                marginBottom: '9px'
            },
            '& #textPulsar': {
                marginLeft: '10px',

                '& strong': {
                    fontWeight: '500'
                }
            }
        },
        logo: {
            height: '500px',
            width: '500px'
        },
        textContent: {
            display: 'flex',
            flexDirection: 'column !important',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    });

});