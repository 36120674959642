import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { styles } from "../../../../../helpers";

const GridDataAgreement = ({ tramitsAgreement }) => {

    return (
        <Grid container spacing={1} className={styles.gridContainer}>

            <Grid item xs={12}>
                <TableContainer
                    component={Paper}
                >
                    <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Tipo Firma
                                </TableCell>
                                <TableCell>
                                    Precio Notaria
                                </TableCell>
                                <TableCell>Precio</TableCell>
                                <TableCell>Iva</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {tramitsAgreement.length > 0
                                ? tramitsAgreement.map((item, idx) => {
                                    return (

                                        <TableRow
                                            key={`${item.iContractTypeFeeID}-${idx}`}
                                            sx={{
                                                "&:last-child td, &:last-child th":
                                                {
                                                    border: 0,
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {item.tipoFirma}
                                            </TableCell>

                                            <TableCell>
                                                {Math.round(item.notaryPrice)}
                                            </TableCell>

                                            <TableCell>
                                                {Math.round(item.price)}
                                            </TableCell>

                                            <TableCell>
                                                {`${Math.round(item.iva)}%`}
                                            </TableCell>

                                        </TableRow>
                                    );
                                })
                                : null}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    );
};

export default GridDataAgreement;
