import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const DetailSignatoriesStyles = makeStyles((theme) => {

    return createStyles({

        statusHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        cardHeader: {
            padding: '9px !important'
        },
        mainContainer: ({ screenSizeHook }) => ({
            width: '100%',
            //paddingRight: '.813rem',
            "& div": {
                borderRadius: "6px",
            },
            "& .actionParticipantsButtons": {
                marginTop: '.938rem'
            },
            "& h6": {
                lineHeight: "1.2",
                fontFamily: "PoppinsBold",
                fontSize: ".875rem",
            },
            [theme.breakpoints.up('md')]: {
                height: "32rem",
                width: '17.875rem',
            }
        }),
        cardContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
            minHeight: '19rem',
            margin: '1rem 0',
            padding: "1.625rem 1.75rem",
            background: theme.palette.white.main,
            boxShadow: "0px 4px 22px 1px rgba(0, 79, 159, 0.03)",
            "& > div#textInfoParticipans": {
                width: '70%',
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                marginLeft: '1.56rem',
                [theme.breakpoints.up('md')]: {
                    width: '100%',
                    margin: '0',
                    marginTop: '.844rem',
                    justifyContent: 'start',
                    textAlign: 'center',
                    alignItems: 'center'
                },
            },
            [theme.breakpoints.up('md')]: {
                minHeight: '27rem',
                justifyContent: 'start',
                flexDirection: "column",
            }
        },
        textRol: {
            fontSize: '.93rem',
            color: theme.palette.primary.light
        },
        roleItem: {
            display: 'flex',
            flexDirection: 'row',
            margin: '0.438rem 0 0!important',
        },
        contentActionParticipants: {
            width: "100%",
            display: "flex",
            margin: "0px 0px 0px 0px !important",
            "& svg": {
                marginBottom: "5px",
                marginTop: "5px !important",
                fill: theme.palette.white.main,
            },
        },
        contentActionButtoms: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: 'transparent',
            '& > div': {
                display: 'flex',
                alignItems: 'center',
                width: '52.94px',
                height: '50.82px',
                borderRadius: '7px',
                cursor: 'pointer',
                background: theme.palette.white.main
            }
        },
        statusPay: {
            display: 'flex',
            marginTop: '5px',
            '& > svg': {
                marginRight: '3px'
            }
        },
        expandMoreContent: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'stretch',
            '& > div': {
                display: 'flex',
                justifyContent: 'center'
            }
        }
    })

});