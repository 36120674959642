import React, { useState, useEffect } from "react";
import _, { toInteger } from "lodash";
import { Avatar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getContractDetail } from "../../store/contract/contractDetail";
import { store } from "../../store/storeConfig";
import { getUserToken, getUserInfo } from "../storeSelector/storeSelector";
import {
    BreadCrumbTracker,
    Spinner,
    Btn,
    Text,
    PopUp,
    ContractDetailSummary,
} from "..";
import {
    BsCloudDownload,
    BsHandThumbsUp,
    BsInfoCircle,
    BsEnvelope,
    BsCheck2,
} from "react-icons/bs";
import {
    downloadContractDoc,
    downloadCompiledContractDoc,
} from "../../utils/api";
import NumberFormat from "react-number-format";
import { bodyTextStyles } from "../../utils/validations";

const Contractdetail = () => {
    const { state, pathname } = useLocation();
    const userToken = getUserToken();
    const userinfo = getUserInfo();
    const [contractDetail, setContractDetail] = useState(null);
    const [contractMembers, setContractMembers] = useState(null);
    const [contractFiles, setContractFiles] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
        let run = true;
        if (run) {
            store.dispatch(getContractDetail(state, userToken));
            const unsubscribe = store.subscribe(() => {
                const { contractCreator } = store.getState().contract;
                let total = 0;
                let iva = 0;
                let notary = 0;
                let signature = 0;

                if (!_.isEmpty(contractCreator)) {
                    if (contractCreator.contractCreatedDetails !== null) {
                        setContractDetail(
                            contractCreator.contractCreatedDetails.contrato
                        );
                        setContractMembers(
                            contractCreator.contractCreatedDetails.firmantes
                        );
                        setContractFiles(
                            contractCreator.contractCreatedDetails.archivos
                        );

                        contractCreator.contractCreatedDetails.firmantes.forEach(
                            (firmante) => {
                                total =
                                    parseInt(total) +
                                    parseInt(firmante.totalPay);
                                iva = parseInt(iva) + parseInt(firmante.iva);
                                notary =
                                    parseInt(notary) +
                                    parseInt(firmante.notaryPay);
                                signature =
                                    parseInt(signature) +
                                    parseInt(firmante.amount);
                            }
                        );

                        setTotalAmount({
                            total,
                            iva,
                            notary,
                            signature,
                        });
                    }
                }
            });
            return () => unsubscribe();
        }
        return () => (run = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userAvatar = {
        height: "70px",
        width: "70px",
    };

    const buttonText = {
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "transparent",
        color: "black.main",
        fontWeight: "bold",
        "&:hover": {
            color: "primary.light",
            backgroundColor: "transparent",
        },
    };

    const infoTag = {
        display: "flex",
        justifyContent: "center",
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "transparent",
        color: "black.main",
        fontWeight: "bold",
    };

    const closeModal = () => {
        setModalTitle("");
        setModalMessage("");
        setShowModal(!showModal);
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={closeModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
            </PopUp>
        );
    };

    const downloadUniqFile = async (fileName) => {
        setLoading(true);

        try {
            const response = await downloadContractDoc(fileName, userToken);
            response && setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const getFiles = () => {
        if (!_.isEmpty(contractFiles)) {
            return (
                <ul className="margin-y--lg">
                    {contractFiles.map((file, index) => {
                        return (
                            <li
                                key={index}
                                className="flex--start margin-bottom--sm"
                            >
                                <div className="list-unorder__bullet margin-right--sm">
                                    <span>{file.orden + 1}</span>
                                </div>
                                {file.file_name}
                                <BsCloudDownload
                                    className="icon-pointer margin-left--sm"
                                    onClick={() =>
                                        downloadUniqFile(file.file_name)
                                    }
                                />
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    const downloadContract = async () => {
        setLoading(true);

        try {
            const response = await downloadCompiledContractDoc(
                state,
                userToken,
                contractDetail[0].sStatus
            );
            response && setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const getContractMembersSignatureStatus = (showBill, member) => {
        return (
            <div className="flex--start">
                <div className="flex--start status_tag status--approved">
                    {<BsHandThumbsUp className="icon--xs margin-right--sm" />}{" "}
                    Documento aceptado
                </div>
                {showBill ? (
                    <>
                        {member.payment !== "PAYOUT" && (
                            <Btn
                                variant="text"
                                text={"Notificar pago"}
                                size="xs"
                                color="primary"
                                sx={buttonText}
                                startIcon={<BsEnvelope className="icon--xxs" />}
                            />
                        )}
                        {member.payment === "PENDING" ? (
                            <Text sx={infoTag} align="left" variant="body1">
                                <BsInfoCircle className="icon--xxs margin-right--xs" />
                                Pendiente de pago
                            </Text>
                        ) : (
                            <Text sx={infoTag} align="left" variant="body1">
                                <BsCheck2 className="icon--xxs margin-right--xs" />
                                Pago realizado
                            </Text>
                        )}
                    </>
                ) : null}
            </div>
        );
    };

    const showPayersAmount = (member, index) => {
        return (
            <div className="margin-bottom--lg">
                <ul className="flex--1" key={index}>
                    <li className="flex--between margin-top--lg">
                        <div className="flex--start">
                            <span className="margin-right--sm">
                                {member.full_name}
                            </span>
                            <span className="margin-left--xs portion-payer__container">
                                {toInteger(member.amount) > 0
                                    ? `${toInteger(member.portion)}%`
                                    : "0%"}
                            </span>
                        </div>
                        <NumberFormat
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            prefix={"$"}
                            value={
                                parseInt(member.amount) +
                                parseInt(member.iva) +
                                parseInt(member.notaryPay)
                            }
                            displayType={"text"}
                            renderText={(value, props) => (
                                <strong {...props}>{value}</strong>
                            )}
                        />
                    </li>
                </ul>
            </div>
        );
    };

    const renderMembers = (getBill) => {
        if (!_.isEmpty(contractMembers)) {
            return contractMembers.map((member, index) => {
                return (
                    <ul className="flex--1" key={index}>
                        <li className="flex--start padding-bottom--md">
                            <div className="list-item-name flex--1 font-color--primary-light" />
                            <div className="flex--2">
                                {getBill
                                    ? getContractMembersSignatureStatus(
                                          true,
                                          member
                                      )
                                    : getContractMembersSignatureStatus()}
                            </div>
                        </li>
                        <li className="flex--start padding-bottom--md">
                            <strong className="list-item-name flex--1 font-color--primary-light">
                                {`Firmante ${index + 1}`}{" "}
                            </strong>
                        </li>
                        <li className="flex--start padding-bottom--xs">
                            <strong className="list-item-name flex--1">
                                Nombre
                            </strong>
                            <span className="flex--2 font-color--quinary">
                                {member.full_name}
                            </span>
                        </li>
                        <li className="flex--start padding-bottom--xs">
                            <strong className="list-item-name flex--1">
                                RUT
                            </strong>
                            <span className="flex--2 font-color--quinary">
                                {member.RUT}
                            </span>
                        </li>
                        <li className="flex--start padding-bottom--xs">
                            <strong className="list-item-name flex--1">
                                Correo electrónico
                            </strong>
                            <span className="flex--2 font-color--quinary">
                                {member.email}
                            </span>
                        </li>
                        <li className="flex--start padding-bottom--xs">
                            <strong className="list-item-name flex--1">
                                Número de celular
                            </strong>
                            <span className="flex--2 font-color--quinary">
                                {member.phone}
                            </span>
                        </li>
                    </ul>
                );
            });
        }
    };

    const renderPayersMembers = () => {
        if (!_.isEmpty(contractMembers)) {
            return contractMembers.map((member, index) => {
                let ret;
                if (member.payment === "PENDING" && member.amount !== "")
                    ret = showPayersAmount(member, index);
                return ret;
            });
        }
    };

    return (
        <>
            {displayModal()}
            <div className="content-detail__container">
                <div className="contract-details__main">
                    <BreadCrumbTracker
                        location={pathname}
                        status={
                            !_.isEmpty(contractDetail) &&
                            contractDetail[0].sStatus
                        }
                    />
                    <div className="contract-details__container">
                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            1
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos personales solicitante
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Mis datos personales
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="info-body padding-y--xxs flex--between">
                                <div className="flex--start flex--align-start flex--1 padding-top--xxl">
                                    <div className="flex--center">
                                        <Avatar sx={userAvatar} />
                                    </div>
                                    <ul className="padding-left--lg flex--1">
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Nombre
                                            </strong>
                                            <span className="flex--2 font-color--quinary">{`${userinfo.firstName} ${userinfo.lastName}`}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="flex--1" />
                            </div>
                        </section>
                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            2
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos del trámite
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Datos del trámite a realizar
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="info-body padding-y--xxs flex--between">
                                <div className="flex--start flex--align-start flex--1 padding-top--xxl">
                                    <ul className="flex--1">
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Id de trámite
                                            </strong>
                                            <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                {!_.isEmpty(contractDetail) &&
                                                    contractDetail[0].autoId}
                                            </span>
                                        </li>
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Tipo de trámite
                                            </strong>
                                            <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                {!_.isEmpty(contractDetail) &&
                                                    contractDetail[0]
                                                        .sTipoContrato}
                                            </span>
                                        </li>
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Trámite a gestionar
                                            </strong>
                                            <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                {!_.isEmpty(contractDetail) &&
                                                    contractDetail[0]
                                                        .sClaseContrato}
                                            </span>
                                        </li>
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Firmantes
                                            </strong>
                                            <span className="flex--2 font-color--quinary">
                                                {!_.isEmpty(contractDetail) &&
                                                    contractDetail[0]
                                                        .NumeroFirmantes}
                                            </span>
                                        </li>
                                        <li className="flex--start padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Tipo de firma
                                            </strong>
                                            <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                {!_.isEmpty(contractDetail) &&
                                                    contractDetail[0]
                                                        .sTipoFirma}
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="container--relative padding-left--lg flex--1">
                                        {loading && (
                                            <Spinner
                                                type="clip"
                                                loading={true}
                                                opacity={true}
                                            />
                                        )}
                                        <div className="width--full flex--around padding-bottom--xs">
                                            <strong className="list-item-name flex--1">
                                                Documentos a firmar
                                            </strong>
                                            <button
                                                className="flex--start button--sm button--lg-rounded margin-right--xs"
                                                onClick={downloadContract}
                                            >
                                                <BsCloudDownload className="icon--xxs icon-pointer margin-right--sm" />{" "}
                                                <span>Descargar</span>
                                            </button>
                                        </div>
                                        <div>{getFiles()}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between container--relative">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            3
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos de los firmantes
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Datos de las personas que firman los
                                            documentos
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="info-body padding-y--xxs flex--between">
                                <div className="width--full grid--columns-2 padding-top--xxl">
                                    {renderMembers()}
                                </div>
                            </div>
                        </section>
                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between container--relative">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            4
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos de facturación
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Datos de las personas responsables
                                            de pago
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="grid--columns-2">
                                <div className="info-body padding-y--xxs flex--between">
                                    <div className="width--full grid--columns-2 padding-top--xxl">
                                        {renderMembers(true)}
                                    </div>
                                </div>
                                <ContractDetailSummary
                                    summary={totalAmount}
                                    contract={contractDetail}
                                    payers={renderPayersMembers()}
                                />
                                {/* <div className="info-body padding-y--xxs flex--between">
                                    <div className="flex--start flex--align-start flex--1 padding-top--xxl">
                                        <div className="flex--1">
                                            <strong className="list-item-name margin-bottom--sm">Resumen del pedido</strong>
                                            <div className="contract-summary__detail">
                                                <div className="margin-bottom--lg text--lowercase first-letter--uppercase"><strong>{`${!_.isEmpty(contractDetail) && contractDetail[0].sTipoFirma}, ${!_.isEmpty(contractDetail) && contractDetail[0].NumeroFirmantes} firmantes`}</strong></div>
                                                <ul className="margin-bottom--sm">
                                                    <li className="flex--between margin-bottom--xs"><span>Servicio de notaría</span>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={totalAmount.notary}
                                                            displayType={"text"}
                                                            renderText={(value, props) => <span {...props}>{value}</span>}
                                                        />
                                                    </li>
                                                    <li className="flex--between margin-bottom--xs"><span>Servicio firma electrónica</span>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={totalAmount.signature}
                                                            displayType={"text"}
                                                            renderText={(value, props) => <span {...props}>{value}</span>}
                                                        />
                                                    </li>
                                                    <li className="flex--between margin-bottom--xs"><span>IVA</span>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={totalAmount.iva}
                                                            displayType={"text"}
                                                            renderText={(value, props) => <span {...props}>{value}</span>}
                                                        />
                                                    </li>
                                                    <li className="flex--between margin-top--lg"><strong>Total</strong>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={totalAmount.total}
                                                            displayType={"text"}
                                                            renderText={(value, props) => <strong {...props}>{value}</strong>}
                                                        />
                                                    </li>
                                                </ul>
                                                <div className="line-break--sm margin-top--lg" />
                                                {renderPayersMembers()}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contractdetail;
