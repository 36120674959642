import React from "react";
import ContentCards from "./ContentCards";

const ContentDetail = () => {
    return (
        <div className="content-detail__container">
            <ContentCards />
        </div>
    );
};

export default ContentDetail;
