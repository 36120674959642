import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";
import { BsLink } from 'react-icons/bs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { ModalAtachTypeContractToProviderStyles } from "./ModalAtachTypeContractToProvider.css";
import Spinner from "../../../../spinners/Spinner";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { assocContractToProvider } from "../../../../../utils/api-providers";
import { getUserToken } from "../../../../storeSelector/storeSelector";
import ManageTramits from "../../../../manageTramits";


const ModalAtachTypeContractToProvider = ({ proveedor }) => {
    const {
        contract: {
            tramites: {
                providersTramList
            }
        }
    } = useSelector((state) => state);
    const screenSizeHook = ScreenSizeHook();
    const styles = ModalAtachTypeContractToProviderStyles();
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: {
            contractTypeList: '',
            tramtTypes: []
        }
    });
    const userToken = getUserToken();
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [description, setDescription] = useState('');
    const [texButtonGoback, setTexButtonGoback] = useState('');
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [contractsType, setContractsType] = useState([]);

    const [signatureCertList, setSignatureCertList] = useState([]);
    const [signatureList, setSignatureList] = useState([]);
    const [signatureProtList, setSignatureProtList] = useState([]);

    const filter = watch('contractTypeList');

    const list = [...contractsType];

    const handleClickOpen = () => {
        setModalTitle('Tipo de Firma por Proveedor');
        setDescription(`Seleccione los tipos de contratos a asociar al proveedor ${proveedor['notaryName']}.`);
        setTexButtonGoback('Cerrar');
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (loading) return;
        reset();
        setOpen(false);
    };

    const onSubmit = async () => {
        try {

            setLoading(true);

            const selectedTramits = [...signatureCertList, ...signatureList, ...signatureProtList];

            const arrayList = []

            selectedTramits.map((item) => arrayList.push(item['iContractTypeFeeID']));

            if (selectedTramits.length === 0) {
                setLoading(false);
                return;
            };

            const restAssocContractToProdv = await assocContractToProvider({ rut: proveedor['rut'], contractTypeList: arrayList }, userToken);

            if (restAssocContractToProdv['status'] === 'success') {

                setTexButtonGoback('Cerrar');
                setModalTitle('Tipos de Contratos');
                setDescription('Los Contratos selecionados han sido asociado correctamente.');
                reset();
                setRestStatus(true);

            } else {
                reset();
                setTexButtonGoback('Cerrar');
                setModalTitle('Tipos de Contratos');
                setDescription(restAssocContractToProdv['message']['msg']);
                setRestStatus(false);
            }

            setLoading(false);

        } catch (error) {
            setTexButtonGoback('Cerrar');
            setModalTitle('Tipos de Contratos');
            setDescription('Hubo un problema en el registro de la asociación de contratos seleccionados, intentelo nuevamente o comuniquese con un administrador del sistema.');
            setRestStatus(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (contractsType.length === 0) setContractsType(providersTramList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providersTramList]);

    return (
        <React.Fragment>

            {contractsType.length !== 0 && (<BsLink className="icon--xs icon-pointer icon--blue-light" onClick={() => handleClickOpen()} />)}

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={['xs'].includes(screenSizeHook) ? 'xs' : 'xl'}
            >

                {(restStatus === undefined && <Stack className={styles.buttomsTopContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <AiOutlineCloseCircle fontSize="inherit" size={20} />
                    </IconButton>

                </Stack>)}


                {restStatus === true && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegGrinWink fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                {restStatus === false && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegSadCry fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>

                        {loading && <Spinner loading={true} type="bar" opacity={true} />}

                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (<Grid container spacing={1} className={styles.gridContainer}>


                            <Grid item xs={12}>
                                <Controller
                                    name="contractTypeList"
                                    control={control}
                                    rules={{
                                        required: 'Debe Seleccionar al menos un tipo de contrato'
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {

                                        return (
                                            <FormControl sx={{ m: 1, width: '100%' }} error={fieldState.error ? true : false}>

                                                <InputLabel id="demo-simple-select-label">Tipo de Contrato</InputLabel>

                                                <Select
                                                    ref={ref}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={value}
                                                    label="Tipo de Contrato"
                                                    onChange={onChange}
                                                >

                                                    <MenuItem value={'SIGNATURE_CERT_NOTARIAL'}>Certificación</MenuItem>

                                                    <MenuItem value={'SIGNATURE'}>Firma</MenuItem>

                                                    <MenuItem value={'SIGNATURE_PROT_NOTARIAL'}>Protocolización</MenuItem>

                                                </Select>

                                                <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>

                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 2.5, display: filter === 'SIGNATURE_CERT_NOTARIAL' ? 'flex' : 'none' }}>

                                <ManageTramits
                                    list={list.filter((item) => item['sModal'] === 'SIGNATURE_CERT_NOTARIAL')}
                                    listTitle="Certificación"
                                    selecteds={(e) => setSignatureCertList([...e])}
                                />

                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: 2.5, display: filter === 'SIGNATURE' ? 'flex' : 'none' }}>

                                <ManageTramits
                                    list={list.filter((item) => item['sModal'] === 'SIGNATURE')}
                                    listTitle="Firma"
                                    selecteds={(e) => setSignatureList(e)}
                                />

                            </Grid>

                            <Grid item xs={12} sx={{ display: filter === 'SIGNATURE_PROT_NOTARIAL' ? 'flex' : 'none' }}>

                                <ManageTramits
                                    list={list.filter((item) => item['sModal'] === 'SIGNATURE_PROT_NOTARIAL')}
                                    listTitle="Protocolización"
                                    selecteds={(e) => setSignatureProtList(e)}
                                />

                            </Grid>

                        </Grid>)}

                    </DialogContent>

                    <DialogActions style={{ justifyContent: 'center' }}>

                        {restStatus === undefined && (<Stack className={styles.constentDialogActionSend}>
                            <Button onClick={handleClose} variant="contained" className={styles.cancelButton} >Cancelar</Button>
                        </Stack>)}

                        {restStatus === undefined && (<Stack className={styles.constentDialogActionSend}>
                            <Button variant="contained" type="submit" className={styles.goBackButton} /* disabled={valuePrueba.length === 0 ? true : false} */ >Registrar</Button>
                        </Stack>)}

                        {restStatus !== undefined && (<Stack className={styles.constentDialogActionGoBack}>
                            <Button variant="contained" onClick={() => { handleClose() }} className={styles.goBackButton}>{texButtonGoback}</Button>
                        </Stack>)}

                    </DialogActions>

                </form>

            </Dialog>

        </React.Fragment>
    );
}

export default ModalAtachTypeContractToProvider;
