import React, { useState } from 'react';

import { Box, Button, Grid, Typography, styled } from '@mui/material';

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/icon-info-outlined.svg';

import { ExpressProcessStyles } from '../../../index.css';
import ModalAddSignInfo from '../../../../../modalAddSignsInfo';

const HeaderAddSignInfo = () => {

    const StyledButton = styled(Button)((props) => ({
        textTransform: 'none',
        padding: 0
    }));

    const [openModal, setOpenModal] = useState(false);

    const styles = ExpressProcessStyles();

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    return (
        <Grid className={styles.containerHeader}>
            <ModalAddSignInfo openModal={openModal} setOpenModal={setOpenModal} />

            <Box className='box-icon'>
                <InfoIcon />
            </Box>

            <Box className='box-text'>
                <Typography>
                    Por favor, <span>haz clic en el documento</span> que has subido para asignar firmas. <br />
                    <span>Recuerda asignar uno a uno tus clientes en el espacio correspondiente.</span>
                </Typography>
                <StyledButton onClick={handleOpenModal}>¿Cómo hago esto?</StyledButton>
            </Box>
        </Grid>
    )
}

export default HeaderAddSignInfo