import React from 'react';
import NumberFormat from 'react-number-format';
import _ from "lodash";

const ContractDetailSummary = ({summary, contract, payers}) => {

    return (
        <div className="info-body padding-y--xxs flex--between">
            <div className="flex--start flex--align-start flex--1 padding-top--xxl">
                <div className="flex--1">
                    <strong className="list-item-name margin-bottom--sm">Resumen del pedido</strong>
                    <div className="contract-summary__detail">
                        <div className="margin-bottom--lg text--lowercase first-letter--uppercase"><strong>{`${!_.isEmpty(contract) && contract[0].sTipoFirma}, ${!_.isEmpty(contract) && contract[0].NumeroFirmantes} firmantes`}</strong></div>
                        <ul className="margin-bottom--sm">
                            <li className="flex--between margin-bottom--xs"><span>Servicio de notaría</span>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    value={summary.notary}
                                    displayType={"text"}
                                    renderText={(value, props) => <span {...props}>{value}</span>}
                                />
                            </li>
                            <li className="flex--between margin-bottom--xs"><span>Servicio firma electrónica</span>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    value={summary.signature}
                                    displayType={"text"}
                                    renderText={(value, props) => <span {...props}>{value}</span>}
                                />
                            </li>
                            <li className="flex--between margin-bottom--xs"><span>IVA</span>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    value={summary.iva}
                                    displayType={"text"}
                                    renderText={(value, props) => <span {...props}>{value}</span>}
                                />
                            </li>
                            <li className="flex--between margin-top--lg"><strong>Total</strong>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    value={summary.total}
                                    displayType={"text"}
                                    renderText={(value, props) => <strong {...props}>{value}</strong>}
                                />
                            </li>
                        </ul>
                        {payers ? <><div className="line-break--sm margin-top--lg" /> {payers}</> : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContractDetailSummary;
