import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";
import moment from "moment";
// import { createSelector } from "reselect";

const slice = createSlice({
    name: "signup",
    initialState: {
        user: {},
        loading: false,
        lastFetch: null,
        message: "",
        error: null,
        resetForm: false,
        status: null,
        code: null
    },
    reducers: {
        PURGE_SIGNUP_STATE: (status, action) => {
            status.user = {};
            status.loading = false;
            status.lastFetch = null;
            status.message = "";
            status.error = null;
            status.status = null;
            status.code = null;
        },
        USER_SIGNUP_REQUESTED: (status, action) => {
            status.message = "";
            status.loading = true;
            status.user = {};
        },
        USER_SIGNUP_REQUESTED_FAIL: (status, action) => {
            status.message = "";
            status.loading = false;
        },
        USER_SIGNUP_RECIVED: (status, action) => {
            if (action.payload.status !== "fail") {
                status.message = action.payload.status;
                status.user = action.payload.message;
                status.loading = false;
                status.lastFetch = Date.now();
            } else {
                status.loading = false;
                status.message = action.payload.message;
            }
        },
        USER_SIGNUP_SUCCESS: (status, action) => {
            //console.log(action.payload)

            //if(action.payload.status !== "fail"){
            console.log('action: ', action.payload['status']);// success

            if (action.payload['status'] === 'success') {
                status.error = "";
                status.message = action.payload.message
                status.loading = false;
                status.status = action.payload['status'];
            } else {
                status.error = action.payload.message['msg'];
                status.message = action.payload.message['msg'];
                status.loading = false;
                status.status = action.payload['status'];
                status.code = action.payload.message['error'];
            }


            /* if (action.payload['status'] === 'status') {
                status.error = action.payload.message[0].userApp.error;
                status.message = action.payload.message[0].userApp.msg;
                status.loading = false;
            } else {
                status.error = "";
                status.message = action.payload.status
                status.loading = false;
                status.resetForm = true;
            } */

            /*} else {
                status.error = "";
                status.message = action.payload.message;
                status.loading = false;
            }*/
        },
        USER_SIGNUP_FAIL: (status, action) => {
            status.message = action.payload.message;
            status.loading = false;
            status.status = action.payload.status
        },
    },
});

const {
    PURGE_SIGNUP_STATE,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAIL,
    USER_SIGNUP_RECIVED,
    USER_SIGNUP_REQUESTED,
    USER_SIGNUP_REQUESTED_FAIL,
} = slice.actions;

export const resetSignup = () => {
    return { type: PURGE_SIGNUP_STATE.type };
};

export const setSession = (user) =>
    apiCallStart({
        url: "/api/v1/userapp",
        method: "POST",
        data: user,
        onStart: USER_SIGNUP_REQUESTED.type,
        onSuccess: USER_SIGNUP_SUCCESS.type,
        onError: USER_SIGNUP_FAIL.type,
    });

export const loadSession = () => (dispatch, getState) => {
    const { lastFetch } = getState().session.signup;

    const diffMinutes = moment().diff(moment(lastFetch), "minutes");

    if (diffMinutes < 10) return;
    dispatch(
        apiCallStart({
            url: "/api/v1/userapp",
            onStart: USER_SIGNUP_REQUESTED.type,
            onSuccess: USER_SIGNUP_RECIVED.type,
            onError: USER_SIGNUP_REQUESTED_FAIL.type,
        }),
    );
};

export default slice.reducer;

// Selectors
// export const getLogged = createSelector(
//   (state) => state.entities.signup,
//   (signup) => signup.login
// );
