import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const ManageCouponsTypeStyless = makeStyles((theme) => {
    return createStyles({
        container: {
            margin: '.5rem 1rem'
        },
        goBack_box: {
            display: 'flex',
            alignItems: 'center',
            flex: '0 0 auto',
            margin: '2rem 0',
            '& > p': {
                marginLeft: '1rem',
                fontSize: '1.8rem',
                color: theme.palette.primary.main,
                textDecoration: 'underline'
            },
            '& > :hover': {
                cursor: 'pointer',
            },
        },
        breadcrumbs_box: {
            display: 'flex',
            alignItems: 'center',
            margin: '1rem 0'
        },
        breadcrumbs_item_active: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.white.main,
            padding: '.25rem 1rem',
            margin: '0 .5rem',
            borderRadius: '10px',
            '& > p': {
                color: theme.palette.primary.main,
                fontFamily: 'PoppinsBold',
                fontSize: '.8rem'
            }
        },
        breadcrumbs_item_disabled: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.white.main,
            padding: '.25rem .5rem',
            margin: '0 .5rem',
            borderRadius: '10px',
            '& > p': {
                color: theme.palette.gray.light,
                marginLeft: '.25rem',
                fontSize: '.8rem'
            }
        },
        formStyles: {
            backgroundColor: theme.palette.white.main,
            padding: '.5rem 1rem',
            "& p": {
                fontSize: '.85rem'
            }
        },
        form_box: {
            margin: '.5rem 0'
        },
        formCheckSection: {
            display: 'flex'
        },
        form_boldText: {
            fontSize: '.9rem',
            fontFamily: 'PoppinsBold',
            margin: '0 0 0 0'
        },
        form_regularText: {
            fontSize: '.85rem'
        },
        form_radioBox: {
            display: 'flex',
            alignItems: 'center',
            '& > label': {
                marginRight: '1rem'
            }
        }
    });
});