import React from "react";
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditTwoTone from '@mui/icons-material/EditTwoTone';

import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import { getNombres } from "../../../../../utils/validations";
import CompletarDatosUsuario from "../completarDatosUsuario";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";


const DatosUsuario = () => {
    const {
        fvavanzada: {
            clvUnicadAtosUsuario
        }
    } = useSelector(state => state);
    const screenSizeHook = ScreenSizeHook();
    const styles = FirmaVirtualAvanzadaStyles();

    const [editar, setEditar] = React.useState(false);

    return (
        <React.Fragment>

            <Box className={styles.mainContentCarUserInfo} style={{ justifyContent: 'space-between' }}>

                <Box className={styles.itemContentUserInfo} style={{ marginRight: 5 }}>

                    <Typography component="div" variant="h7">
                        Rut:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {![null].includes(clvUnicadAtosUsuario) ? clvUnicadAtosUsuario['RolUnico']['numero'] + '-' + clvUnicadAtosUsuario['RolUnico']['DV'] : ''}
                    </Typography>

                </Box>

                <Box className={styles.itemContentUserInfo}>

                    <Typography component="div" variant="h7">
                        Nombres:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {![null].includes(clvUnicadAtosUsuario) ? getNombres(clvUnicadAtosUsuario['name']['nombres']) : ''}
                    </Typography>

                </Box>

                <Box className={styles.itemContentUserInfo}>

                    <Typography component="div" variant="h7">
                        Apellidos:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {![null].includes(clvUnicadAtosUsuario) ? getNombres(clvUnicadAtosUsuario['name']['apellidos']) : ''}
                    </Typography>

                </Box>

            </Box>

            <br />

            <Typography variant="body2" gutterBottom sx={{ textAlign: 'center' }}>
                Verifique su informacion antes de continuar, recuerde que debe iniciar sesion con su clave unica para obtener su FEA.
            </Typography>

            <br />

            <Box
                className={styles.mainContentCarUserInfo}
                style={{
                    justifyContent: ['xs'].includes(screenSizeHook) ? 'center' : 'space-between',
                    flexDirection: ['xs'].includes(screenSizeHook) ? 'column' : 'row'
                }}
            >

                {/* <Box className={styles.contentUserInfo}> */}

                <Box className={styles.itemContentUserInfo}>

                    <Typography component="div" variant="h7">
                        Número de Documento:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['numeroDocumento'] : ''}
                    </Typography>

                    <br />

                    <Typography component="div" variant="h7">
                        Teléfono:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['telefono'] : ''}
                    </Typography>

                </Box>

                <Box className={styles.itemContentUserInfo}>

                    <Typography component="div" variant="h7">
                        Estado Civil:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['estadoCivil'] : ''}
                    </Typography>

                    <br />

                    <Typography component="div" variant="h7">
                        Correo:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['email'] : ''}
                    </Typography>

                </Box>

                <Box className={styles.itemContentUserInfo}>

                    <Typography component="div" variant="h7">
                        Fecha de nacimiento:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['fechaNacimiento'] : ''}
                    </Typography>

                    <br />

                    <Typography component="div" variant="h7">
                        Pin Firma:
                    </Typography>

                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {(![null, undefined].includes(clvUnicadAtosUsuario) && ![null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? clvUnicadAtosUsuario['formData']['pinFirma'] : ''}
                    </Typography>

                </Box>

                <Box className={styles.itemContentUserInfo}>

                    <IconButton aria-label="delete" size="small" onClick={() => setEditar(!(editar))}>
                        <EditTwoTone fontSize="small" />
                    </IconButton>

                    {!['xs'].includes(screenSizeHook) && (
                        <React.Fragment>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                &nbsp;
                            </Typography>

                            <br />

                            <Typography component="div" variant="h7">
                                &nbsp;
                            </Typography>

                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                &nbsp;
                            </Typography>
                        </React.Fragment>
                    )}

                </Box>

                {/* </Box> */}

            </Box>

            {editar && (<CompletarDatosUsuario />)}

        </React.Fragment>
    );
}

export default DatosUsuario;