import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Tabs
                sx={{borderBottom: 1, borderColor: "divider"}}
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                aria-label="basic tabs example"
                variant="fullWidth"
            >
                <Tab label="Cut" {...a11yProps(0)} />
                <Tab label="Ruby" {...a11yProps(1)} />
                <Tab label="Php" {...a11yProps(2)} />
                <Tab label="Phyton" {...a11yProps(3)} />
                <Tab label="Nodejs" {...a11yProps(4)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                &lt;server&gt; &lt;featureManager&gt;
                &lt;feature&gt; &lt;/feature&gt; &lt;/featureManager&gt;
                &lt;/server&gt;
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
            <TabPanel value={value} index={3}>
                Item Three
            </TabPanel>
            <TabPanel value={value} index={4}>
                Item Three
            </TabPanel>
        </Box>
    );
}
