import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
// import { theme } from '../../../theme/theme';

export const EditParticipantStyles = makeStyles((theme) => {

    return createStyles({
        formParticipant: {
            margin: '29px',
            marginTop: '0px'
        },
        dataGridNoRows: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        },
        svgIconAddSignatories: {
            width: '50px',
            height: '50px',
            cursor: 'pointer'
        },
        errorMessageStyles: {
            color: "white.main",
            fontSize: "0.95rem",
            fontWeight: "500"
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        floatButtom: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        payoutMembersStyles: {
            color: "black.main",
            marginBottom: "0",
            fontSize: "0.95rem"
        },
        payoutTitleStyles: {
            color: theme.palette.primary.light,
            fontWeight: "500",
            marginBottom: "10px",
            fontSize: "0.95rem"
        },
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "right",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        autocompleteInput: {
            background: "#f8faff",
            border: "2px solid #b4bacc",
            borderRadius: "7px",
        },
        labelStyles: {
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "0.95rem"
        },
        hintStyles: {
            color: theme.palette.quinary.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '44px'
        },
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        }
    });

});