import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsEye } from 'react-icons/bs';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import ScreenSizeHook from '../../../../../hooks/ScreenSizeHook';
import Spinner from '../../../../spinners/Spinner';
import { getProvider } from '../../../../../store/providers/providers';
import ListAsocDocsHook from '../../hooks/listAsocDocsHook';
import { ProvidesStyles } from '../../ProvidersStyles.css';



const columns = [
    { id: 'sContractTypeName', label: 'Nombre', minWidth: 170 },
    { id: 'groupBy', label: 'Tipo de Contrato', minWidth: 100 },
    { id: 'iSignCount', label: 'Cant. Firmantes', minWidth: 100, align: 'center' }
];

const createData = (sContractTypeName, groupBy, iSignCount) => {
    return { sContractTypeName, groupBy, iSignCount };
}

const ModalAssocDocs = ({ provider }) => {
    const styles = ProvidesStyles();
    const {
        session: {
            login: { user }
        },
        providers: {
            provider: restProvider,
            loading
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [description, setDescription] = useState('');
    const listAsocDocsHook = ListAsocDocsHook(restProvider);


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    // eslint-disable-next-line
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleClickOpen = () => {

        const { token } = user;

        const { rut } = provider;

        dispatch(getProvider(rut, token));

        setModalTitle(`Documentos asociados al proveedor ${provider['notaryName']}`);
        setDescription(`Lista de documentos.`);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {

        setRows(listAsocDocsHook.map((item) => createData(item['sContractTypeName'], item['groupBy'], item['iSignCount'])));

    }, [listAsocDocsHook]);

    return (
        <Fragment>

            <BsEye className="icon--xs icon-pointer icon--blue-light" onClick={() => handleClickOpen()} />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={['xs'].includes(screenSizeHook) ? 'xs' : 'xl'}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    {modalTitle}
                </DialogTitle>

                <DialogContent>

                    {loading && <Spinner loading={true} type="bar" opacity={true} />}

                    <DialogContentText align="center">
                        {description}
                    </DialogContentText>

                    <br />

                    <Grid container spacing={1}>

                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {column.format && typeof value === 'number'
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>

                    </Grid>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center' }}>

                    <Stack>
                        <Button variant="contained" className={styles.goBackButton} onClick={() => handleClose()} >Cerrar</Button>
                    </Stack>

                </DialogActions>

            </Dialog>

        </Fragment>
    );
}

export default ModalAssocDocs;