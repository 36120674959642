import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry, FaRegSurprise } from "react-icons/fa";

import Spinner from "../../../../spinners/Spinner";

import { BsTrash } from "react-icons/bs";
import { ModalUserDeleteStyles } from "./ModalUserDelete.css";
import { addUsers } from "../../../../../store/users/userList";

const ModalUserDelete = ({ userData }) => {
    const {
        users: { listUsers },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const styles = ModalUserDeleteStyles();
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [description, setDescription] = useState("");
    const [texButtonGoback, setTexButtonGoback] = useState("");
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle("Eliminar usuario");
        setDescription("Seguro desea eliminar al usuario?.");
        setTexButtonGoback("Volver");
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        if (loading) return;

        setOpen(false);
    };

    const deleteDocs = async () => {
        try {
            const arrayList = [...listUsers];

            setLoading(true);

            const indexOfObject = arrayList.findIndex((object) => {
                return object.sRUT === userData["sRUT"];
            });

            if (indexOfObject > -1) {
                arrayList.splice(indexOfObject, 1);
            }

            dispatch(addUsers(arrayList));

            setRestStatus(true);
            setTexButtonGoback("Cerrar");
            setModalTitle("Usuario eliminado");
            setDescription("El Usuario ha sido eliminado de forma exitosa");

            setLoading(false);
        } catch (error) {
            setTexButtonGoback("Cerrar");
            setModalTitle("Problemas al borrar");
            setDescription(
                "El Proveedor no ha podido ser eliminado, intente nuevamente o comunquese con un administrador del sistema"
            );
            setRestStatus(false);
            setLoading(false);
        }
    };

    return (
        <>
            <BsTrash
                onClick={() => handleClickOpen()}
                className="icon--xs icon-pointer icon--blue-light"
            />

            <Dialog open={open} onClose={handleClose} maxWidth={"xs"}>
                {restStatus === undefined && (
                    <Stack className={styles.buttomsTopContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <AiOutlineCloseCircle
                                fontSize="inherit"
                                size={20}
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === true && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegGrinWink
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                {restStatus === false && (
                    <Stack className={styles.buttomsTopSuccessContent}>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={handleClose}
                        >
                            <FaRegSadCry
                                fontSize="inherit"
                                size={150}
                                className="icon--red"
                            />
                        </IconButton>
                    </Stack>
                )}

                <DialogTitle className={styles.constentDialogTitle}>
                    {modalTitle}
                </DialogTitle>

                <DialogContent align="center">
                    <DialogContentText align="center">
                        {description}
                    </DialogContentText>

                    <br />

                    {restStatus === undefined && (
                        <FaRegSurprise
                            fontSize="inherit"
                            size={150}
                            className="icon--red"
                        />
                    )}

                    {loading && (
                        <Spinner loading={true} type="bar" opacity={true} />
                    )}
                </DialogContent>

                <DialogActions>
                    {restStatus === undefined && (
                        <Stack className={styles.constentDialogActionSend}>
                            <Button
                                variant="contained"
                                className={styles.goBackButton}
                                onClick={() => deleteDocs()}
                            >
                                Borrar
                            </Button>
                        </Stack>
                    )}

                    {restStatus !== undefined && (
                        <Stack className={styles.constentDialogActionGoBack}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleClose();
                                }}
                                className={styles.goBackButton}
                            >
                                {texButtonGoback}
                            </Button>
                        </Stack>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalUserDelete;
