import React from 'react';

import { Divider, Grid, Link, Typography } from '@mui/material';

import { ReactComponent as DownloadIcon } from '../../assets/icons/icon-download-outlined-blue.svg';
import { theme } from '../../theme/theme';

const UserManuals = ({ download }) => {

    const containerStyle = {
        margin: '0 0 1rem 0'
    };

    const downloadBox = {
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        marginBottom: '1rem',
        '& p': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            fontFamily: 'PoppinsRegular'
        },
        '& > *': {
            margin: '0 .25rem'
        }
    };

    return (
        <Grid sx={containerStyle}>
            <Grid sx={downloadBox}>
                <Grid>
                    <DownloadIcon />
                </Grid>

                <Grid>
                    <Link href={download} target='_blank'>
                        <Typography>Descargar manual de uso</Typography>
                    </Link>
                </Grid>
            </Grid>

            <Divider />
        </Grid>
    )
}

export default UserManuals