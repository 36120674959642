import { createSlice } from "@reduxjs/toolkit";
// import { PURGE } from "redux-persist";

const slice = createSlice({
    name: "dashboard",
    initialState: {
        loaded: "",
    },
    reducers: {
        LOAD_CONTENT: (state, action) => {
            state.loaded = action.payload;
        },
    },
});

export const { LOAD_CONTENT, CURRENT_STEP } = slice.actions;

//! Remove this reducer
export const loadContent = (content) => {
    return { type: LOAD_CONTENT.type, payload: content };
};

export default slice.reducer;
