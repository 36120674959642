import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomToolBar from './molecules/CustomToolBar';
import { gridColumns, localizedTextsMap } from "./constants";
import Spinner from "../../spinners/Spinner";
import { DataGrid } from '@mui/x-data-grid';
import { styles } from '../../../helpers';
import { getUsersStorage } from '../../../store/users/userList';
import ModalAlert from '../../floats/ModalAlert';

const ManagerUsers = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            listUsers,
            loading,
            status,
            message
        },
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [key, setKey] = useState("");
    const [textSpinner, setTextSpinner] = React.useState('');
    const [dataGrid, setDataGrid] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [modalStatus, setModalStatus] = React.useState({});

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    const getUsers = () => {
        const { token } = user;
        dispatch(getUsersStorage(token));
    }

    React.useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    React.useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTextSpinner('Cargando lista de usuarios...');
        setDataGrid(
            listUsers.map((user) => {
                return {
                    ...user,
                    sPicture: "https://placeimg.com/100/100/people",
                };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listUsers]);

    const genKey = () => {
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );
    };

    const renderList = React.useCallback(() => {

        return (
            <div className="container--relative contracts-status-list__container" style={{ height: 'auto', width: '100%' }}>

                <div style={{ display: 'flex', height: '100%' }}>

                    <div style={{ flexGrow: 1 }}>

                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.sRUT}
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading}
                            sx={styles.gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolBar
                                        genKey={genKey}
                                        updateData={() => { setTextSpinner('Actualizando lista de usuarios...'); getUsers(); }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "firstName", sort: "desc" },
                                    ],
                                },
                            }}
                        />

                    </div>

                </div>

            </div>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, gridColumns]);

    return (
        <div className="content-detail__container" key={key}>

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
}

export default ManagerUsers