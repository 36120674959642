import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import FilePreview from "react-file-preview-latest";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import { downloadANFContractDoc, downloadCompiledContractDoc } from "../../../../../utils/api";
import Spinner from "../../../../spinners/Spinner";
import { downloadFileFromObject, /* downloadFileFromURL ,*/ verifiVariable } from "../../../../../utils/enums";
import { useLocation } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 11px 5px rgba(0, 79, 159, 0.04)",
    borderRadius: "7px",
    p: 4,
};

const ViewModalDocs = ({ restError, openModal, setOpenModal, tramitType, download, docLog }) => {
    const {
        formANFTramit: {
            detailContract,
            detailContractFromExpress: {
                contrato: contratoExpress,
            }
        },
        formExpressTramit: { detailContract: contractCreatedDetails },
        contract: {
            contractCreator: { contractCreatedDetails: contractCreatedDetailsAproved },
        },
        session: {
            login: { user },
        }
    } = useSelector((state) => state);

    const [filePreview, setFilePreview] = useState(undefined);
    const [data, setData] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();

    const handleOpen = () => {
        setOpen(true);
        downloadContract();
    };

    const handleClose = () => {
        setOpen(false);
        setOpenModal({});
    };

    const downloadContract = async () => {

        setLoading(true);

        try {

            const { token } = user;

            if (tramitType === 'express') {
                const response = await downloadCompiledContractDoc(
                    contractCreatedDetails["contrato"][0]["sContractID"],
                    token,
                    contractCreatedDetails["contrato"][0]["sStatus"],
                    docLog
                );
                response && setData(response);
            } else if (tramitType === 'ANF') {

                let response;

                if (verifiVariable(docLog)) {

                    const archivo = !verifiVariable(detailContract) && !verifiVariable(detailContract["archivos"]) && detailContract["archivos"].length > 0 ? detailContract["archivos"][0]["file_name"] : "";

                    response = await downloadANFContractDoc(archivo, true);

                } else {
                    response = await downloadCompiledContractDoc(
                        contratoExpress?.[0]?.sContractID,
                        token,
                        contratoExpress?.[0]?.sStatus,
                        docLog
                    );
                }

                response && setData(response);

            } else if (tramitType === 'approvers') {
                const response = await downloadCompiledContractDoc(
                    state,
                    token,
                    contractCreatedDetailsAproved["contrato"][0]["sStatus"],
                    docLog
                );
                response && setData(response);
            };

        } catch (error) {
            setLoading(false);
            handleClose();
            restError && restError();
            console.log(error);
        };
    };

    useEffect(() => {

        if (data) {
            if (download) {
                if (tramitType === 'ANF' && verifiVariable(docLog)) {
                    downloadFileFromObject(data);
                }
                handleClose();
            } else {
                setFilePreview(data);
            };
        }

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {

        if (openModal) {
            handleOpen();
        } else {
            handleClose()
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);

    if (download) {
        return null
    };

    if (loading) {
        return <Spinner
            loading={true}
            type="bar"
            text={"Cargando documento"}
            opacity={true}
        />
    }

    return (
        <Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {filePreview && (
                            <FilePreview
                                type={"file"}
                                file={filePreview}
                                onError={() => console.log("error")}
                            />
                        )}
                    </Box>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default ViewModalDocs;