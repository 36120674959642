import React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { icons, manageProcedures, to } from '../../../utils/enums';
import { normalizeString } from "../../../utils/transform";
import CardOption from './molecules/cardOption';

const ManagementCouponsMenu = () => {
    const navigate = useNavigate();

    return (
        <div className="content-detail__container">

            <div className="detail__container">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_COUPONS}
                            id={normalizeString(manageProcedures.MANAGE_COUPONS)}
                            subtitle="Ir al trámite"
                            icon={icons.NEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_COUPONS)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_COUPONS_TYPE}
                            id={normalizeString(manageProcedures.MANAGE_COUPONS_TYPE)}
                            subtitle="Ir al trámite"
                            icon={icons.REVIEW_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_COUPONS_TYPE)}
                        />

                    </Grid>

                </Grid>

            </div>

        </div>
    );
};

export default ManagementCouponsMenu;