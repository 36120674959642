import { useEffect, useState, forwardRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiSubdirectoryLeft } from "react-icons/bi";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { ManagerAgreementsStyles } from "../../index.css";
import {
    clearAgreementStatus,
    createAgreement,
} from "../../../../../store/formAgreement";
import Spinner from "../../../../spinners/Spinner";
import { useNavigate } from "react-router-dom";
import { to } from "../../../../../utils/enums";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TypeFormAgreements = ({ children, lastPage, getValues }) => {
    const {
        session: {
            login: { user },
        },
        formAgreement: { errorStep, loading, status, code },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const styles = ManagerAgreementsStyles();
    const [fields, setFields] = useState(0);
    const [open, setOpen] = useState(false);
    children = [...children];

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const addAgreement = () => {
        const { token } = user;
        const arrayList = [];
        const dataAgreements = getValues("agreements");

        dataAgreements.map((item) => {
            const {
                SIGNATURE,
                SIGNATURE_CERT_NOTARIAL,
                SIGNATURE_PROT_NOTARIAL,
                SIGNATURE_ANF,
            } = item["contractTypeFeeID"];

            [
                ...SIGNATURE,
                ...SIGNATURE_CERT_NOTARIAL,
                ...SIGNATURE_PROT_NOTARIAL,
                ...SIGNATURE_ANF,
            ].forEach((itemSiganture) => {
                arrayList.push({
                    contractTypeFeeID: itemSiganture["iContractTypeFeeID"],
                    notaryPrice: item["notaryPrice"],
                    price: item["price"],
                    iva: item["iva"],
                });
            });

            return arrayList;
        });

        const data = {
            rut: getValues("rut"),
            agreements: [
                {
                    agreementName: getValues("agreementName"),
                    isDefault: 1,
                    paymentModal: getValues("modalPayment"),
                    priceList: arrayList,
                },
            ],
        };

        dispatch(createAgreement(token, data));
    };

    const nextField = () => {
        if (fields < children.length) setFields((prev) => prev + 1);
    };

    const prevField = () => {
        if (fields > 0) setFields((prev) => prev - 1);
        if (fields === 0) navigation(to.MANAGE_AGREEMENTS);
    };

    useEffect(() => {
        if (!loading && code != null) {
            handleClick();

            setTimeout(() => {
                navigation(to.MANAGE_AGREEMENTS);
                dispatch(clearAgreementStatus());
            }, 1500);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, status, code]);

    useEffect(() => {
        lastPage(children[children.length - 1]);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={status === "success" ? "success" : "error"}
                        sx={{ width: "100%" }}
                    >
                        {status === "success"
                            ? "Convenio creado correctamente"
                            : "Problemas al crear el convenio"}
                    </Alert>
                </Snackbar>
            </Stack>

            <form className={styles.formBox}>
                {loading && (
                    <Spinner
                        loading={true}
                        type="bar"
                        text={"Creando Notaria"}
                        opacity={true}
                    />
                )}

                {children[fields]}

                <Grid xs={12} className={styles.buttomBox}>
                    <button
                        type="button"
                        onClick={prevField}
                        style={{
                            cursor: "pointer",
                            marginRight: "15px",
                        }}
                    >
                        Atras
                    </button>

                    {fields === 2 && (
                        <button
                            type="button"
                            disabled={errorStep}
                            onClick={() => addAgreement()}
                            style={{
                                cursor: errorStep ? "default" : "pointer",
                            }}
                        >
                            Crear Convenio
                        </button>
                    )}

                    {fields !== 2 && (
                        <button
                            type="button"
                            disabled={errorStep}
                            onClick={nextField}
                            style={{
                                cursor: errorStep ? "default" : "pointer",
                            }}
                        >
                            Siguiente
                        </button>
                    )}

                    <Typography id="textPulsar" variant="body2" gutterBottom>
                        Pulsa <strong>Enter</strong>
                    </Typography>

                    <BiSubdirectoryLeft />
                </Grid>
            </form>
        </Fragment>
    );
};

export default TypeFormAgreements;
