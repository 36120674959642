import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CustomToolbar from "./molecules/CustomToolbar";
import { styles } from "../../../helpers";
import { gridColumns, localizedTextsMap } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { getUsersStorage } from "../../../store/users/userList";
import { getTramitsStorage, getTypeTramitStorage } from "../../../store/contract/tramites";
import Spinner from "../../spinners/Spinner";
import ModalAlert from "../../floats/ModalAlert";

const ManageProcedures = () => {
    const {
        session: {
            login: { user },
        },
        users: {
            listUsers,
            loading,
            status: requestStatusUsersList,
            message: resquestUserListMsg
        },
        contract: {
            tramites: {
                tramList,
                typeTramList,
                loadingTramits,
                loadingTypeTramits,
                status,
                message
            }
        }
    } = useSelector((state) => state);

    const dispatch = useDispatch();

    const [key, setKey] = useState("");
    const [textSpinner, setTextSpinner] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const getContracts = () => {

        const { token } = user;

        dispatch(getUsersStorage(token));

        dispatch(getTramitsStorage(token));

        dispatch(getTypeTramitStorage(token));

    }

    React.useEffect(() => {

        if (![null, undefined, 'success', ''].includes(status)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: message,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        } else if (![null, undefined, 'success', ''].includes(requestStatusUsersList)) {
            setModalStatus({
                title: "¡Ups!, Algo ha pasado",
                message: resquestUserListMsg,
                buttonText: "Cerrar",
                modalIcon: "warnIcon"
            });
            setShowModal(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestStatusUsersList]);

    useEffect(() => {
        getContracts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const genKey = () =>
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    useEffect(() => {
        setTextSpinner("Cargando listado de trámites...");
        const tramites = tramList.map((tramite) => {
            listUsers.forEach((user) => {
                if (tramite.iContractOwner === user.iUserID) {
                    tramite = { ...tramite, user };
                }
            });

            typeTramList.forEach((type) => {
                if (tramite.iContractTypeFeeID === type.iContractTypeID) {
                    tramite = {
                        ...tramite,
                        type,
                    };
                }
            });

            return tramite;
        });

        setDataGrid(tramites.filter((item) => item['sPaymentStatus'] === 'PENDING' || item['sPaymentStatus'] === 'PAIDOUT' || item['sPaymentStatus'] === 'PARTIALLY_PAID'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeTramList, tramList]);

    const renderList = React.useCallback(() => {
        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={dataGrid}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.sContractID}
                            getCellClassName={null}
                            rowHeight={56}
                            loading={loading || loadingTramits || loadingTypeTramits}
                            sx={[
                                styles.gridContainerStyles,
                                {
                                    [`& .${gridClasses.cell}`]: {
                                        py: 2,
                                        fontSize: "8px",
                                    },
                                },
                            ]}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                        updateData={() => {
                                            setTextSpinner(
                                                "Actualizando listado de trámites..."
                                            );
                                            getContracts();
                                        }}
                                    />
                                ),
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        { field: "sNumberID", sort: "desc" },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, loadingTramits, loadingTypeTramits, gridColumns]);

    return (
        <div className="content-detail__container" key={key}>
            {(loading || loadingTramits || loadingTypeTramits) && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textSpinner}
                    opacity={true}
                />
            )}

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {renderList()}
        </div>
    );
};

export default ManageProcedures;