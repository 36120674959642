import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { endSession } from "../../../../store/auth/userLogin";
import { purgeSigners } from "../../../../store/contract/signatoriesList";
import { clearFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { ReactComponent as Logo } from "../../../../assets/logo/logo.svg";
import { FormularioSolicitudStyles } from "../../index.css";
import { clearForm, createTramit } from "../../../../store/formExpressTramit";

const ConfirmPage = () => {
    const styles = FormularioSolicitudStyles();
    const {
        session: {
            login: { user },
        },
        formSolicitud: {
            document,
            iPaymentService,
            iContractTypeFeeID,
            iSignedCount,
            signers,
        },
        formExpressTramit: { status },
    } = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        /* setTimeout(() => {
            endSession();
            dispatch(purgeSigners());
            dispatch(clearFormSolicitud());

            window.location.replace(
                "https://firmavirtual.legal/gracias-por-tu-solicitud"
            );
        }, 1000); */

        if (["success", "fail"].includes(status)) {
            endSession();
            dispatch(purgeSigners());
            dispatch(clearFormSolicitud());
            dispatch(clearForm());

            window.location.replace(
                "https://firmavirtual.legal/gracias-por-tu-solicitud"
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    useEffect(() => {
        console.log("signers: ", signers);

        const { token } = user;

        const firmantes = signers.filter(
            (item) => !["", null, undefined].includes(item["nombres"])
        );

        const countSigned = firmantes.filter((v) => v.pay).length;

        const data = {
            sOwnerType: "NATURAL",
            sPaymentStatus: "PENDING",
            iPaymentService,
            iContractTypeFeeID,
            iSignedCount,
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            signers: firmantes.map((item) => {
                const firmante = {
                    order: 1,
                    rol: 0, //TODO pagador firmante item["pay"] === true ? 3 :
                    payment: "0.00",
                    type: "NATURAL",
                    portion: countSigned === 1 ? "100" : "50",
                    full_name: item["nombres"],
                    email: item["email"],
                    rutId: item["rutId"],
                    phone: item["phone"],
                };

                return firmante;
            }),
            document: {
                content_file: document,
            },
        };

        console.log("data: ", data);

        dispatch(createTramit(token, data));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Logo className={styles.logo} />

            <h1 style={{ fontSize: "calc(25px + 1vw)" }}>
                Su solicitud a sido enviada con exito{" "}
            </h1>

            <br />

            <CircularProgress size={200} />

            <h1 style={{ fontSize: "calc(25px + 1vw)" }}>Redirigiendo...</h1>
        </Box>
    );
};

export default ConfirmPage;
