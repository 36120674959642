import { Box } from '@mui/material';
import React from 'react';
import { styles } from '../../../../../helpers';
import Text from '../../../../heading/Text';

const RenderCreatedAt = ({ item }) => {

    const [createdAt, setCreatedAt] = React.useState('');

    React.useEffect(() => {

        if (![null, undefined, ''].includes(item.dContractDT)) setCreatedAt(item.dContractDT.split(" ")[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    return (
        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center',
            padding: "6px",
            whiteSpace: 'normal'
        }} className="flex--1 flex--center">

            <Text sx={styles.userNameStyles} variant="h6">{createdAt}</Text>

        </Box>
    );
};

export default RenderCreatedAt;