import { createStyles, makeStyles } from "@mui/styles";

export const ContractsCreatedSuccessStyles = makeStyles((theme) => {
    return createStyles({
        container: {
            backgroundColor: theme.palette.white.main,
            margin: '1rem 0 0 0',
            padding: '1.2rem .4rem'
        },
        header: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        headerText: {
            color: theme.palette.primary.main,
            '& > p': {
                fontSize: '1.06rem',
                fontFamily: 'PoppinsBold'
            }
        },
        infoBox: {
            display: 'flex',
            alignItems: 'center',
            margin: '1.9rem 0 0 0',
            padding: '.8rem',
            border: `1px solid ${theme.palette.primary.light}`,
            backgroundColor: theme.palette.primary.xxlight,
            borderTopRightRadius: '7px',
            borderBottomRightRadius: '7px',
            '& > div > p': {
                fontSize: '.8rem',
                fontFamily: 'PoppinsBold'
            }
        },
        bodyText_Box: {
            textAlign: 'center',
            margin: '1.2rem 0 0 0'
        },
        sliderContent: {
            //position: 'relative'
        },
        slider: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        image_container: {
            display: 'flex',
            transition: 'transform 0.5s ease-in-out',
            margin: '2rem 0 0 0',
        },
        slider_image: {
            width: '15rem',
            height: '25rem',
            margin: '0 10px',
            opacity: '.5'
        },
        slider_image__active: {
            opacity: '1',
            [theme.breakpoints.up('md')]: {
                scale: '1.05'
            }
        },
        slider_dots_container: {
            display: 'flex',
            justifyContent: 'center',
            margin: '2rem 0 0 0'
        },
        slider_dot: {
            width: '0.667rem',
            height: '0.667rem',
            backgroundColor: theme.palette.gray.light,
            borderRadius: '50%',
            margin: '0 .5rem'
        },
        slider_dot__active: {
            backgroundColor: theme.palette.primary.light,
        },
        buttonsBox: {
            display: 'flex',
            justifyContent: 'center',
            margin: '2rem 0'
        },
        button_back: {
            backgroundColor: theme.palette.white.main,
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '7px',
            textTransform: 'none',
            color: theme.palette.primary.main,
            margin: '0 .5rem 0 0',
            width: '9rem',
            height: '2.75rem',
            '& > span': {
                fontFamily: 'PoppinsBold',
                fontSize: '1.067rem'
            }
        },
        button_next: {
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            borderRadius: '7px',
            textTransform: 'none',
            color: theme.palette.white.main,
            margin: '0 0 0 .5rem',
            width: '9rem',
            '& > span': {
                fontFamily: 'PoppinsBold',
                fontSize: '1.067rem'
            }
        },
    })
})