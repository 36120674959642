import React, { useState, useEffect, forwardRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiCloudUpload } from "react-icons/bi";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getContractDetail } from "../../store/contract/contractDetail";
import { store } from "../../store/storeConfig";
import { getUserToken, getUserInfo } from "../storeSelector/storeSelector";
import { to, docStatus, apiMessage, typeUser } from "../../utils/enums";
import {
    BreadCrumbTracker,
    Spinner,
    Notificationbanner,
    Btn,
    Text,
    PopUp,
    ContractDetailSummary,
} from "..";
import {
    BsCloudDownload,
    BsEnvelope,
    BsCloudArrowDown,
    BsExclamationTriangle,
    BsHandThumbsUp,
    BsHandThumbsDown,
    BsXCircle,
    BsFileCheck,
    BsFileX,
    BsCheck2,
    BsInfoCircle,
} from "react-icons/bs";

import { BiTrash } from "react-icons/bi";
import {
    downloadContractDoc,
    downloadCompiledContractDoc,
    sendContractEmail,
    deleteContract,
    updateMemberInfoApiCall,
    deleteMemberApiCall,
    deleteContractDoc,
} from "../../utils/api";
import { ModalUploadNotaryDoc } from "../index";
import { bodyTextStyles } from "../../utils/validations";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import { AiOutlineReload } from "react-icons/ai";
import { getUserList } from "../../utils/api-user";
import { addUsers } from "../../store/users/userList";
import DetailSignatories from "./detailSignatories";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ContractDetailStyles = makeStyles(() => {
    return createStyles({
        mainContainerDialog: {
            "& div.MuiPaper-root": {
                borderRadius: "10px",
            },
        },
        muiAlert: {
            width: "100%",
            /* '& svg, div': {
                color: 'white !important',
            } */
        },
    });
});

const Contractdetail = () => {
    const {
        session: {
            login: { userInfo },
        },
        users: { listUsers },
    } = useSelector((state) => state);
    const styles = ContractDetailStyles();
    const screenSizeHook = ScreenSizeHook();
    const { state, pathname } = useLocation();
    const userToken = getUserToken();
    const userinfo = getUserInfo();
    const navigation = useNavigate();
    const [contractDetail, setContractDetail] = useState(null);
    const [contractMembers, setContractMembers] = useState(null);
    const [contractFiles, setContractFiles] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [reminderSpinner, setReminderSpinner] = useState(false);
    const [showReminderMessage, setShowReminderMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");
    const [modalButtonTitle, setModalButtonTitle] = useState("");
    const [modalButtonLoading, setModalButtonLoading] = useState(false);
    const [memberIdToUpdate, setMemberIdToUpdate] = useState(0);
    const [memberIdToDelete, setMemberIdToDelete] = useState(0);
    const [updateInfoSpinner, setUpdateInfoSpinner] = useState(false);
    const [removeSignner, setRemoveSignner] = useState(false);
    const [modalActionToRun, setModalActionToRun] = useState(null);
    const [statusContract, setStatusContract] = useState("");
    const [hiddenButtonAction, setHiddenButtonAction] = useState(false);
    const [owner, setOwner] = useState(null);
    const [alertStatusContract, setAlertStatusContract] = useState(false);

    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const handleCloseAlertStatusContract = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertStatusContract(false);
    };

    const getContract = () => {
        let run = true;
        if (run) {
            store.dispatch(getContractDetail(state, userToken));
            const unsubscribe = store.subscribe(() => {
                const { contractCreator } = store.getState().contract;

                if (!_.isEmpty(contractCreator)) {
                    if (contractCreator.contractCreatedDetails !== null) {
                        setContractDetail(
                            contractCreator.contractCreatedDetails.contrato
                        );

                        setAlertStatusContract(
                            contractCreator.contractCreatedDetails.contrato[0]
                                .sStatus === "REJECTED"
                                ? true
                                : false
                        );

                        setStatusContract(
                            contractCreator.contractCreatedDetails.contrato[0]
                                .sStatus
                        );

                        setOwner(
                            contractCreator.contractCreatedDetails.contrato[0]
                                .iContractOwner
                        );

                        setContractMembers(
                            contractCreator.contractCreatedDetails.firmantes
                        );
                        setContractFiles(
                            contractCreator.contractCreatedDetails.archivos
                        );
                        setTotalAmount(
                            getTotals(
                                contractCreator.contractCreatedDetails.firmantes
                            )
                        );
                    }
                }
            });
            return () => unsubscribe();
        }
        return () => {
            run = false;
            setAlertStatusContract(false);
        };
    };

    const getUsers = async () => {
        try {
            const restGetUsers = await getUserList(userToken);

            if (restGetUsers["status"] === "success") {
                dispatch(addUsers(restGetUsers["message"]));
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getContract();
        if (userinfo.profileId === typeUser.ADMIN) {
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTotals = (firmantes) => {
        let total = 0;
        let iva = 0;
        let notary = 0;
        let signature = 0;

        firmantes.forEach((firmante) => {
            if (firmante.portion !== 0) {
                total += parseInt(firmante.totalPay);
                iva += parseInt(firmante.iva);
                notary += parseInt(firmante.notaryPay);
                signature += parseInt(firmante.amount);
            }
        });

        return {
            total,
            iva,
            notary,
            signature,
        };
    };

    const callToUpdateInfo = async (memberInfoToUpdate) => {
        setUpdateInfoSpinner(true);

        const response = await updateMemberInfoApiCall(
            state,
            memberIdToUpdate,
            userToken,
            memberInfoToUpdate
        );
        if (response.status === apiMessage.SUCCESS) {
            setUpdateInfoSpinner(false);
            setMemberIdToUpdate(0);
            window.location.reload(false);
        }
    };

    const callToDeleteSignner = async () => {
        let memberPayment = contractMembers.filter(
            (member) =>
                member.RUT === memberIdToDelete && member.portion !== "0.00"
        );

        setUpdateInfoSpinner(true);
        setModalButtonLoading(true);

        try {
            if (memberPayment.length) {
                setHiddenButtonAction(true);
                setModalButtonLoading(false);
                setModalTitle("¡Ups!, el firmante no puede ser eliminado");
                setModalMessage("Por favor vuelve a intentarlo");
                setShowModal(true);
            } else {
                const response = await deleteMemberApiCall(
                    state,
                    memberIdToDelete,
                    userToken
                );

                if (response.status === apiMessage.SUCCESS) {
                    setUpdateInfoSpinner(false);
                    setModalButtonLoading(false);
                    window.location.reload(false);
                }
            }
        } catch (error) {
            setModalButtonLoading(false);
            setModalTitle("¡Ups!, al parecer ha ocurrido un error");
            setModalMessage("Por favor vuelve a intentarlo");
            setModalButtonTitle("Aceptar");
            setShowModal(true);
            console.error(error);
        }
    };

    const displayDeleteSignnerWarning = (memberId) => {
        setModalActionToRun("deleteSignner");
        setMemberIdToDelete(memberId);
        setModalTitle("Eliminar Firmante");
        setModalMessage("¿Está seguro que desea eliminar este firmante?");
        setModalButtonTitle("Si, eliminar");
        setShowModal(true);
    };

    const userAvatar = {
        height: "70px",
        width: "70px",
    };

    const button = {
        marginTop: `${["xs"].includes(screenSizeHook) ? "15px" : "0px"}`,
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "quinary.light",
        color: "primary.light",
        "&:hover": {
            backgroundColor: "primary.xlight",
        },
    };

    const buttonText = {
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "transparent",
        color: "black.main",
        fontWeight: "bold",
        "&:hover": {
            color: "primary.light",
            backgroundColor: "transparent",
        },
    };

    const buttonxs = {
        fontSize: "0.9rem",
        textTransform: "none",
        textDecoration: "underline",
        fontWeight: "500",
        color: "black.main",
        "&:hover": {
            color: "primary.light",
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    };

    const infoTag = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8rem",
        textTransform: "none",
        backgroundColor: "transparent",
        color: "black.main",
        fontWeight: "bold",
        marginLeft: "0px",
    };

    const closeModal = () => {
        setModalTitle("");
        setModalMessage("");
        setModalButtonTitle("");
        setMemberIdToDelete(0);
        setRemoveSignner(false);
        setModalActionToRun(null);
        setShowModal(!showModal);
        setHiddenButtonAction(false);
    };

    const deleteCurrentContract = async () => {
        // TODO delete contract
        setModalButtonLoading(true);

        try {
            await deleteContract(state, userToken);
            closeModal();
            setModalButtonLoading(false);
            navigation(to.CONTRACT_STATUS);
        } catch (error) {
            setModalButtonLoading(false);
            setModalTitle("Error al eliminar contrato");
            setModalMessage("El contrato no ha podido ser eliminado");
            setModalButtonTitle("Aceptar");
            setShowModal(true);
            console.error(error);
        }
    };

    const ResendContract = () => {
        const [open, setOpen] = useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (
            <Fragment>
                {!_.isEmpty(contractFiles) && (
                    <button
                        disabled={_.isEmpty(contractFiles)}
                        style={{
                            cursor: _.isEmpty(contractFiles) ? "default" : "",
                        }}
                        className="flex--end button--sm button--lg-rounded margin-right--xs"
                        onClick={handleClickOpen}
                    >
                        <BsEnvelope className="icon--xxs icon-pointer margin-right--sm" />{" "}
                        <span>Reenviar contrato</span>
                        {/* TODO boton resend */}
                    </button>
                )}

                <Dialog
                    fullWidth={true}
                    TransitionComponent={Transition}
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    className={styles.mainContainerDialog}
                >
                    <DialogTitle sx={{ textAlign: "center" }}>
                        ¿Estás seguro que deseas realizar esta acción?
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Si reenvías el contrato a los participantes, todos
                            los firmantes deben realizar el proceso de revisión
                            del documento nuevamente.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions
                        style={{
                            justifyContent: "center",
                            paddingBottom: "20px",
                        }}
                    >
                        <Btn
                            variant="outlined"
                            text="Cancelar"
                            color="error"
                            onClick={() => handleClose()}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                            }}
                        />

                        <Btn
                            variant="outlined"
                            text="Aceptar"
                            color="primary"
                            onClick={() => {
                                sendReminder();
                                handleClose();
                            }}
                            sx={{
                                height: "39px",
                                borderRadius: "20px",
                                marginLeft: "30px !important",
                            }}
                        />
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    };

    const displayModalButtons = () => {
        return (
            <div className="flex--center" style={{ marginTop: "32px" }}>
                {!hiddenButtonAction ? (
                    <Btn
                        variant="contained"
                        text={modalButtonTitle}
                        size="lg"
                        color="secondary"
                        type="submit"
                        buttonClass="button-submit"
                        loading={modalButtonLoading}
                        onClick={
                            (modalActionToRun === "deleteContract" &&
                                deleteCurrentContract) ||
                            (modalActionToRun === "deleteSignner" &&
                                callToDeleteSignner)
                        }
                    />
                ) : null}

                <div className="margin-right--sm" />
                {!modalButtonLoading ? (
                    <Btn
                        variant="contained"
                        text="Cancelar"
                        size="lg"
                        color="primary"
                        type="submit"
                        buttonClass="button-submit"
                        onClick={closeModal}
                    />
                ) : null}
            </div>
        );
    };

    const displayModal = () => {
        return (
            <PopUp
                open={showModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                title={modalTitle}
                onBackdropClick={closeModal}
            >
                <Text
                    sx={bodyTextStyles}
                    align="center"
                    variant="body1"
                    paragraph
                >
                    {modalMessage}
                </Text>
                {displayModalButtons()}
            </PopUp>
        );
    };

    const displayRemoveContractModal = () => {
        setModalActionToRun("deleteContract");
        setModalTitle("¿Deseas eliminar este contrato?");
        setModalMessage("Si eliminas este contrato, no podrás recuperarlo.");
        setModalButtonTitle("Eliminar");
        setShowModal(true);
    };

    const downloadUniqFile = async (fileName) => {
        setLoading(true);

        try {
            const response = await downloadContractDoc(fileName, userToken);
            response && setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    // eslint-disable-next-line
    const deleteUniqFile = async (fileName) => {
        try {
            setLoading(true);
            const response = await deleteContractDoc(userToken, fileName);
            response && setLoading(false);
            getContract();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const getFiles = (id) => {
        if (!_.isEmpty(contractFiles)) {
            return (
                <ul className="margin-y--lg">
                    {contractFiles.map((file, index) => {
                        return (
                            <li
                                key={index}
                                className="flex--start margin-bottom--sm"
                            >
                                <div className="list-unorder__bullet margin-right--sm">
                                    <span>{file.orden + 1}</span>
                                </div>
                                {`${id}_${file.file_name}`}
                                <BsCloudDownload
                                    className="icon-pointer margin-left--sm"
                                    onClick={() =>
                                        downloadUniqFile(file.file_name)
                                    }
                                />
                                {/* {(userInfo && userInfo['iUserID'] === contractDetail[0]['iContractOwner']) && (<BsTrash
                                    className="icon-pointer margin-left--sm"
                                    onClick={() =>
                                        deleteUniqFile(file.file_name)
                                    }
                                />)} */}
                            </li>
                        );
                    })}
                </ul>
            );
        }
    };

    /* A function that is called when the user clicks on the boardHandler or addFileHandler. */
    // const boardHandler = () => {
    //     navigation(to.CONTRACT_SORT_FILES, {state: state});
    // }

    // const addFileHandler = () => {
    //     navigation(to.CONTRACT_CREATOR_DOCS, { state: state });
    // };

    const downloadContract = async () => {
        setLoading(true);

        try {
            const response = await downloadCompiledContractDoc(
                state,
                userToken,
                contractDetail[0].sStatus
            );
            response && setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const sendReminder = async () => {
        setReminderSpinner(true);

        try {
            const response = await sendContractEmail(state, userToken);
            if (response) {
                setReminderSpinner(false);
                setShowReminderMessage(true);
                setAlertType("success");
                setAlertMessage(
                    "Se ha enviado un recordatorio a todos los firmantes"
                );

                setTimeout(() => {
                    setShowReminderMessage(false);
                    setAlertType("");
                    setAlertMessage("");
                }, 4000);
            }
        } catch (error) {
            setReminderSpinner(false);
            console.log(error);
        }
    };

    const checkSuccessAgrrement = () => {
        let success = false;

        if (contractMembers) {
            const memberAgreed = contractMembers.filter(
                (member) => member.agree_draft === docStatus.SUCCESS
            );
            if (memberAgreed.length === contractMembers.length) success = true;
        }

        return success;
    };

    const getContractMembersSignatureStatus = (member) => {
        const { download_draft, agree_draft, disagree_draft } = member;
        let statusTag;

        if (
            download_draft === docStatus.ACTIVE &&
            agree_draft === docStatus.NOT_ACTIVE &&
            disagree_draft === docStatus.NOT_ACTIVE
        ) {
            statusTag = (
                <div className="flex--start status_tag status--pending">
                    {<BsCloudArrowDown className="icon--xs margin-right--sm" />}{" "}
                    Documento listo para descarga
                </div>
            );
        }

        if (
            download_draft === docStatus.SUCCESS &&
            agree_draft === docStatus.ACTIVE &&
            disagree_draft === docStatus.ACTIVE
        ) {
            statusTag = (
                <div className="flex--start status_tag status--pending">
                    {
                        <BsExclamationTriangle className="icon--xs margin-right--sm" />
                    }{" "}
                    Documento por aceptar
                </div>
            );
        }

        if (
            download_draft === docStatus.SUCCESS &&
            agree_draft === docStatus.SUCCESS &&
            disagree_draft === docStatus.NOT_ACTIVE
        ) {
            statusTag = (
                <div className="flex--start status_tag status--approved">
                    {<BsHandThumbsUp className="icon--xs margin-right--sm" />}{" "}
                    Documento aceptado
                </div>
            );
        }

        if (
            download_draft === docStatus.SUCCESS &&
            agree_draft === docStatus.NOT_ACTIVE &&
            disagree_draft === docStatus.SUCCESS
        ) {
            statusTag = (
                <div className="flex--start status_tag status--rejected">
                    {<BsHandThumbsDown className="icon--xs margin-right--sm" />}{" "}
                    Documento no aceptado
                </div>
            );
        }

        if (
            download_draft === undefined ||
            agree_draft === undefined ||
            disagree_draft === undefined
        ) {
            statusTag = (
                <div className="flex--start status_tag status--pending">
                    {
                        <BsExclamationTriangle className="icon--xs margin-right--sm" />
                    }{" "}
                    Sin información
                </div>
            );
        }

        return statusTag;
    };

    const statusPayment = (member) => {
        const { payment } = member;
        return payment === "PENDING" ? (
            <div style={{ flexWrap: "nowrap", display: "flex" }}>
                <Tooltip
                    title="El firmante aun no ha realizado su pago."
                    placement="top"
                >
                    <IconButton>
                        <BsInfoCircle className="icon--xxs" />
                    </IconButton>
                </Tooltip>
                <Text sx={infoTag} align="left" variant="body1">
                    Pendiente de pago
                </Text>
            </div>
        ) : (
            <div style={{ flexWrap: "nowrap", display: "flex" }}>
                <Tooltip
                    title="El firmante ya ha realizado su pago."
                    placement="top"
                >
                    <IconButton>
                        <BsCheck2 className="icon--xxs" />
                    </IconButton>
                </Tooltip>
                <Text sx={infoTag} align="left" variant="body1">
                    Pago realizado
                </Text>
            </div>
        );
    };

    const statuSign = (status, member) => {
        if (
            (status === "SIGNED_PENDING" ||
                status === "DELIVERED" ||
                status === "IN_NOTARY") &&
            member.sign_status === "SIGNED"
        ) {
            return (
                <div className="flex--start status_tag status--approved">
                    {<BsFileCheck className="icon--xs margin-right--sm" />}{" "}
                    Firmó
                </div>
            );
        } else if (
            (status === "SIGNED_PENDING" || status === "DELIVERED") &&
            member.sign_status === "NOTHING"
        ) {
            return (
                <div className="flex--start status_tag status--rejected">
                    {<BsFileX className="icon--xs margin-right--sm" />} No firmó
                </div>
            );
        }
    };

    const onUpdateInfo = (data) => {
        if (data.email !== "" || data.phone !== "") {
            const selectedSigner = contractMembers.filter(
                (signer) => signer.RUT === memberIdToUpdate
            )[0];

            callToUpdateInfo({
                full_name: selectedSigner.full_name,
                rutId: selectedSigner.RUT,
                email: data.email !== "" ? data.email : selectedSigner.email,
                phone: data.phone !== "" ? data.phone : selectedSigner.Phone,
            });
        } else {
            setShowReminderMessage(true);
            setAlertType("warning");
            setAlertMessage("Los datos de este firmante no se han moditicado");

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 5000);
        }
    };

    const updateMemberInfo = (member, index) => {
        if (memberIdToUpdate !== member.RUT) {
            return (
                <DetailSignatories
                    updateContract={() => getContract()}
                    member={member}
                    index={index}
                    contractDetail={contractDetail}
                    contractMembers={contractMembers}
                    contractFiles={contractFiles}
                    removeSignner={removeSignner}
                    deleteSigner={() => displayDeleteSignnerWarning(member.RUT)}
                />
            );
        } else {
            return (
                <div className="container--relative">
                    {updateInfoSpinner && (
                        <Spinner type="spin" loading={true} opacity={true} />
                    )}

                    <form onSubmit={handleSubmit(onUpdateInfo)}>
                        <ul className="flex--1 container--relative" key={index}>
                            <li className="flex--start padding-bottom--md">
                                <div className="list-item-name flex--1 font-color--primary-light" />
                                <div
                                    className="flex--2"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {getContractMembersSignatureStatus(member)}
                                    {statuSign(
                                        contractDetail[0].sStatus,
                                        member
                                    )}
                                    {statusPayment(member)}
                                </div>
                            </li>

                            <li className="flex--start padding-bottom--md">
                                <strong className="list-item-name flex--1 font-color--primary-light">
                                    {`Firmante ${index + 1}`}{" "}
                                </strong>
                                {!checkSuccessAgrrement() ? (
                                    <div
                                        className="flex--2"
                                        style={{ display: "flex" }}
                                    >
                                        {removeSignner ? (
                                            <Btn
                                                variant="text"
                                                onClick={() =>
                                                    displayDeleteSignnerWarning(
                                                        member.RUT
                                                    )
                                                }
                                                startIcon={
                                                    <BiTrash className="icon--xxs" />
                                                }
                                                text="Eliminar firmante"
                                                size="xs"
                                                sx={buttonxs}
                                            />
                                        ) : (
                                            <>
                                                {contractDetail[0].sStatus ===
                                                    "CREATED" && (
                                                    <Btn
                                                        variant="text"
                                                        onClick={() =>
                                                            setMemberIdToUpdate(
                                                                member.RUT
                                                            )
                                                        }
                                                        text="Modificar datos"
                                                        size="xs"
                                                        sx={buttonxs}
                                                    />
                                                )}

                                                {(contractDetail[0].sStatus ===
                                                    "SIGNED_PENDING" ||
                                                    contractDetail[0]
                                                        .sStatus ===
                                                        "DELIVERED") &&
                                                    member.sign_status ===
                                                        "NOTHING" && (
                                                        <Btn
                                                            onClick={() =>
                                                                console.log(
                                                                    "click"
                                                                )
                                                            }
                                                            variant="contained"
                                                            text="Notificar firma"
                                                            startIcon={
                                                                <BsEnvelope className="icon--xxs icon-pointer" />
                                                            }
                                                            size="xs"
                                                            sx={buttonText}
                                                        />
                                                    )}
                                            </>
                                        )}
                                    </div>
                                ) : null}
                            </li>

                            <li className="flex--start padding-bottom--md">
                                <strong className="list-item-name flex--1 font-color--primary-light">
                                    {`Firmante ${index + 1}`}{" "}
                                </strong>
                            </li>

                            <li className="flex--start padding-bottom--xs">
                                <strong className="list-item-name flex--1">
                                    Nombre
                                </strong>
                                <span className="flex--2 font-color--quinary">
                                    {member.full_name}
                                </span>
                            </li>

                            <li className="flex--start padding-bottom--xs">
                                <strong className="list-item-name flex--1">
                                    RUT
                                </strong>
                                <span className="flex--2 font-color--quinary">
                                    {member.RUT}
                                </span>
                            </li>

                            <li className="flex--start padding-bottom--xs">
                                <strong className="list-item-name flex--1">
                                    Correo electrónico
                                </strong>
                                <input
                                    type="text"
                                    className="flex--2 font-color--quinary input-field--xs"
                                    placeholder={member.email}
                                    {...register("email")}
                                />
                            </li>

                            <li className="flex--start padding-bottom--xs">
                                <strong className="list-item-name flex--1">
                                    Número de celular
                                </strong>
                                <input
                                    type="text"
                                    className="flex--2 font-color--quinary input-field--xs"
                                    placeholder={member.Phone}
                                    {...register("phone")}
                                />
                            </li>

                            <div className="flex--end">
                                <Btn
                                    variant="text"
                                    onClick={() => setMemberIdToUpdate(0)}
                                    text="Cancelar"
                                    size="xs"
                                    sx={buttonxs}
                                />
                                <Btn
                                    variant="text"
                                    text="Aceptar"
                                    size="xs"
                                    type="submit"
                                    loading={updateInfoSpinner}
                                    sx={buttonxs}
                                />
                            </div>
                        </ul>
                    </form>
                </div>
            );
        }
    };

    const renderMembers = () => {
        //TODO renderizando firmantes
        if (!_.isEmpty(contractMembers)) {
            return contractMembers.map((member, index) => {
                return updateMemberInfo(member, index);
            });
        }
    };

    const getContractOwner = () => {
        const contractOwner = listUsers.find((user) => user.iUserID === owner);

        return (
            <ul className="padding-left--lg flex--1">
                <li className="flex--start padding-bottom--xs">
                    <strong className="list-item-name flex--1">Nombre</strong>
                    <span
                        className={`flex--${
                            ["xs"].includes(screenSizeHook) ? 4 : 2
                        } font-color--quinary`}
                    >
                        {!_.isEmpty(contractOwner) &&
                            `${contractOwner.sFirstName} ${contractOwner.sLastName}`}
                    </span>
                </li>

                <li className="flex--start padding-bottom--xs">
                    <strong className="list-item-name flex--1">Correo</strong>
                    <span
                        className={`flex--${
                            ["xs"].includes(screenSizeHook) ? 4 : 2
                        } font-color--quinary`}
                    >
                        {!_.isEmpty(contractOwner) && `${contractOwner.sEmail}`}
                    </span>
                </li>
                <li className="flex--start padding-bottom--xs">
                    <strong className="list-item-name flex--1">Teléfono</strong>
                    <span
                        className={`flex--${
                            ["xs"].includes(screenSizeHook) ? 4 : 2
                        } font-color--quinary`}
                    >
                        {!_.isEmpty(contractOwner) && `${contractOwner.sPhone}`}
                    </span>
                </li>
            </ul>
        );
    };

    return (
        <>
            <Notificationbanner
                display={showReminderMessage}
                notificationType={alertType}
            >
                <strong>{alertMessage}</strong>
            </Notificationbanner>
            {displayModal()}
            <div className="content-detail__container">
                <div className="contract-details__main">
                    <BreadCrumbTracker
                        showGoback={true}
                        location={pathname}
                        status={statusContract}
                    />
                    <div className="contract-details__container">
                        <section className="contract-detail__info-container">
                            <div
                                className={`info-header ${
                                    ["xs"].includes(screenSizeHook)
                                        ? ""
                                        : "flex--between"
                                }`}
                            >
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            1
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos personales solicitante
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Mis datos personales
                                        </span>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {!checkSuccessAgrrement() && (
                                        <Btn
                                            onClick={displayRemoveContractModal}
                                            variant="contained"
                                            buttonClass={
                                                "contract-buttom-status"
                                            }
                                            text="Eliminar"
                                            startIcon={
                                                <BiTrash className="icon--xxs icon--blue-light" />
                                            }
                                            size="xs"
                                            sx={button}
                                        />
                                    )}

                                    <Btn
                                        onClick={() => getContract()}
                                        variant="contained"
                                        text="Actualizar seguimiento"
                                        buttonClass={"contract-buttom-status"}
                                        startIcon={
                                            <AiOutlineReload className="icon--xs icon--blue-light" />
                                        }
                                        size="xs"
                                        sx={button}
                                    />

                                    {userInfo.iProfileID === typeUser.ADMIN &&
                                        ["IN_NOTARY"].includes(
                                            statusContract
                                        ) && (
                                            <ModalUploadNotaryDoc
                                                styledButton={button}
                                                contract={contractDetail}
                                                userToken={userToken}
                                                inList={false}
                                            />
                                        )}

                                    {_.isEmpty(contractFiles) && ( // TODO modal subir tramite
                                        <Btn
                                            onClick={() =>
                                                navigation(
                                                    to.DASHBOARD_UPLOAD_FILE
                                                )
                                            }
                                            variant="contained"
                                            text="Subir Documento del Contrato"
                                            buttonClass={
                                                "contract-buttom-status"
                                            }
                                            startIcon={
                                                <BiCloudUpload className="icon--xxs icon--blue-light" />
                                            }
                                            size="xs"
                                            sx={button}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="info-body padding-y--xxs flex--between">
                                <div className="flex--start flex--align-start flex--1 padding-top--xxl">
                                    <div className="flex--center">
                                        <Avatar sx={userAvatar} />
                                    </div>
                                    {userInfo.iProfileID === typeUser.ADMIN ? (
                                        getContractOwner()
                                    ) : (
                                        <ul className="padding-left--lg flex--1">
                                            <li className="flex--start padding-bottom--xs">
                                                <strong className="list-item-name flex--1">
                                                    Nombre
                                                </strong>
                                                <span
                                                    className={`flex--${
                                                        ["xs"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? 4
                                                            : 2
                                                    } font-color--quinary`}
                                                >{`${userInfo.sFirstName} ${userInfo.sLastName}`}</span>
                                            </li>

                                            <li className="flex--start padding-bottom--xs">
                                                <strong className="list-item-name flex--1">
                                                    Correo
                                                </strong>
                                                <span
                                                    className={`flex--${
                                                        ["xs"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? 4
                                                            : 2
                                                    } font-color--quinary`}
                                                >
                                                    {`${userInfo.sEmail}`}
                                                </span>
                                            </li>
                                            <li className="flex--start padding-bottom--xs">
                                                <strong className="list-item-name flex--1">
                                                    Teléfono
                                                </strong>
                                                <span
                                                    className={`flex--${
                                                        ["xs"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? 4
                                                            : 2
                                                    } font-color--quinary`}
                                                >
                                                    {`${userInfo.sPhone}`}
                                                </span>
                                            </li>
                                        </ul>
                                    )}
                                </div>

                                <div className="flex--1" />
                            </div>
                        </section>

                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            2
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos del trámite
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Datos del trámite a realizar
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="info-body padding-y--xxs flex--between flex--wrap">
                                {/* <div className={`flex--start flex--align-start padding-top--xxl ${['xs', 'sm'].includes(screenSizeHook) ? 'flex--3' : 'flex--1'}`}> */}

                                <Grid
                                    container
                                    className={`flex--start flex--align-start padding-top--xxl ${
                                        ["xs", "sm"].includes(screenSizeHook)
                                            ? "flex--3"
                                            : "flex--1"
                                    }`}
                                >
                                    <Grid item xs={12} lg={6}>
                                        <ul
                                            className={
                                                ["xs", "sm"].includes(
                                                    screenSizeHook
                                                )
                                                    ? "flex--3"
                                                    : "flex--1"
                                            }
                                        >
                                            <li className="flex--start padding-bottom--xs">
                                                <strong
                                                    className={`list-item-name ${
                                                        ["xs", "sm"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? "flex--3"
                                                            : "flex--1"
                                                    }`}
                                                >
                                                    Id de trámite
                                                </strong>
                                                <span className="flex--2 font-color--quinary text--uppercase-all first-letter--uppercase">
                                                    {!_.isEmpty(
                                                        contractDetail
                                                    ) &&
                                                        contractDetail[0]
                                                            .autoId}
                                                </span>
                                            </li>

                                            <li className="flex--start padding-bottom--xs">
                                                <strong
                                                    className={`list-item-name ${
                                                        ["xs", "sm"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? "flex--3"
                                                            : "flex--1"
                                                    }`}
                                                >
                                                    Tipo de trámite
                                                </strong>
                                                <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                    {!_.isEmpty(
                                                        contractDetail
                                                    ) &&
                                                        contractDetail[0]
                                                            .sTipoContrato}
                                                </span>
                                            </li>

                                            <li className="flex--start padding-bottom--xs">
                                                <strong
                                                    className={`list-item-name ${
                                                        ["xs", "sm"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? "flex--3"
                                                            : "flex--1"
                                                    }`}
                                                >
                                                    Trámite a gestionar
                                                </strong>
                                                <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                    {!_.isEmpty(
                                                        contractDetail
                                                    ) &&
                                                        contractDetail[0]
                                                            .sClaseContrato}
                                                </span>
                                            </li>

                                            <li className="flex--start padding-bottom--xs">
                                                <strong
                                                    className={`list-item-name ${
                                                        ["xs", "sm"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? "flex--3"
                                                            : "flex--1"
                                                    }`}
                                                >
                                                    Firmantes
                                                </strong>
                                                <span className="flex--2 font-color--quinary">
                                                    {!_.isEmpty(
                                                        contractDetail
                                                    ) &&
                                                        contractDetail[0]
                                                            .iSignedCount}
                                                </span>
                                            </li>

                                            <li className="flex--start padding-bottom--xs">
                                                <strong
                                                    className={`list-item-name ${
                                                        ["xs", "sm"].includes(
                                                            screenSizeHook
                                                        )
                                                            ? "flex--3"
                                                            : "flex--1"
                                                    }`}
                                                >
                                                    Tipo de firma
                                                </strong>
                                                <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                    {!_.isEmpty(
                                                        contractDetail
                                                    ) &&
                                                        contractDetail[0]
                                                            .sTipoFirma}
                                                </span>
                                            </li>
                                            {!_.isEmpty(contractDetail) &&
                                            contractDetail[0].Repertorio !==
                                                null ? (
                                                <li className="flex--start padding-bottom--xs">
                                                    <strong
                                                        className={`list-item-name ${
                                                            [
                                                                "xs",
                                                                "sm",
                                                            ].includes(
                                                                screenSizeHook
                                                            )
                                                                ? "flex--3"
                                                                : "flex--1"
                                                        }`}
                                                    >
                                                        OT
                                                    </strong>
                                                    <span className="flex--2 font-color--quinary text--lowercase first-letter--uppercase">
                                                        {
                                                            contractDetail[0]
                                                                .Repertorio
                                                        }
                                                    </span>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        lg={6}
                                        className="container--relative flex--1"
                                    >
                                        {loading && (
                                            <Spinner
                                                type="clip"
                                                loading={true}
                                                opacity={true}
                                            />
                                        )}
                                        <div className="width--full flex--around padding-bottom--xs margin-top--lg">
                                            <strong className="list-item-name flex--1 flex--center">
                                                Documentos a firmar
                                            </strong>
                                            {!checkSuccessAgrrement() ? (
                                                <div className="flex--1 flex--start">
                                                    {/* Habilitar cuando se integre la generación de PDF */}
                                                    {/*['IN_NOTARI'].includes(statusContract) ? (
                                                        <button
                                                            className="flex--start button--sm button--lg-rounded margin-right--xs"
                                                            onClick={
                                                                addFileHandler
                                                            }
                                                        >
                                                            <BsCloudUpload className="icon--xxs icon-pointer margin-right--sm" />{" "}
                                                            <span>Añadir</span>
                                                        </button>
                                                        ) : null*/}

                                                    {contractFiles.length ? (
                                                        <>
                                                            {/* Habilitar cuando se integre la generación de PDF */}
                                                            {/* <button className="flex--start button--sm button--lg-rounded margin-right--xs" onClick={boardHandler}>
                                                                <BsPencil className="icon--xxs icon-pointer margin-right--sm"/> <span>Editar orden</span>
                                                            </button> */}
                                                            <button
                                                                className="flex--start button--sm button--lg-rounded margin-right--xs"
                                                                onClick={
                                                                    downloadContract
                                                                }
                                                            >
                                                                <BsCloudDownload className="icon--xxs icon-pointer margin-right--sm" />{" "}
                                                                <span>
                                                                    Descargar
                                                                </span>
                                                            </button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div>
                                            {!_.isEmpty(contractDetail)
                                                ? getFiles(
                                                      contractDetail[0].autoId
                                                  )
                                                : getFiles(0)}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </section>

                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between container--relative">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            3
                                        </strong>
                                    </div>

                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Datos de los firmantes
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Datos de las personas que firman los
                                            documentos
                                        </span>
                                    </div>
                                </div>

                                {reminderSpinner ? (
                                    <Spinner
                                        type="clip"
                                        loading={true}
                                        opacity={false}
                                    />
                                ) : contractMembers &&
                                  contractMembers.length &&
                                  !checkSuccessAgrrement() ? (
                                    <div className="flex--end">
                                        {[
                                            "CREATED",
                                            "REVIEW",
                                            "REJECTED",
                                        ].includes(statusContract) && (
                                            <ResendContract />
                                        )}

                                        {[
                                            "CREATED",
                                            "REVIEW",
                                            "REJECTED",
                                        ].includes(statusContract) ? (
                                            <button
                                                className={`button--sm button--lg-rounded ${
                                                    ["xs"].includes(
                                                        screenSizeHook
                                                    )
                                                        ? "margin-top--xs"
                                                        : ""
                                                } margin-right--xs ${
                                                    removeSignner
                                                        ? "button--red"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setRemoveSignner(
                                                        !removeSignner
                                                    )
                                                }
                                            >
                                                {removeSignner ? (
                                                    <div className="flex--end">
                                                        <BsXCircle className="icon--xxs icon-pointer margin-right--sm" />{" "}
                                                        <span>Cancelar</span>
                                                    </div>
                                                ) : (
                                                    <div className="flex--end">
                                                        <BiTrash className="icon--xxs icon-pointer margin-right--sm" />{" "}
                                                        <span>
                                                            Eliminar firmantes
                                                        </span>
                                                    </div>
                                                )}
                                            </button>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>

                            <div className="info-body padding-y--xxs flex--evenly">
                                {renderMembers()}
                            </div>
                        </section>

                        <section className="contract-detail__info-container">
                            <div className="info-header flex--between">
                                <div className="flex--start">
                                    <div className="contract-detail__identifier">
                                        <strong className="font-size--sm">
                                            4
                                        </strong>
                                    </div>
                                    <div className="whitespace--left-xlg">
                                        <h3 className="info-header__title">
                                            Resumen del pedido
                                        </h3>
                                        <span className="info-header__subtitle font-size--xxs">
                                            Total a pagar
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <ContractDetailSummary
                                summary={totalAmount}
                                contract={contractDetail}
                            />
                        </section>
                    </div>
                </div>
            </div>

            <Snackbar
                open={alertStatusContract}
                autoHideDuration={10000}
                onClose={handleCloseAlertStatusContract}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
            >
                <Alert
                    onClose={handleCloseAlertStatusContract}
                    severity="error"
                    className={styles.muiAlert}
                >
                    Uno de los firmantes ha rechazado el documento, por lo que
                    deberá eliminar u omitir este trámite y corregir el
                    documento.
                </Alert>
            </Snackbar>
        </>
    );
};

export default Contractdetail;
