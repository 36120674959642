import { Stack } from "@mui/material";
import React from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import { ResponsiveContainer } from "recharts";
import { Btn } from "..";
import { CustomLineChart } from "../common/lineChart/CustomLineChart";

/**
 * Component Line Chart Work Stadistics
 * @param data information to display on the chart
 * @returns component chart
 */
export const LineChartWorkStadistics = ({ data }) => {
    const buttonxs = {
        fontSize: "0.9rem",
        textTransform: "none",
        textDecoration: "underline",
        fontWeight: "500",
        color: "black.main",
        "&:hover": {
            color: "primary.light",
            backgroundColor: "transparent",
            textDecoration: "underline",
        },
    };

    const stackJob = {        
        display: "flex",
        justifyContent: "space-between"
    }

    const textJob = {
        fontWeight: '600'
    }

    return (
        <>
            <Stack direction="row" spacing={2} style={stackJob}>

                <div className="flex--start" style={textJob}>
                    Estadísticas de trabajo
                </div>

                <div>

                    <Btn
                        variant="text"
                        onClick={() => console.log("click")}
                        startIcon={<FaRegCalendarAlt />}
                        text="Esta semana"
                        size="lg"
                        sx={buttonxs}
                    />
                    
                    <Btn
                        variant="text"
                        onClick={() => console.log("click")}
                        startIcon={<FaRegCalendarAlt />}
                        text="Este mes"
                        size="lg"
                        sx={buttonxs}
                    />

                </div>

            </Stack>

            <ResponsiveContainer width={"100%"} height={400}>

                <CustomLineChart
                    data={data}

                    XdataKey="name"
                    YdataKey="cant_mes"
                    lineValueX="cant_firmas"
                    lineValueY="cant_mes"
                    colorLineY="#004f9f"
                    typeLine="monotone"
                />

            </ResponsiveContainer>


        </>
    );
};
