import React from "react";
import { Card } from "@mui/material";
import Text from "../../../../heading/Text";

const CardOption = ({ title, subtitle, icon, onClick, id }) => {

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    };

    const subTitleStyles = {
        color: "quinary.main",
        fontWeight: "400",
        marginBottom: "0",
    };

    return (
        <Card onClick={onClick} id={id}>
            {icon}
            <Text sx={titleStyles} align="center" mgButton variant="h6">
                {title}
            </Text>
            <Text
                sx={subTitleStyles}
                align="center"
                mgButton
                variant="subtitle2"
            >
                {subtitle}
            </Text>
        </Card>
    );
};

export default CardOption;
