import { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ManagerAgreementsStyles } from "../../index.css";
import ManageTramits from "../../../../manageTramits";
import { errorStepFormAgreement } from "../../../../../store/formAgreement";

const errrorTitleTramits = "tramits";

const StepThreeUploadPriceList = ({
    control,
    useWatch,
    trigger,
    fields,
    append,
    remove,
    formState,
    setValue,
    getValues,
    setError,
    clearErrors,
}) => {
    const {
        contract: {
            tramites: { allTramitAgreements },
        },
    } = useSelector((state) => state);
    const styles = ManagerAgreementsStyles();
    const dispatch = useDispatch();
    const [contractsType, setContractsType] = useState([]);
    // const [errorInput, setErrorInput] = useState(undefined);

    const watchAgreements = useWatch({ control, name: `agreements` });

    const addAgreement = () => {
        append({
            typeContract: "",
            contractTypeFeeID: {
                SIGNATURE_CERT_NOTARIAL: [],
                SIGNATURE: [],
                SIGNATURE_PROT_NOTARIAL: [],
                SIGNATURE_ANF: [],
            },
            notaryPrice: "",
            price: "",
            iva: "19",
        });
    };

    useEffect(() => {
        if (
            [
                typeof formState.errors[errrorTitleTramits],
                typeof formState.errors["agreements"],
            ].includes("object")
        ) {
            dispatch(errorStepFormAgreement(true));
        } else {
            dispatch(errorStepFormAgreement(false));
        }

        /* return () => {
            remove();
        } */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        if (contractsType.length === 0) setContractsType(allTramitAgreements);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allTramitAgreements]);

    useEffect(() => {
        if (fields.length === 0) addAgreement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    useEffect(() => {
        trigger();

        const isError = watchAgreements.map((item) => {
            const {
                contractTypeFeeID: {
                    SIGNATURE,
                    SIGNATURE_CERT_NOTARIAL,
                    SIGNATURE_PROT_NOTARIAL,
                    SIGNATURE_ANF,
                },
            } = item;

            return SIGNATURE.length > 0 ||
                SIGNATURE_CERT_NOTARIAL.length > 0 ||
                SIGNATURE_PROT_NOTARIAL.length > 0 ||
                SIGNATURE_ANF.length > 0
                ? false
                : true;
        });

        if (isError.includes(true)) {
            setError(errrorTitleTramits, { type: "required" });
        } else {
            clearErrors(errrorTitleTramits);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchAgreements]);

    return (
        <div className={styles.stepBox}>
            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >
                <Grid container xs={12}>
                    <Grid item xs={1} className={styles.boxIndetify}>
                        3<BiRightArrowAlt color="rgb(0, 79, 159)" />
                    </Grid>

                    <Grid
                        item
                        xs={11}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box className={styles.titleBoxStepThree}>
                            <Typography
                                variant="h4"
                                className={styles.cssTexto1}
                                gutterBottom
                            >
                                Cargar lista de precios
                            </Typography>

                            <IconButton
                                aria-label="delete"
                                size="large"
                                onClick={() => addAgreement()}
                            >
                                <PostAddSharpIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                {fields.map((item, index) => (
                    <Fragment key={item.id}>
                        <Grid container xs={12}>
                            <Grid
                                item
                                xs={1}
                                className={styles.boxIndetify}
                            ></Grid>

                            <Grid
                                item
                                xs={11}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box className={styles.titleInputAgreementStep}>
                                    <Typography
                                        variant="h5"
                                        className={styles.cssTexto1}
                                        gutterBottom
                                    >
                                        {index + 1}- Tipo de contrato
                                    </Typography>

                                    <IconButton
                                        aria-label="delete"
                                        size="large"
                                        onClick={() => remove(index)}
                                    >
                                        <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>

                                <Controller
                                    name={`agreements.${index}.typeContract`}
                                    control={control}
                                    rules={{
                                        required:
                                            "Debe Seleccionar al menos un tipo de contrato",
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                sx={{ m: 1, width: "100%" }}
                                                error={
                                                    fieldState.error
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <RadioGroup
                                                    row
                                                    name="controlled-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel
                                                        value="SIGNATURE_CERT_NOTARIAL"
                                                        control={<Radio />}
                                                        label="Certificación"
                                                    />
                                                    <FormControlLabel
                                                        value="SIGNATURE"
                                                        control={<Radio />}
                                                        label="Firma"
                                                    />
                                                    <FormControlLabel
                                                        value="SIGNATURE_ADVANCE"
                                                        control={<Radio />}
                                                        label="Firma Avanzada"
                                                    />
                                                    <FormControlLabel
                                                        value="SIGNATURE_PROT_NOTARIAL"
                                                        control={<Radio />}
                                                        label="Protocolización"
                                                    />
                                                    <FormControlLabel
                                                        value="SIGNATURE_ANF"
                                                        control={<Radio />}
                                                        label="ANF"
                                                    />
                                                </RadioGroup>

                                                <FormHelperText>
                                                    {fieldState.error
                                                        ? fieldState.error
                                                            .message
                                                        : null}
                                                </FormHelperText>
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid
                                item
                                xs={1}
                                className={styles.boxIndetify}
                            ></Grid>

                            <Grid
                                item
                                xs={11}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        marginBottom: 20,
                                        display:
                                            (![undefined, null, ""].includes(
                                                watchAgreements[index]
                                            )
                                                ? watchAgreements[index][
                                                "typeContract"
                                                ]
                                                : "") ===
                                                "SIGNATURE_CERT_NOTARIAL"
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    <ManageTramits
                                        list={contractsType.filter(
                                            (item) =>
                                                item["sModal"] ===
                                                "SIGNATURE_CERT_NOTARIAL"
                                        )}
                                        listTitle="Certificación"
                                        selecteds={(e) => {
                                            const formInput = `agreements.${index}.contractTypeFeeID`;
                                            const arrayValue = [...e];

                                            setValue(
                                                formInput,
                                                Object.assign(
                                                    getValues(formInput),
                                                    {
                                                        SIGNATURE_CERT_NOTARIAL:
                                                            arrayValue,
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        marginBottom: 20,
                                        display:
                                            (![undefined, null, ""].includes(
                                                watchAgreements[index]
                                            )
                                                ? watchAgreements[index][
                                                "typeContract"
                                                ]
                                                : "") === "SIGNATURE"
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    <ManageTramits
                                        list={contractsType.filter(
                                            (item) =>
                                                item["sModal"] === "SIGNATURE"
                                        )}
                                        listTitle="Firma"
                                        selecteds={(e) => {
                                            const formInput = `agreements.${index}.contractTypeFeeID`;
                                            const arrayValue = [...e];

                                            setValue(
                                                formInput,
                                                Object.assign(
                                                    getValues(formInput),
                                                    { SIGNATURE: arrayValue }
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        marginBottom: 20,
                                        display:
                                            (![undefined, null, ""].includes(
                                                watchAgreements[index]
                                            )
                                                ? watchAgreements[index][
                                                "typeContract"
                                                ]
                                                : "") ===
                                                "SIGNATURE_PROT_NOTARIAL"
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    <ManageTramits
                                        list={contractsType.filter(
                                            (item) =>
                                                item["sModal"] ===
                                                "SIGNATURE_PROT_NOTARIAL"
                                        )}
                                        listTitle="Protocolización"
                                        selecteds={(e) => {
                                            const formInput = `agreements.${index}.contractTypeFeeID`;
                                            const arrayValue = [...e];

                                            setValue(
                                                formInput,
                                                Object.assign(
                                                    getValues(formInput),
                                                    {
                                                        SIGNATURE_PROT_NOTARIAL:
                                                            arrayValue,
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </div>

                                <div
                                    style={{
                                        marginBottom: 20,
                                        display:
                                            (![undefined, null, ""].includes(
                                                watchAgreements[index]
                                            )
                                                ? watchAgreements[index][
                                                "typeContract"
                                                ]
                                                : "") === "SIGNATURE_ANF"
                                                ? "flex"
                                                : "none",
                                    }}
                                >
                                    <ManageTramits
                                        list={contractsType.filter(
                                            (item) =>
                                                item["sModal"] ===
                                                "SIGNATURE_ANF"
                                        )}
                                        listTitle="Protocolización"
                                        selecteds={(e) => {
                                            const formInput = `agreements.${index}.contractTypeFeeID`;
                                            const arrayValue = [...e];

                                            setValue(
                                                formInput,
                                                Object.assign(
                                                    getValues(formInput),
                                                    {
                                                        SIGNATURE_ANF:
                                                            arrayValue,
                                                    }
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid
                                item
                                xs={1}
                                className={styles.boxIndetify}
                            ></Grid>

                            <Grid
                                item
                                xs={11}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    className={styles.cssTexto1}
                                    gutterBottom
                                >
                                    {index + 1}- Valor de la firma
                                </Typography>

                                <Controller
                                    name={`agreements.${index}.price`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={
                                                    fieldState.error
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <TextField
                                                    type="number"
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    variant={"outlined"}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    // placeholder={"Escribe aqui tu respuesta..."}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container xs={12} sx={{ paddingBottom: "50px" }}>
                            <Grid
                                item
                                xs={1}
                                className={styles.boxIndetify}
                            ></Grid>

                            <Grid
                                item
                                xs={11}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="h5"
                                    className={styles.cssTexto1}
                                    gutterBottom
                                >
                                    {index + 1}- Valor de la notaria
                                </Typography>

                                <Controller
                                    name={`agreements.${index}.notaryPrice`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={
                                                    fieldState.error
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <TextField
                                                    type="number"
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    variant={"outlined"}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={
                                                        fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null
                                                    }
                                                    // placeholder={"Escribe aqui tu respuesta..."}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Fragment>
                ))}
            </motion.div>
        </div>
    );
};

export default StepThreeUploadPriceList;
