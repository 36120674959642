import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import { ReactComponent as ErrorSignAuthIcon } from "../icons/error-sign-auth-icon.svg";

import { SignAuthorizationStyles } from "../index.css";
import ContactUs from "../molecules/contacUs";
import { to, verifiVariable } from "../../../../utils/enums";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
}));

const CustomButton = styled(Button)((props) => ({
    marginBottom: "50px",
    width: "190px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    padding: "11px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    alignSelf: "center",
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const ErroUniqueKey = () => {
    const styles = SignAuthorizationStyles();
    const { code } = useParams();
    const navigate = useNavigate();
    const [texto, setTexto] = useState("");

    useEffect(() => {
        switch (code) {
            case "400":
                return setTexto("Error al subir el video");
            case "11001":
                return setTexto("Enlace clave única caducado");
            case "11002":
                return setTexto("Rut no coincide");
            case "11003":
                return setTexto("Máxima cantidad de intentos permitidos");
            default:
                return setTexto("");
        }
    }, [code]);

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Item className={styles.itemContent}>
                        <ErrorSignAuthIcon className="rest-icon" />

                        <Typography
                            className={`${styles.mainTitle} ${styles.mainTitleError}`}
                            variant="h5"
                            gutterBottom
                        >
                            Ha ocurrido un error, por favor ingresar clave única
                            nuevamente
                        </Typography>

                        {!verifiVariable(code) && (
                            <Typography
                                className={styles.errorInfoText}
                                variant="subtitle1"
                                gutterBottom
                            >
                                {texto}
                            </Typography>
                        )}

                        {code !== "11003" && (
                            <Typography
                                className={styles.errorInfoText}
                                variant="subtitle1"
                                gutterBottom
                            >
                                Intente nuevamente y si el problema persiste,
                                comuníquese con nuestro
                                <span> Servicio de Atención al Cliente</span>
                            </Typography>
                        )}

                        <CustomButton
                            onClick={() => navigate(to.LOGIN)}
                            variant="contained"
                            color="primary"
                        >
                            Ir a login
                        </CustomButton>

                        <Divider variant="fullWidth" />

                        <ContactUs contactMarginTop={"50px"} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ErroUniqueKey;
