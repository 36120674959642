import { combineReducers } from "redux";
import authReducers from "./auth/entities";
import dashboardReducers from "./dashboard/entities";
import contractReducers from "./contract/entities";
import fvavanzadaReducers from "./fvavanzada/fvavanzada";
import firmaDocsReducers from "./firmaDocs/firmaDocs";
import providersReducers from "./providers/providers";
import usersReducers from "./users/userList";
import formSolicitudReducers from "./formSolicitud/formSolicitud";
import organizationsReducers from "./organization";
import formAgreementReducer from "./formAgreement";
import formUachReducer from "./formUach/formUach";
import formTramitReducer from "./formTramit";
import schedulingReducer from "./scheduling";
import agreementReducer from "./agreement";
import formExpressTramitReducer from "./formExpressTramit";
import formANFTramitReducer from "./formANFTramit";
import couponsReducers from "./coupons/entities";

export default combineReducers({
    agreement: agreementReducer,
    contract: contractReducers,
    coupons: couponsReducers,
    dashboard: dashboardReducers,
    firmaDocs: firmaDocsReducers,
    formAgreement: formAgreementReducer,
    formANFTramit: formANFTramitReducer,
    formExpressTramit: formExpressTramitReducer,
    formSolicitud: formSolicitudReducers,
    formTramit: formTramitReducer,
    formUach: formUachReducer,
    fvavanzada: fvavanzadaReducers,
    organizations: organizationsReducers,
    providers: providersReducers,
    scheduling: schedulingReducer,
    session: authReducers,
    users: usersReducers,
});
