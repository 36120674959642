import { useEffect, useRef, useState } from "react";

import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import BorderColorIcon from '@mui/icons-material/BorderColor';
//import ZoomInIcon from '@mui/icons-material/ZoomIn';
//import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { DndProvider } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import MultiBackend, { TouchTransition, Preview } from 'react-dnd-multi-backend';
import * as PdfjsLib from 'pdfjs-dist';

import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {
    convertDataURIToBinary, getClosestScaleNumber,/* , signedBoxHeight, signedBoxWidth */
    signedBoxHeight,
    signedBoxWidth
} from "../../../../../utils/validations";
import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import { gestionSignatureBoxes, loadDocBase64 } from "../../../../../store/firmaDocs/firmaDocs";
import DraggableContainer from "../draggableContainer";
// import { addESingnerCertPinFirma } from "../../../../../store/fvavanzada/fvavanzada";
import { sigDocWithCert } from "../../../../../utils/api-fva";
import GetFromCertXml from "../../hooks/get-from-cert-xml";
import { api as Api, verificarSigs } from "../../../../../utils/enums";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import ErrorDocumentAlert from "../../../../errorDocumentAlert";


const withMenu = 316;

let initialViewportWidth = 0;

const documentViewportScales = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend
        },
        {
            backend: TouchBackend, // Note that you can call your backends with options
            preview: true,
            transition: TouchTransition
            // skipDispatchOnTransition: true
        }
    ]
};

const PreViewPdf = () => {
    const styles = FirmaVirtualAvanzadaStyles();
    PdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const {
        firmaDocs: {
            pdfBase64,
            signatureBoxes
        },
        fvavanzada: {
            clvUnicadAtosUsuario
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const pdfViewerContainer = useRef();
    const draggableContainer = useRef();
    const [pdfViewer, setPdfViewer] = useState(null);
    const [draggableScale, setDraggableScale] = useState(1);
    const [leftPositionToSet, setLeftPositionToSet] = useState(0);
    const [draggableDimensions, setDraggableDimensions] = useState({});
    const [documentFail, setDocumentFail] = useState(0);

    const [currentDocumentPage, setCurrentDocumentPage] = useState(1);
    const [numberOfDocumentPages, setNumberOfDocumentPages] = useState(undefined);
    const dataCert = GetFromCertXml();

    const buildId = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    };

    const newSignatureData = {
        [buildId(4)]: {
            top: 100,
            left: 10,
            title: `Firma`,
            pageLocation: 1,
            backgroundColor: `#CCE5FF`,
            color: `#6666FF`,
            count: 1
        }
    }

    /* const zoomInt = () => {
        if (draggableScale !== 2) setDraggableScale((draggableScale) + 0.25);
    }

    const zoomOut = () => {
        if (draggableScale !== 0.25) setDraggableScale((draggableScale) - 0.25)
    } */

    const sigDoc = async () => {
        try {

            const infoSigBox = { ...Object.values(signatureBoxes)[0] };

            const xmlBody = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:ws="http://ws.signserver.esign.com/">
                <soapenv:Header/>
                <soapenv:Body>
                <ws:intercambiaDocCoorPDFTSAPin>
                    <!--Optional:-->
                    <Encabezado>
                        <User>${Api.X_API_APP}</User>
                        <Password>${Api.X_API_KEY}</Password>
                        <NombreConfiguracion>${dataCert['restNombreConfiguracion'].split(",")[0]}</NombreConfiguracion>
                        <PinFirma>${clvUnicadAtosUsuario['formData']['pinFirma']}</PinFirma>
                    </Encabezado>
                    <!--Optional:-->
                    <Parametro>
                        <Documento>${pdfBase64.replace("data:application/pdf;base64,", "")}</Documento>
                        <NombreDocumento>prueba.pdf</NombreDocumento>
                        <MetaData></MetaData>
                        <UtilizaImagen>true</UtilizaImagen>
                        <ImagenDinamica>true</ImagenDinamica>
                        <CoordenadaXInferiorizquierda>${infoSigBox['left'] + 4}</CoordenadaXInferiorizquierda>
                        <CoordenadaYInferiorizquierda>${(parseInt(draggableDimensions.height.substring(0, draggableDimensions.height.length - 2)) - infoSigBox['top']) - 100}</CoordenadaYInferiorizquierda>
                        <CoordenadaXSuperiorDerecha>${200 + infoSigBox['left'] + 75}</CoordenadaXSuperiorDerecha>
                        <CoordenadaYSuperiorDerecha>${100 + (parseInt(draggableDimensions.height.substring(0, draggableDimensions.height.length - 2)) - infoSigBox['top']) - 100}</CoordenadaYSuperiorDerecha>
                        <PaginaImagen>0</PaginaImagen>
                        <UtilizaTSA>false</UtilizaTSA>
                        <NumeroPagina>1</NumeroPagina>
                    </Parametro>
                </ws:intercambiaDocCoorPDFTSAPin>
                </soapenv:Body>
            </soapenv:Envelope>`;

            console.log('prueba', dataCert['restNombreConfiguracion'].split(",")[0]);

            // console.log('clvUnicadAtosUsuario', clvUnicadAtosUsuario); ${dataCert['restNombreConfiguracion'].split(",")[0]}
            // console.log('dataCert', dataCert);
            console.log('xmlBody', xmlBody);
            // console.log('infoSigBox', infoSigBox);
            // console.log('signedBoxHeight', signedBoxHeight);
            // console.log('signedBoxWidth', signedBoxWidth);

            const restSigDoc = await sigDocWithCert(xmlBody);

            console.log('restSigDoc', restSigDoc);

        } catch (error) {
            console.log('error', error);
        }
    }

    const handleSetViewportScale = () => {
        let scaleToSet = 1;

        if (initialViewportWidth > window.innerWidth + (['xs', 'sm'].includes(screenSizeHook) ? 0 : withMenu)) {
            const closestScaleNumber = getClosestScaleNumber(documentViewportScales, (window.innerWidth + (['xs', 'sm'].includes(screenSizeHook) ? 0 : withMenu)) / initialViewportWidth);

            if (closestScaleNumber === 0.25) scaleToSet = documentViewportScales[documentViewportScales.indexOf(closestScaleNumber)];
            else scaleToSet = documentViewportScales[documentViewportScales.indexOf(closestScaleNumber) - 1];
        }

        setDraggableScale(scaleToSet);
        // setHandleSetInitialZoom(scaleToSet);

        /* if (['xs', 'sm'].includes(screenSizeHook)) {
            setDraggableScale(0.5);
        } else {
            setDraggableScale(1);
        } */

    };

    const generatePreview = ({ item, style }) => {
        let newTransform = style.transform;
        newTransform = newTransform.substring(10, newTransform.length - 1);
        newTransform = newTransform.split(',');
        newTransform = newTransform.map((data) => {
            data = data.trim();
            data = Number(data.substring(0, data.length - 2));
            return data;
        });
        newTransform = `translate(${newTransform[0]}px, ${newTransform[1]}px) scale(${item.draggableContainerScale})`;
        style.transform = newTransform;
        delete style.transform;
        delete style.WebkitTransform;

        const containerScrolledPosition = document.getElementById('draggable-container').getBoundingClientRect();
        const topPosition = 0 - containerScrolledPosition.y;
        const leftPosition = 0 - containerScrolledPosition.x;

        const newStyle = {
            transform: newTransform,
            WebkitTransform: newTransform,
            ...style,
            width: signedBoxWidth,
            height: signedBoxHeight,
            display: 'flex',
            fontWeight: 'bold',
            alignItems: 'center',
            fontFamily: 'Nunito',
            justifyContent: 'center',
            top: `${topPosition}px`,
            left: `${leftPosition}px`,
            padding: '0.5rem 1rem',
            border: '1px dashed gray',
            transformOrigin: 'center center',
            backgroundColor: `#CCE5FF`,
            color: `#6666FF`
        };

        return <div style={newStyle}>{item.title}</div>;
    };

    /* useEffect(() => {

        setTimeout(() => {

            if (['xs', 'sm'].includes(screenSizeHook)) setDraggableScale(0.5);

        }, 1000);

    }, [screenSizeHook]); */

    useEffect(() => {
        if (pdfViewer) {
            setNumberOfDocumentPages(pdfViewer._pdfInfo.numPages);
            const pageToRender = currentDocumentPage;
            // setControlsBlocked(true);
            pdfViewer.getPage(pageToRender).then(handleLoadDocumentPages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocumentPage, pdfViewer]);

    /* useEffect(() => {
        dispatch(addESingnerCertPinFirma(`<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
        <soap:Body>
            <ns2:descargaCertCreaConfPinFirmaResponse xmlns:ns2="http://ws.signserver.esign.com/">
                <responseDescargaConf>
                    <estado>OK</estado>
                    <comentarios>Configuracion y certificado creado con exito</comentarios>
                    <nombreConfiguracion>769697624_PDF_157712306_CLASE_TRES,769697624_XML_157712306_CLASE_TRES</nombreConfiguracion>
                </responseDescargaConf>
            </ns2:descargaCertCreaConfPinFirmaResponse>
        </soap:Body>
    </soap:Envelope>`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    const handleLoadDocumentPages = async (page) => {
        let initialScaleChanges = false;
        const pdfViewport = page.getViewport({ scale: 1 });
        const canvasContext = pdfViewerContainer.current.getContext('2d');
        pdfViewerContainer.current.height = pdfViewport.height;
        pdfViewerContainer.current.width = pdfViewport.width;

        if (pdfViewport.width > window.innerWidth + (['xs', 'sm'].includes(screenSizeHook) ? 0 : withMenu)) initialScaleChanges = true;

        setDraggableDimensions({
            height: `${pdfViewport.height}px`,
            width: `${pdfViewport.width}px`
        });

        const renderContext = {
            viewport: pdfViewport,
            canvasContext
        };

        const pageRendering = page.render(renderContext);
        const completeCallback = pageRendering._internalRenderTask.callback;
        pageRendering._internalRenderTask.callback = function (error) {
            completeCallback.call(this, error);
            // setControlsBlocked(false);

            setTimeout(() => {
                if (!initialScaleChanges) {
                    handleSetInitialSize();
                } else {
                    initialViewportWidth = initialViewportWidth === 0 ? document.getElementById('draggable-container').getBoundingClientRect().width : initialViewportWidth;
                    handleSetViewportScale();
                }
            }, 100);
        };
    };

    const handleSetInitialSize = () => {
        const dragabbleContainerData = document.getElementById('draggable-container').getBoundingClientRect();

        const widthSpaceAvailable = window.innerWidth - dragabbleContainerData.width + (['xs', 'sm'].includes(screenSizeHook) ? 0 : withMenu);

        if (widthSpaceAvailable > 0) setLeftPositionToSet(widthSpaceAvailable / 2);
        else setLeftPositionToSet(0);
    };

    useEffect(() => {

        (async () => {

            dispatch(gestionSignatureBoxes(null));

            const fileAsBinary = convertDataURIToBinary(pdfBase64);
            const loadPdfDocumentTask = PdfjsLib.getDocument({ data: fileAsBinary });            

            const encrypted = await verificarSigs(fileAsBinary);

            if (encrypted) {
                setDocumentFail(1 + documentFail);
            } else {
                const pdf = await loadPdfDocumentTask.promise;

                dispatch(gestionSignatureBoxes(newSignatureData));

                setPdfViewer(pdf);

                window.addEventListener('resize', handleSetViewportScale);
            }            

        })();

        /*return () => {
            setCurrentDocumentPage(1);
            setCurrentDocumentScale(1);
            window.removeEventListener('resize', handleSetViewportScale);
        }; */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.preViewPdfMainContent} style={{width: '100%'}}>

            <ErrorDocumentAlert errors={documentFail} />

            <div
                id="draggable-container"
                ref={draggableContainer}
                className={styles.draggable}
                style={{ height: draggableDimensions.height, width: draggableDimensions.width, transform: `scale(${draggableScale})`, transformOrigin: 'center center', left: leftPositionToSet }}
            >

                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                    <div style={{ transform: `scale(${1 / draggableScale})`, transformOrigin: 'center center' }}>
                        <Preview>{generatePreview}</Preview>
                    </div>
                    <DraggableContainer
                        hideSourceOnDrag
                        componentMarginTop={20}
                        currentDocumentPage={currentDocumentPage}
                        draggableContainerDimensions={draggableDimensions}
                        draggableContainerScale={draggableScale || 1}
                        boxes={newSignatureData || {}}
                        userSession={/* userSession ||  */{}}
                        handleUpdateBoxesContext={(item, leftUpdated, topUpdated, delta) => {
                            const signatureBoxesUpdated = { ...signatureBoxes[item.id] };
                            Object.assign(signatureBoxesUpdated, { left: leftUpdated, top: topUpdated, title: item.title, pageLocation: item.pageLocation });

                            /* signatureBoxesUpdated = {
                                [item.id]: {
                                    top: topUpdated,
                                    left: leftUpdated,
                                    title: item.title,
                                    pageLocation: item.pageLocation,
                                    backgroundColor: `black`,
                                    color: `red`,
                                    count: 1
                                }
                            } */

                            dispatch(gestionSignatureBoxes({ [item.id]: signatureBoxesUpdated }));
                        }}
                    />
                </DndProvider>

            </div>

            <canvas
                style={{ position: 'absolute', zIndex: 9, transform: `scale(${draggableScale})`, transformOrigin: 'center center', left: leftPositionToSet, top: '197px' }}
                className={!pdfViewerContainer ? styles.noViewerEditorCanvas : styles.viewerEditorCanvas}
                ref={pdfViewerContainer}
            />{/* [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] */}

            <Stack direction="row" mt={5} spacing={2} zIndex={100}>

                <Button
                    onClick={() => dispatch(loadDocBase64(null))}
                    variant="outlined"
                    startIcon={<PictureAsPdfIcon />}
                >
                    Nuevo Contrato
                </Button>

                <Button
                    onClick={() => {
                        setCurrentDocumentPage((currentDocumentPage) - 1);
                    }}
                    disabled={currentDocumentPage === 1 ? true : false}
                    variant="outlined"
                    startIcon={<ArrowBackIosNewIcon />}
                >
                    Atras
                </Button>

                <Button
                    onClick={() => {
                        setCurrentDocumentPage((currentDocumentPage) + 1);
                    }}
                    disabled={numberOfDocumentPages === currentDocumentPage ? true : false}
                    variant="outlined"
                    endIcon={<ArrowForwardIosIcon />}
                >
                    Siguiente
                </Button>

                <Button
                    onClick={() => sigDoc()}
                    variant="outlined"
                    endIcon={<BorderColorIcon />}
                >
                    Firmar
                </Button>

                {/* <IconButton onClick={() => zoomOut()} aria-label=".." size="large">
                    <ZoomOutIcon fontSize="inherit" />
                </IconButton>

                <IconButton onClick={() => zoomInt()} aria-label=".." size="large">
                    <ZoomInIcon fontSize="inherit" />
                </IconButton> */}

            </Stack>

        </div>
    );
}

export default PreViewPdf;