import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "signatories",
    initialState: {
        loading: false,
        lastFetch: null,
        previousSignatoriesList: [],
        message: ''
    },
    reducers: {
        SIGNATORIES_PURGE_STATE: (state, action) => {
            state.loading = false;
            state.lastFetch = null;
            state.previousSignatoriesList = []
            state.message = '';
        },

        SIGNATORIES_REQUEST: (state, action) => {
            state.loading = true;
            state.lastFetch = null;
            state.previousSignatoriesList = []
            state.message = '';
        },

        SIGNATORIES_REQUEST_FAIL: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.lastFetch = Date.now();
        },

        SIGNATORIES_REQUEST_SUCCESS: (state, action) => {
            state.loading = false;
            state.lastFetch = Date.now();
            state.previousSignatoriesList = action.payload['message'].map(((item) => { return { ...item, RUT: item['rut'] } }));
        }
    },
});

export const {
    SIGNATORIES_PURGE_STATE,
    SIGNATORIES_REQUEST_SUCCESS,
    SIGNATORIES_REQUEST_FAIL,
    SIGNATORIES_REQUEST
} = slice.actions;


export const getPreviusSigners = (token) => {
    return apiCallStart({
        url: `/api/v1/signer/list`,
        method: "GET",
        onStart: SIGNATORIES_REQUEST.type,
        onSuccess: SIGNATORIES_REQUEST_SUCCESS.type,
        onError: SIGNATORIES_REQUEST_FAIL.type,
        authorization: token
    });
};

export const purgeSigners = () => {
    return { type: SIGNATORIES_PURGE_STATE.type, payload: [] };
};

export default slice.reducer;
