import axios from "axios";
import { api as Api } from "../utils/enums";

const baseUrl = Api.BASE_URI;

const version = "v1";

const api = "api";

const urlUser = {
    list: `${baseUrl}/${api}/${version}/userapp`,
    create: `${baseUrl}/${api}/${version}/userapp`,
    edit: `${baseUrl}/${api}/${version}/userapp`,
};

export const getUser = async (userId, token) => {
    try {
        const response = await axios.get(urlUser.edit + '/' + userId, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const createUser = async (data, token) => {
    try {
        const response = await axios.post(urlUser.create, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const editUser = async (data, userId, token) => {
    try {
        const response = await axios.post(`${urlUser.edit}/upd/${userId}`, data, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${token}`,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getUserList = async (token) => {
    try {
        const response = await axios.get(urlUser.list, {
            headers: {
                "Authorization": `${token}`,
            },
        });

        return response.data;
    } catch (error) {
        return error;
    }
};
