import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { MdLockOutline, MdLockOpen, MdKeyboardArrowLeft } from "react-icons/md";

import Grid from '@mui/material/Grid';

import { Btn } from "../../components";
import { setNewPassword } from "../../utils/api";
import { to } from "../../utils/enums";
import { validation } from "../../utils/validations";
import ModalAlert from "../floats/ModalAlert";


const titleError = '¡Vaya!, ha ocurrido un error';
const msnError = 'Su código temporal ha sido ingresado incorrectamente, verifíquelo y vuelva a intentar.';
const buttomTitleError = 'Volver a intentarlo';

export default function ResetPasswordForm() {
    const navigate = useNavigate();
    const [showCode, setShowCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passReseted, setPassReseted] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        await setNewPassword(data.email, data.recoverCode, data.newPassword)
            .then((response) => {
                console.log(response);
                if (response?.status !== undefined) {
                    if (response?.status === "success") {
                        setPassReseted(true);
                        setModalStatus({
                            title: "¡Excelente!",
                            message: `Tu contraseña ha sido restablecida, ahora puedes iniciar sesión.`,
                            buttonText: "Iniciar sesión",
                            modalIcon: "warnIcon"
                        });
                        setShowModal(true);
                    } else {
                        setPassReseted(false);
                        setModalStatus({
                            title: titleError,
                            message: msnError,
                            buttonText: buttomTitleError,
                            modalIcon: "warnIcon"
                        });
                        setShowModal(true);
                    }
                } else {
                    setPassReseted(false);
                    setModalStatus({
                        title: titleError,
                        message: msnError,
                        buttonText: buttomTitleError,
                        modalIcon: "warnIcon"
                    });
                    setShowModal(true);
                }
            })
            .catch((error) => {
                setPassReseted(false);
                setModalStatus({
                    title: titleError,
                    message: msnError,
                    buttonText: buttomTitleError,
                    modalIcon: "warnIcon"
                });
                setShowModal(true);
                console.log("error: ", error);
            });
    };

    const renderInputPasswordButton = (field, setField) => {
        if (field) {
            return (
                <MdLockOpen
                    className="icon--md icon--blue icon-pointer"
                    onClick={() => togglePasswordView(field, setField)}
                />
            );
        } else {
            return (
                <MdLockOutline
                    className="icon--md icon--blue icon-pointer"
                    onClick={() => togglePasswordView(field, setField)}
                />
            );
        }
    };

    const togglePasswordView = (field, setField) => {
        setField(!field);
    };

    const handleModal = () => {
        if (passReseted) {
            setShowModal(false);
            navigate(to.HOME);
        } else {
            setShowModal(false);
        }
    };

    return (
        <>
            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container justifyContent={'center'}>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                        <div className="input-container">
                            <label>Correo electrónico</label>
                            <input
                                type="text"
                                className={errors.email && "input-container--error"}
                                placeholder="tucorreo@mail.com"
                                {...register("email", {
                                    required: true,
                                    pattern: validation.email,
                                })}
                            />
                            {errors.email?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {errors.email?.type === "pattern" && (
                                <span className="input--error">
                                    Ingresa un correo electrónico valido
                                </span>
                            )}
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                        <div className="input-container">
                            <label>Código temporal</label>
                            <div className="input-container">
                                <div className="input-container--relative">
                                    <input
                                        type={!showCode ? "password" : "text"}
                                        placeholder="Código temporal"
                                        className={
                                            errors.recoverCode &&
                                            "input-container--error"
                                        }
                                        {...register("recoverCode", {
                                            required: true,
                                        })}
                                    />
                                    <div className="input-icon--right">
                                        {renderInputPasswordButton(
                                            showCode,
                                            setShowCode,
                                        )}
                                    </div>
                                </div>
                                {errors.recoverCode?.type === "required" && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}
                            </div>
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                        <div className="input-container">
                            <label>Nueva contraseña</label>
                            <div className="input-container">
                                <div className="input-container--relative">
                                    <input
                                        type={!showPassword ? "password" : "text"}
                                        className={
                                            errors.newPassword
                                                ? "input-container--error"
                                                : ""
                                        }
                                        {...register("newPassword", {
                                            required: true,
                                            minLength: 8,
                                            maxLength: 128,
                                            validate: {
                                                regexMayuscula: (value) =>
                                                    /[A-Z]/g.test(value) ||
                                                    "Debe contener al menos una letra mayúscula",
                                                regexMinuscula: (value) =>
                                                    /[a-z]/g.test(value) ||
                                                    "Debe contener al menos una letra minúscula",
                                                regexNumero: (value) =>
                                                    /\d/g.test(value) ||
                                                    "Debe contener al menos un numero",
                                                // eslint-disable-next-line
                                                regexCaracterEspecial: (value) =>
                                                    /[!_@$*+-.=:#?&%^]/g.test(
                                                        value
                                                    ) ||
                                                    "Debe contener al menos un caracter especial",
                                            }
                                        })}
                                    />
                                    <div className="input-icon--right">
                                        {renderInputPasswordButton(
                                            showPassword,
                                            setShowPassword,
                                        )}
                                    </div>
                                </div>
                                {errors.newPassword?.type === "required" && (
                                    <span className="input--error">
                                        Este campo es obligatorio
                                    </span>
                                )}
                                {errors.newPassword?.type === "minLength" && (
                                    <span className="input--error">
                                        Por su seguridad, debe ingresar una
                                        contrasena de mas de 8 caracteres.
                                    </span>
                                )}
                                {errors.newPassword?.type === "maxLength" && (
                                    <span className="input--error">
                                        La cantidad maxima de caracteres para su
                                        contrasena debe ser de 128 caracteres.
                                    </span>
                                )}
                                {errors.newPassword?.type === "regexMayuscula" && (
                                    <span className="input--error">
                                        Debe contener al menos una letra mayúscula
                                    </span>
                                )}

                                {errors.newPassword?.type === "regexMinuscula" && (
                                    <span className="input--error">
                                        Debe contener al menos una letra minúscula
                                    </span>
                                )}

                                {errors.newPassword?.type === "regexNumero" && (
                                    <span className="input--error">
                                        Debe contener al menos un número
                                    </span>
                                )}
                                {errors.newPassword?.type === "regexCaracterEspecial" && (
                                    <span className="input--error">
                                        Debe contener al menos un caracter especial
                                    </span>
                                )}
                            </div>
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                        <Btn
                            variant="contained"
                            text="Restablecer contraseña"
                            size="lg"
                            color="primary"
                            type="submit"
                            buttonClass="button-submit"
                            fullWidth
                        />

                    </Grid>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2, marginBottom: 5 }}>

                        <div className="flex--center">
                            <Btn
                                variant="text"
                                text="Atrás para iniciar sesión"
                                buttonClass="button-link"
                                onClick={() => navigate('/')}
                                startIcon={
                                    <MdKeyboardArrowLeft className="icon--md icon--blue" />
                                }
                            />
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={9} md={8} sx={{ marginRight: 2, marginLeft: 2 }}>

                        <div className="flex--center signup-link">
                            <span className="whitespace--right-md">
                                ¿Nuevo en nuestra plataforma?
                            </span>
                            <Link to="/signup-account">Crea una cuenta</Link>
                        </div>

                    </Grid>

                </Grid>

            </form>
        </>
    );
}
