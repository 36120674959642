import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { Text } from '../../../..';
import { styles } from '../../../../../helpers';


const RenderUserName = ({data: { sFirstName, sLastName, sPicture, sLogin }}) => {

    /*const userNickNameStyles = {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
        fontSize: "0.9rem",
    };

    const userNameStyles = {
        color: "black.main",
        textTransform: "capitalize",
        lineHeight: 1.1,
    };*/

    const userNameContainerWidth = {
        width: "150px",
    };

    

    return (
        <Box sx={styles.userAvatarContainerStyles}>
            <Avatar
                sx={styles.serAvatarPictureStyles}
                variant="square"
                src={sPicture}
                name={`${sFirstName}_${sLastName}_picture`}
                alt={`${sFirstName} ${sLastName} picture`}
            />
            <Box sx={[styles.userNameContainerStyles, userNameContainerWidth]}>
                <Text
                    sx={styles.userNameStyles}
                    variant="h6"
                >{`${sFirstName} ${sLastName}`}</Text>
                <Text sx={styles.userNickNameStyles} variant="h6">
                    {sLogin}
                </Text>
            </Box>
        </Box>
    );
}

export default RenderUserName;