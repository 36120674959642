import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as PdfjsLib from "pdfjs-dist";

import {
    MdCloudUpload,
    MdModeEdit,
    MdOutlineCancelPresentation,
} from "react-icons/md";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import FilePreview from "react-file-preview-latest";

import Text from "../../heading/Text";
import { DocumentsStyles } from "./index.css";
import {
    asyncBase64ToFile,
    asyncBlobToBase64,
    asyncFileToBase64,
    to,
    verifiVariable,
} from "../../../utils/enums";
import { saveFormInfo } from "../../../store/formTramit";

import { ReactComponent as FolderIcon } from "./img/folder.svg";
import { mergeDocumentsOptions2 } from "./services";
import Spinner from "../../spinners/Spinner";
import { Context } from "../../../context/utilsContext";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { validation } from "../../../utils/validations";
import ModalContractUploadError from "../../modalContractUploadError";
import RenderedPDF from "../../dashboard/expressProcess/molecules/renderedPDF/RenderedPDF";
import { Box } from "@mui/material";
//import AttachedCDV from "../../attachedCDV";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.9rem",
    // padding: '11px 81px',
    marginTop: "11px",
    marginBottom: "20px",
    border: `1px solid ${props.theme.palette.primary.light}`,
    "&:hover": {
        border: `1px solid ${props.theme.palette.primary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const MergeCustomButtom = styled(Button)((props) => ({
    // width: '8rem',

    [props.theme.breakpoints.not('xs')]: {
        width: '14rem !important',
    },

    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.9rem",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.primary.light}`,
    order: 2,
    "&:hover": {
        border: `1px solid ${props.theme.palette.primary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
    [props.theme.breakpoints.up('lg')]: {
        width: '12rem',
        order: 1,
        marginRight: '1.2rem'
    }
}));

const CancelMergeCustomButtom = styled(Button)((props) => ({
    width: '8rem',
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    color: props.theme.palette.secondary.main,
    fontSize: "0.9rem",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.secondary.light}`,
    order: 1,
    "&:hover": {
        border: `1px solid ${props.theme.palette.secondary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
    [props.theme.breakpoints.up('lg')]: {
        width: '12rem',
        order: 2,
    }
}));

const TextIconfolder = styled(Typography)((props) => ({
    color: props.theme.palette.gray.dark,
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const Documents = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } =
        useContext(Context);
    const navigate = useNavigate();
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const inputMergeRef = useRef(null);
    const inputFileRef = useRef(null);
    const {
        formTramit: {
            firms,
            loading: loadingRequest,
            uploadProgress,
            firstStep,
            thirdStep: { fileName, docPdfBase64 },
            fourthStep,
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const styles = DocumentsStyles({ screenSizeHook });
    const [showMergeContainer, setShowMergeContainer] = useState(false);
    const [arrayMerge, setArrayMerge] = useState([]);
    const [fileTramit, setFileTramit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [open, setOpen] = useState(false);
    const [showMaxSizeText, setShowMaxSizeText] = useState(false);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);
    const [errorsFiles, setErrorsFiles] = useState([]);

    const tramitTypeName = firstStep?.procedureid?.name;

    const mergePaddingButtoms = {
        padding: screenSizeHook === "xs" ? "10px 10px" : "11px 32px",
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleSort = () => {
        //duplicate items
        let _arrayMerge = [...arrayMerge];

        //remove and save the dragged item content
        const draggedItemContent = _arrayMerge.splice(dragItem.current, 1)[0];

        //switch the position
        _arrayMerge.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setArrayMerge(_arrayMerge);
    };

    const handleFileClick = () => {
        inputFileRef.current.click();
    };

    const handleMergeClick = () => {
        inputMergeRef.current.click();
    };

    const addData = (array) => {
        return new Promise((resolve) => {

            const formData = new FormData();

            array.forEach((element) => {
                formData.append('files', element["file"]);
            });

            resolve(formData);

        });
    };

    const mergeDocs = async () => {
        try {
            setLoading(true);

            setTextLoading(
                "Uniendo documento este proceso puede durar un par de minutos por favor espere..."
            );

            const formData = await addData(arrayMerge);

            const response = await mergeDocumentsOptions2(formData);

            const fileBase64 = await asyncBlobToBase64(response);

            setLoading(false);

            setShowMergeContainer((value) => !value);
            setArrayMerge([]);

            setFileTramit({
                name: "tramite",
            });

            dispatch(
                saveFormInfo({
                    indexe: "thirdStep",
                    value: {
                        fileName: "tramite",
                        docPdfBase64: fileBase64.replace("data:application/pdf;base64,", ""),
                    },
                })
            );

            dispatch(
                saveFormInfo({
                    indexe: "firms",
                    value: {},
                })
            );
            setToResumen(undefined);
        } catch (error) {
            setLoading(false);
            handleClick();
            console.log(error);
        }
    };

    const handleFileChange = async (event) => {
        try {
            const fileObj = event.target.files && event.target.files[0];

            if (!fileObj) {
                return;
            }

            if (fileObj.type !== 'application/pdf') {
                setErrorsUpload(["Solo se permiten archivos PDF."]);
                setOpenModalErrors(true)
                return;
            }

            if (fileObj.size > validation.fileMaxSize) {
                setShowMaxSizeText(true);
                return;
            }

            const arrayBuffer = await fileObj.arrayBuffer();

            await PdfjsLib.getDocument({ data: arrayBuffer }).promise;

            setShowMaxSizeText(false);

            event.target.value = null;

            const docPdfBase64 = await asyncFileToBase64(fileObj);

            dispatch(
                saveFormInfo({
                    indexe: "thirdStep",
                    value: {
                        docPdfBase64: docPdfBase64.replace(
                            "data:application/pdf;base64,",
                            ""
                        ),
                        fileName: fileObj.name,
                    },
                })
            );

            setFileTramit({
                name: fileObj.name,
            });

            dispatch(
                saveFormInfo({
                    indexe: "firms",
                    value: {},
                })
            );
            setToResumen(undefined);
        } catch (error) {
            setErrorsUpload(['El archivo PDF está corrupto o tiene una estructura inválida.']);
            setOpenModalErrors(true);
            console.log("error: ", error);
        }
    };

    const handleFileMergeChange = async (event) => {
        const arrayData = [...arrayMerge];
        const errors = [];
        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/png',
            'image/jpg',
            'image/jpeg'
        ];

        try {
            for (let index = 0; index < event.target.files.length; index++) {
                const element = event.target.files[index];

                if (element.type === 'application/pdf') {
                    const arrayBuffer = await element.arrayBuffer();
                    await PdfjsLib.getDocument({ data: arrayBuffer }).promise;
                }
                console.log({ element })

                if (!allowedTypes.includes(element.type || element.type === "")) {
                    console.log('Uno de los archivos que has intentado unir no es permitido, por favor, incluye archivos .pdf, .doc, .docx, .jpg, .jpeg o .png.')
                    errors.push('Uno de los archivos que has intentado unir no es permitido, por favor, incluye archivos .pdf, .doc, .docx, .jpg, .jpeg o .png.');
                };
                console.log({ errors })
                if (errors.length > 0) {
                    setErrorsFiles(errors);
                } else {

                    const restDocPdfBase64 = await asyncFileToBase64(element);

                    const docPdfBase64 = restDocPdfBase64.split("base64,");

                    arrayData.push({
                        file: element,
                        name: element.name,
                        docPdfBase64: docPdfBase64[1],
                    });
                };
            };

            event.target.value = null;

            setArrayMerge(arrayData);
            setErrorsFiles([]);
        } catch (error) {
            errors.push('Uno de los archivos que has intentado subir está dañado. Por favor, intenta cargar otro.');
            setErrorsFiles(errors);
        };
    };

    const removeFileFromArray = (name) => {
        const arrayData = [...arrayMerge].filter(
            (item) => item["name"] !== name
        );
        setArrayMerge(arrayData);
    };

    useEffect(() => {
        if (nextPage !== 0) {
            if (
                !verifiVariable(fourthStep) &&
                Object.values(firms).length !== 0
            ) {
                navigate(to.CONTRACT_CREATION_DATA_PREVIEW);
            } else {
                navigate(to.CONTRACT_CREATION_ADD_SIGNATURES);
            }
        }

        return () => {
            setNextPage(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {
        if (!verifiVariable(toResumen)) navigate(toResumen);

        return () => {
            setToResumen(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {
        if (docPdfBase64)
            asyncBase64ToFile(
                "data:application/pdf;base64," + docPdfBase64,
                fileName
            ).then((file) =>
                setFileTramit({ file, docPdfBase64, name: fileName })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdfBase64]);

    useEffect(() => {
        setTextLoading("Limpiando archivo " + uploadProgress + "%");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadProgress]);

    useEffect(() => {

        if (tramitTypeName !== 'Contrato de arriendo' &&
            tramitTypeName !== 'Promesa de compraventa' &&
            tramitTypeName !== 'Mandato de administración') {
            dispatch(
                saveFormInfo({
                    indexe: 'attachedCDV',
                    value: null
                })
            );
        };

    }, [tramitTypeName, dispatch]);

    return (
        <div
            className={`${styles.procedureCreationFormContainer} procedure-creation__form-container`}
        >
            <ModalContractUploadError
                openModalErrors={openModalErrors}
                setOpenModalErrors={setOpenModalErrors}
                data={errorsUpload}
                modalIcon={'warnIcon'}
            />

            {(loading || loadingRequest) && (
                <Spinner
                    loading={true}
                    type="bar"
                    opacity={true}
                    text={textLoading}
                />
            )}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Ha habido un error durante la previsualización del archivo
                </Alert>
            </Snackbar>

            <div className={`${styles.formContainer} form__container`}>
                <div className={styles.titleContainer}>
                    <Text className={styles.titleStyles} align="left">
                        Adjuntar documentos
                    </Text>

                    <br />

                    <Text
                        className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                        align="left"
                    >
                        Adjunta los documentos necesarios para continuar con el
                        trámite.
                    </Text>

                    <Text className={styles.hintStyles} align="left">
                        Recuerda adjuntar como mínimo contrato a firmar y
                        cédulas de los participantes o firmantes.
                    </Text>

                    <br />

                    <Text className={styles.hintStyles} align="left">
                        Para proteger tu contrato bajo la nueva ley N° 21.461 "Devuélveme mi Casa", incluye el certificado de dominio vigente de la propiedad (con menos de 30 días de antigüedad) en la documentación.
                        Si aún no lo tienes, te sugerimos crear el tramite nuevamente incluyendo el dominio vigente de la propiedad.
                        Sin el certificado de dominio vigente, el contrato de arriendo no queda protegido bajo la nueva ley de arriendo.
                    </Text>

                    <br />

                    {/* {
                        (
                            tramitTypeName === 'Contrato de arriendo' ||
                            tramitTypeName === 'Promesa de compraventa' ||
                            tramitTypeName === 'Mandato de administración'
                        ) && (

                            <>
                                <AttachedCDV signatureType={'aprovers'} />

                                <br />
                            </>

                        )
                    } */}

                    <Text
                        className={`${styles.bottomTitleStyles} ${styles.subTitleStyles} ${styles.titleStyles}`}
                        align="left"
                    >
                        Recuerda adjuntar como mínimo: contrato a firmar y cédulas de los participantes o firmantes. Si corresponde,
                        el certificado de representante legal (e-RUT).
                    </Text>

                    {showMaxSizeText && (
                        <Typography
                            variant="body1"
                            gutterBottom
                            color={["error"]}
                            textAlign={"center"}
                        >
                            El archivo que seleccionaste es demasiado grande.
                            Por favor, selecciona un archivo que sea menor a
                            10MB.
                        </Typography>
                    )}
                </div>
            </div>

            {!showMergeContainer && !fileTramit && (
                <div
                    className={`${styles.fileButomsContainer} form__container`}
                >
                    <div className={styles.uploadButtomContainer}>
                        <input
                            hidden
                            accept="application/pdf"
                            style={{ display: "none" }}
                            ref={inputFileRef}
                            type="file"
                            onChange={handleFileChange}
                        />

                        <Text
                            className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                            align="center"
                        >
                            ¿Ya cuentas con todos tus archivos unidos en (1)
                            sólo PDF?
                        </Text>

                        <UploadCustomButtom
                            fullWidth={screenSizeHook === "xs" ? true : false}
                            sx={mergePaddingButtoms}
                            onClick={() => handleFileClick()}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdCloudUpload className="icon--blue icon--lg" />
                            }
                        >
                            Subir archivo
                        </UploadCustomButtom>

                        <div>o</div>

                        <Text
                            className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                            sx={{ marginTop: "11px" }}
                            align="center"
                        >
                            ¿Necesitas unir tus documentos en un solo archivo
                            PDF?
                        </Text>

                        <MergeCustomButtom
                            fullWidth={screenSizeHook === "xs" ? true : false}
                            sx={mergePaddingButtoms}
                            onClick={() => {
                                setShowMergeContainer((validate) => !validate);
                                /* urlUnificarDocs(); */
                            }}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdModeEdit
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    className="icon--white icon--md icon-back-ground-color-blue"
                                />
                            }
                        >
                            Unir PDF
                        </MergeCustomButtom>
                    </div>
                </div>
            )}

            {docPdfBase64 && fileTramit && (
                <div
                    className={`${styles.fileButomsContainer} form__container`}
                >
                    <div className={styles.FilePreview}>
                        <div className={styles.conatinerCloseButtomPreview}>
                            <DisabledByDefaultIcon
                                className="icon--red icon-pointer"
                                onClick={() => {
                                    dispatch(
                                        saveFormInfo({
                                            indexe: "thirdStep",
                                            value: {},
                                        })
                                    );
                                    setFileTramit(undefined);
                                }}
                            />
                        </div>

                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            align="center"
                            color={"gray.dark"}
                        >
                            {fileName.length < 19
                                ? fileName
                                : fileName.substring(0, 15) + "..."}
                        </Typography>

                        <FilePreview
                            type={"file"}
                            file={fileTramit["file"]}
                            onError={() => console.log("error")}
                        />
                    </div>
                </div>
            )}

            {showMergeContainer && (
                <div
                    className={`${styles.fileButomsContainer}`}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <MergeCustomButtom
                            sx={mergePaddingButtoms}
                            onClick={() => mergeDocs()}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdModeEdit
                                    style={{ borderRadius: "5px" }}
                                    className="icon--white icon--md icon-back-ground-color-blue"
                                />
                            }
                        >
                            Unir
                        </MergeCustomButtom>

                        {errorsFiles.length > 0 && errorsFiles.map(ele => (
                            <Typography>
                                {ele}
                            </Typography>
                        ))}

                        <CancelMergeCustomButtom
                            style={{
                                marginRight:
                                    screenSizeHook !== "xs"
                                        ? "20px"
                                        : "16px",
                            }}
                            sx={mergePaddingButtoms}
                            onClick={() => {
                                setShowMergeContainer((value) => !value);
                                setArrayMerge([]);
                            }}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdOutlineCancelPresentation
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    className="icon--white icon--md icon-back-ground-color-red"
                                />
                            }
                        >
                            Cancelar
                        </CancelMergeCustomButtom>
                    </div>

                    {arrayMerge.map((item, index) => {
                        return (
                            <Box
                                sx={{ display: 'block' }}
                                draggable
                                onDragStart={(e) => (dragItem.current = index)}
                                onDragEnter={(e) => (dragOverItem.current = index)}
                                onDragEnd={handleSort}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <RenderedPDF
                                    key={item["name"] + index}
                                    index={index}
                                    item={item}
                                    removeFileFromArray={removeFileFromArray}
                                    handleSort={handleSort}
                                />
                            </Box>
                        );
                    })}

                    <div
                        className={styles.folderIconContainer}
                        onClick={handleMergeClick}
                    >
                        <input
                            hidden
                            accept="image/png, image/jpeg, image/jpg, application/pdf, .docx, .docm, .doc, .pptx, .pptm, .potx, .ppsx"
                            style={{ display: "none" }}
                            ref={inputMergeRef}
                            type="file"
                            multiple
                            onChange={handleFileMergeChange}
                        />

                        <FolderIcon />

                        <TextIconfolder variant="subtitle1" gutterBottom>
                            Añadir archivo
                        </TextIconfolder>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Documents;
