import React from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Step1 from "./molecules/step1";
import Step2 from "./molecules/step2";
import Step3 from "./molecules/step3";
import Step4 from "./molecules/step4";
import { QontoConnector, QontoStepIconRoot } from "./FirmaVirtualAvanzadaStyles.css";


const steps = [
    "Clave Unica",
    "Challenge de preguntas",
    "Registra tu FEA",
    "Firma Documentos",
];

const FirmaVirtualAvanzada = () => {
    const {
        fvavanzada: {
            clvUnicadAtosUsuario
        }
    } = useSelector(state => state);
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [blockNext, setBlockNext] = React.useState(false);

    const isStepOptional = (step) => {
        return step === 100;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const showStep = React.useCallback(() => {

        switch (activeStep) {
            case 0:

                return <Step1 />;
            case 1:
                return <Step2 />;
            case 2:
                return <Step3 />;
            default:
                return <Step4 />;
        }

    }, [activeStep]);
    
    React.useEffect(() => {
        console.log('blockNext: ', blockNext);
    }, [blockNext]);

    React.useEffect(() => {

        /* console.log('activeStep', activeStep); */

        if (activeStep === 0) setBlockNext(([null, undefined].includes(clvUnicadAtosUsuario) || [null, undefined].includes(clvUnicadAtosUsuario['formData'])) ? true : false);

    }, [activeStep, clvUnicadAtosUsuario]);

    const QontoStepIcon = (props) => {
        const { active, completed, className, icon } = props;

        return (
            <QontoStepIconRoot ownerState={{ active }} className={className}>
                {completed ? (
                    // <BsCheck2 className="QontoStepIcon-completedIcon" />
                    <div className="QontoStepIcon-completedIcon">
                        <span className="QontoStepNumber">{icon}</span>
                    </div>
                ) : (
                    <div className="QontoStepIcon-circle">
                        <span className="QontoStepNumber">{icon}</span>
                    </div>
                )}
            </QontoStepIconRoot>
        );
    }

    return (
        <Box sx={{ width: "100%", padding: '34px' }}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<QontoConnector />}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={QontoStepIcon}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
                        Felicitaciones a completado todos los pasos.
                    </Typography>

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {/* {prueba()} */}
                    {showStep(activeStep)}

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Atrás
                        </Button>

                        <Box sx={{ flex: "1 1 auto" }} />

                        {isStepOptional(activeStep) && (
                            <Button
                                color="inherit"
                                onClick={handleSkip}
                                sx={{ mr: 1 }}
                            >
                                Skip
                            </Button>
                        )}

                        <Button /* disabled={blockNext} */ onClick={handleNext}>
                            {activeStep === steps.length - 1
                                ? "Terminar"
                                : "Siguiente"}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

export default FirmaVirtualAvanzada;
