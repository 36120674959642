// import RenderCreatedAt from "../atoms/renderCreatedAt";
// import RenderCell from "../atoms/renderCell";
// import WithDocument from "../atoms/withDocument";
// import RenderSigners from "../atoms/renderSigners";
// import RenderStatus from "../atoms/renderStatus";
// import RenderActions from "../atoms/renderActions";
// import RenderContractDetail from "../atoms/renderContractDetail";

import RenderActions from "../atoms/renderActions";

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

const gridColumns = [
    {
        field: "full_name",
        headerAlign: "center",
        headerName: "Nombre completo",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase-all text--center",
        width: 270
    },
    {
        field: "email",
        headerAlign: "center",
        headerName: "Correo eletrónico",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        // renderCell: (params) => <RenderContractDetail data={params.row} />
    },
    {
        field: "rutId",
        headerAlign: "center",
        headerName: "RUT o Pasaporte",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase text--center",
        width: 270,
        // renderCell: (params) => <RenderCell item={params.value} />
    },
    {
        field: "phone",
        headerAlign: "center",
        headerName: "Número de celular",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 180,
        // renderCell: (params) => <RenderCreatedAt item={params.value} />
    },
    {
        field: "pay",
        headerAlign: "center",
        headerName: "Action",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 100,
        renderCell: (params) => <RenderActions item={params.row} />
    }
];

export {
    gridColumns,
    localizedTextsMap
};