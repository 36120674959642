import { forwardRef, useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import { ReplaceRolDocumentStyles } from "../../index.css";
import Btn from "../../../../buttons/Button";



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorAlert = ({ errors, title, msn }) => {
    const styles = ReplaceRolDocumentStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (errors > 0) handleClickOpen();
    }, [errors]);

    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            className={styles.mainContainerDialog}
        >
            <DialogTitle
                sx={{ textAlign: "center" }}
                color={["secondary.main"]}
            >
                {title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>{msn}</DialogContentText>
            </DialogContent>

            <DialogActions
                style={{ justifyContent: "center", paddingBottom: "20px" }}
            >
                <Btn
                    variant="outlined"
                    text="OK"
                    color="primary"
                    onClick={handleClose}
                    sx={{
                        height: "39px",
                        borderRadius: "20px",
                        marginLeft: "30px !important",
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ErrorAlert;
