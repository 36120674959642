import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Context from './context/utilsContext';
import reportWebVitals from './reportWebVitals';
import './fonts/Poppins/Poppins-Bold.ttf';
import dayjs from 'dayjs';
// import * as Sentry from "@sentry/react";

require('dayjs/locale/es');

dayjs.locale('es');

/* Sentry.init({
  dsn: "https://5ed4de78735a4eb8a5bcff4a064e5d93@o4505280404586496.ingest.sentry.io/4505286592888832",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/, /^https:\/\/dev\.firmavirtual\.com/, /^https:\/\/qa\.firmavirtual\.com/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */



ReactDOM.render(
  <React.StrictMode>
    <Context.Provider>
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
