import React, { useState } from 'react'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Images from '../images';
import MainProgresBar from '../mainProgressBar';
import ResponsiveText from '../responsiveText';
import { Box, IconButton } from '@mui/material';
import { ModalContractCreatedAndContractStepsStyles } from '../../index.css';

const ImgSlider = ({ images, stepsNumbers, text, stepsTitle }) => {

    const [steps] = useState(stepsNumbers);
    const [active, setActive] = useState(0);
    const [imgActive, setImgActive] = useState(0);
    const [imgPrevInactive, setImgPrevInactive] = useState(imgActive - 1);
    const [imgNextInactive, setImgNextInactive] = useState(imgActive + 1);

    const handlePrevSlide = () => {
        active <= 0 ? setActive(0) : setActive(active - 1)
        imgActive <= 0 ? setImgActive(0) : setImgActive(imgActive - 1)
        imgPrevInactive > -1 && setImgPrevInactive(imgPrevInactive - 1)
        imgNextInactive > 1 && setImgNextInactive(imgNextInactive - 1)
    }

    const handleNextSlide = () => {
        active < steps - 1 && setActive(active + 1)
        imgActive < images.length - 1 && setImgActive(imgActive + 1)
        imgPrevInactive < images.length - 2 && setImgPrevInactive(imgPrevInactive + 1)
        imgNextInactive < images.length && setImgNextInactive(imgNextInactive + 1)
    }

    const styles = ModalContractCreatedAndContractStepsStyles();

    return (
        <Box>
            <MainProgresBar
                steps={steps}
                active={active}
                stepsTitle={stepsTitle}
            />

            <ResponsiveText
                text={text}
                active={active}
            />

            <div className={styles.slider_Container}>
                <Box sx={{ display: active === 0 ? 'none' : '' }}>
                    <IconButton>
                        <ArrowBackIosIcon onClick={handlePrevSlide} />
                    </IconButton>
                </Box>
                <div className={styles.slider_imgBox}>
                    {images.map((item, i) => (
                        <Images key={i}
                            id={i}
                            src={item.src}
                            alt={item.alt}
                            active={imgActive}
                            nextInactive={imgNextInactive}
                            prevInactive={imgPrevInactive}
                        />
                    ))}
                </div>
                <Box sx={{ display: active === (steps - 1) ? 'none' : '' }}>
                    <IconButton>
                        <ArrowForwardIosIcon onClick={handleNextSlide} />
                    </IconButton>
                </Box>
            </div>
        </Box >
    )
}

export default ImgSlider