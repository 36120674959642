import React, { useEffect, useState } from 'react';
import { ReactComponent as IconInfoOutlined } from "../../../../../assets/icons/icon-info-outlined.svg";
import { ReactComponent as IconWarningOutlined } from "../../../../../assets/icons/icon-warning-orange-outlined.svg";
import { styles } from '../../../../../helpers';
import { Box, Grid, Typography, styled } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { theme } from '../../../../../theme/theme';

const CustomText = styled(Typography)((props) => ({
    fontWeight: 900,
    textAlign: "center",
    fontSize: '.8rem',
    [props.theme.breakpoints.up('md')]: {
        fontSize: '1rem',
    }
}));

const InfoMessage = ({ icon, text, close, setClose, setActive }) => {

    const [iconModal, setIconModal] = useState({});

    const handleClose = () => {
        setClose(undefined);
        setActive(undefined)
    };

    const getIconModal = () => {
        icon === 'info' && setIconModal({
            colorBG: theme.palette.primary.xxlight,
            colorBorder: theme.palette.primary.main,
            iconShow: <IconInfoOutlined />
        });

        icon === 'warning' && setIconModal({
            colorBG: theme.palette.warning.xlight,
            colorBorder: theme.palette.warning.light,
            colorBorderLeft: theme.palette.warning.dark,
            iconShow: <IconWarningOutlined width='7rem' />
        });
    };

    /* const popUpWindow = {
        position: 'absolute',
        width: '80%',
        zIndex: 999
    } */

    useEffect(() => {
        getIconModal(icon);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [icon])

    return (
        <Grid sx={{
            ...styles.infoMessageBox_info,
            backgroundColor: iconModal.colorBG,
            borderColor: iconModal.colorBorder,
            borderLeftColor: iconModal.colorBorderLeft,
            /* ...(popUpWindow && {...popUpWindow}) */
        }}>
            {close &&
                (<Box
                    style={styles.infoMessageBox_info_close}
                    onClick={handleClose}
                >
                    <Close />
                </Box>)}
            {iconModal.iconShow}
            <CustomText
                variant="p"
                px={1}
                sx={{ textAlign: 'start' }}
                dangerouslySetInnerHTML={{ __html: text }}>
            </CustomText>
        </Grid>
    )
}

export default InfoMessage