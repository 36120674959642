import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

/**
 * Componente Bar Chart Stadistics
 * @param data information to display on the chart
 * @returns component chart
 */
export const BarChartStadistics = ({ data }) => {
    const textJob = {
        fontWeight: "600",
        width: "100%",
        padding: "15px 0 5px 10px",
    };

    const textTool01 = {
        fontWeight: "500",
        fontSize: "0.8rem",
        color: "#34C73B",
    };

    const textTool02 = {
        fontWeight: "500",
        fontSize: "0.8rem",
        color: "#0000FF",
    };

    const textTool03 = {
        fontWeight: "500",
        fontSize: "0.8rem",
        color: "#DCDCDC",
    };

    const tri03 = {
        color: "#DCDCDC",
    };

    const CustomTooltip = ({ active, payload, label }) => {
        const payload1 = { ...payload[0] };
        const obj = { ...payload1.payload };

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p style={{ padding: "5px", textAlign: "center" }}>
                        <span className="label">{label}</span>
                    </p>
                    <p>
                        <span style={textTool01}>Trimestre 01:</span>{" "}
                        <span className="doc">{obj.trimestre1}</span>
                    </p>
                    <p>
                        <span style={textTool02}>Trimestre 02:</span>{" "}
                        <span className="intro">{obj.trimestre2}</span>{" "}
                    </p>
                    <p>
                        <span style={textTool03}>Trimestre 03:</span>{" "}
                        <span style={tri03}>{obj.trimestre3}</span>
                    </p>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className="flex--start" style={textJob}>
                Barra estadística
            </div>

            <ResponsiveContainer width={"100%"} height={400}>

                <BarChart
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="trimestre1" fill="#34C73B" />
                    <Bar dataKey="trimestre2" fill="#0000FF" />
                    <Bar dataKey="trimestre3" fill="#DCDCDC" />
                </BarChart>

            </ResponsiveContainer>


        </>
    );
};
