import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Stack, ToggleButton, ButtonGroup } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import storage from "redux-persist/lib/storage";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";

import {
    dashboardMenu,
    manageProcedures,
    path,
    to,
    typeUser,
} from "../../utils/enums";
import { Btn, HelpTramits } from "../../components";
import { endSession } from "../../store/auth/userLogin";
import { ReactComponent as HomeIcon } from "../../assets/icons/home-icon.svg";
import { ReactComponent as ExpressIconMenu } from "../../assets/icons/express-icon-menu.svg";
import { ReactComponent as AnfIconMenu } from "../../assets/icons/anf-icon.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/file-icon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/archive-icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/icons/log-out-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/help-icon.svg";

import NavLink from "../../components/routing/NavLink";
import ModalUpdateUserPerfil from "../modalUpdateUserPerfil/ModalUpdateUserPerfil";
import { getUserInfo } from "../storeSelector/storeSelector";
import { MenuasideStyles } from "./MenuAside.css";
import { cleanDetailDoc, clearForm } from "../../store/formTramit";
import { clearForm as cleanFormExpressTramit } from "../../store/formExpressTramit";
import { clearForm as cleanFormANFTramit } from "../../store/formANFTramit";
import ModalAlert from "../floats/ModalAlert";

const Menuaside = () => {
    const dispatch = useDispatch();
    const styles = MenuasideStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [actionModal, setActionModal] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [modalStatus, setModalStatus] = useState({});
    const userInfo = getUserInfo();

    const styleAccordionConfig = {
        width: "100%",
        boxShadow: "none",
        border: "none",
        "&:before": {
            display: "none",
        },
    };

    const styleAccordionSummaryConfig = {
        padding: "0",
        margin: "0",
    };

    const styleButtonGroupConfig = {
        width: "100%",
        padding: "0 15px",
        paddingTop: "10px",
    };

    const styleToggleButtonConfig = {
        textTransform: "none",
        fontSize: "1rem",
        padding: "12px 8px",
        border: "none",
        justifyContent: "space-between",
    };

    const styleButtonConfig = {
        textTransform: "none",
        fontSize: "1rem",
        padding: "12px 8px",
        borderBottom: "0px",
        justifyContent: "flex-start",
        color: "quinary.xdark",
    };

    // eslint-disable-next-line
    const openTab = (url) => window.open(url, "_blank");

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const endUserSession = () => {
        dispatch(clearForm());
        dispatch(cleanDetailDoc());
        dispatch(cleanFormExpressTramit());
        dispatch(cleanFormANFTramit());
        endSession();

        if ("caches" in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                    caches.delete(name);
                });
            });
        }

        storage.removeItem("persist:root");
        storage.removeItem("session");
        window.location.reload(true);

        if (!localStorage.getItem("session")) {
            navigate(to.HOME);
        }
    };

    const openModal = (msg) => {
        switch (msg) {
            case "endSession":
                setModalStatus({
                    title: "Cerrar sesión",
                    message: "¿Estas a punto de cerrar tu sesión? ¿estas seguro(a) de esto?",
                    buttonText: "Si, cerrar sesión",
                    modalIcon: "warnIcon"
                });
                setActionModal("endSession");
                break;
            default:
                setModalStatus({
                    title: "",
                    message: "",
                    buttonText: "",
                    modalIcon: ""
                });
                break;
        }
        setShowModal(true);
    };

    const handleModal = () => {
        switch (actionModal) {
            case "endSession":
                endUserSession();
                break;
            default:
                /* endUserSession(); */
                break;
        }
    };

    return (
        <Fragment>

            <div className="menu-aside__container">
                <ModalAlert
                    buttonText={modalStatus.buttonText}
                    modalIcon={modalStatus.modalIcon}
                    onAcept={handleModal}
                    openModal={showModal}
                    setOpenModal={setShowModal}
                    subTitle={modalStatus.message}
                    title={modalStatus.title}
                />
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={styleButtonGroupConfig}
                >
                    <ToggleButton
                        className={styles.toggleButton}
                        onClick={() => navigate(to.DASHBOARD_WELCOME)}
                        value={path.DASHBOARD_WELCOME}
                        fullWidth={true}
                        selected={
                            location === to.DASHBOARD_WELCOME ? true : false
                        }
                        color={
                            location === to.DASHBOARD_WELCOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.DASHBOARD_WELCOME}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <HomeIcon />
                            <span className="menu-aside__item-text">Home</span>
                        </NavLink>
                    </ToggleButton>

                    {/* <ToggleButton
                        onClick={() => navigate(to.DASHBOARD_HOME)}
                        value={dashboardMenu.MENU_ITEM_HOME}
                        fullWidth={true}
                        selected={location === to.DASHBOARD_HOME ? true : false}
                        color={
                            location === to.DASHBOARD_HOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.DASHBOARD_HOME}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <StackIcon className="icon--xs icon--grey-dark" />
                            <span className="menu-aside__item-text">
                                Dashboard
                            </span>
                        </NavLink>
                    </ToggleButton> */}

                    <Accordion
                        className={styles.accordion}
                        expanded={
                            expanded ===
                            dashboardMenu.MENU_ITEM_MANAGE_EXPRESS_PROCEDURES
                        }
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE_EXPRESS_PROCEDURES
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary
                            sx={styleAccordionSummaryConfig}
                            focusVisibleClassName="menu-aside__item-focus"
                        >
                            <ToggleButton
                                value={
                                    dashboardMenu.MENU_ITEM_MANAGE_EXPRESS_PROCEDURES
                                }
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.DASHBOARD_WELCOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    {/* <DocIcon className="icon--xs icon--grey-dark" /> */}
                                    <ExpressIconMenu />
                                    <span className="menu-aside__item-text">
                                        {
                                            manageProcedures.MANAGE_EXPRESS_PROCEDURES
                                        }
                                    </span>
                                </NavLink>

                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>

                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                <li>
                                    <NavLink
                                        to={to.EXPRESS_CONTRACT_CREATION}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {
                                            manageProcedures.EXPRESS_PROCEDURES_CREATION
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        state={{ list: "EXPRESS" }}
                                        to={to.EXPRESS_DOCUMENT_MANAGE}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DOCUMENTATION}
                                    </NavLink>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        className={styles.accordion}
                        expanded={
                            expanded ===
                            dashboardMenu.MENU_ITEM_MANAGE_ANF_PROCEDURES
                        }
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE_ANF_PROCEDURES
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary
                            sx={styleAccordionSummaryConfig}
                            focusVisibleClassName="menu-aside__item-focus"
                        >
                            <ToggleButton
                                value={
                                    dashboardMenu.MENU_ITEM_MANAGE_ANF_PROCEDURES
                                }
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.DASHBOARD_WELCOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    {/* <DocIcon className="icon--xs icon--grey-dark" /> */}
                                    <AnfIconMenu />
                                    <span className="menu-aside__item-text">
                                        {manageProcedures.MANAGE_ANF_PROCEDURES}
                                    </span>
                                </NavLink>

                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>

                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                <li>
                                    <NavLink
                                        to={to.ANF_CREATE}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {
                                            manageProcedures.ANF_PROCEDURES_CREATION
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        state={{ list: "EXPRESS" }}
                                        to={to.ANF_MANAGE}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DOCUMENTATION}
                                    </NavLink>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        className={styles.accordion}
                        expanded={expanded === dashboardMenu.MENU_ITEM_MANAGE}
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary
                            sx={styleAccordionSummaryConfig}
                            focusVisibleClassName="menu-aside__item-focus"
                        >
                            <ToggleButton
                                value={dashboardMenu.MENU_ITEM_MANAGE}
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.DASHBOARD_WELCOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    {/* <DocIcon className="icon--xs icon--grey-dark" /> */}
                                    <FileIcon />
                                    <span className="menu-aside__item-text">
                                        {manageProcedures.MANAGE_PROCEDURES}
                                    </span>
                                </NavLink>
                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_CREATION}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.PROCEDURES_CREATION}
                                    </NavLink>
                                </li>

                                {userInfo.profileId === typeUser.ADMIN && (
                                    <Fragment>
                                        <li>
                                            <NavLink
                                                to={to.CREATE_SCHEDULE}
                                                className="navlink"
                                                inactiveClassName="navlink--inactive"
                                                activeClassName="navlink--active"
                                            >
                                                {
                                                    manageProcedures.SCHEDULE_CREATION
                                                }
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                to={to.MANAGE_SCHEDULE_HISTORY}
                                                className="navlink"
                                                inactiveClassName="navlink--inactive"
                                                activeClassName="navlink--active"
                                            >
                                                {
                                                    manageProcedures.MANAGE_SCHEDULE
                                                }
                                            </NavLink>
                                        </li>
                                    </Fragment>
                                )}

                                <li>
                                    <NavLink
                                        to={to.CONTRACT_STATUS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_REVIEWS}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_APROVED}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_PAYMENTS}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.CONTRACT_SIGNATURE_PENDING}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_SIGNETURES}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.MANAGE_NOTARY}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_NOTARIES}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.MANAGE_DELIVERY}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DELIVERY}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={to.DOCUMENT_MANAGE}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_DOCUMENTATION}
                                    </NavLink>
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>

                    {/* <ToggleButton
                        onClick={() => navigate(to.FV_AVANZADA)}
                        value={dashboardMenu.MENU_ITEM_HOME}
                        fullWidth={true}
                        selected={location === to.DASHBOARD_HOME ? true : false}
                        color={
                            location === to.DASHBOARD_HOME
                                ? "primary"
                                : "standard"
                        }
                        sx={styleToggleButtonConfig}
                    >
                        <NavLink
                            to={to.FV_AVANZADA}
                            className="flex--center"
                            inactiveClassName="secctionlink--inactive"
                            activeClassName="secctionlink--active"
                        >
                            <StackIcon className="icon--xs icon--grey-dark" />
                            <span className="menu-aside__item-text">
                                {manageProcedures.REGISTER_FEA}
                            </span>
                        </NavLink>
                    </ToggleButton> */}

                    {userInfo.profileId === typeUser.ADMIN ? (
                        <Accordion
                            className={styles.accordion}
                            expanded={
                                expanded ===
                                dashboardMenu.MENU_ITEM_ADMINISTRATION
                            }
                            onChange={handleAccordionChange(
                                dashboardMenu.MENU_ITEM_ADMINISTRATION
                            )}
                            sx={styleAccordionConfig}
                        >
                            <AccordionSummary sx={styleAccordionSummaryConfig}>
                                <ToggleButton
                                    onClick={() =>
                                        navigate(
                                            to.DASHBOARD_WELCOME
                                        )
                                    }
                                    value={
                                        dashboardMenu.MENU_ITEM_ADMINISTRATION
                                    }
                                    fullWidth={true}
                                    sx={styleToggleButtonConfig}
                                >
                                    <NavLink
                                        to={to.DASHBOARD_WELCOME}
                                        className="flex--center"
                                        inactiveClassName="secctionlink--inactive"
                                        activeClassName="secctionlink--active"
                                    >
                                        <ArchiveIcon />
                                        <span className="menu-aside__item-text">
                                            Administración
                                        </span>
                                    </NavLink>
                                    <BsChevronDown className="icon--xs icon--blue-light" />
                                </ToggleButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="menu-aside__list">
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_USERS}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_USERS}
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink
                                        to={to.MANAGE_CLIENTS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_CLIENTS}
                                    </NavLink>
                                </li> */}

                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PROVIDERS}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_SUPPLIERS}
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PAYMENT_HISTORY}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {
                                                manageProcedures.MANAGE_PAYMENT_HISTORY
                                            }
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_PROCEDURES_HISTORY}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {
                                                manageProcedures.MANAGE_PROCEDURES_HISTORY
                                            }
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                    <NavLink
                                        to={to.MANAGE_INTEGRATION}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.MANAGE_INTEGRATION}
                                    </NavLink>
                                </li> */}
                                    {/* <li>
                                    <NavLink
                                        to={to.DISCOUNT_COUPONS}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        {manageProcedures.DISCOUNT_COUPONS}
                                    </NavLink>
                                </li> */}
                                    {
                                        <li>
                                            <NavLink
                                                to={to.MANAGE_AGREEMENTS}
                                                className="navlink"
                                                inactiveClassName="navlink--inactive"
                                                activeClassName="navlink--active"
                                            >
                                                {
                                                    manageProcedures.MANAGE_AGREEMENTS
                                                }
                                            </NavLink>
                                        </li>
                                    }
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ) : null}

                    {/* {userInfo.profileId === typeUser.ADMIN ? (
                        <Accordion
                            className={styles.accordion}
                            expanded={
                                expanded ===
                                dashboardMenu.MENU_ITEM_MANAGE_COUPONS
                            }
                            onChange={handleAccordionChange(
                                dashboardMenu.MENU_ITEM_MANAGE_COUPONS
                            )}
                            sx={styleAccordionConfig}
                        >
                            <AccordionSummary sx={styleAccordionSummaryConfig}>
                                <ToggleButton
                                    value={
                                        dashboardMenu.MENU_ITEM_MANAGE_COUPONS
                                    }
                                    fullWidth={true}
                                    sx={styleToggleButtonConfig}
                                >
                                    <NavLink
                                        to={to.COUPONS_MANAGEMENT_MENU}
                                        className="flex--center"
                                        inactiveClassName="secctionlink--inactive"
                                        activeClassName="secctionlink--active"
                                    >
                                        <FileIcon />
                                        <span className="menu-aside__item-text">
                                            Gestión de cupones
                                        </span>
                                    </NavLink>
                                    <BsChevronDown className="icon--xs icon--blue-light" />
                                </ToggleButton>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="menu-aside__list">
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_COUPONS}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_COUPONS}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={to.MANAGE_COUPONS_TYPE}
                                            className="navlink"
                                            inactiveClassName="navlink--inactive"
                                            activeClassName="navlink--active"
                                        >
                                            {manageProcedures.MANAGE_COUPONS_TYPE}
                                        </NavLink>
                                    </li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ) : null} */}
                </Stack>

                <ButtonGroup
                    className={styles.buttonGroup}
                    variant="text"
                    orientation="vertical"
                    sx={styleButtonGroupConfig}
                >
                    <ModalUpdateUserPerfil styledButton={styleButtonConfig} />

                    <br />

                    <Btn
                        text="Cerrar sesión"
                        buttonClass={`button-link ${styles.buttom}`}
                        startIcon={<LogOutIcon />}
                        onClick={() => openModal("endSession")}
                        sx={styleButtonConfig}
                    />
                </ButtonGroup>

                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    sx={styleButtonGroupConfig}
                >
                    <Accordion
                        className={styles.accordion}
                        expanded={
                            expanded === dashboardMenu.MENU_ITEM_MANAGE_HELP
                        }
                        onChange={handleAccordionChange(
                            dashboardMenu.MENU_ITEM_MANAGE_HELP
                        )}
                        sx={styleAccordionConfig}
                    >
                        <AccordionSummary sx={styleAccordionSummaryConfig}>
                            <ToggleButton
                                fullWidth={true}
                                sx={styleToggleButtonConfig}
                            >
                                <NavLink
                                    to={to.ADMINISTRATION_DASHBOARD_HOME}
                                    className="flex--center"
                                    inactiveClassName="secctionlink--inactive"
                                    activeClassName="secctionlink--active"
                                >
                                    <HelpIcon />
                                    <span className="menu-aside__item-text">
                                        Ayuda
                                    </span>
                                </NavLink>
                                <BsChevronDown className="icon--xs icon--blue-light" />
                            </ToggleButton>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul className="menu-aside__list">
                                {/* <li
                                    onClick={() =>
                                        openTab(
                                            "https://api.whatsapp.com/send/?phone=56945227304&text&type=phone_number&app_absent=0"
                                        )
                                    }
                                >
                                    <NavLink
                                        to={"#"}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        Comercial
                                    </NavLink>
                                </li>

                                <li
                                    onClick={() =>
                                        openTab(
                                            "https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0"
                                        )
                                    }
                                >
                                    <NavLink
                                        to={"#"}
                                        className="navlink"
                                        inactiveClassName="navlink--inactive"
                                        activeClassName="navlink--active"
                                    >
                                        Soporte
                                    </NavLink>
                                </li> */}

                                <li>
                                    <HelpTramits
                                        title={"Seguir estatus de trámites"}
                                        url={
                                            "https://www.loom.com/embed/0299ee5541c54023b5a478d67fc51a34"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={"Cómo firmar el documento"}
                                        url={
                                            "https://www.loom.com/embed/79771c02fbb647b289cb3925ada57fdd"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={"Cómo pagar el servicio"}
                                        url={
                                            "https://www.loom.com/embed/787f477dd26d4a298f274551586b504b"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={"Cómo aprobar los trámites"}
                                        url={
                                            "https://www.loom.com/embed/ff6a0e62962a4a0d8432a99292b50496"
                                        }
                                    />
                                </li>

                                <li>
                                    <HelpTramits
                                        title={"Cómo crear trámites"}
                                        url={
                                            "https://www.loom.com/embed/0299ee5541c54023b5a478d67fc51a34" //TODO verificar
                                        }
                                    />
                                </li>
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </Stack>

                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        variant="subtitle1"
                        color={"quinary.dark"}
                        gutterBottom
                    >
                        Versión: 1.1.2
                    </Typography>
                </Stack>
            </div>
        </Fragment>
    );
};

export default Menuaside;
