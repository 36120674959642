import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Brightness1Icon from '@mui/icons-material/Brightness1';
import { Tooltip, IconButton, TableCell, TableRow, Switch, styled, Box } from "@mui/material";

import { GeneralListProcedStyles } from "../../index.css";
import { to } from "../../../../../utils/enums";
import { theme } from "../../../../../theme/theme";

import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon-outlined-blue.svg"

const iconBox = {
    display: 'flex',
    justifyContent: 'center'
}

const tableCellStyles = {
    backgroundColor: theme.palette.gray.xlight,
    '> .iconBox': {
        ...iconBox
    }
};

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    marginLeft: '.5rem',
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const Rows = ({ row, deleteContrat }) => {

    const navigate = useNavigate();
    const styles = GeneralListProcedStyles();
    // const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const [urlDetail, setUrlDetail] = useState(to.COUPONS_TYPE_EDIT);

    const handleNavegate = (id) => {
        navigate(`${urlDetail}/${id}`);
    };

    return (
        <Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell sx={tableCellStyles}>
                    <Box className='iconBox'>
                        <Tooltip title={"Editar tipo de cupón"}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                    console.log("urlDetail: ", urlDetail);
                                    handleNavegate(row.autoId);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {/* <Tooltip title={"Desactivar tipo de cupón"}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                deleteContrat(1);
                                console.log("deleteContrat", row);
                            }}
                        >
                            <BsTrash className="icon--xxs icon--red icon-pointer" />
                        </IconButton>
                    </Tooltip> */}

                </TableCell>
                <TableCell className={styles.rowText}>{row.cuponName.length < 18 ? row.cuponName : `${row.cuponName.slice(0, 17)} ...`}</TableCell>
                <TableCell className={styles.rowText}>{row.useNumber}</TableCell>
                <TableCell className={styles.rowText}>{row.discountTypeValue === 'PERCENTAGE' ? `${row.discountValue}%` : Number(row.discountValue).toLocaleString('es')}</TableCell>
                <TableCell className={styles.rowText}>{row.discountTypeValue === 'PERCENTAGE' ? 'Porcentaje' : 'Absoluto'}</TableCell>
                <TableCell className={styles.rowStatus}>
                    <Box sx={iconBox}>
                        <Box sx={{ display: 'flex' }}>
                            <Brightness1Icon color={row.status === 'ACTIVE' ? 'success' : 'error'} />
                            {row.status === 'ACTIVE' ? 'Activo' : 'Inactivo'}
                        </Box>
                        <Box>
                            <AntSwitch
                                //onChange={e => setValue('individualUse', e.target.checked)}
                                checked={row.status === 'ACTIVE'}
                            />
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

Rows.propTypes = {
    row: PropTypes.shape({
        sContractID: PropTypes.string.isRequired,
        action: PropTypes.string,
        autoId: PropTypes.string.isRequired,
        tramit: PropTypes.object.isRequired,
        sStatus: PropTypes.object.isRequired,
        information: PropTypes.arrayOf(
            PropTypes.shape({
                dContractDT: PropTypes.string.isRequired,
                withDocument: PropTypes.number.isRequired,
                iSignedCount: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default Rows;
