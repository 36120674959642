import React from "react";
import { useDispatch } from "react-redux";

import Typography from '@mui/material/Typography';

import { useDropzone } from "react-dropzone";

import { FirmaVirtualAvanzadaStyles } from "../../FirmaVirtualAvanzadaStyles.css";
import { loadDocBase64 } from "../../../../../store/firmaDocs/firmaDocs";

const CargarPdf = () => {

    const styles = FirmaVirtualAvanzadaStyles();
    const dispatch = useDispatch();

    const onDrop = React.useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {

            if (acceptedFiles[0].type.indexOf('pdf') === -1) {
                console.log('no es un pdf');
                // handleOpenWarningModal('En este módulo de prueba sólo se aceptan archivos con formato PDF.')
                return
            }

            /* if (acceptedFiles[0].size > 1000000) {
                // Mayor a 1Mb
                handleOpenWarningModal('Para este módulo de prueba sólo se aceptan archivos de máximo 1MB.')
                return
            }

            if (!termsAndConditionsAccepted) {
                handleOpenWarningModal('Es necesario que aceptes los términos y condiciones de uso de este módulo.')
                return
            } */

            const reader = new global.FileReader()
            reader.onload = () => {
                dispatch(loadDocBase64(reader.result));
                /* setDocumentToSign(reader.result)
                setProcessStep(2) */
            }
            reader.readAsDataURL(acceptedFiles[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className={styles.dropzoneMainContent} style={{width: '100%'}} {...getRootProps()}>

            <div className={styles.dropzoneEmptyContentText} style={{width: '100%'}}>

                <input {...getInputProps()} multiple={false} accept="application/pdf" />

                {isDragActive ? (
                    <Typography style={{ fontWeight: 'bold' }}>
                        Arrastra y suelta tu archivo aquí...
                    </Typography>
                ) : (
                    <Typography style={{ fontWeight: 'bold' }}>
                        Arrastra y suelta tu archivo a firmar, o haz clic para seleccionarlo
                    </Typography>
                )}

            </div>

        </div>
    );
}

export default CargarPdf;