import {
    forwardRef,
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import Snackbar from "@mui/material/Snackbar";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

import { SignatoriesStyles } from "../../index.css";
import Text from "../../../../heading/Text";
import {
    formaRut,
    validatePhoneNumber,
    validation,
} from "../../../../../utils/validations";
import { clearEditSigner, saveFormInfo } from "../../../../../store/formTramit";
import { Context } from "../../../../../context/utilsContext";

import { ReactComponent as SuccessIcon } from "./../../img/Firmante_añadido_con_exito.svg";
import { verifiVariable } from "../../../../../utils/enums";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
//import { mailValidate } from "../../../../common/services";
import InfoMessage from "../../../../dashboard/contentWelcome/molecules/infoMessage";
import { Box, CircularProgress } from "@mui/material";
import { theme } from "../../../../../theme/theme";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustompDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiGrid-root": {
        marginLeft: "0px",
    },
}));

const ActionsButton = styled(Button)(({ theme }) => ({
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: theme.palette.primary.xlight,
        borderColor: theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    "& .MuiSnackbarContent-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

const CustomDialogTitle = ({ children, onClose, ...other }) => {
    return (
        <DialogTitle
            sx={{
                marginLeft: "16px",
                marginTop: "20px",
                color: (theme) => theme.palette.primary.light,
            }}
            {...other}
        >
            {children}
            {onClose && (
                <DisabledByDefaultRoundedIcon
                    aria-label="close"
                    onClick={onClose}
                    className="icon--red icon--lg"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        zIndex: 9999,
                        cursor: "pointer",
                    }}
                >
                    <CloseIcon />
                </DisabledByDefaultRoundedIcon>
            )}
        </DialogTitle>
    );
};
CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const AddParticipant = () => {
    const { addParticipant, setToResumen } = useContext(Context);
    const screenSizeHook = ScreenSizeHook();
    const styles = SignatoriesStyles({ screenSizeHook });
    const {
        contract: {
            signatories: { previousSignatoriesList },
        },
        formTramit: {
            secondStep,
            editSigner,
            firstStep: { iSignedCount },
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [prevSigners, setPrevSigner] = useState(previousSignatoriesList);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbar] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [disabledCheckSigner, setDisabledCheckSigner] = useState(false);
    //const [formValidated, setFormValidated] = useState(false);
    const [addParticipantLoader, setAddParticipantLoader] = useState(false);
    const {
        control,
        setValue,
        handleSubmit,
        register,
        formState: { errors },
        watch,
        reset,
    } = useForm({
        defaultValues: {
            full_name: "",
            email: "",
            rutId: "",
            phone: "",
            rol: [],
            documentIDType: "Rut",
            passport: ""
        },
    });
    const storeSigners = !verifiVariable(editSigner)
        ? [...secondStep].filter((participant) => participant["rut"] !== editSigner["rut"])
        : [...secondStep];

    const payInfo = storeSigners
        .map((participant) => participant["pay"])
        .filter((payProperty) => ["50", "100"].includes(payProperty));

    const isFullPay = secondStep
        .filter(participant => participant.email !== editSigner?.email)
        .map(participant => Number(participant.pay))
        .reduce((acc, sum) => acc + sum, 0);

    const watchRol = watch("rol");
    const watchDocumentIDType = watch('documentIDType');

    const handleClickOpenSnack = () => {
        setSnackbar(true);
    };

    const handleCloseSnack = () => {
        setSnackbar(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        reset();
        dispatch(clearEditSigner());
    };

    const blockRadio50Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...secondStep].filter(
                (participant) =>
                    participant["rol"].includes("Pagador") &&
                    participant["rutId"] !== editSigner["rutId"]
            )
            : [...secondStep].filter((participant) => participant["rol"].includes("Pagador"));

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay > 50;
    }, [secondStep, editSigner]);

    const blockRadio100Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...secondStep].filter(
                (participant) =>
                    participant["rol"].includes("Pagador") &&
                    participant["rutId"] !== editSigner["rutId"]
            )
            : [...secondStep].filter((participant) => participant["rol"].includes("Pagador"));

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay !== 0;
    }, [secondStep, editSigner]);

    const blockCheckSigned = useCallback(() => {
        const signPart = !verifiVariable(editSigner)
            ? [...secondStep].filter(
                (item) =>
                    item["rol"].includes("Firmante") &&
                    item["rutId"] !== editSigner["rutId"]
            )
            : [...secondStep].filter((item) =>
                item["rol"].includes("Firmante")
            );

        return (
            (watchRol.includes("Pre Aprobador") &&
                watchRol.includes("Pagador")) ||
            (watchRol.includes("Pre Aprobador") ? true : false) ||
            (!verifiVariable(iSignedCount) &&
                iSignedCount === "1" &&
                signPart.length >= 1)
        );
    }, [secondStep, watchRol, iSignedCount, editSigner]);

    const onSubmit = async (data) => {
        setAddParticipantLoader(true);
        const arrayPrevSigners = [...prevSigners];
        const arrayData = [...secondStep];

        if (editSigner) {
            for (let index = 0; index < arrayData.length; index++) {
                const element = arrayData[index];

                if (
                    element["rutId"] === editSigner["rutId"] ||
                    element["email"] === editSigner["email"]
                ) {
                    arrayData[index] = Object.assign({ ...element }, { ...data, rutId: watchDocumentIDType === 'Rut' ? data["rutId"] : data['passport'] });
                }
            }

            for (let index = 0; index < arrayPrevSigners.length; index++) {
                const element = arrayPrevSigners[index];

                if (
                    element["RUT"] === editSigner["rutId"] ||
                    element["email"] === editSigner["email"]
                ) {
                    arrayPrevSigners[index] = Object.assign(
                        { ...element },
                        { ...data, RUT: watchDocumentIDType === 'Rut' ? data["rutId"] : data['passport'] }
                    );
                }
            }
        } else {
            arrayData.push({ ...data, rutId: watchDocumentIDType === 'Rut' ? data["rutId"] : data['passport'] });
            arrayPrevSigners.push({ ...data, RUT: watchDocumentIDType === 'Rut' ? data["rutId"] : data['passport'] });
        }

        /* const isValidEmail = async (email) => {
            const response = await mailValidate(email);
            return response.status;
        }

        const status = await isValidEmail(data.email);
        if (status === 'fail' || status === 'warning') {
            setValidEmail(true)
            setFormValidated(true);
            setAddParticipantLoader(false);
            if (!formValidated) {
                return
            }
        } */

        setToResumen(undefined);
        dispatch(saveFormInfo({ indexe: "firms", value: {} }));
        dispatch(saveFormInfo({ indexe: "secondStep", value: arrayData }));
        setPrevSigner(arrayPrevSigners);
        handleClose();
        handleClickOpenSnack();
        setValidEmail(false)
        //   setFormValidated(false)
        setAddParticipantLoader(false);
        /* setTimeout(() => {
            handleCloseSnack();
        }, 2500);
        reset(); */

        // console.log("data: ", data);
    };

    useEffect(() => {
        if (editSigner && Object.keys(editSigner).length !== 0) {
            setValue("full_name", editSigner["full_name"]);
            setValue("email", editSigner["email"]);
            setValue("rutId", editSigner["rutId"]);
            setValue("phone", editSigner["phone"]);
            setValue("rol", editSigner["rol"]);
            setValue("pay", editSigner["pay"]);
            setValue("documentIDType", editSigner["documentIDType"] || 'Rut');
            if ((editSigner["rut"])) setValue("rut", editSigner["rut"]);
            if ((editSigner["passport"])) setValue("passport", editSigner["passport"]);
        } else {
            setValue("documentIDType", "Rut");
            setValue("passport", "");
            setValue("rutId", "");
            reset({
                full_name: "",
                email: "",
                rutId: "",
                phone: "",
                rol: [],
                documentIDType: "Rut",
                passport: ""
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editSigner]);

    useEffect(() => {
        if (addParticipant > 0 && !open) handleClickOpen();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addParticipant]);

    useEffect(() => {
        if (!watchRol.includes("Pagador")) {
            setValue("pay", "0");
        };

        if (watchRol.includes("Pagador") && watch('pay') !== '0') {
            setValue("pay", editSigner?.["pay"] || watch('pay'));
        };

        if (watchRol.includes("Pagador") && watch('pay') === '0') {
            setValue("pay", editSigner?.["pay"] || "50");
        };
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchRol]);

    useEffect(() => {

        if (!verifiVariable(secondStep)) {
            const signerList = [...secondStep].filter((participant) => participant["rol"].includes("Firmante"));
            if (signerList.length >= 10) setDisabledCheckSigner(true);
        };

    }, [secondStep]);

    return (
        <Fragment>
            <CustomSnackBar
                style={{
                    "& .MuiSnackbarContent-root": {
                        all: "initial",
                        padding: "10px",
                    },
                }}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                open={snackbarOpen}
                onClose={handleCloseSnack}
                message={<SuccessIcon />}
            />

            <CustompDialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="md"
                className={styles.mainContainerDialog}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.formParticipant}
                >
                    <CustomDialogTitle onClose={handleClose}>
                        {editSigner ? (
                            `Editando participante ${secondStep.findIndex(participant => participant.email === editSigner.email) + 1}`
                        ) : (
                            `Participante ${secondStep.length + 1}`
                        )}
                    </CustomDialogTitle>

                    <DialogContent>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Controller
                                    name={`full_name`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        maxLength: {
                                            value: 50,
                                            message: 'La longitud máxima es de 50 caracteres'
                                        },
                                        pattern: {
                                            value: validation.nombre,
                                            message: 'No está permitido utilizar ciertos caracteres especiales. Carcateres especiales permitidos "-_#()@:?$+= áéíóúÁÉÍÓÚñÑüÜ.,!"'
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={
                                                    fieldState.error
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <label htmlFor="full_name">
                                                    <Text
                                                        color={
                                                            fieldState.error
                                                                ? "error.main"
                                                                : undefined
                                                        }
                                                        className={
                                                            styles.labelStyles
                                                        }
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Nombre completo
                                                    </Text>
                                                </label>

                                                <AutocompleteMui
                                                    id="full_name"
                                                    defaultValue={() => {
                                                        if (editSigner) {
                                                            const resultValue = [...prevSigners].find((prevSigner) => prevSigner["RUT"] === editSigner["rutId"]);

                                                            return resultValue ? { ...resultValue, full_name: editSigner["full_name"] } : null;
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                    color={fieldState.error ? "error" : "success"}
                                                    freeSolo={true}
                                                    handleHomeEndKeys
                                                    multiple={false}
                                                    options={prevSigners}
                                                    getOptionLabel={(option) => { return option.full_name; }}
                                                    onChange={(
                                                        e,
                                                        options,
                                                        reason,
                                                        details
                                                    ) => {
                                                        onChange(
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(options)
                                                                ? options.full_name
                                                                : ""
                                                        );
                                                        setValue(
                                                            `email`,
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(options)
                                                                ? options.email
                                                                : ""
                                                        );
                                                        setValue(
                                                            `rutId`,
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(options)
                                                                ? options.RUT
                                                                : ""
                                                        );
                                                        setValue(
                                                            `phone`,
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(options)
                                                                ? options.phone
                                                                : ""
                                                        );
                                                    }}
                                                    onInputChange={(event, data) => onChange(data)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Nombre completo"
                                                            className={
                                                                styles.autocompleteInput
                                                            }
                                                        />
                                                    )}
                                                />

                                                <FormHelperText
                                                    hidden={
                                                        fieldState.error
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {fieldState.error
                                                        ? fieldState.error
                                                            .message
                                                        : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name={`email`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        pattern: {
                                            message:
                                                "Formato de correo inválido",
                                            value: validation.email.toString(),
                                        },
                                        validate: {
                                            uniqueEmail: (value) => {
                                                const list = [...secondStep.filter((item) => ![null, undefined, "",].includes(item["email"]))];

                                                const emailList = [];

                                                for (let index = 0; index < list.length; index++) {
                                                    const element = list[index];

                                                    if (editSigner) {
                                                        // verifica si va a aeditar
                                                        if (editSigner["email"] !== element["email"]) emailList.push(element["email"]);
                                                    } else {
                                                        emailList.push(element["email"]);
                                                    }
                                                }

                                                return emailList.includes(value) ? "Email duplicado" : true;
                                            },
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={
                                                    fieldState.error
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <label htmlFor="email">
                                                    <Text
                                                        color={
                                                            fieldState.error
                                                                ? "error.main"
                                                                : undefined
                                                        }
                                                        className={
                                                            styles.labelStyles
                                                        }
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Correo electrónico
                                                    </Text>
                                                </label>

                                                <TextField
                                                    id="email"
                                                    autoComplete="email"
                                                    type="text"
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={
                                                        styles.autocompleteInput
                                                    }
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        const deleteSpaces = e.target.value.replace(/\s/g, '');
                                                        setValue('email', deleteSpaces);
                                                    }}
                                                    value={value}
                                                    placeholder={
                                                        "tucorreo@mail.com"
                                                    }
                                                    color={
                                                        fieldState.error ? "error" :
                                                            validEmail ? "info" :
                                                                "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    hidden={
                                                        fieldState.error
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {fieldState.error
                                                        ? fieldState.error
                                                            .message
                                                        : null}
                                                </FormHelperText>

                                                {validEmail &&
                                                    <InfoMessage
                                                        icon={'warning'}
                                                        text={"Nuestro sistema detectó un problema con este correo. Puedes modificarlo, o si estás seguro de que el correo es correcto y deseas continuar, haz clic en 'Agregar participante'."}
                                                    />
                                                }

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Controller
                                    name={`phone`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {
                                            validPhoneNumber: (value) => validatePhoneNumber(value) || "Formato de numero telefonico invalido",
                                            validateBlanks: (value) => !/\s/g.test(value) || "No estan permitidos los espacios en blanco",
                                            uniquePhone: (value) => {
                                                const list = [...secondStep.filter((item) => ![null, undefined, "",].includes(item["phone"]))];

                                                const phonelList = [];

                                                for (let index = 0; index < list.length; index++) {
                                                    const element = list[index];

                                                    if (editSigner) {
                                                        // verifica si va a aeditar
                                                        if (editSigner["phone"] !== element["phone"]) phonelList.push(element["phone"]);
                                                    } else {
                                                        phonelList.push(element["phone"]);
                                                    }
                                                }

                                                return phonelList.includes(value) ? "Nro de teléfono duplicado" : true;
                                            }
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={!!fieldState.error}
                                            >
                                                <label htmlFor="phone">
                                                    <Text
                                                        color={fieldState.error ? "error.main" : undefined}
                                                        className={styles.labelStyles}
                                                        align="left"
                                                        mgButton
                                                        variant="h6"
                                                    >
                                                        Número de teléfono (WhatsApp)
                                                    </Text>
                                                </label>

                                                <TextField
                                                    id="phone"
                                                    autoComplete="tel"
                                                    type="text"
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={
                                                        styles.autocompleteInput
                                                    }
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        const deleteSpaces = e.target.value.replace(/[^\d+]/g, '');
                                                        setValue('phone', deleteSpaces);
                                                    }}
                                                    value={value}
                                                    placeholder={"+56912345478"}
                                                    color={fieldState.error ? "error" : "success"}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    hidden={
                                                        fieldState.error
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {fieldState.error
                                                        ? fieldState.error
                                                            .message
                                                        : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    error={errors.rol ? true : false}
                                >
                                    <label>
                                        <Text
                                            color={
                                                errors.rol
                                                    ? "error.main"
                                                    : undefined
                                            }
                                            className={styles.labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            ¿Qué rol desempeñará el
                                            participante?
                                        </Text>
                                    </label>

                                    <FormGroup row>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                    gutterBottom
                                                >
                                                    Esta persona estará
                                                    encargada de aprobar el
                                                    documento antes de enviarlo
                                                    a firma. El pre aprobador
                                                    recibirá solo el correo de
                                                    revisión.
                                                </Typography>
                                            }
                                            placement="top-start"
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            disableInteractive
                                            arrow
                                        >
                                            <FormControlLabel
                                                disabled={
                                                    watchRol.includes(
                                                        "Firmante"
                                                    ) || (watchRol.includes(
                                                        'Copia'
                                                    )) || (watchRol.includes(
                                                        "Pagador"
                                                    ) &&
                                                        watchRol.includes(
                                                            "Firmante"
                                                        ))
                                                        ? true
                                                        : false
                                                }
                                                control={
                                                    <Checkbox
                                                        defaultChecked={
                                                            editSigner
                                                                ? editSigner[
                                                                    "rol"
                                                                ].includes(
                                                                    "Pre Aprobador"
                                                                )
                                                                    ? true
                                                                    : false
                                                                : false
                                                        }
                                                        {...register("rol", {
                                                            required:
                                                                "Este campo es requerido",
                                                        })}
                                                        value={"Pre Aprobador"}
                                                    />
                                                }
                                                label="Pre Aprobador"
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                    gutterBottom
                                                >
                                                    Esta persona participará
                                                    pagando el trámite. Puede
                                                    ser un Pre Aprobador o un
                                                    Firmante
                                                </Typography>
                                            }
                                            placement="top-start"
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            disableInteractive
                                        >
                                            <FormControlLabel
                                                disabled={
                                                    payInfo.includes("100")
                                                        || isFullPay === 100
                                                        || (watchRol.includes('Copia'))
                                                        ? true
                                                        : false
                                                }
                                                control={
                                                    <Checkbox
                                                        defaultChecked={
                                                            editSigner
                                                                ? editSigner[
                                                                    "rol"
                                                                ].includes(
                                                                    "Pagador"
                                                                )
                                                                    ? true
                                                                    : false
                                                                : false
                                                        }
                                                        {...register("rol", {
                                                            required:
                                                                "Este campo es requerido",
                                                        })}
                                                        value={"Pagador"}
                                                    />
                                                }
                                                label="Pagador"
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            title={
                                                !disabledCheckSigner ? (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                    gutterBottom
                                                >
                                                    Esta persona participará
                                                    aceptando y firmando el
                                                    documento únicamente.
                                                    También podrá pagar el
                                                    trámite si así lo desea.
                                                </Typography>) : (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                    gutterBottom
                                                >
                                                    Solo esta permitido un maximo de 10 firmantes por trámites.
                                                </Typography>)
                                            }
                                            placement="top-start"
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            disableInteractive
                                        >
                                            <FormControlLabel
                                                disabled={blockCheckSigned() || (watchRol.includes('Copia')) || disabledCheckSigner}
                                                control={
                                                    <Checkbox
                                                        defaultChecked={
                                                            editSigner
                                                                ? editSigner[
                                                                    "rol"
                                                                ].includes(
                                                                    "Firmante"
                                                                )
                                                                    ? true
                                                                    : false
                                                                : false
                                                        }
                                                        {...register("rol", {
                                                            required:
                                                                "Este campo es requerido",
                                                        })}
                                                        value={"Firmante"}
                                                    />
                                                }
                                                label="Firmante"
                                            />
                                        </Tooltip>

                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                    gutterBottom
                                                >
                                                    Esta persona recibirá
                                                    una copia del documento.
                                                </Typography>
                                            }
                                            placement="top-start"
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            disableInteractive
                                        >
                                            <FormControlLabel
                                                disabled={blockCheckSigned() || (
                                                    watchRol.includes('Pre Aprobador')
                                                    || watchRol.includes('Firmante')
                                                    || watchRol.includes('Pagador')
                                                )}
                                                control={
                                                    <Checkbox
                                                        defaultChecked={
                                                            editSigner
                                                                ? editSigner[
                                                                    "rol"
                                                                ].includes(
                                                                    "Copia"
                                                                )
                                                                    ? true
                                                                    : false
                                                                : false
                                                        }
                                                        {...register("rol", {
                                                            required:
                                                                "Este campo es requerido",
                                                        })}
                                                        value={"Copia"}
                                                    />
                                                }
                                                label="Copia"
                                            />
                                        </Tooltip>
                                    </FormGroup>

                                    <FormHelperText
                                        hidden={errors.rol ? false : true}
                                    >
                                        {errors.rol ? errors.rol?.message : ""}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Grid>
                                        <Controller
                                            name={`documentIDType`}
                                            control={control}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    ref,
                                                },
                                                fieldState,
                                            }) => {
                                                return (
                                                    <FormControl
                                                        fullWidth
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        <label>
                                                            <Text
                                                                color={fieldState.error ? "error.main" : undefined}
                                                                className={styles.labelStyles}
                                                                align="left"
                                                                mgButton
                                                                variant="h6"
                                                            >
                                                                Selecciona el tipo de documento de identificación
                                                            </Text>
                                                        </label>

                                                        <RadioGroup
                                                            defaultValue={editSigner?.["documentID"] ? editSigner["documentID"] : "Rut"}
                                                            row
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            "&.Mui-checked":
                                                                            {
                                                                                color: `${theme.palette.primary.light} !important`,
                                                                            },
                                                                        }}
                                                                        value="Rut"
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                label="Rut"
                                                            />

                                                            <FormControlLabel
                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            "&.Mui-checked":
                                                                            {
                                                                                color: `${theme.palette.primary.light} !important`,
                                                                            },
                                                                        }}
                                                                        value="Passport"
                                                                        onChange={onChange}
                                                                    />
                                                                }
                                                                label="Pasaporte"
                                                            />
                                                        </RadioGroup>

                                                        <FormHelperText
                                                            hidden={
                                                                fieldState.error
                                                                    ? false
                                                                    : true
                                                            }
                                                        >
                                                            {fieldState.error
                                                                ? fieldState.error
                                                                    .message
                                                                : null}
                                                        </FormHelperText>
                                                    </FormControl>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>

                            {watchDocumentIDType && watchDocumentIDType === 'Rut' && (

                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={`rutId`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            pattern: {
                                                value: validation.rut,
                                                message: 'RUT inválido'
                                            },
                                            validate: {

                                                uniqueRut: (value) => {
                                                    const list = [
                                                        ...secondStep.filter(
                                                            (item) =>
                                                                ![
                                                                    null,
                                                                    undefined,
                                                                    "",
                                                                ].includes(
                                                                    item["rutId"]
                                                                )
                                                        ),
                                                    ];

                                                    const rutList = [];

                                                    for (
                                                        let index = 0;
                                                        index < list.length;
                                                        index++
                                                    ) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // Verifica si va a editar
                                                            if (
                                                                editSigner[
                                                                "rutId"
                                                                ] !==
                                                                element["rutId"]
                                                            )
                                                                rutList.push(
                                                                    element["rutId"]
                                                                );
                                                        } else {
                                                            rutList.push(
                                                                element["rutId"]
                                                            );
                                                        }
                                                    }

                                                    return rutList.includes(value)
                                                        ? "RUT o Pasaporte duplicado"
                                                        : true;
                                                },
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        fieldState.error
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <label htmlFor="rut">
                                                        <Text
                                                            color={
                                                                fieldState.error
                                                                    ? "error.main"
                                                                    : undefined
                                                            }
                                                            className={
                                                                styles.labelStyles
                                                            }
                                                            align="left"
                                                            mgButton
                                                            variant="h6"
                                                        >
                                                            RUT
                                                        </Text>
                                                    </label>

                                                    <TextField
                                                        id="rut"
                                                        autoComplete="on"
                                                        type="text"
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={
                                                            styles.autocompleteInput
                                                        }
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            const value = e.target.value;
                                                            const formattedRut = formaRut(value);
                                                            setValue('rutId', formattedRut);
                                                        }}
                                                        value={value}
                                                        placeholder={
                                                            "11111111-1 ó 11111111"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />

                                                    <FormHelperText
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            {watchDocumentIDType && watchDocumentIDType === 'Passport' && (
                                <Grid item xs={12} md={6}>
                                    <Controller
                                        name={`passport`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            pattern: {
                                                value: validation.passport,
                                                message: 'Pasaporte inválido'
                                            },
                                            validate: {
                                                uniquePassport: (value) => {
                                                    const list = [
                                                        ...storeSigners.filter(
                                                            (item) =>
                                                                ![
                                                                    null,
                                                                    undefined,
                                                                    "",
                                                                ].includes(item["rut"])
                                                        ),
                                                    ];

                                                    const passportList = [];

                                                    for (
                                                        let index = 0;
                                                        index < list.length;
                                                        index++
                                                    ) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // Verifica si va a editar
                                                            if (
                                                                editSigner["passport"] !==
                                                                element["passport"]
                                                            )
                                                                passportList.push(
                                                                    element["passport"]
                                                                );
                                                        } else {
                                                            passportList.push(
                                                                element["passport"]
                                                            );
                                                        }
                                                    }

                                                    return passportList.includes(value)
                                                        ? "Pasaporte duplicado"
                                                        : true;
                                                },
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        fieldState.error ? true : false
                                                    }
                                                >
                                                    <label htmlFor="passport">
                                                        <Text
                                                            color={
                                                                fieldState.error
                                                                    ? "error.main"
                                                                    : undefined
                                                            }
                                                            className={
                                                                styles.labelStyles
                                                            }
                                                            align="left"
                                                            mgButton
                                                            variant="h6"
                                                        >
                                                            Pasaporte
                                                        </Text>
                                                    </label>

                                                    <TextField
                                                        id="passport"
                                                        autoComplete="on"
                                                        type="text"
                                                        error={
                                                            fieldState.error
                                                                ? true
                                                                : false
                                                        }
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.autocompleteInput}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={
                                                            "Ejemplo: AA123456"
                                                        }
                                                        color={
                                                            fieldState.error
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        focused={
                                                            fieldState.isTouched ||
                                                            fieldState.isDirty
                                                        }
                                                        fullWidth
                                                    />

                                                    <FormHelperText
                                                        className={styles.errorText}
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error.message
                                                            : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            {watchRol.includes("Pagador") && (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`pay`}
                                        control={control}
                                        rules={{
                                            validate: {
                                                verifyPay: (value) => !(value === '0' && watchRol.includes('Pagador')) || 'Este campo es requerido.'
                                            }
                                        }}
                                        render={({
                                            field: { onChange, value },
                                            fieldState
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label>
                                                        <Text
                                                            color={fieldState.error ? "error.main" : undefined}
                                                            className={styles.labelStyles}
                                                            align="left"
                                                            mgButton
                                                            variant="h6"
                                                        >
                                                            ¿Este participante
                                                            realizará el pago
                                                            del 100% del
                                                            trámite?
                                                        </Text>
                                                    </label>

                                                    <RadioGroup
                                                        value={value}
                                                        onChange={(e) => { onChange(e.target.value) }}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            disabled={blockRadio50Pay()}
                                                            control={<Radio value="50" />}
                                                            label="50%"
                                                        />

                                                        <FormControlLabel
                                                            disabled={blockRadio100Pay()}
                                                            control={<Radio value="100" />}
                                                            label="100%"
                                                        />
                                                    </RadioGroup>

                                                    <FormHelperText
                                                        hidden={fieldState.error ? false : true}
                                                    >
                                                        {fieldState.error ? fieldState.error.message : null}
                                                    </FormHelperText>

                                                    <Typography
                                                        variant="caption"
                                                        display="block"
                                                        gutterBottom
                                                        color={"gray.dark"}
                                                    >
                                                        Selecciona al menos 1 de
                                                        los firmantes como
                                                        responsable del pago.{" "}
                                                        <br />
                                                        Puedes dividir el pago
                                                        hasta 2 firmantes.
                                                    </Typography>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions
                        style={{
                            justifyContent: "flex-end",
                            paddingBottom: "20px",
                            marginRight: "16px",
                        }}
                    >
                        <ActionsButton
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {addParticipantLoader
                                ? <CircularProgress size={30} color="white" />
                                : (
                                    !verifiVariable(editSigner)
                                        ? "Guardar"
                                        : "Agregar participante"
                                )}
                        </ActionsButton>
                    </DialogActions>
                </form>
            </CustompDialog>
        </Fragment>
    );
};

export default AddParticipant;
