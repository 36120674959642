import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: 'coupons',

    initialState: {
        allCoupons: '',
        coupon: '',
        loading: false,
        message: {},
        status: ''
    },

    reducers: {
        PURGE_COUPONS_STATE: (state, action) => {
            state.allCoupons = '';
            state.coupon = '';
            state.loading = false;
            state.message = {};
            state.status = ''
        },

        //create

        COUPONS_CREATE_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONS_CREATE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.coupon = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        // edit

        COUPONS_EDIT_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONS_EDIT_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.coupon = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        // get one

        COUPONS_GETONE_REQUESTED: (state, action) => {
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONS_GETONE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.coupon = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        // get all

        COUPONS_GETALL_REQUESTED: (state, action) => {
            state.allCoupons = '';
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONS_GETALL_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.allCoupons = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        //request failed

        COUPONS_REQUESTED_FAILED: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.status = action.payload.status
        },

        // edit state info

        COUPONS_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },
    }
});

export const {
    PURGE_COUPONS_STATE,
    COUPONS_CREATE_REQUESTED,
    COUPONS_CREATE_REQUESTED_SUCCESS,
    COUPONS_EDIT_REQUESTED,
    COUPONS_EDIT_REQUESTED_SUCCESS,
    COUPONS_GETONE_REQUESTED,
    COUPONS_GETONE_REQUESTED_SUCCESS,
    COUPONS_GETALL_REQUESTED,
    COUPONS_GETALL_REQUESTED_SUCCESS,
    COUPONS_REQUESTED_FAILED,
    COUPONS_SAVE_INFO
} = slice.actions;

export const cleanCouponsForm = () => {
    return { type: PURGE_COUPONS_STATE.type };
};

export const couponsSaveInfo = (data) => {
    return { type: COUPONS_SAVE_INFO, payload: data }
}

export const createCoupon = (data, token) => {
    return apiCallStart({
        url: `/api/v2/coupon/create`,
        method: 'POST',
        data,
        onStart: COUPONS_CREATE_REQUESTED.type,
        onSuccess: COUPONS_CREATE_REQUESTED_SUCCESS.type,
        onError: COUPONS_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const updateCoupon = (data, token) => {
    return apiCallStart({
        url: `/api/v2/coupon/update`,
        method: 'POST',
        data,
        onStart: COUPONS_EDIT_REQUESTED.type,
        onSuccess: COUPONS_EDIT_REQUESTED_SUCCESS.type,
        onError: COUPONS_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const getCoupon = (id, token) => {
    return apiCallStart({
        url: `/api/v2/coupon/getone/${id}`,
        method: 'GET',
        onStart: COUPONS_GETONE_REQUESTED.type,
        onSuccess: COUPONS_GETONE_REQUESTED_SUCCESS.type,
        onError: COUPONS_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const getAllCoupons = (token) => {
    return apiCallStart({
        url: `/api/v2/coupon/getall`,
        method: 'GET',
        onStart: COUPONS_GETALL_REQUESTED.type,
        onSuccess: COUPONS_GETALL_REQUESTED_SUCCESS.type,
        onError: COUPONS_REQUESTED_FAILED.type,
        authorization: token
    });
};

export default slice.reducer;