import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { icons, manageProcedures, to } from '../../../utils/enums';
import { normalizeString } from "../../../utils/transform";
import CardOption from './molecules/cardOption';

const HomeManagementProcedures = () => {
    const navigate = useNavigate();
    const { type } = useParams();

    const navegationUrl = {
        express: {
            createTramit: to.EXPRESS_CONTRACT_CREATION,
            reviewManagement: to.EXPRESS_DOCUMENT_MANAGE

        },
        anf: {
            createTramit: to.ANF_CREATE,
            reviewManagement: to.ANF_MANAGE
        },
        aprovers: {
            createTramit: to.CONTRACT_CREATION,
            reviewManagement: to.DOCUMENT_MANAGE
        }
    }

    const navigateTo = (to) => {
        navigate(navegationUrl[type][to]);
    };

    return (
        <div className="content-detail__container">

            <div className="detail__container">

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.PROCEDURES_CREATION}
                            id={normalizeString(manageProcedures.PROCEDURES_CREATION)}
                            subtitle="Ir al trámite"
                            icon={icons.NEW_PROCEDURES_ICON}
                            onClick={() => navigateTo('createTramit')}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_REVIEWS}
                            id={normalizeString(manageProcedures.MANAGE_REVIEWS)}
                            subtitle="Ir al trámite"
                            icon={icons.REVIEW_PROCEDURES_ICON}
                            onClick={() => navigateTo('reviewManagement')}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_PAYMENTS}
                            id={normalizeString(manageProcedures.MANAGE_PAYMENTS)}
                            subtitle="Ir al trámite"
                            icon={icons.PAYMENT_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_APROVED)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_SIGNETURES}
                            id={normalizeString(manageProcedures.MANAGE_SIGNETURES)}
                            subtitle="Ir al trámite"
                            icon={icons.SIGNATURE_PROCEDURES_ICON}
                            onClick={() => navigate(to.CONTRACT_SIGNATURE_PENDING)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_NOTARIES}
                            id={normalizeString(manageProcedures.MANAGE_NOTARIES)}
                            subtitle="Ir al trámite"
                            icon={icons.NOTARIES_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_NOTARY)}
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_DELIVERY}
                            id={normalizeString(manageProcedures.MANAGE_DELIVERY)}
                            subtitle="Ir al trámite"
                            icon={icons.DELIVERY_PROCEDURES_ICON}
                            onClick={() => navigate(to.MANAGE_DELIVERY)}
                        />

                    </Grid>

                    {/* <Grid item xs={12} sm={6} lg={4}>

                        <CardOption
                            title={manageProcedures.MANAGE_DOCUMENTATION}
                            id={normalizeString(manageProcedures.MANAGE_DOCUMENTATION)}
                            subtitle="Ir al trámite"
                            icon={icons.DOCUMENTS_PROCEDURES_ICON}
                            onClick={() => navigate(to.DOCUMENT_MANAGE)}
                        />

                    </Grid> */}

                </Grid>

            </div>

        </div>
    );
};

export default HomeManagementProcedures;