import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

import { ReactComponent as FileIcon } from "../../img/file-icon.svg";
import { ReactComponent as DownloadIcon } from "../../img/download-icon.svg";
import { ContractDetailReviewStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";
import ViewModalDocs from "../viewModalDocs";
import AddSignDoc from "../addSignDoc";
import { downloadCompiledTramitDoc } from "../../services";


const InfoDocs = ({ loading, textLoading, restUpDoc, contractCreatedDetails }) => {
    const styles = ContractDetailReviewStyles();
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const [detailContract, setDetailContract] = useState(undefined);
    // eslint-disable-next-line
    const [mergedDoc, setMergedDoc] = useState(undefined);


    const downloadContract = async (open) => {
        try {
            const { token } = user;
            loading(true);
            textLoading('Descargando documento');

            const response = await downloadCompiledTramitDoc(
                detailContract['contrato']['sContractID'],
                token,
                open
            );

            response && loading(false);

        } catch (error) {
            loading(false);
            console.log(error);
        }
    };


    useEffect(() => {
        if (!verifiVariable(contractCreatedDetails)) setDetailContract(contractCreatedDetails);
    }, [contractCreatedDetails]);

    return (
        <div className={styles.infoDocsContainer}>

            <AddSignDoc
                docToSign={mergedDoc}
                loading={(e) => loading(e)}
                textLoading={(e) => textLoading(e)}
                restUpDoc={() => restUpDoc()}
            />

            {(!verifiVariable(detailContract) && detailContract['contrato']['withDocument'] === 1) && (<div className={styles.infoDocs}>

                <div className={styles.userTitleInfo}>

                    <Typography variant="subtitle1" gutterBottom>
                        Documento a firmar
                    </Typography>

                </div>

                {!verifiVariable(detailContract) && (
                    <div className={`${styles.userInfo} ${styles.fileIcon}`}>
                        <FileIcon />
                        <Typography variant="subtitle1" gutterBottom>
                            {`fvl_${detailContract['contrato']['sContractID']}_document.pdf`}
                        </Typography>
                    </div>
                )}

            </div>)}

            <div className={styles.optionDocs}>

                <ViewModalDocs contractCreatedDetails={detailContract} loading={(e) => loading(e)} textLoading={(e) => textLoading(e)} />

                {(!verifiVariable(detailContract) && detailContract['contrato']['withDocument'] === 1) && (
                    <Tooltip
                        title="Descargar contrato"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        arrow
                    >

                        <div>
                            <DownloadIcon onClick={() => downloadContract(false)} />
                        </div>

                    </Tooltip>
                )}

            </div>

        </div>
    );

};

export default InfoDocs;