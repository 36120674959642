import React from 'react'
import { Box } from '@material-ui/core'
import { ContractsCreatedSuccessStyles } from '../../index.css'

const SliderDots = ({ dotsNumber, dotActive }) => {

    const styles = ContractsCreatedSuccessStyles();

    return (
        <Box className={styles.slider_dots_container}>
            {Array(dotsNumber).fill().map((_, index) => (
                <Box
                    key={index}
                    className={`
                            ${styles.slider_dot}
                            ${index === dotActive ? styles.slider_dot__active : {}}
                        `}
                ></Box>
            ))}
        </Box>
    )
}

export default SliderDots