import React from 'react';
import { BsHandThumbsUp, BsXCircle, BsExclamationTriangle, BsEnvelopeOpen } from "react-icons/bs"

const Notificationbanner = (props) => {
    const {display, notificationType, children} = props;

    const notificationClass = () => {
        switch (notificationType) {
            case "success":
                return "notification-banner notification-banner--success";
            case "error":
                return "notification-banner notification-banner--error";
            case "warning":
                return "notification-banner notification-banner--warning";
            default:
                return "notification-banner notification-banner--info";
        }
    }

    const notificationIcon = () => {
        switch (notificationType) {
            case "success":
                return <BsHandThumbsUp className="icon--xs margin-right--sm"/>;
            case "error":
                return <BsXCircle className="icon--xs margin-right--sm"/>;
            case "warning":
                return <BsExclamationTriangle className="icon--xs margin-right--sm"/>;
            default:
                return <BsEnvelopeOpen className="icon--xs margin-right--sm"/>;
        }
    }

    const notificationDisplayed = () => display ? "notification--show" : "notification--hide";

    return (
        <div className={`container--fixed flex--start ${notificationClass()} ${notificationDisplayed()}`}>
            {notificationIcon()}
            {children}
        </div>
    );
};

export default Notificationbanner;