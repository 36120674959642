import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import { ReactComponent as LogoOpenModal } from "../../assets/logo/img-persona.svg";

import './index.css'

const CustomButtom = styled(Button)((props) => ({
    position: 'absolute',
    left: 16,
    bottom: 18,
    zoom: .3,
    borderRadius: '25px',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontWeight: 'bolder',
    textTransform: 'none',
    fontSize: 35,
    padding: '11px 50px',
    marginTop: '25px',
    color: 'white',
    background: props.theme.palette.primary.main,
    border: `1px solid ${props.theme.palette.primary.main}`,
    '&:hover': {
        border: `1px solid ${props.theme.palette.primary.main}`,
        background: props.theme.palette.primary.main
    },
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const OpenModalToNewPlatform = ({ open }) => {

    return (
        <div
            className='main-content'
            /* style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                padding: "10px",
                borderRadius: '5px',
            }} */

        >
            <LogoOpenModal
                className='icon-svg'
                onClick={() => open()}
                /* style={{
                    cursor: 'pointer',
                    border: "white 5px solid",
                    borderRadius: "43px"
                }} */
            />

            <CustomButtom onClick={() => open()}>
                Comenzar
            </CustomButtom>

        </div>
    );

}


export default OpenModalToNewPlatform;