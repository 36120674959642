import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { errorStepFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { FormularioSolicitudStyles } from "../../index.css";
import { validatePhoneNumber } from "../../../../utils/validations";

const StepSignerPhone = ({ control, trigger, setValue, index, useWatch, watch }) => {
    const styles = FormularioSolicitudStyles();
    const {
        formSolicitud: {
            signers
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const refFirstLoad = React.useRef({});
    const [errorInput, setErrorInput] = React.useState(undefined);

    const watchSignersName = useWatch({ control, name: `signers.${index}.phone` });

    React.useEffect(() => {
        if (![undefined].includes(errorInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    React.useEffect(() => {
        if (signers[index]) setValue(`signers?.${index}?.phone`, signers[index].phone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    refFirstLoad.current = false;

    React.useEffect(() => {
        trigger();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchSignersName]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        ?<BiRightArrowAlt />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            Firmante {index + 1}: Número de celular *
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            (+56912345478)
                        </Typography>

                        <Controller
                            name={`signers.${index}.phone`}
                            control={control}
                            rules={{
                                required: "Este campo es requerido",
                                validate: {
                                    validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido'
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorInput(fieldState.error);
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <TextField
                                            type="text"
                                            error={fieldState.error ? true : false}
                                            ref={ref}
                                            onBlur={onBlur}
                                            variant={'standard'}
                                            onChange={onChange}
                                            value={value}
                                            helperText={
                                                fieldState.error
                                                    ? fieldState.error.message
                                                    : null
                                            }
                                            placeholder={"Escribe aqui tu respuesta..."}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepSignerPhone;