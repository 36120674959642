import { useForm } from "react-hook-form";

import Grid from '@mui/material/Grid';

import { FormularioUachtyles } from "../../index.css";
import StepOne from "../../atoms/stepOne";
import StepTwo from "../../atoms/stepTwo";
import StepThree from "../../atoms/stepThree";
import StepFour from "../../atoms/stepFour";
import StepFive from "../../atoms/stepFive";
import TypeForm from "../typeForm";
import StepSix from "../../atoms/stepSix";

const Stepsper = () => {
    const styles = FormularioUachtyles();

    const { control, watch, trigger, setValue, formState } = useForm({
        defaultValues: {
            fullName: '',
            rut: '',
            email: '',
            phone: '',
            tramitPdf: ''
        }
    });

    return (
        <Grid className={styles.stepsperContent} container>

            <TypeForm>

                <StepOne control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

                <StepTwo control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

                <StepThree control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

                <StepFour control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

                <StepFive control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

                <StepSix control={control} trigger={trigger} watch={watch} setValue={setValue} formState={formState} />

            </TypeForm>

        </Grid>
    );
}

export default Stepsper;