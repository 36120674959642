import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/system";

export const FirmaVirtualAvanzadaStyles = makeStyles(() => {

    return createStyles({
        mainContentFEA: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
        viewerEditorCanvas: {
            /* position: 'relative', */
            /* marginTop: '20px',
            marginBottom: '20px',
            left: '0px', */
            backgroundColor: '#fff',
            boxShadow: '3px 3px 10px #aaa'
        },
        noViewerEditorCanvas: {
            /* position: 'relative', */
            /* width: '0px !important',
            height: '0px !important' */
        },
        draggable: {
            /* position: 'relative', */
            /* marginTop: '20px',
            marginBottom: '20px',
            left: '0px', */
            zIndex: 10
        },
        preViewPdfMainContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
        },
        dropzoneEmptyContentText: {
            textAlign: 'center',
            maxWidth: '80%',
            [`& .MuiTypography-root`]: {
                color: '#6666FF'
            },
        },
        dropzoneMainContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: "35vh",
            border: "5px dashed #2badea",
            marginTop: 20,
            cursor: 'pointer'
        },
        formRestChalle: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            borderRadius: '15px',
            backgroundColor: 'white',
            padding: '35px',
            marginTop: '20px'
        },
        contentLoginFVA: {
            textAlign: 'center',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            padding: '25px',
            border: 'solid',
            borderRadius: '10px',
            borderColor: '#c0cbd1'
        },
        itemContentUserInfo: {
            display: 'flex',
            flexDirection: 'column'
        },
        mainContentCarUserInfo: {
            display: 'flex',            
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '7px',
            width: '100%'
        },
        stepsContent: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center'
        }

    })

});

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#1F64AA",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: "#1F64AA",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor:
            theme.palette.mode === "dark"
                ? theme.palette.grey[800]
                : "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
        color: "#1F64AA",
    }),
    "& .QontoStepIcon-disabledIcon": {
        color: "#FFF",
        zIndex: 1,
        fontSize: 18,
    },
    "& .QontoStepIcon-completedIcon": {
        zIndex: 1,
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: "transparent",
        border: "2px solid #1F64AA",
        display: "flex",
        justifyContent: "center",
    },
    "& .QontoStepIcon-circle": {
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: "currentColor",
        "& .QontoStepNumber": {
            color: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
        },
    },
}));