import React from "react";

export const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                dx={-5}
                textAnchor="start"
                fontSize={2}
                fill="#666"
            >
                {payload.value}
            </text>
        </g>
    );
};
