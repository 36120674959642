import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlineClose } from "react-icons/ai";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import { IconButton } from '@mui/material';


import { ReactComponent as CheckModalIcon } from "../../assets/icons/check-icon-modal.svg";
import { ReactComponent as ErrorModalIcon } from "../../assets/icons/error-modal-icon.svg";
import { ReactComponent as MailModalIcon } from "../../assets/icons/mail-icon-modal.svg";
import { ReactComponent as IconWarning } from '../../assets/icons/icon-warning-red-outlined.svg';
import { ModalAlertStyles } from './index.css';
import { verifiVariable } from '../../utils/enums';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    borderRadius: '0px 0px 7px 7px',
};

const AcceptCustomButton = styled(Button)((props) => ({
    textTransform: "none",
    color: props.theme.palette.white.main,
    fontFamily: "PoppinsBold",
    padding: '1rem 5rem',
    whiteSpace: 'nowrap'
}));

const CancelCustomButton = styled(Button)((props) => ({
    textTransform: "none",
    color: props.theme.palette.primary.main,
    fontFamily: "PoppinsBold",
}));

const ModalAlert = ({
    buttonText,
    cancelButtom,
    message,
    modalIcon,
    onAcept,
    onClose,
    openModal,
    setOpenModal,
    subTitle,
    title
}) => {
    const styles = ModalAlertStyles();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (onClose) onClose();
        setOpen(false);
        setOpenModal(false);
    }

    useEffect(() => {
        if (openModal) {
            handleOpen(true);
        }
    }, [openModal]);

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style} className={styles.modalContent}>
                        <Stack className={styles.headerContent}>
                            {modalIcon === 'successIcon' && (<CheckModalIcon className='modal-icon' />)}
                            {modalIcon === 'warnIcon' && (<ErrorModalIcon className='modal-icon' />)}
                            {modalIcon === 'emailIcon' && (<MailModalIcon className='modal-icon' />)}
                            <IconButton className='close-icon_Box'>
                                <AiOutlineClose className='close-icon' onClick={handleClose} />
                            </IconButton>
                        </Stack>

                        <Typography className='title' variant="h6" component="h2">
                            {title}
                        </Typography>

                        <Box className='subtitle_box_container'>

                            {!verifiVariable(message) && message.map((ele, index) => (
                                <Box key={index} className='subtitle_box'>
                                    <Stack className='subtitle_icon'>
                                        {modalIcon === 'successIcon' && (<IconWarning />)}
                                        {modalIcon === 'warnIcon' && (<IconWarning />)}
                                        {modalIcon === 'emailIcon' && (<IconWarning />)}
                                    </Stack>
                                    <Typography className='subtitle'>
                                        {ele.subTitle}
                                    </Typography>
                                </Box>
                            ))}

                            {!verifiVariable(subTitle) && (<Typography className='subtitle'>
                                {subTitle}
                            </Typography>)}

                        </Box>

                        <Stack className={styles.footerContent} spacing={4} direction={'row'}>

                            {cancelButtom === true
                                && (<CancelCustomButton
                                    variant="outlined"
                                    color={'customBlueLight'}
                                    onClick={handleClose}>
                                    Cancelar
                                </CancelCustomButton>)}

                            <AcceptCustomButton
                                variant="contained"
                                color={'customBlueLight'}
                                onClick={() => {
                                    handleClose();
                                    onAcept && onAcept();
                                }}
                            >
                                {buttonText}
                            </AcceptCustomButton>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

ModalAlert.propTypes = {
    buttonText: PropTypes.string,
    cancelButtom: PropTypes.bool,
    message: PropTypes.array,
    modalIcon: PropTypes.oneOf(['successIcon', 'warnIcon', 'emailIcon', '']),
    onAcept: PropTypes.func,
    onClose: PropTypes.func,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    subTitle: PropTypes.string,
    title: PropTypes.string
};

export default ModalAlert;