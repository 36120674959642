import { forwardRef, Fragment, useEffect, useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Chip from '@mui/material/Chip';
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { ReactComponent as UserIcon } from "../../img/user-icon.svg";
import { ReactComponent as CardIcon } from "../../img/card-icon.svg";

import ResendUserContract from './molecules/resendUserContract';
import { DetailSignatoriesStyles } from './index.css';
import EditParticipant from '../editParticipant';
import { api, verifiVariable } from '../../../../../utils/enums';
import RejectCommentsModal from '../rejectCommentsModal';
import { theme } from '../../../../../theme/theme';


const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomTitle = styled(Typography)((props) => ({
    lineHeight: '1.2',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const DetailSignatories = ({ comentarios, member, index, contractDetail, contractMembers, contractFiles, removeSignner, deleteSigner, updateContract, loading, textLoading }) => {
    const url = `${api.BASE_URI}/webpay/create/${member["token_payment"]}/${['contrato']["sContractID"]}`;
    const styles = DetailSignatoriesStyles();
    // eslint-disable-next-line
    const [statusContract, setStatusContract] = useState(undefined);
    const [contract, setContract] = useState(undefined);
    const [expanded, setExpanded] = useState(false);
    // const [loading, setLoading] = useState(false);
    // eslint-disable-next-line
    const [textBox, setTextBox] = useState('');
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [statusText, setStatusText] = useState('');

    const handleClickSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const handleClose = () => {
        setOpen(false);
    }


    useEffect(() => {

        if (contractDetail) {
            setStatusContract(['contrato'].sStatus);
            setContract(['contrato']);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractDetail]);

    useEffect(() => {

        if ([4, 2].includes(member['iRole'])) {
            setStatusText('Pre aprobador');
        }

        if ([1, 0].includes(member['iRole'])) {
            setStatusText('Firmante');
        }


        const splitText = member['fullName'].split(' ');

        let newText = "";

        splitText.forEach((texto, index) => {
            if (index !== 3) newText += `${texto.substring(0, 1).toUpperCase()}`;
        });

        setTextBox(newText);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    return (

        <Fragment>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
            >
                <Alert
                    className={styles.muiAlert}
                    onClose={handleCloseSnackbar}
                    severity="success"
                >
                    El link de pago ha sido copiado, puedes enviárselo a
                    el firmante.
                </Alert>
            </Snackbar>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center', color: 'red' }}>
                    Ah ocurrido un error!
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>
                        Lo sentimos, ah ocurrido un error.
                        Le pedimos disculpas por las molestias causadas y lo invitamos a
                        reportar el error a nuestro equipo de soporte desde el siguiente enlace.

                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=56930139035&text&type=phone_number&app_absent=0", "_blank");
                                handleClose();
                            }}
                        >
                            <FaWhatsapp className="icon--white" style={{ fontSize: 28, fill: '#5cc858' }} />
                        </IconButton>


                    </DialogContentText>

                </DialogContent>

            </Dialog>

            <div className={styles.mainContainer}>

                <Card sx={{ width: 300 }} className={styles.cardContainer}>

                    <UserIcon />

                    <CustomTitle sx={{ fontSize: '1.1rem', marginTop: '15px' }} color={['primary.light']}>
                        Participante {1 + index}
                    </CustomTitle>

                    <CustomTitle sx={{ fontSize: '1.1rem' }} color={['black.main']}>
                        {member['fullName']}
                    </CustomTitle>

                    <CardActions disableSpacing style={{ marginTop: '65px' }}>

                        {/* {loading && (<CircularProgress />)} */}

                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />

                        </ExpandMore>

                    </CardActions>

                    <Collapse in={expanded} timeout="auto" unmountOnExit className={styles.expandMoreContent}>

                        <CardContent>

                            <List sx={{ width: '100%', /* maxWidth: 360, */ bgcolor: 'background.paper' }}>

                                <ListItem alignItems="flex-start">

                                    <ListItemText
                                        primary="RUT"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member['RUT']}
                                                </Typography>

                                                <Divider />

                                            </Fragment>
                                        }
                                    />

                                </ListItem>

                                <ListItem alignItems="flex-start">

                                    <ListItemText
                                        primary="Correo electrónico"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member['email']}
                                                </Typography>

                                                <Divider />

                                            </Fragment>
                                        }
                                    />

                                </ListItem>

                                <ListItem alignItems="flex-start">

                                    <ListItemText
                                        primary="Número de celular"
                                        secondary={
                                            <Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {member['phone']}
                                                </Typography>

                                                <Divider />

                                            </Fragment>
                                        }
                                    />

                                </ListItem>

                                <ListItem alignItems="flex-start">

                                    <ListItemText
                                        primary="Rol"
                                        secondary={
                                            <Fragment>

                                                {member['iRole'] !== 3 && (<Chip
                                                    label={
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            {statusText}
                                                        </Typography>
                                                    }
                                                />)}

                                                {member['portion'] !== '0.00' && (<Chip
                                                    sx={{ marginLeft: '10px' }}
                                                    label={
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                        >
                                                            Pagador
                                                        </Typography>
                                                    }
                                                />)}

                                                <Divider />

                                            </Fragment>
                                        }
                                    />

                                </ListItem>

                            </List>

                        </CardContent>

                    </Collapse>

                </Card>

                {/* <div>
                        <ModalDeleteParticipant />
                        </div> */}

                {
                    (!verifiVariable(contract) &&
                        ['REVIEW', 'CREATED', 'REJECTED', 'APPROVED'].includes(contract['sStatus'])) &&
                    (!verifiVariable(contract) &&
                        ['REQUIRED', 'BEFORE_DISAGREE', 'BEFORE_REVIEW', 'BEFORE_AGREE', 'NONE'].includes(contract['sPreAprobado'])) &&
                    (!verifiVariable(contract) &&
                        ['PENDING', 'PARTIALLY_PAID'].includes(contract['sPaymentStatus'])) &&
                    (
                        <div className={styles.contentActionButtoms}>

                            {/* CASOS BOTON COMENTARIOS */}

                            {
                                ['CREATED', 'REJECTED', 'REVIEW'].includes(contract['sStatus']) &&
                                (!verifiVariable(comentarios) && comentarios.filter((item) => item['SignerName'] === member['fullName']).length >= 1) &&
                                (
                                    <div>
                                        <RejectCommentsModal
                                            member={member}
                                            index={index}
                                            comenmts={comentarios}
                                        />
                                    </div>
                                )
                            }

                            {/* CASOS BOTONES PRE APROBADORES */}
                            {
                                [2].includes(member['iRole']) &&
                                ['CREATED', 'REJECTED'].includes(contract['sStatus']) &&
                                ['REQUIRED', 'BEFORE_DISAGREE', 'BEFORE_REVIEW'].includes(contract['sPreAprobado']) &&
                                (
                                    <Fragment>

                                        <div>
                                            <EditParticipant
                                                member={member}
                                                contractMembers={contractMembers}
                                                numParticipant={1 + index}
                                                idContract={['contrato']['sContractID']}
                                                loading={(e) => loading(e)}
                                                textLoading={(e) => textLoading(e)}
                                            />
                                        </div>

                                        <div style={{ background: theme.palette.primary.light }}>
                                            <ResendUserContract
                                                contractMembers={contractMembers}
                                                contractFiles={contractFiles}
                                                member={member}
                                            />
                                        </div>

                                    </Fragment>
                                )
                            }

                            {/* CASOS BOTONES FIRMANTES */}

                            {
                                [0, 1, 3, 4].includes(member['iRole']) &&
                                ((['NONE'].includes(contract['sPreAprobado']) && ['CREATED', 'REJECTED', 'REVIEW'].includes(contract['sStatus'])) ||
                                    ['BEFORE_AGREE'].includes(contract['sPreAprobado'])) &&
                                (
                                    <Fragment>

                                        <div>
                                            <EditParticipant
                                                member={member}
                                                contractMembers={contractMembers}
                                                numParticipant={1 + index}
                                                idContract={['contrato']['sContractID']}
                                                loading={(e) => loading(e)}
                                                textLoading={(e) => textLoading(e)}
                                            />
                                        </div>

                                        <div style={{ background: theme.palette.primary.light }}>
                                            <ResendUserContract
                                                contractMembers={contractMembers}
                                                contractFiles={contractFiles}
                                                member={member}
                                            />
                                        </div>

                                    </Fragment>
                                )
                            }

                            {/* CASOS BOTON PAGADOR */}

                            {
                                [3, 4, 0].includes(member['iRole']) &&
                                ['APPROVED'].includes(contract['sStatus']) &&
                                ['PENDING', 'PARTIALLY_PAID'].includes(contract['sPaymentStatus']) &&
                                member['portion'] !== '0.00' &&
                                (
                                    <div style={{ background: theme.palette.primary.light }}>

                                        <CopyToClipboard text={url}>

                                            <CardIcon
                                                onClick={() => {
                                                    handleClickSnackbar();
                                                    window.open(url, '_blank');
                                                }}
                                                className='icon--lg'
                                            />

                                        </CopyToClipboard>

                                    </div>
                                )
                            }

                        </div>
                    )
                }

            </div>


        </Fragment>
    );
}

export default DetailSignatories;