import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "organizations",
    initialState: {
        listOrganizations: [],
        agreements: [],
        agreement: null,
        loading: false,
        message: null,
        code: null,
        status: null
    },
    reducers: {

        CLEAR_ALL: (state, action) => {
            state.listOrganizations = [];
        },

        SAVE_LIST_ORGANIZATIONS: (state, action) => {
            state.listOrganizations = action.payload;
        },

        AGREEMENT_SUCCESS: (state, action) => {
            state.agreement = action.payload['message']['agreement'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        LIST_AGREEMENTS_SUCCESS: (state, action) => {
            state.agreements = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        ORGANIZATIONS_LIST_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        ORGANIZATIONS_LIST_SUCCESS: (state, action) => {
            state.listOrganizations = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        ORGANIZATIONS_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
        }
    },
});

export const {
    CLEAR_ALL,
    SAVE_LIST_ORGANIZATIONS,
    ORGANIZATIONS_LIST_REQUEST,
    AGREEMENT_SUCCESS,
    LIST_AGREEMENTS_SUCCESS,
    ORGANIZATIONS_LIST_SUCCESS,
    ORGANIZATIONS_LIST_FAIL
} = slice.actions;

export const clearAll = () => {
    return { type: CLEAR_ALL.type, payload: null };
};

export const addOrganizations = (rest) => {
    return { type: SAVE_LIST_ORGANIZATIONS.type, payload: rest };
};

export const getAgreementStorage = (token, agrementId) => {
    return apiCallStart({
        url: `/api/v1/agreement/find/${agrementId}`,
        method: "POST",
        data: {},
        onStart: ORGANIZATIONS_LIST_REQUEST.type,
        onSuccess: AGREEMENT_SUCCESS.type,
        onError: ORGANIZATIONS_LIST_FAIL.type,
        authorization: token
    });
};

export const getAllAgreementsStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/agreement/find-all`,
        method: "POST",
        data: {},
        onStart: ORGANIZATIONS_LIST_REQUEST.type,
        onSuccess: LIST_AGREEMENTS_SUCCESS.type,
        onError: ORGANIZATIONS_LIST_FAIL.type,
        authorization: token
    });
};

export const getOrganizationsStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/organization/all`,
        method: "POST",
        data: {},
        onStart: ORGANIZATIONS_LIST_REQUEST.type,
        onSuccess: ORGANIZATIONS_LIST_SUCCESS.type,
        onError: ORGANIZATIONS_LIST_FAIL.type,
        authorization: token
    });
};

export default slice.reducer;