import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "fvavanzada",
    initialState: {
        clvUnicaObtenerToken: null,
        clvUnicaUrlAutenticacion: null,
        clvUnicaAccessToken: null,
        clvUnicadAtosUsuario: null,
        challeGenToken: null,
        challeIngresValidacion: null,
        challeRespuestaValidacion: null,
        raWSIngresoSolicitud: null,
        esignerWSDescCertificadoConfPinFirma: null,
        esignerWSIntercambiaDocSoap: null
    },
    reducers: {

        CLEAR_ALL: (state, action) => {
            state.clvUnicaObtenerToken = null;
            state.clvUnicaUrlAutenticacion = null;
            state.clvUnicaAccessToken = null;
            state.clvUnicadAtosUsuario = null;
            state.challeGenToken = null;
            state.challeIngresValidacion = null;
            state.challeRespuestaValidacion = null;
            state.raWSIngresoSolicitud = null;
            state.esignerWSDescCertificadoConfPinFirma = null;
            state.esignerWSIntercambiaDocSoap = null;
        },

        SAVE_CLV_UNICA_TOKEN: (state, action) => {
            state.clvUnicaObtenerToken = action.payload;
        },

        CLEAR_CLV_UNICA_TOKEN: (state, action) => {
            state.clvUnicaObtenerToken = null;
        },

        SAVE_CLV_UNICA_URL_AUTH: (state, action) => {
            state.clvUnicaUrlAutenticacion = action.payload;
        },

        CLEAR_CLV_UNICA_URL_AUTH: (state, action) => {
            state.clvUnicaUrlAutenticacion = null;
        },

        SAVE_CLV_UNICA_ACCESS_TOKEN: (state, action) => {
            state.clvUnicaAccessToken = action.payload;
        },

        CLEAR_CLV_UNICA_ACCESS_TOKEN: (state, action) => {
            state.clvUnicaAccessToken = null;
        },

        SAVE_CLV_UNICA_DATOS_USUARIO: (state, action) => {
            state.clvUnicadAtosUsuario = action.payload;
        },

        CLEAR_CLV_UNICA_DATOS_USUARIO: (state, action) => {
            state.clvUnicadAtosUsuario = null;
        },

        SAVE_CHALLE_GEN_TOKEN: (state, action) => {
            state.challeGenToken = action.payload;
        },

        CLEAR_CHALLE_GEN_TOKEN: (state, action) => {
            state.challeGenToken = null;
        },

        SAVE_CHALLE_INGRES_VALIDACION: (state, action) => {
            state.challeIngresValidacion = action.payload;
        },

        CLEAR_CHALLE_INGRES_VALIDACION: (state, action) => {
            state.challeIngresValidacion = null;
        },

        SAVE_CHALLE_REST_VALIDACION: (state, action) => {
            state.challeRespuestaValidacion = action.payload;
        },

        CLEAR_CHALLE_REST_VALIDACION: (state, action) => {
            state.challeRespuestaValidacion = null;
        },

        SAVE_RA_WS_INGRES_SOLICITUD: (state, action) => {
            state.raWSIngresoSolicitud = action.payload;
        },

        CLEAR_RA_WS_INGRES_SOLICITUD: (state, action) => {
            state.raWSIngresoSolicitud = null;
        },

        SAVE_E_SIGNER_CERT_PIN_FIRMA: (state, action) => {
            state.esignerWSDescCertificadoConfPinFirma = action.payload;
        },

        CLEAR_E_SIGNER_CERT_PIN_FIRMA: (state, action) => {
            state.esignerWSDescCertificadoConfPinFirma = null;
        },

        SAVE_E_SIGNER_INTER_CAMBIO_DOC_SOAP: (state, action) => {
            state.esignerWSIntercambiaDocSoap = action.payload;
        },

        CLEAR_E_SIGNER_INTER_CAMBIO_DOC_SOAP: (state, action) => {
            state.esignerWSIntercambiaDocSoap = null;
        }
    },
});

export const {
    CLEAR_ALL,
    SAVE_E_SIGNER_INTER_CAMBIO_DOC_SOAP,
    CLEAR_E_SIGNER_INTER_CAMBIO_DOC_SOAP,
    SAVE_E_SIGNER_CERT_PIN_FIRMA,
    CLEAR_E_SIGNER_CERT_PIN_FIRMA,
    SAVE_RA_WS_INGRES_SOLICITUD,
    CLEAR_RA_WS_INGRES_SOLICITUD,
    SAVE_CHALLE_REST_VALIDACION,
    CLEAR_CHALLE_REST_VALIDACION,
    SAVE_CHALLE_INGRES_VALIDACION,
    CLEAR_CHALLE_INGRES_VALIDACION,
    SAVE_CHALLE_GEN_TOKEN,
    CLEAR_CHALLE_GEN_TOKEN,
    SAVE_CLV_UNICA_DATOS_USUARIO,
    CLEAR_CLV_UNICA_DATOS_USUARIO,
    SAVE_CLV_UNICA_ACCESS_TOKEN,
    CLEAR_CLV_UNICA_ACCESS_TOKEN,
    SAVE_CLV_UNICA_URL_AUTH,
    CLEAR_CLV_UNICA_URL_AUTH,
    SAVE_CLV_UNICA_TOKEN,
    CLEAR_CLV_UNICA_TOKEN
} = slice.actions;

export const clearAll = () => {
    return { type: CLEAR_ALL.type, payload: null };
};

export const addESingnerInterCambioDocSoap = (rest) => {
    return { type: SAVE_E_SIGNER_INTER_CAMBIO_DOC_SOAP.type, payload: rest };
};

export const removeESingnerInterCambioDocSoap = () => {
    return { type: CLEAR_E_SIGNER_INTER_CAMBIO_DOC_SOAP.type, payload: null };
};

export const addESingnerCertPinFirma = (rest) => {
    return { type: SAVE_E_SIGNER_CERT_PIN_FIRMA.type, payload: rest };
};

export const removeESingnerCertPinFirma = () => {
    return { type: CLEAR_E_SIGNER_CERT_PIN_FIRMA.type, payload: null };
};

export const addRaWsIngresSolicitud = (rest) => {
    return { type: SAVE_RA_WS_INGRES_SOLICITUD.type, payload: rest };
};

export const removeRaWsIngresSolicitud = () => {
    return { type: CLEAR_RA_WS_INGRES_SOLICITUD.type, payload: null };
};

export const addChalleRestValidacion = (rest) => {
    return { type: SAVE_CHALLE_REST_VALIDACION.type, payload: rest };
};

export const removeChalleRestValidacion = () => {
    return { type: CLEAR_CHALLE_REST_VALIDACION.type, payload: null };
};

export const addChalleIngresValidacion = (rest) => {
    return { type: SAVE_CHALLE_INGRES_VALIDACION.type, payload: rest };
};

export const removeChalleIngresValidacion = () => {
    return { type: CLEAR_CHALLE_INGRES_VALIDACION.type, payload: null };
};

export const addChalleGenToken = (rest) => {
    return { type: SAVE_CHALLE_GEN_TOKEN.type, payload: rest };
};

export const removeChalleGenToken = () => {
    return { type: CLEAR_CHALLE_GEN_TOKEN.type, payload: null };
};

export const addClvUnicaDatosUsuario = (rest) => {
    return { type: SAVE_CLV_UNICA_DATOS_USUARIO.type, payload: rest };
};

export const removeClvUnicaDatosUsuario = () => {
    return { type: CLEAR_CLV_UNICA_DATOS_USUARIO.type, payload: null };
};

export const addClvUnicaAccessToken = (rest) => {
    return { type: SAVE_CLV_UNICA_ACCESS_TOKEN.type, payload: rest };
};

export const removeClvUnicaAccessToken = () => {
    return { type: CLEAR_CLV_UNICA_ACCESS_TOKEN.type, payload: null };
};

export const addClvUnicaUrlAuth = (rest) => {
    return { type: SAVE_CLV_UNICA_URL_AUTH.type, payload: rest };
};

export const removeClvUnicaUrlAuth = () => {
    return { type: CLEAR_CLV_UNICA_URL_AUTH.type, payload: null };
};

export const addClvUnicaToken = (rest) => {
    return { type: SAVE_CLV_UNICA_TOKEN.type, payload: rest };
};

export const removeClvUnicaToken = () => {
    return { type: CLEAR_CLV_UNICA_TOKEN.type, payload: null };
};

export default slice.reducer;
