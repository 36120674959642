import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";


const buttomsBackgroundColor = '#003c77';

export const FormStyles = makeStyles(() => {
    return createStyles({
        gridRoot: {
            display: "flex",
            justifyContent: "center",
        },
        gridTitle: {
            display: "flex",
            flexDirection: "column",
        },
        gridSubTitle: {
            display: "flex",
            flexDirection: "row",
            margin: '10px 0'
        },
        titleform: {
            fontSize: "1.5rem",
            fontWeight: "600",
            color: "#00adea"
        },
        subTitleform: {
            fontSize: "1rem",
            fontWeight: "400",
        },
        goBackButton: {
            marginTop: "15px",
            marginRight: "15px",
            backgroundColor: "#ff326e",
            '&:hover': {
                backgroundColor: "#ff326e"
            }
        },
        registerButton: {
            marginTop: "15px",
            backgroundColor: buttomsBackgroundColor,
            '&:hover': {
                backgroundColor: buttomsBackgroundColor
            }
        },
    });
});
