import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

const marginBottomActions = 23;
const buttomsBackgroundColor = '#3fce88';

export const ModalUserDeleteStyles = makeStyles(() => {

    return createStyles({

        buttomsTopSuccessContent: {
            display: 'flex',
            alignItems: 'center'
        },
        buttomsTopContent: {
            display: 'flex',
            alignItems: 'end',
            marginTop: "13px",
            marginRight: "13px"
        },
        uploadContent: {
            display: 'flex',
            alignItems: 'initial'
        },
        uploadButton: {
            height: "116%",
            borderRadius: '0px 5px 5px 0px',
            backgroundColor: buttomsBackgroundColor,
            '&:hover': {
                backgroundColor: buttomsBackgroundColor
            }
        },
        goBackButton: {
            backgroundColor: buttomsBackgroundColor,
            '&:hover': {
                backgroundColor: buttomsBackgroundColor
            }
        },
        inputs: {
            backgroundColor: theme.palette.grey[50],
            border: `0px solid ${theme.palette.grey[100]}`
        },
        gridContainer: {
            justifyContent: "center",
            alignItems: "center"
        },
        constentDialogActionSend: {
            marginRight: '10px',
            marginBottom: marginBottomActions
        },
        constentDialogActionGoBack: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: marginBottomActions
        },
        constentDialogTitle: {
            textAlign: 'center',
            color: 'black',
            fontWeight: 900,
            fontSize: '1.7rem'
        }
    })

});

