import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

/* ref = { ref }
value = { value }
onBlur = { onBlur }
onChange = { onChange } */

const AutoCompleteSelectAll = ({
    items,
    label,
    placeholder,
    selectAllLabel,
    noOptionsText,
    limitTags,
    value,
    ref,
    onBlur,
    onChange
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const allSelected = items.length === selectedOptions.length;

    const handleToggleOption = (selectedOptions) => {
        console.log('selectedOptions: ', selectedOptions);
        setSelectedOptions(selectedOptions);
    }

    const handleClearOptions = () => setSelectedOptions([]);

    const getOptionLabel = option => `${option.label}`;

    const handleSelectAll = isSelected => {
        if (isSelected) {
            setSelectedOptions(items);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOptions, reason) => {

        console.log('event: ', event);
        console.log('selectedOptions: ', selectedOptions);
        console.log('reason: ', reason);

        if (reason === "select-option" || reason === "remove-option") {

            if (selectedOptions.find(option => option.value === "select-all")) {
                handleToggleSelectAll();
                let result = [];
                result = items.filter(el => el.value !== "select-all");
                return onChange(result);
            } else {
                handleToggleOption && handleToggleOption(selectedOptions);
                return onChange(selectedOptions);
            }

        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
        }

    }

    const optionRenderer = (props, option, { selected }) => {
        const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    const inputRenderer = params => (
        <TextField {...params} label={label} placeholder={placeholder} />
    );

    /* const getOptionSelected = (option, anotherOption) =>
        option.value === anotherOption.value; */

    const filter = createFilterOptions();

    React.useEffect(() => {
        setSelectedOptions(value);
    }, [value]);

    React.useEffect(() => {
        console.log('items: ', items);
    }, [items]);

    return (
        <Autocomplete
            multiple
            size="medium"
            limitTags={limitTags}
            options={items}
            value={selectedOptions}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            // getOptionSelected={getOptionSelected}
            
            noOptionsText={noOptionsText}
            filterOptions={(options, params) => {
                console.log('options: ', options);
                console.log('params: ', params);
                const filtered = filter(options, params);
                return [{ label: selectAllLabel, value: "select-all" }, ...filtered];
            }}
            onBlur={onBlur}
            ref={ref}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
        />
    );
};

/* 


<Autocomplete
                                                    multiple
                                                    options={list.sort((a, b) => -b['groupBy'].localeCompare(a['groupBy']))}
                                                    groupBy={(option) => option['groupBy']}
                                                    getOptionLabel={(option) => {
                                                        return option['sContractTypeName'] ? option['sContractTypeName'] + ' - CANT. FIRMANTES ' + option['iSignCount'] : "";
                                                    }}
                                                    disableCloseOnSelect
                                                    renderOption={(props, option, { selected }) => option['sModal'] === filter && (
                                                        <li {...props} key={option['iContractTypeFeeID']}>
                                                            <Checkbox
                                                                key={option['iContractTypeFeeID'] + 'Checkbox'}
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option['sContractTypeName'] + ' - CANT. FIRMANTES ' + option['iSignCount']}
                                                        </li>
                                                    )}

                                                    value={value}
                                                    onChange={(event, item) => {
                                                        onChange(item);
                                                    }}
                                                    renderInput={params => <TextField error={fieldState.error ? true : false} {...params} label="Trámites" />}
                                                />

*/

AutoCompleteSelectAll.defaultProps = {
    limitTags: 5,
    items: [],
    selectedValues: [],
    getOptionLabel: value => value
};

export default AutoCompleteSelectAll;
