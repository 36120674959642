import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import Box from "@mui/material/Box";

import { ExpressProcessStyles } from "../../index.css";
import { typeUser, verifiVariable } from "../../../../../utils/enums";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";

const SignatureType = () => {
    const {
        organizations: { agreements },
        formExpressTramit: { signatureType, tramitType, agrement, optionSignature },
        contract: {
            tramites: { allTramitAgreements, contracTypesNames },
        },
        session: {
            login: { userInfo },
        },
    } = useSelector((state) => state);
    const refInputControl = useRef();
    const refInputControlTypeTramit = useRef();
    const styles = ExpressProcessStyles();
    const dispatch = useDispatch();
    const { control, watch, setValue } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            typeFirm: "",
            typeAgrement: "",
        },
    });

    const typeFirmWatch = watch("typeFirm");
    const typeTramitWatch = watch("typeTramit");
    const typeAgrementWatch = watch("typeAgrement");

    const getOptionLabel = (option) => {

        if (option.trim && option.trim() === "") {
            return "";
        }

        return option.agreementId + " - " + option.agreement + " - " + option.ModalPayment;
    };

    const getOptionLabelTypeTramit = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }
        return option.name;
    };

    const renderAgrementsOptions = (props, option) => {
        return (
            <li
                {...props}
                key={option.agreementId}>
                {option.agreementId} - {option.agreement} - {option.ModalPayment}
            </li>
        );
    };

    useEffect(() => {
        if (!verifiVariable(signatureType)) {
            setValue("typeFirm", signatureType);
        } else {
            setValue("typeFirm", "");
        }
        // eslint-disable-next-line
    }, [signatureType]);

    useEffect(() => {
        if (!verifiVariable(typeAgrementWatch)) {
            dispatch(
                saveTramitExpressInfo({
                    indexe: "agrement",
                    value: typeAgrementWatch,
                })
            );
        }
        // eslint-disable-next-line
    }, [typeAgrementWatch]);

    useEffect(() => {

        const iContractTypeFeeIDOptions = [];

        if (!verifiVariable(typeTramitWatch)) {
            const options = allTramitAgreements.filter(ele => ele.sContractTypeName === typeTramitWatch.value);
            iContractTypeFeeIDOptions.push(...options);
        } else if (tramitType) {
            const options = allTramitAgreements.filter(ele => ele.sContractTypeName === tramitType.value);
            iContractTypeFeeIDOptions.push(...options);
        };

        if (iContractTypeFeeIDOptions.length > 0) {
            dispatch(
                saveTramitExpressInfo({
                    indexe: "tramitType",
                    value: typeTramitWatch || tramitType,
                })
            );

            dispatch(
                saveTramitExpressInfo({
                    indexe: "optionTypeTramit",
                    value: iContractTypeFeeIDOptions,
                })
            );
        };

        // eslint-disable-next-line
    }, [typeTramitWatch, allTramitAgreements]);

    useEffect(() => {

        if (!verifiVariable(typeFirmWatch)) {
            if (optionSignature) {
                const arrayData = optionSignature.filter((item) =>
                    item["sModal"] === typeFirmWatch
                );

                dispatch(
                    saveTramitExpressInfo({
                        indexe: "signatureType",
                        value: typeFirmWatch,
                    })
                );

                dispatch(
                    saveTramitExpressInfo({
                        indexe: "optionSignature",
                        value: arrayData,
                    })
                );
            };
        };
        // eslint-disable-next-line
    }, [typeFirmWatch, allTramitAgreements]);

    return (
        <Box className={styles.spacinContent}>
            {!verifiVariable(userInfo) &&
                userInfo["iProfileID"] === typeUser.ADMIN && (
                    <Controller
                        name={"typeAgrement"}
                        control={control}
                        rules={{
                            required: {
                                value:
                                    !verifiVariable(userInfo) &&
                                        userInfo["iProfileID"] === typeUser.ADMIN
                                        ? true
                                        : false,
                                message: "Este campo es requerido",
                            },
                        }}
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState,
                        }) => {
                            return (
                                <FormControl
                                    fullWidth
                                    error={fieldState.error ? true : false}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        className={styles.title}
                                        color={["black.main"]}
                                    >
                                        Selecciona tipo de convenio
                                    </Typography>

                                    <AutocompleteMui
                                        value={agrement}
                                        isOptionEqualToValue={(option, value) => option.agreementId === value.agreementId}
                                        multiple={false}
                                        options={agreements}
                                        getOptionLabel={getOptionLabel}
                                        onChange={(e, options, reason) => {
                                            if (reason === "clear") {
                                                dispatch(
                                                    saveTramitExpressInfo({
                                                        indexe: "agrement",
                                                        value: null,
                                                    })
                                                );
                                            }
                                            onChange(options);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                ref={refInputControl}
                                                {...params}
                                                placeholder="Selecciona tipo de convenio"
                                                className={
                                                    styles.autocompleteInput
                                                }
                                            />
                                        )}
                                        renderOption={renderAgrementsOptions}
                                    />

                                    <FormHelperText
                                        hidden={fieldState.error ? false : true}
                                    >
                                        {fieldState.error
                                            ? fieldState.error.message
                                            : null}
                                    </FormHelperText>

                                    <br />
                                </FormControl>
                            );
                        }}
                    />
                )}

            <Controller
                name={"typeTramit"}
                control={control}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState,
                }) => {
                    return (
                        <FormControl
                            fullWidth
                            error={fieldState.error ? true : false}
                            sx={{ paddingBottom: '1.5rem' }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={styles.title}
                                color={["black.main"]}
                            >
                                ¿Qué trámite necesitas gestionar?
                            </Typography>

                            <AutocompleteMui
                                value={tramitType}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                multiple={false}
                                options={!verifiVariable(contracTypesNames) && contracTypesNames.length > 0 ? contracTypesNames : []}
                                getOptionLabel={getOptionLabelTypeTramit}
                                onChange={(e, options, reason) => {
                                    if (reason === "clear") {
                                        dispatch(
                                            saveTramitExpressInfo({
                                                indexe: "signatureType",
                                                value: null,
                                            })
                                        );
                                        dispatch(
                                            saveTramitExpressInfo({
                                                indexe: "signatureTypeList",
                                                value: [],
                                            })
                                        );
                                        dispatch(
                                            saveTramitExpressInfo({
                                                indexe: "tramitType",
                                                value: null,
                                            })
                                        );
                                    }
                                    onChange(options);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        ref={refInputControlTypeTramit}
                                        {...params}
                                        placeholder="¿Qué trámite necesitas gestionar?"
                                        className={styles.autocompleteInput}
                                    />
                                )}
                            />

                            <FormHelperText
                                hidden={fieldState.error ? false : true}
                            >
                                {fieldState.error
                                    ? fieldState.error.message
                                    : null}
                            </FormHelperText>
                        </FormControl>
                    );
                }}
            />

            <Typography
                variant="h6"
                gutterBottom
                className={styles.title}
                color={["black.main"]}
            >
                ¿Qué tipo de firma necesitas?
            </Typography>

            <Controller
                name={"typeFirm"}
                control={control}
                rules={{
                    required: "Debe elegir una opción",
                }}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState,
                }) => {
                    return (
                        <FormControl
                            fullWidth
                            error={fieldState.error ? true : false}
                        >
                            <RadioGroup
                                onBlur={onBlur}
                                ref={ref}
                                value={value}
                                onChange={(e, options) => {
                                    onChange(options);
                                }}
                            >
                                <FormControlLabel
                                    key={"Certificación Notarial"}
                                    value="SIGNATURE_CERT_NOTARIAL"
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            className={styles.textRadioButtom}
                                        >
                                            Certificación Notarial
                                        </Typography>
                                    }
                                />

                                <FormControlLabel
                                    key={"Protocolización Notarial"}
                                    value="SIGNATURE_PROT_NOTARIAL"
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            className={styles.textRadioButtom}
                                        >
                                            Protocolización Notarial
                                        </Typography>
                                    }
                                />

                                <FormControlLabel
                                    key={"Firma Electrónica Simple (FES) - Sin Notaría"}
                                    value="SIGNATURE"
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            className={styles.textRadioButtom}
                                        >
                                            Firma Electrónica Simple (FES) - <strong>Sin Notaría</strong>
                                        </Typography>
                                    }
                                />

                                {/* <FormControlLabel
                                    key={"Firma electrónica avanzada - Sólo firma electrónica avanzada"}
                                    value="SIGNATURE_ADVANCE"
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            variant="caption"
                                            gutterBottom
                                            className={styles.textRadioButtom}
                                        >
                                            Firma electrónica avanzada (FEA) - <strong>Sólo firma electrónica avanzada</strong>
                                        </Typography>
                                    }
                                /> */}
                            </RadioGroup>
                        </FormControl>
                    );
                }}
            />
        </Box>
    );
};

export default SignatureType;