import { Fragment } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { ReactComponent as AddParticipans } from "../../../../../assets/icons/add-participans.svg";
import { ReactComponent as ReviewProcedures } from "../../../../../assets/icons/reviewprocedures.svg";
import { ReactComponent as ManageProcedures } from "../../../../../assets/icons/manageprocedures.svg";
import { ReactComponent as SignatureProcedures } from "../../../../../assets/icons/signatureprocedures.svg";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import Contact from "../contact";
import { SignAuthorizationStyles } from "../../index.css";

const WelcomeOptionsContent = () => {
    const screenSizeHook = ScreenSizeHook();
    const styles = SignAuthorizationStyles();

    return (
        <Fragment>
            <Typography variant="h5" gutterBottom className={styles.titleFV}>
                Bienvenido a firma electrónica{" "}
                {!["xs"].includes(screenSizeHook) && <br />} con autorización
                notarial de firmas
            </Typography>

            <Box className={styles.welcomeOptionsContent}>
                <Box>
                    <ReviewProcedures />

                    <Typography variant="h6" gutterBottom>
                        Elige qué tipo <br /> de firma necesitas
                    </Typography>
                </Box>

                <Box>
                    <AddParticipans />

                    <Typography variant="h6" gutterBottom>
                        Agrega <br /> participantes
                    </Typography>
                </Box>
            </Box>

            <Box className={styles.welcomeOptionsContent}>
                <Box>
                    <ManageProcedures />

                    <Typography variant="h6" gutterBottom>
                        Sube los documentos en un solo archivo
                    </Typography>
                </Box>

                <Box>
                    <SignatureProcedures />

                    <Typography variant="h6" gutterBottom>
                        Asigna las firmas <br /> de los participantes
                    </Typography>
                </Box>
            </Box>

            <Box
                className={styles.contactUs}
                sx={{
                    paddingBottom: ["xs", "sm", "md"].includes(screenSizeHook)
                        ? "35px"
                        : "74px",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Gracias por usar nuestros servicios
                </Typography>

                <Contact showMsnInfo={true} />
            </Box>
        </Fragment>
    );
};

export default WelcomeOptionsContent;
