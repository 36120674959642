import React from "react";
import { CustomixedYAxisTick } from "./customs/CustomixedYAxisTick";
import { CustomizedXAxisTick } from "./customs/CustomizedXAxisTick";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from "recharts";

/**
 * Component in charge of displaying line chart
 * @param data information to be loaded into the chart
 * @param width chart width 
 * @param height chart height 
 * @param XdataKey key data X 
 * @param YdataKey key data Y 
 * @param lineValueX value line X
 * @param lineValueY value line Y 
 * @param colorLineY color line Y 
 * @param typeLine type line chart 
 * @returns Component LineChart
 */
export const CustomLineChart = ({
    data,
    width,
    height,
    XdataKey,
    YdataKey,
    lineValueX,
    lineValueY,
    colorLineY,
    typeLine,
}) => {
    const textTool = {
        fontWeight: "500",
        fontSize: "0.8rem",
    };

    const CustomTooltip = ({ active, payload, label }) => {
        const payload1 = { ...payload[0] };
        const obj = { ...payload1.payload };

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p>
                        <span style={textTool}>Doc:</span>{" "}
                        <span className="doc">{obj.doc}</span>
                    </p>
                    <p>
                        <span style={textTool}>Firmas:</span>{" "}
                        <span className="intro">{obj.firmas}</span>{" "}
                    </p>
                    <p>
                        <span style={textTool}>Este mes:</span>{" "}
                        <span className="label">{label}</span>
                    </p>
                </div>
            );
        }

        return null;
    };

    return (
        <LineChart
            width={width}
            height={height}
            data={data}
            margin={{
                top: 20,
                right: 5,
                left: 25,
                bottom: 10,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
                dataKey={XdataKey}
                height={20}
                tick={<CustomizedXAxisTick />}
            />
            <YAxis
                dataKey={YdataKey}
                width={10}
                tick={<CustomixedYAxisTick />}
            />
            <Tooltip content={<CustomTooltip />} />

            <Line
                type={typeLine}
                dataKey={lineValueX}
                stroke="#ff326e"
                strokeWidth={2}
            />
            <Line
                type={typeLine}
                dataKey={lineValueY}
                stroke={colorLineY}
                strokeWidth={2}
            />
        </LineChart>
    );
};
