import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegGrinWink, FaRegSadCry } from "react-icons/fa";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import Btn from "../buttons/Button";
import { ModalUpdateUserPerfilStyles } from "./modalUpdateUserPerfil.css";
import Spinner from "../spinners/Spinner";
import { validatePhoneNumber, validation } from "../../utils/validations";
import { editUser, getUser } from "../../utils/api-user";
import { MenuasideStyles } from "../dashboard/MenuAside.css";


const ModalUpdateUserPerfil = ({ styledButton }) => {
    const {
        session: {
            login: {
                user,
                userInfo
            }
        }
    } = useSelector(state => state);
    const styles = ModalUpdateUserPerfilStyles();
    const menuStyles = MenuasideStyles();
    const { control, setValue, handleSubmit, watch } = useForm({
        defaultValues: {
            sFirstName: '',
            sLastName: '',
            sEmail: '',
            sPhone: '',
            sLogin: '',
            sPassword: '',
            sRepeatPassword: ''
        }
    });
    const password = useRef({});
    password.current = watch("sPassword");
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [description, setDescription] = useState('');
    const [texButtonGoback, setTexButtonGoback] = useState('');
    const [restStatus, setRestStatus] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setModalTitle('Gestión de perfil');
        setDescription('Modifica los campos editables.');
        setTexButtonGoback('Cerrar');
        setRestStatus(undefined);
        setLoading(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);

            const userData = {
                sFirstName: data['sFirstName'].trim(),
                sLastName: data['sLastName'].trim(),
                sEmail: data['sEmail'],
                sPhone: data['sPhone'].trim(),
                sLogin: data['sLogin'],
                sRUT: userInfo.sRUT
            };

            if (data['sPassword'] !== '') Object.assign(userData, { sPassword: data['sPassword'] });

            const response = await editUser(userData, user['userId'], user['token']);

            if (response.status === 'success') {
                setRestStatus(true);
                setTexButtonGoback('Cerrar');
                setModalTitle('Modificación Exitosa');
                setDescription('Sus datos personales han sido modificados correctamente');
            } else if (response.status === 'success') {
                if (response.message.err_lis[0].err === 451) {
                    setTexButtonGoback('Cerrar');
                    setModalTitle('Modificación Fallida');
                    setDescription('Error en el campo RUT. Verifica el campo y vuelve a intentarlo.');
                    setRestStatus(false);
                } else {
                    setTexButtonGoback('Cerrar');
                    setModalTitle('Modificación Fallida');
                    setDescription('La consulta ha fallado, por favor intentelo nuevamente o comunícate con un administrador del sistema');
                    setRestStatus(false);
                };
            };

            setLoading(false);

        } catch (error) {
            setTexButtonGoback('Cerrar');
            setModalTitle('Modificación Fallida');
            setDescription('La consulta ha fallado, por favor intentelo nuevamente o comunícate con un administrador del sistema');
            setRestStatus(false);
            setLoading(false);
        }
    };

    React.useEffect(() => {

        (async () => {

            try {

                const userPerfil = await getUser(user['userId'], user['token']);

                setValue('sFirstName', userPerfil['message']['sFirstName']);
                setValue('sLastName', userPerfil['message']['sLastName']);
                setValue('sEmail', userPerfil['message']['sEmail']);
                setValue('sPhone', userPerfil['message']['sPhone']);
                setValue('sLogin', userPerfil['message']['sLogin']);

            } catch (error) {
                console.log('error', error);
            }

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <React.Fragment>

            {loading && <Spinner loading={true} type="bar" opacity={true} />}

            <Btn
                onClick={() => handleClickOpen()}
                text="Gestión de perfil"
                buttonClass={`button-link ${menuStyles.buttom}`}
                startIcon={
                    <ProfileIcon />
                }
                sx={styledButton}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'xl'}
            >

                {(restStatus === undefined && <Stack className={styles.buttomsTopContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <AiOutlineCloseCircle fontSize="inherit" size={20} />
                    </IconButton>

                </Stack>)}


                {restStatus === true && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegGrinWink fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                {restStatus === false && (<Stack className={styles.buttomsTopSuccessContent}>

                    <IconButton aria-label="delete" size="small" onClick={handleClose}>
                        <FaRegSadCry fontSize="inherit" size={150} className="icon--red" />
                    </IconButton>

                </Stack>)}

                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">

                    <DialogTitle className={styles.constentDialogTitle}>
                        {modalTitle}
                    </DialogTitle>

                    <DialogContent>

                        <DialogContentText align="center">
                            {description}
                        </DialogContentText>

                        <br />

                        {restStatus === undefined && (<Grid container spacing={1} className={styles.gridContainer}>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Nombres</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sFirstName"
                                        control={control}
                                        rules={{
                                            required: 'Este campo es obligatorio.'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Nombres'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Apellidos</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sLastName"
                                        control={control}
                                        rules={{
                                            required: 'Este campo es obligatorio.'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Apellidos'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>
                                        Correo electrónico
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sEmail"
                                        control={control}
                                        rules={{
                                            required: 'Este campo es obligatorio.',
                                            pattern: {
                                                value: validation.email,
                                                message: 'Formato de correo invalido'
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    disabled="true"
                                                    error={fieldState.error ? true : false}
                                                    styles={{ fontWeight: "800px", backgroundColor: "#CCC" }}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Correo electrónico'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Teléfono</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sPhone"
                                        control={control}
                                        rules={{
                                            required: 'Este campo es obligatorio.',
                                            validate: {
                                                validPhoneNumber: (value) => validatePhoneNumber(value) || 'Formato de numero telefonico invalido'
                                            }
                                            // pattern: {
                                            //     value: validation.phone,
                                            //     message: 'Formato invalido'
                                            // }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        const deleteSpaces = e.target.value.replace(/[^\d+]/g, '');
                                                        setValue('sPhone', deleteSpaces);
                                                    }}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Teléfono'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 16
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Clave</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sPassword"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    type={'password'}
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Clave'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid xs={12} sm={6} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Repetir clave</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sRepeatPassword"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: password.current ? true : false,
                                                message: 'Este campo es obligatorio.'
                                            },
                                            validate: value => value === password.current || "Las contraseñas no coinciden"
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    type={'password'}
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Repetir clave'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            {/* <Grid xs={12} item>

                                <Grid item xs={12}>
                                    <Typography variant={''}>Nombre de Usuario</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Controller
                                        name="sLogin"
                                        control={control}
                                        rules={{
                                            required: 'Este campo es obligatorio.'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (
                                                <TextField
                                                    error={fieldState.error ? true : false}
                                                    className={styles.inputs}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    placeholder={'Nombres'}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />
                                            );
                                        }}
                                    />
                                </Grid>

                            </Grid> */}

                        </Grid>)}

                    </DialogContent>

                    <DialogActions>

                        {restStatus === undefined && (<Stack className={styles.constentDialogActionSend}>
                            <Button variant="contained" type="submit" className={styles.goBackButton} >Enviar</Button>
                        </Stack>)}

                        {restStatus !== undefined && (<Stack className={styles.constentDialogActionGoBack}>
                            <Button variant="contained" onClick={() => { handleClose() }} className={styles.goBackButton}>{texButtonGoback}</Button>
                        </Stack>)}

                    </DialogActions>

                </form>

            </Dialog>

        </React.Fragment>
    );
}

export default ModalUpdateUserPerfil;