import { Box } from "@mui/system";
import * as React from "react";
import { Text } from "../../../..";
import { styles } from "../../../../../helpers";

const renderUserName = ({ data: { rut, firtsName, lastName } }) => {
    const customSub = {
        fontSize: "12px",
        color: "quinary.dark",
    };

    return (
        <Box sx={styles.userNameContainerStyles}>
            <Text sx={[styles.subTitleStyles, customSub]} variant="h6">
                {rut}
            </Text>
            <Text sx={styles.userNameStyles} variant="h6">
                {firtsName + ' ' + lastName}
            </Text>
        </Box>
    );
};

export default renderUserName;
