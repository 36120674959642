import { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";

import { ReactComponent as Trash2 } from "../../icons/trash-2.svg";
import { ReactComponent as VectorTopLeft } from "../../icons/vector-top-left.svg";
import { ReactComponent as VectorTopRight } from "../../icons/vector-top-right.svg";
import { ReactComponent as VectorDownLeft } from "../../icons/vector-down-left.svg";
import { ReactComponent as VectorDownRight } from "../../icons/vector-down-right.svg";
import { ReactComponent as RecorderIcon } from "../../icons/recorder-icon.svg";
import { ReactComponent as PlayIcon } from "../../icons/play-icon.svg";
import { ReactComponent as StopIcon } from "../../icons/stop-icon.svg";

import { SignAuthorizationStyles } from "../../index.css";
import { asyncBlobToBase64, verifiVariable } from "../../../../../utils/enums";
import BrowserNameHook from "../../../../../hooks/BrowserNameHook";

const VideoCapture = ({ openCamera, videoBase64, loadingVideo, caprute }) => {
    const Ref = useRef(null);
    const [timer, setTimer] = useState("00:01:00");
    const navegador = BrowserNameHook();
    const styles = SignAuthorizationStyles();
    const [isRecording, setIsRecording] = useState(false);
    const videoRef = useRef(null);
    const playVideoRef = useRef(null);
    const streamRef = useRef(null);
    const [downloadLink, setDownloadLink] = useState(null);
    const streamRecorderRef = useRef(null);
    // eslint-disable-next-line
    const [audioSource, setAudioSource] = useState("");
    // eslint-disable-next-line
    const [videoSource, setVideoSource] = useState("");
    // eslint-disable-next-line
    const [audioSourceOptions, setAudioSourceOptions] = useState([]);
    // eslint-disable-next-line
    const [videoSourceOptions, setVideoSourceOptions] = useState([]);
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    const chunks = useRef([]);

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                    ":" +
                    (minutes > 9 ? minutes : "0" + minutes) +
                    ":" +
                    (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer("00:01:00");

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    };

    const playVideo = () => {
        const video = playVideoRef.current;
        video.play();
    };

    const startRecording = () => {
        clearTimer(getDeadTime());
        const regex = /safari/i;

        streamRecorderRef.current = new MediaRecorder(streamRef.current, {
            mimeType: regex.test(navegador) ? "video/mp4" : "video/webm",
        });
        streamRecorderRef.current.start();
        setTimeout(() => {
            caprute();
        }, 2000);
        streamRecorderRef.current.ondataavailable = async (event) => {
            try {
                if (chunks.current) {
                    loadingVideo(true);
                    chunks.current.push(event.data);

                    const blob = new Blob(chunks.current, {
                        type: regex.test(navegador)
                            ? "video/mp4"
                            : "video/webm",
                    });

                    const blobToBase64 = await asyncBlobToBase64(blob);

                    videoBase64(blobToBase64);

                    setDownloadLink(URL.createObjectURL(blob));

                    chunks.current = [];
                    loadingVideo(false);
                }
            } catch (error) {
                loadingVideo(false);
                console.log("error: ", error);
            }
        };
        setIsRecording(true);
    };

    const stopRecording = () => {
        clearTimer("00:01:00");
        streamRecorderRef.current.stop();
        setIsRecording(false);
    };

    const gotStream = (stream) => {
        streamRef.current = stream;
        if (videoRef.current) {
            videoRef.current.srcObject = stream;
        }
    };

    const getStream = async () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => {
                track.stop();
            });
        }
        const constraints = {
            audio: {
                deviceId:
                    audioSource !== "" ? { exact: audioSource } : undefined,
            },
            video: {
                deviceId:
                    videoSource !== "" ? { exact: videoSource } : undefined,
            },
        };

        try {
            const stream = await navigator.mediaDevices.getUserMedia(
                constraints
            );
            gotStream(stream);
        } catch (error) {
            setError(error);
        }
    };

    const getDevices = () => {
        return navigator.mediaDevices.enumerateDevices();
    };

    const gotDevices = (deviceInfos) => {
        const audioSourceOptions = [];
        const videoSourceOptions = [];
        for (const deviceInfo of deviceInfos) {
            if (deviceInfo.kind === "audioinput") {
                audioSourceOptions.push({
                    value: deviceInfo.deviceId,
                    label:
                        deviceInfo.label || `Microphone ${deviceInfo.deviceId}`,
                });
            } else if (deviceInfo.kind === "videoinput") {
                videoSourceOptions.push({
                    value: deviceInfo.deviceId,
                    label: deviceInfo.label || `Camera ${deviceInfo.deviceId}`,
                });
            }
        }
        setAudioSourceOptions(audioSourceOptions);
        setVideoSourceOptions(videoSourceOptions);
    };

    const initCamera = async () => {
        try {
            await getStream();
            const mediaDevices = await getDevices();
            gotDevices(mediaDevices);
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const removeVideo = async () => {
        setTimer("00:01:00");
        videoBase64(null);
        setDownloadLink(null);
        initCamera();
    };

    useEffect(() => {
        if (!verifiVariable(openCamera)) initCamera();

        // eslint-disable-next-line
    }, [openCamera]);

    useEffect(() => {
        if (timer.replace("00:", "") === "00:00") {
            stopRecording();
        }
        // eslint-disable-next-line
    }, [timer]);

    return (
        <div className={styles.videoContent}>
            {/* <div>
                <select id="videoSource" name="videoSource" value={videoSource}>
                    {videoSourceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <select id="audioSource" name="audioSource" value={audioSource}>
                    {audioSourceOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div> */}

            <VectorTopLeft className="vectorTopLeft" />

            <VectorTopRight className="vectorTopRight" />

            {!downloadLink && (
                <video
                    id="camera"
                    ref={videoRef}
                    autoPlay
                    muted
                    playsInline
                    onReset={() => console.log("ok")}
                ></video>
            )}

            {downloadLink && (
                <video ref={playVideoRef} src={downloadLink}></video>
            )}

            <VectorDownLeft className="vectorDownLeft" />

            <VectorDownRight className="vectorDownRight" />

            {!isRecording && !downloadLink && (
                <RecorderIcon
                    onClick={startRecording}
                    className="RecorderIcon action-camera"
                />
            )}

            {downloadLink && (
                <PlayIcon
                    onClick={playVideo}
                    className="PlayIcon action-camera"
                />
            )}

            {!downloadLink && isRecording && (
                <StopIcon
                    onClick={stopRecording}
                    className="StopIcon action-camera"
                />
            )}

            <div className="content-info">
                {!verifiVariable(videoRef.current) && (
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        className="timer"
                    >
                        {timer.replace("00:", "")}

                        {/* {videoRef.current.currentTime} */}
                    </Typography>
                )}

                <Typography variant="subtitle1" gutterBottom className="played">
                    {downloadLink && "Reproducir video"}
                    {!isRecording && !downloadLink && "Iniciar grabación"}
                    {!downloadLink && isRecording && "Detener grabación"}
                </Typography>

                {downloadLink && (
                    <Trash2
                        onClick={removeVideo}
                        className="removeVideoButtom"
                    />
                )}
            </div>
        </div>
    );
};

export default VideoCapture;
