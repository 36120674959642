import React from 'react';
import { BsCloudDownload } from "react-icons/bs";
import { getUserToken } from "../storeSelector/storeSelector";
import { downloadContractDoc } from "../../utils/api";

const Listcarditem = (props) => {
    const {id, file, onClick} = props;
    const userToken = getUserToken();

    const downloadUniqFile = async (fileName) => {
        try {
            const response = await downloadContractDoc(fileName, userToken);
            console.log(response);
        } catch(error) {
            console.log(error);
        }
    }

    const returnItem = () => {
        return ( 
            <div key={id} onClick={onClick} className={"flex--between"}>
                <div className="flex--start">
                    <div className="list-unorder__bullet margin-right--sm">
                        <span>{file.orden + 1}</span>
                    </div>
                    {file.document}
                </div>
                <div className="flex--start">
                    <BsCloudDownload className="icon-pointer margin-left--sm" onClick={() => downloadUniqFile(file.document)} />
                </div>
            </div>
        )
    }

    return returnItem();
}

export default Listcarditem;
