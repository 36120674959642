import { forwardRef, Fragment, useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

import Btn from "../../../../buttons/Button";
import { ExpressProcessStyles } from "../../index.css";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteContractAlert = ({ openDeleteContract, deleteCurrentContract }) => {
    const styles = ExpressProcessStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (openDeleteContract > 0) handleClickOpen();
    }, [openDeleteContract]);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                className={styles.mainContainerDialog}
            >
                <DialogTitle sx={{ textAlign: "center" }}>
                    ¿Deseas eliminar este contrato?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            Si eliminas este contrato, no podrás recuperarlo.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    style={{ justifyContent: "center", paddingBottom: "20px" }}
                >
                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() => {
                            deleteCurrentContract();
                            handleClose();
                        }}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                            marginLeft: "30px !important",
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DeleteContractAlert;
