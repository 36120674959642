import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const ProvidesStyles = makeStyles(() => {

    return createStyles({
        goBackButton: {
            backgroundColor: "#00adea",
            '&:hover': {
                backgroundColor: "#00adea"
            }
        }
    })

});

