import React, { useEffect, useState } from "react";
import { DragSource } from "react-dnd";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

// import { FaPen } from "react-icons/fa";
import { itemTypes } from "../../../../../utils/enums";
import {
    signedBoxHeight,
    signedBoxWidth,
} from "../../../../../utils/validations";

// import { useMediaQuery, useTheme } from '@material-ui/core';

const DraggableBox = ({
    hideSourceOnDrag,
    id,
    left,
    top,
    connectDragSource,
    draggableContainerScale,
    isDragging,
    children,
    backgroundColor,
    color,
}) => {
    // const { signatureBoxes, setSignatureBoxes } = useContext(Context);
    // const theme = useTheme();
    // const mobileDown = useMediaQuery(theme.breakpoints.only('xs'));

    const [style, setStyle] = useState({
        position: "absolute",
        border: `2px dashed ${color}`,
        borderRadius: "3px",
        backgroundColor,
        padding: "0.5rem 1rem",
        cursor: "move",
        // width: mobileDown ? '34vw' : '180px',
        width: signedBoxWidth,
        // height: mobileDown ? '8vh' : '70px',
        height: signedBoxHeight,
        display: "flex",
        fontWeight: "bold",
        alignItems: "center",
        fontFamily: "Nunito",
        justifyContent: "center",
        color,
        boxSizing: "border-box",
        transformOrigin: "top left",
        // fontSize: mobileDown ? '58%' : '90%'
        fontSize: "90%",
    });

    useEffect(() => {
        const componentStateUpdated = {
            ...style,
            width: /* mobileDown ? '34vw' :  */ "20px",
            height: /* mobileDown ? '8vh' :  */ "10px",
            fontSize: /* mobileDown ? '58%' :  */ "90%",
        };
        setStyle(componentStateUpdated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const componentStateUpdated = { ...style };
        setStyle(componentStateUpdated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggableContainerScale]);

    /* const handleDeleteSignature = () => {

        const signatureBoxesUpdated = {  };//...signatureBoxes

        delete signatureBoxesUpdated[id];
        // setSignatureBoxes(signatureBoxesUpdated);

    }; */

    if (isDragging && hideSourceOnDrag) {
        return null;
    }

    return connectDragSource(
        // eslint-disable-next-line
        <div style={{ ...style, left, top }} role="DraggableBox">
            {children}
            {/* <FaPen onClick={() => handleDeleteSignature()} style={{ position: 'absolute', width: '20px', height: '20px', top: '0px', marginTop: '-20px', right: '0px', marginRight: '-20px' }} /> */}
        </div>
    );
};

export default DragSource(
    itemTypes.SIGNATURE_CONTAINER,
    {
        beginDrag(props) {
            const {
                id,
                left,
                top,
                title,
                draggableContainerScale,
                componentMarginTop,
                pdfViewerContainer,
                pageLocation,
            } = props;
            return {
                id,
                left,
                top,
                title,
                draggableContainerScale,
                componentMarginTop,
                pdfViewerContainer,
                pageLocation,
            };
        },
        canDrag: (props) => {
            const { canDrag } = props;
            return canDrag;
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        canDrag: false,
    })
)(DraggableBox);
