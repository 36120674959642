import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Link, Typography } from '@mui/material';

import { ReactComponent as IconMaxSecurity } from '../../../../assets/icons/icon-max-security.svg';
import { ReactComponent as IconHighSecurity } from '../../../../assets/icons/icon-high-security.svg';
import { ReactComponent as IconMidSecurity } from '../../../../assets/icons/icon-more-security.svg';
import { ReactComponent as IconGoodSecurity } from '../../../../assets/icons/icon-good-security.svg';
import { ReactComponent as IconCheck } from '../../../../assets/icons/icon-check-contained-green.svg';
import { ReactComponent as IconPen } from '../../../../assets/icons/icon_pen_outlined_gray.svg';
import { ReactComponent as IconCreditCard } from '../../../../assets/icons/icon-creditcard-outlined-gray.svg';
import { ReactComponent as IconWarning } from '../../../../assets/icons/icon-warning-red-contained.svg';

import { ProceduresHistoryStyles } from '../../index.css';
import { to } from '../../../../utils/enums';
import { theme } from '../../../../theme/theme';

const LastProceduresCard = ({
    autoID,
    contractID,
    contractType,
    IcontractTypeFeeID,
    iSignedCount,
    iSignerCount,
    processType,
    sPaymentStatus,
    status,
    tramitType
}) => {

    const [tramitTypeStyle, setTramitType] = useState({});
    const navegate = useNavigate();

    const styles = ProceduresHistoryStyles();

    const handleClickNavigate = () => {

        if (processType === 'NORMAL') {
            navegate(to.CONTRACT_DETAIL, {
                state: { sContractID: contractID }
            });
        } else if (processType === 'EXPRESS') {
            navegate(to.DETAIL_EXPRESS_PROCEDURE, {
                state: { sContractID: contractID },
            });
        } else if (processType === 'ANF') {
            navegate(to.ANF_DETAIL, {
                state: { sContractID: contractID },
            });
        };

    };

    useEffect(() => {
        if (tramitType === 'FIRMA') {
            setTramitType({
                borderLeft: '2px solid #DE7717',
                icon: <IconGoodSecurity />,
                procedureType: 'FES',
                to: ''
            });
        } else if (tramitType === 'FIRMA CON CERTIFICACION NOTARIAL') {
            setTramitType({
                borderLeft: {
                    borderLeft: "2px solid transparent",
                    background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(0deg, #dde13f, #DC8558) border-box",
                },
                icon: <IconMidSecurity />,
                procedureType: 'Certificación notarial',
                to: ''
            });
        } else if (tramitType === 'FIRMA CON PROTOCOLIZACION NOTARIAL') {
            setTramitType({
                borderLeft: {
                    borderLeft: "2px solid transparent",
                    background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(0deg, #7EDBAE, #dde13f) border-box",
                },
                icon: <IconHighSecurity />,
                procedureType: 'Protocolización notarial',
                to: ''
            });
        } else if (tramitType === 'ANF') {
            setTramitType({
                borderLeft: '2px solid #7EDBAE',
                icon: <IconMaxSecurity />,
                procedureType: 'ANF',
                to: ''
            });
        } else if (tramitType === 'FIRMA ELECTRONICA AVANZADA') {
            setTramitType({
                borderLeft: `2px solid ${theme.palette.primary.light}`,
                icon: <IconMaxSecurity />,
                procedureType: 'Firma electrónica avanzada',
                to: ''
            });
        }
    }, [tramitType]);

    return (
        <Grid item>
            <Box
                className={styles.frequentProcedures_Box}
                sx={{ borderLeft: tramitTypeStyle.borderLeft }}
            >
                <Box className={styles.frequentProcedures_Box_tramitType_Box}>
                    <Box className={styles.frequentProcedures_Box_tramitType}>
                        {tramitTypeStyle.icon}

                        <Typography className='text'>
                            {tramitTypeStyle.procedureType}
                        </Typography>
                    </Box>
                </Box>

                <Typography className={styles.frequentProcedures_Box_tramitType_Text}>
                    {contractType.charAt(0) + contractType.slice(1).toLowerCase()}
                </Typography>

                <Box className={styles.tramitInfo_Box}>
                    <Typography className={styles.tramitInfo_Box_Text}>
                        ID: {autoID}
                    </Typography>

                    <Box className={styles.tramitInfo_Box_Role}>
                        <Typography className={styles.tramitInfo_Box_Text}>
                            {iSignedCount}
                        </Typography>

                        <IconPen />
                        {status === 'SIGNED' || status === 'DELIVERED' || status === 'IN_NOTARY' || status === 'FINISHED' ? (
                            <IconCheck />
                        ) : (
                            <IconWarning />
                        )}
                    </Box>

                    <Box className={styles.tramitInfo_Box_Role}>

                        <IconCreditCard />

                        {sPaymentStatus === 'PAIDOUT' ? (
                            <IconCheck />
                        ) : (
                            <IconWarning />
                        )}

                    </Box>
                </Box>

                <Link
                    component="button"
                    variant="body2"
                    onClick={handleClickNavigate}
                    className={styles.frequentProcedures_Box_tramitType_link}
                >
                    Ir al trámite
                </Link>
            </Box>
        </Grid>
    )
}

export default LastProceduresCard