import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Avatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";

import { getUserInfo } from "../storeSelector/storeSelector";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import WhatsappIconSpeedDial from "../whatsappIconSpeedDial";
import { to, verifiVariable } from "../../utils/enums";

//import PopupStateNotifications from "../common/notifications";

/*const notifications = [
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: false
        },
        {
            title: "Titulo notificacion",
            date: "10/10/2022",
            description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
            read: true
        }
    ]*/

const Menutop = () => {
    const refInputControl = useRef();
    const {
        contract: {
            contractCreator: { contractListActive },
        },
        formANFTramit: { allAnfTramit },
    } = useSelector((state) => state);
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");

    const handleSearch = (event, value) => {
        setSearchValue(value);

        if (!verifiVariable(value)) {
            if (value["sProcess"] === "NORMAL") {
                navigate(to.CONTRACT_DETAIL, { state: value["sContractID"] });
            }

            if (value["sProcess"] === "EXPRESS") {
                navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                    state: { sContractID: value["sContractID"] },
                });
            }

            if (value["sProcess"] === "ANF") {
                navigate(to.ANF_DETAIL, {
                    state: { sContractID: value["sContractID"] },
                });
            }
        }
    };

    const arrayData = useMemo(() => {
        const data = [];

        const { message } = contractListActive;

        if (!verifiVariable(allAnfTramit)) {
            allAnfTramit.forEach((element) => {
                data.push({
                    sContractID: element["contractID"],
                    autoId: element["numberID"],
                    sProcess: "ANF",
                });
            });
        }

        if (!verifiVariable(message) && Array.isArray(message)) {
            message.forEach((element) => {
                data.push(element);
            });
        }

        return data;
    }, [contractListActive, allAnfTramit]);

    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }

        if (!verifiVariable(option.sTipoContrato)) {
            return `${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma}`;
        } else {
            return `${option.sContractID} - ${option.autoId}`;
        }
    };

    return (
        <div className="top-menu__container">
            <div className="top-menu__logo">
                <Logo className="logo-size--xs" />
            </div>
            <div className="top-menu__user-access">
                <div className="top-menu__searchbar">
                    <AutocompleteMui
                        getLimitTagsText={2}
                        fullWidth
                        autoHighlight
                        multiple={false}
                        freeSolo
                        options={arrayData}
                        value={searchValue}
                        isOptionEqualToValue={(option, value) =>
                            option.sContractID === value.sContractID
                        }
                        getOptionLabel={getOptionLabel}
                        onChange={handleSearch}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                ref={refInputControl}
                                {...params}
                                label="Buscar trámite"
                                variant="outlined"
                            />
                        )}
                    />
                </div>
                <div className="user-menu-notif">
                    {/*<PopupStateNotifications notifications={notifications} />*/}
                    {/* <FaCircle className="icon--xxs icon--red new-msg-alert" /> */}
                    {/* <FaBell className="icon--xs icon--grey-dark icon-pointer" /> */}
                    <WhatsappIconSpeedDial />
                </div>

                <div className="user-data-name">
                    <div className="name__container">
                        <strong className="user-name">{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                        <span className="user-rol">Administrator</span>
                    </div>
                    <Avatar alt="User name" />
                </div>
            </div>
        </div>
    );
};

export default Menutop;
