import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import { FaSearch } from "react-icons/fa";

import { ReactComponent as Logo } from "../../../assets/logo/logo.svg";
import { getUserInfo } from "../../storeSelector/storeSelector";
import { AppBarMenuStyles } from "./AppBarMenu.css";
// import PopupStateNotifications from "../../common/notifications";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import WhatsappIconSpeedDial from "../../whatsappIconSpeedDial";
import { to, verifiVariable } from "../../../utils/enums";


// eslint-disable-next-line
const notifications = [
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: false
    },
    {
        title: "Titulo notificacion",
        date: "10/10/2022",
        description: "Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500..",
        read: true
    }
];

const AppBarMenu = ({ appBarHeight }) => {
    const refInputControl = useRef();
    const {
        contract: {
            contractCreator: { contractListActive },
        },
        formANFTramit: { allAnfTramit },
    } = useSelector((state) => state);
    const styles = AppBarMenuStyles();
    const screenSizeHook = ScreenSizeHook();
    const userInfo = getUserInfo();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [showSearch, setShowSearch] = useState(false);


    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }

        if (!verifiVariable(option.sTipoContrato)) {
            return `${option.sContractID} - ${option.autoId} - ${option.sTipoContrato} - ${option.sTipoFirma}`;
        } else {
            return `${option.sContractID} - ${option.autoId}`;
        }
    };

    const handleSearch = (event, value) => {
        setShowSearch((value) => !value)
        setSearchValue(value);

        if (!verifiVariable(value)) {
            if (value["sProcess"] === "NORMAL") {
                navigate(to.CONTRACT_DETAIL, { state: value["sContractID"] });
            }

            if (value["sProcess"] === "EXPRESS") {
                navigate(to.DETAIL_EXPRESS_PROCEDURE, {
                    state: { sContractID: value["sContractID"] },
                });
            }

            if (value["sProcess"] === "ANF") {
                navigate(to.ANF_DETAIL, {
                    state: { sContractID: value["sContractID"] },
                });
            }
        }
    };

    const arrayData = useMemo(() => {
        const data = [];

        const { message } = contractListActive;

        if (!verifiVariable(allAnfTramit)) {
            allAnfTramit.forEach((element) => {
                data.push({
                    sContractID: element["contractID"],
                    autoId: element["numberID"],
                    sProcess: "ANF",
                });
            });
        }

        if (!verifiVariable(message) && Array.isArray(message)) {
            message.forEach((element) => {
                data.push(element);
            });
        }

        return data;
    }, [contractListActive, allAnfTramit]);

    return (
        <Box className={styles.mainContent}>

            <div style={{ height: showSearch ? 42 : appBarHeight }} className={styles.topUserContent}>

                <Logo className="logo-size--xs" />

                <Box className={styles.rightBlock}>

                    {/* !['xs'].includes(screenSizeHook) && */ 
                    (
                        <Box className={styles.iconsBlock}>

                            <FaSearch
                                style={{ marginRight: 10 }}
                                className={"search-icon icon--xs icon--grey-dark"}
                                onClick={() => setShowSearch((value) => !value)}
                            />

                            {/* <PopupStateNotifications notifications={notifications} /> */}

                        </Box>
                    )
                    }

                    <Box className={styles.userInfoContent}>

                        <WhatsappIconSpeedDial screen={screenSizeHook} />

                        {!['xs'].includes(screenSizeHook) && (<Box className={styles.personInfoContent}>
                            <strong className={styles.userName}>{`${userInfo.firstName} ${userInfo.lastName}`}</strong>
                            <span className={styles.userRol}>Administrator</span>
                        </Box>)}

                        <Avatar alt="User name" />
                    </Box>

                </Box>

            </div>

            {
                showSearch &&
                (<AutocompleteMui
                    getLimitTagsText={2}
                    fullWidth
                    autoHighlight
                    multiple={false}
                    freeSolo
                    options={arrayData}
                    value={searchValue}
                    isOptionEqualToValue={(option, value) => option.sContractID === value.sContractID}
                    getOptionLabel={getOptionLabel}
                    onChange={handleSearch}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            ref={refInputControl}
                            {...params}
                            label="Buscar trámite"
                            variant="outlined"
                        />
                    )}
                />)
            }

        </Box>

    );
}

export default AppBarMenu;