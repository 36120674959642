import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const DetailContracFileUploadStyles = makeStyles((theme) => {

    return createStyles({
        formContainer: {
            width: "100% !important"
        },
        mainContent: {
            display: 'flex',
            width: '87%',
            justifyContent: 'center',
            justifySelf: 'center',
            paddingTop: '70px',
            paddingBottom: '45px'
        },
        fileForm: {
            flex: "1"
        },
        titleHintStyle: {
            color: "quinary.xdark",
            fontWeight: "500",
            fontSize: "1.1rem",
            marginTop: "21px"
        },
        hintBodyLinkText: {
            mx: "3px"
        },
        hintListItem: {
            display: "block"
        },
        hintListContainer: {
            my: "10px"
        },
        titleStyles: {
            color: "black.main",
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        fileNameStyles: {
            color: "black.main",
            fontWeight: "500"
        }

    });

});