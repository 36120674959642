import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import Typography from '@mui/material/Typography';
import ClipLoader from 'react-spinners/ClipLoader';
import BarLoader from 'react-spinners/BarLoader';

import { colorsTheme } from "../../utils/enums";

const overrideClip = css`
    display: block;
    margin: 0 auto;
    border-color: ${colorsTheme.PRIMARY_LIGHT};
`;

const overrideBar = css`
    display: block;
    margin: 0 auto;
    background-color: ${colorsTheme.QUINARY_LIGHT};
`;

const Spinner = (props) => {
    const { loading, type, opacity, text } = props;

    const [bodyHeight, setBodyHeight] = useState(0);

    const renderSpinner = () => {
        switch (type) {
            case "clip":
                return <ClipLoader color={colorsTheme.PRIMARY_LIGHT} loading={loading} css={overrideClip} size={30} />
            case "bar":
                return <BarLoader color={colorsTheme.PRIMARY_LIGHT} loading={loading} css={overrideBar} height={5} width={150} />
            default:
                return <ClipLoader color={colorsTheme.PRIMARY_LIGHT} loading={loading} css={overrideClip} size={30} />
        }
    }



    useEffect(() => {
        const updateBodyHeight = () => {
            setBodyHeight(document.body.clientHeight);
        };

        // Actualiza la altura al montar el componente
        updateBodyHeight();

        // Agrega un listener para actualizar la altura si la ventana se redimensiona
        window.addEventListener('resize', updateBodyHeight);

        // Limpia el listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', updateBodyHeight);
        };
    }, []);

    return (
        <div className={`${opacity ? "opacity--white" : ""} spin__container`} style={{ flexDirection: 'column', width: '100vw', height: bodyHeight + 1024 }}>
            <Typography variant="subtitle1" sx={{ color: 'gray', fontSize: '1.5rem', display: text ? 'flex' : 'none' }}>
                {text}
            </Typography>
            {renderSpinner()}
        </div>
    );
}

export default Spinner;
