import "regenerator-runtime/runtime";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as actions from "../actions/apiMerge";

const uri = process.env.REACT_APP_REPAIR_DOCS;

const prod = process.env.REACT_APP_PROD;

const apiMerge =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== actions.apiCallStart.type) return next(action);

        const {
            url,
            method,
            data,
            onStart,
            onSuccess,
            onError,
            contentType,
            authorization,
            onUploadProgress,
        } = action.payload;

        if (onStart) dispatch({ type: onStart });

        next(action);
        try {
            if (![null, undefined, ""].includes(authorization)) {
                // eslint-disable-next-line
                const timeNow = Math.floor(new Date().getTime() / 1000);

                const { exp } = jwt_decode(
                    authorization.replace("Bearer ", "")
                );

                if (timeNow >= exp) {
                    localStorage.removeItem("persist:root");
                    localStorage.removeItem("session");

                    window.location.href = `${window.location.protocol}//${
                        window.location.hostname
                    }${prod === "true" ? "" : ":" + window.location.port}`;
                }
            }

            const response = await axios.request({
                baseURL: uri,
                url,
                method,
                data,
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    const percentComplete =
                        (progressEvent.loaded / progressEvent.total) * 100;

                    dispatch({
                        type: onUploadProgress,
                        payload: percentComplete,
                    });
                },
                headers: {
                    "Content-type": contentType
                        ? contentType
                        : "application/json",
                    Authorization: authorization,
                },
            });

            dispatch(actions.apiCallSuccess(response.data));

            if (onSuccess) {
                dispatch({ type: onSuccess, payload: response.data });
            }
        } catch (error) {
            console.error("error", error.message);

            dispatch(actions.apiCallFail(error.message));

            if (onError) {
                dispatch({ type: onError, payload: error.message });
            }
        }
    };

export default apiMerge;
