import React, { useEffect, useState } from 'react'

import Steps from '../steps';
import { ModalContractCreatedAndContractStepsStyles } from '../../index.css';

const MainProgresBar = ({
    steps,
    active,
    stepsTitle }) => {

    const [width, setWidth] = useState(0);

    const styles = ModalContractCreatedAndContractStepsStyles();

    useEffect(() => {
        active < steps && setWidth((100 / steps) * active - 1)
        active === 0 && setWidth(0);
    }, [steps, active])

    const arr = [];
    
    for (let i = 0; i < steps; i++) {
        arr.push(<Steps key={i}
            className={i <= active ? `${styles.stepperProgress_stepNumberBox} ${styles.stepperProgress_stepNumberBox_active}` : styles.stepperProgress_stepNumberBox}
            active={active}
            steps={steps}
            index={i}
        >
            {{ index: i, text: stepsTitle[i] }}
        </Steps>)
    }
    return (
        <div className={styles.stepperProgress_Container}>
            <div className={styles.stepperProgress_Content}>
                <div className={styles.stepperProgress_steppersBox}>
                    <div className={styles.stepperProgress_progressBar} style={{ width: width + "%" }}></div>
                    <div className={styles.stepperProgress_progressBar_2}></div>
                    {arr}
                </div>
            </div>
        </div>
    )
}

export default MainProgresBar