import { Fragment } from "react";
import { Link } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { ResetPasswordForm } from "../../components";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";

export default function ResetAccount() {
    const screenSizeHook = ScreenSizeHook();

    return (
        <Fragment>

            <div className="login-wrapper">

                <div className="start-session__container">

                    <div className="start-session-content__container flex--center flex--column">

                        <Grid container>

                            <Grid item xs={12} sx={{ marginRight: 2, marginLeft: 2 }}>

                                <Link to="/">
                                    <Logo className="logo-size--md" style={{ width: '100%' }} />
                                </Link>

                            </Grid>

                        </Grid>

                        <h1 className="title--head text-align--center title">
                            Restablece la contraseña de tu cuenta
                        </h1>

                        <ResetPasswordForm />

                    </div>

                </div>

                {!['xs', 'sm'].includes(screenSizeHook) && (<div className="showcase__container login-bg" />)}

            </div>

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}

        </Fragment>
    );
}
