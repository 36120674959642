import _ from "lodash";
import { store } from "../../store/storeConfig";

const getUpdatedStore = () => {
    store.subscribe(() => store.getState())
}

const getUserToken = () => {
    const { login } = store.getState().session;

    if (!_.isEmpty(login)) {
        return login.user.token;
    }
};

const getUserInfo = () => {
    const { login } = store.getState().session;

    if (!_.isEmpty(login)) {
        return login.user;
    }
};

const getPreviousSignersList = () => {
    const { previousSignatoriesList } = store.getState().contract.signatories;

    return previousSignatoriesList;
};


const getContractListActive = () => {
    const { contractListActive } = store.getState().contract.contractCreator;

    if (!_.isEmpty(contractListActive)) {
        return contractListActive.message;
    }
};

const getContractList = () => {
    const { contractList } = store.getState().contract.contractCreator;

    if (!_.isEmpty(contractList)) {
        return contractList.message;
    }
};

const getContractCreatedID = () => {
    const { contractCreator } = store.getState().contract;

    if (!_.isEmpty(contractCreator)) {
        return contractCreator;
    }
};

const getSessionAuth = () => {
    const { login } = store.getState().session;

    if (login.user.logged) {
        return true;
    } else {
        return false;
    }
}

export { getUserToken, getContractList, getContractCreatedID, getUserInfo, getSessionAuth, getUpdatedStore, getPreviousSignersList, getContractListActive };
