import { useState } from "react";
import Iframe from 'react-iframe';
import { FaTimes } from "react-icons/fa";

import Popover from '@mui/material/Popover';
import { HelpTramitsStyles } from "./index.css";
import Navlink from "../routing/NavLink";

const HelpTramits = ({ Icon, url, title }) => {
    const styles = HelpTramitsStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {Icon && (<Icon
                aria-describedby={id}
                aria-haspopup="true"
                onClick={handleClick}
            />)}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={styles.popover}

                anchorReference="anchorPosition"
                anchorPosition={{ top: '10vw', left: '30vw', }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}

            >
                <div className={styles.divIcon}>
                    <FaTimes onClick={handleClose} id="icon-close" className="icon--red icon--md" />
                </div>
                <Iframe url={url} />

            </Popover>

            {title && (<Navlink
                onClick={handleClick}
                to={"#"}
                className="navlink"
                inactiveClassName="navlink--inactive"
                activeClassName="navlink--active"
            >
                {title}
            </Navlink>)}

        </>
    );
}

export default HelpTramits;