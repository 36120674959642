import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart as apiCallStartMerge } from "../actions/apiMerge";

const slice = createSlice({
    name: "formTramit",
    initialState: {
        fileName: null,
        attachedCDV: null,
        contractName: '',
        docPdfBase64Detail: null,
        editSigner: undefined,
        firms: {},
        firstStep: {},
        fourthStep: undefined,
        loading: false,
        previewPdfSigned: undefined,
        secondStep: [],
        thirdStep: {},
        uploadProgress: 0
    },
    reducers: {
        FORM_USERS_CLEAN_DETAIL_DOC: (state, action) => {
            state.docPdfBase64Detail = null;
        },

        FORM_USERS_MERGE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;

            state.thirdStep = Object.assign(state.thirdStep, {
                docPdfBase64: action.payload["data"]["file"],
            });

            state.loading = false;
        },

        FORM_USERS_EDIT_MERGE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;

            state.docPdfBase64Detail = action.payload["data"]["file"];

            state.loading = false;
        },

        FORM_USERS_UPLOAD_PROGRESS: (state, action) => {
            state.uploadProgress = Math.floor(action.payload);
        },

        FORM_USERS_REQUEST: (state, action) => {
            state.docPdfBase64Detail = null;
            state.loading = true;
        },

        FORM_USERS_REGISTER_SAVE_INFO: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },

        FORM_GET_EDIT_SIGNER: (state, action) => {
            state.editSigner = action.payload;
        },

        FORM_CLEAR_EDIT_SIGNER: (state, action) => {
            state.editSigner = undefined;
        },

        FORM_USERS_REGISTER_PURGE_STATE: (state, action) => {
            state.firstStep = {};
            state.secondStep = [];
            state.thirdStep = {};
            state.fourthStep = undefined;
            state.previewPdfSigned = undefined;
            state.firms = {};
            state.editSigner = undefined;
            state.attachedCDV = null;
            state.contractName = ''
        },

        FORM_USERS_FAIL: (state, action) => {
            state.uploadProgress = 0;
            state.loading = false;
        },
    },
});

export const {
    FORM_USERS_CLEAN_DETAIL_DOC,
    FORM_USERS_UPLOAD_PROGRESS,
    FORM_USERS_REQUEST,
    FORM_USERS_FAIL,
    FORM_CLEAR_EDIT_SIGNER,
    FORM_GET_EDIT_SIGNER,
    FORM_USERS_REGISTER_SAVE_INFO,
    FORM_USERS_MERGE_SUCCESS,
    FORM_USERS_EDIT_MERGE_SUCCESS,
    FORM_USERS_REGISTER_PURGE_STATE,
} = slice.actions;

export const repairDoc = (data, token, edit) => {
    return apiCallStartMerge({
        url: `/pdf/repair`,
        method: "POST",
        data,
        onStart: FORM_USERS_REQUEST.type,
        onSuccess: edit
            ? FORM_USERS_EDIT_MERGE_SUCCESS.type
            : FORM_USERS_MERGE_SUCCESS.type,
        onError: FORM_USERS_FAIL.type,
        onUploadProgress: FORM_USERS_UPLOAD_PROGRESS.type,
        authorization: token,
    });
};

export const cleanDetailDoc = () => {
    return { type: FORM_USERS_CLEAN_DETAIL_DOC.type, payload: undefined };
};

export const getEditSigner = (data) => {
    return { type: FORM_GET_EDIT_SIGNER.type, payload: data };
};

export const clearEditSigner = () => {
    return { type: FORM_CLEAR_EDIT_SIGNER.type, payload: undefined };
};

export const saveFormInfo = (data) => {
    return { type: FORM_USERS_REGISTER_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: FORM_USERS_REGISTER_PURGE_STATE.type, payload: null };
};

export default slice.reducer;
