import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Box, Typography, Grid, Button, Switch, FormControlLabel, TextField, FormHelperText,
    FormControl, Radio, RadioGroup, Autocomplete, Stack, Tooltip, Paper,
    Backdrop,
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ManageCouponsTypeStyless } from '../index.css';
import { theme } from '../../../../theme/theme';

import { ReactComponent as IconCalendar } from '../../../../assets/icons/icon-calendar-outlined.svg';
import { ReactComponent as IconBack } from '../../../../assets/icons/arrow-left-circularButtom.svg';
import { ReactComponent as IconHouseDisabled } from '../../../../assets/icons/icon-house-disabled.svg';

import dayjs from 'dayjs';
import { to } from '../../../../utils/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCouponsType } from '../../../../store/coupons/couponsType';
import { validation } from '../../../../utils/validations';
import {
    createCoupon,
    couponsSaveInfo
} from '../../../../store/coupons/coupons';
import InfoMessage from '../../../infoMessage';
import { getUsersStorage, userListSaveInfo } from '../../../../store/users/userList';
import { couponsUserSaveInfo, createCouponUser } from '../../../../store/coupons/couponsUser';

//components styles
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    marginLeft: '.5rem',
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const CustomButton = styled(Button)((props) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white.main,
    textTransform: 'none',
    padding: '.5rem 1.5rem',
    margin: '2rem 0',
    fontFamily: "PoppinsBold",
    width: '10rem',
    height: '3rem',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

const ProSpan = styled('span')({
    display: 'inline-block',
    height: '1em',
    width: '1em',
    verticalAlign: 'middle',
    marginLeft: '0.3em',
    marginBottom: '0.08em',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
});

const AddCoupons = ({ couponEdit }) => {

    const styles = ManageCouponsTypeStyless();

    //Date picker

    const today = dayjs();

    const openIcon = () => {
        return <IconCalendar />
    };

    function Label({ componentName, isProOnly }) {
        const content = (
            <span>
                <strong>{componentName}</strong>
            </span>
        );

        if (isProOnly) {
            return (
                <Stack direction="row" spacing={0.5} component="span">
                    <Tooltip title="Included on Pro package">
                        <a
                            href="https://mui.com/x/introduction/licensing/#pro-plan"
                            aria-label="Included on Pro package"
                        >
                            <ProSpan />
                        </a>
                    </Tooltip>
                    {content}
                </Stack>
            );
        }

        return content;
    }

    //hooks
    const {
        control,
        register,
        handleSubmit,
        //reset,
        //formState: { errors },
        setValue,
        watch,
        //clearErrors
    } = useForm();
    const token = useSelector(state => state.session.login.user.token);
    const {
        allCouponsTypes,
        status: counponsTypeStatus,
        //message: couponsTypeMessage
    } = useSelector(state => state.coupons.couponsType);
    const {
        coupon,
        status: couponsStatus,
        message: couponsMessage,
        loading: couponsLoading
    } = useSelector(state => state.coupons.coupons);

    const {
        listUsers,
        //loading: userListLoading,
        status: userListStatus,
        //message: userListMessage,
    } = useSelector(state => state.users);

    const {
        //loading: couponsUserLoading,
        //message: couponsUserMessage,
        status: couponsUserStatus
    } = useSelector(state => state.coupons.couponsUser);

    const navegate = useNavigate();
    const dispatch = useDispatch();
    const [dataOptions, setDataOptions] = useState([]);
    const [createCouponQuery, setCreateCouponQuery] = useState({});
    const [getUsersListQuery, setGetUsersListQuery] = useState({});
    const [getUser, setGetUser] = useState({});
    const [createCouponUserQuery, setCreateCouponUserQuery] = useState({});
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({});

    const { userType/* , couponType  */ } = watch();

    //actions

    const options = [
        {
            label: 'Activo',
            value: 'ACTIVE'
        },
        {
            label: 'Inactivo',
            value: 'INACTIVE'
        }
    ];

    const renderOptions = (props, option) => {
        return (
            <li
                {...props}
                key={option.coupon_type_id}>
                {option.coupon_type_name}
            </li>
        );
    };

    const { userEmail, status } = watch();

    //form data
    const onSubmit = (data) => {
        setGetUsersListQuery({});
        setGetUser({});

        const newData = {
            name: data.couponName.trim(),
            code: data.couponCode.trim(),
            coupon_type_id: Number(data.couponType),
            begin_date: dayjs(data.beginDate).format('YYYY/MM/DD'),
            expire_date: dayjs(data.expDate).format('YYYY/MM/DD'),
            status: data.status
        };

        dispatch(createCoupon(newData, token));
    };

    //effecst
    useEffect(() => {
        const today = dayjs();
        //setValue('individualUse', couponEdit?.individualUse || false);
        //setValue('userType', couponEdit?.userType || 'other');
        //setValue('userEmail', couponEdit?.userEmail || '');
        setValue('couponType', couponEdit?.couponType || '');
        setValue('couponID', couponEdit?.couponID || '');
        setValue('discountValueTypeSelected', couponEdit?.discountValueTypeSelected || '');
        setValue('discountValue', couponEdit?.discountValue || '');
        setValue('beginDate', couponEdit?.expDate || today)
        setValue('expDate', couponEdit?.expDate || today)
        //setValue('signType', couponEdit ? couponEdit.signType : '');
        //setValue('tramitType', couponEdit ? couponEdit.tramitTypecouponName : '');
    }, [couponEdit, setValue]);

    useEffect(() => {
        const elements = document.querySelectorAll('.MuiStack-root.css-11a8txn-MuiStack-root');
        if (elements) {
            console.log(elements)
            elements.forEach(ele => ele.style.width = '100%')
        };
    }, []);

    useEffect(() => {
        setLoader(true);
        dispatch(getAllCouponsType(token));
    }, [dispatch, token]);

    useEffect(() => {
        if (counponsTypeStatus === 'success') {
            setDataOptions(allCouponsTypes);
            setLoader(false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counponsTypeStatus,]);

    useEffect(() => {
        if (couponsStatus === 'success') {
            setCreateCouponQuery({
                status: 'success',
                type: 'success',
                title: 'Cupón creado con éxito',
                message: 'El cupón se ha creado con éxito',
                iconSize: 'sm'
            });

            dispatch(getUsersStorage(token));

        } else if (couponsStatus === 'fail') {
            if (couponsMessage?.err === 460) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'No estás autorizado para realizar esta operación',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4615) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'El código del cupón debe ser único',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4722) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'El campo de nombre de cupón no debe estar vacío y sólo puede tener letras y números',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4633) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'El campo de código de cupón no debe estar vacío, solo puede tener letras y números y debe tener entre 6 y 12 caracteres',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4631) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'No se ha detectado el ID tipo de cupón, contacta al soporte del sistema',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4639) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'El ID tipo de cupón no existe',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4643) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'El valor de la fecha de inicio es inválido',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4702) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'La fecha de expiración es inválida',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4698) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'Error en el campo de estatus',
                    iconSize: 'sm'
                });
            } else if (couponsMessage?.[0]?.err === 4648) {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'La fecha de inicio no puede ser posterior a la fecha de expiración',
                    iconSize: 'sm'
                });
            } else {
                setCreateCouponQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema creando el cupón',
                    message: 'Error al crear el cupón, intenta en unos instantes o contacta a soporte',
                    iconSize: 'sm'
                });
            };
        };

        const clearStateInfo = () => {
            dispatch(couponsSaveInfo({
                indexe: 'loading',
                value: false
            }));
            dispatch(couponsSaveInfo({
                indexe: 'status',
                value: ''
            }));
            dispatch(couponsSaveInfo({
                indexe: 'message',
                value: {}
            }));
            setCreateCouponQuery({});
        };

        const timeOut = setTimeout(() => {
            clearStateInfo();
        }, 5000);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponsStatus])

    useEffect(() => {
        if (userListStatus === 'success') {
            if (listUsers.length > 0) {
                const userID = listUsers.find(ele => ele.sEmail === userEmail);
                if (userID) {
                    setUser(userID);
                    const newData = {
                        coupon_id: coupon.coupon_id,
                        user_id: userID.iUserID,
                        contract_id: '',
                        status: status,
                    };
                    dispatch(createCouponUser(newData, token));
                } else {
                    setGetUser({
                        status: 'fail',
                        type: 'error',
                        title: 'Ocurrió un problema',
                        message: 'No se entrontró un usuario con este correo electrónico por lo que el cupón generado no se pudo asignar al usuario',
                        iconSize: 'sm'
                    });
                };
            } else {
                setGetUsersListQuery({
                    status: 'fail',
                    type: 'error',
                    title: 'Ocurrió un problema',
                    message: 'Error al optener la lista de usuarios, intenta en unos instantes y si el problema persiste, contactata a soporte',
                    iconSize: 'sm'
                });
            }
        } else if (userListStatus === 'fail') {
            setGetUsersListQuery({
                status: 'fail',
                type: 'error',
                title: 'Ocurrió un problema',
                message: 'Error al optener la lista de usuarios, intenta en unos instantes y si el problema persiste, contactata a soporte',
                iconSize: 'sm'
            });
        };

        const clearStateInfo = () => {
            dispatch(userListSaveInfo({
                indexe: 'loading',
                value: false
            }));
            dispatch(userListSaveInfo({
                indexe: 'status',
                value: null
            }));
            dispatch(userListSaveInfo({
                indexe: 'message',
                value: []
            }));
            setGetUsersListQuery({});
            setGetUser({});
        };

        const timeOut = setTimeout(() => {
            clearStateInfo();
        }, 5000);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userListStatus, dispatch]);

    useEffect(() => {
        if (couponsUserStatus === 'success') {
            setCreateCouponUserQuery({
                status: 'success',
                type: 'success',
                title: 'Se ha asignado el cupon',
                message: `El cupon se ha asignado correctamente al usuario ${user.sFirstName.split(' ')[0]}, email: ${user.sEmail}`,
                iconSize: 'sm'
            })
        } else if (couponsUserStatus === 'fail') {
            setCreateCouponUserQuery({
                status: 'fail',
                type: 'error',
                title: 'Ocurrió un problema',
                message: 'Ocurrió un error intentado asignar el cupon al usuario, intentanuevamente y si el problema persiste, comunicate con soporte',
                iconSize: 'sm'
            });
        };

        const clearStateInfo = () => {
            dispatch(couponsUserSaveInfo({
                indexe: 'loading',
                value: false
            }));
            dispatch(couponsUserSaveInfo({
                indexe: 'status',
                value: ''
            }));
            dispatch(couponsUserSaveInfo({
                indexe: 'message',
                value: {}
            }));
            setCreateCouponUserQuery({});
        };

        const timeOut = setTimeout(() => {
            clearStateInfo();
        }, 5000);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponsUserStatus, dispatch]);

    if (loader) {
        return (
            <Box>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Box>
        )
    }

    return (
        <Box className={styles.container}>

            <Grid>
                <Box className={styles.goBack_box}>
                    <IconBack onClick={() => navegate(to.MANAGE_COUPONS)} />
                    <Typography onClick={() => navegate(to.MANAGE_COUPONS)} >Volver atrás</Typography>
                </Box>

                <Box className={styles.breadcrumbs_box}>
                    <Typography>Te encuentras aqui: </Typography>

                    <Paper elevation={1} className={styles.breadcrumbs_item_disabled}>
                        <IconHouseDisabled />
                        <Typography>Gestión de cupones</Typography>
                    </Paper>

                    /

                    <Paper elevation={1} className={styles.breadcrumbs_item_active}>
                        <Typography>Agregar nuevo</Typography>
                    </Paper>
                </Box>
            </Grid>

            <Typography fontSize={'1.5rem'} margin={'1.5rem 0'}>
                {couponEdit
                    ? 'Modificar cupón'
                    : 'Agregar nuevo cupón'}
            </Typography>

            <Grid className={styles.formStyles}>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Grid container>

                        <Grid item xs={12} className={styles.form_box}>
                            <Box className={styles.formCheckSection}>
                                <Typography className={styles.form_boldText}>Uso individual</Typography>
                                <AntSwitch
                                    onChange={e => setValue('individualUse', e.target.checked)}
                                    checked={watch('individualUse')} />
                            </Box>
                            <Typography className={styles.form_regularText}>Activa esta opción si el cupón no puede ser utilizado en combinación con otros cupones</Typography>
                        </Grid>

                        <Grid>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                defaultValue={(couponEdit && couponEdit['userType']) ? couponEdit['userType'] : 'customed'}
                            >
                                <Box>
                                    <FormControlLabel
                                        value="perUser"
                                        control={<Radio style={{ color: theme.palette.primary.light }} />}
                                        label="Por usuario"
                                        {...register("userType", {
                                            required: 'Debes seleccionar una opción'
                                        })}
                                    />
                                    <FormControlLabel
                                        value="customed"
                                        control={<Radio style={{ color: theme.palette.primary.light }} />}
                                        label="Personalizado"
                                        {...register("userType", {
                                            required: 'Debes seleccionar una opción'
                                        })}
                                    />
                                </Box>
                            </RadioGroup>
                        </Grid>

                        {userType && userType === 'perUser' &&
                            <Grid item xs={12} className={styles.form_box}>
                                <Controller
                                    name='userEmail'
                                    control={control}
                                    rules={{
                                        required: 'Este campo es requerido',
                                        pattern: {
                                            value: validation.email,
                                            message: 'Email incorrecto'
                                        }
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={!!fieldState.error}
                                            >
                                                <label htmlFor='email'>
                                                    <p className={styles.form_boldText}>
                                                        Correo del usuario
                                                    </p>
                                                </label>

                                                <TextField
                                                    id='email'
                                                    autoComplete='email'
                                                    type='text'
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder='Ingresa el correo del usuario'
                                                    color={
                                                        fieldState.error ? 'error' : 'success'
                                                    }
                                                    focused={
                                                        fieldState.isTouched || fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    hidden={
                                                        fieldState.error ? false : true
                                                    }
                                                >
                                                    {fieldState.error ? fieldState.error.message : null}
                                                </FormHelperText>
                                            </FormControl>
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='couponType'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido'
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={!!fieldState.error}
                                    >
                                        <label htmlFor='combo-box-demo'>
                                            <p className={styles.form_boldText}>
                                                Tipo de cupón
                                            </p>
                                        </label>

                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={dataOptions}
                                            getOptionLabel={option => option.coupon_type_name}
                                            value={dataOptions.find(option => option.coupon_type_id === value || null)}
                                            onChange={(event, newValue) => {
                                                onChange(newValue ? newValue.coupon_type_id : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Selecciona una opcion"
                                                    error={!!fieldState.error}
                                                    color={fieldState.error ? 'error' : 'success'}
                                                />
                                            )}
                                            renderOption={renderOptions}
                                        /* filterOptions={(options, state) => {
                                            options.filter(option =>
                                                option.coupon_type_name.toLowerCase().includes(state.inputValue.toLocaleLowerCase())
                                            )
                                        }} */
                                        //freeSolo
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error ? fieldState.error.message : null}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='couponName'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido',
                                    pattern: {
                                        value: validation.nombre,
                                        message: 'No están permitidos algunos caracteres especiales.'
                                    },
                                    maxLength: {
                                        value: 48,
                                        message: 'Máximo de 48 caracteres permitido.'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label htmlFor='couponName'>
                                                <p className={styles.form_boldText}>
                                                    Nombre del cupón
                                                </p>
                                            </label>

                                            <TextField
                                                id='couponName'
                                                type='text'
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                value={value}
                                                placeholder='Ingresa nombre del tipo de cupón'
                                                color={
                                                    fieldState.error ? 'error' : 'success'
                                                }
                                                focused={
                                                    fieldState.isTouched || fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error ? false : true
                                                }
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='couponCode'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido',
                                    maxLength: {
                                        value: 48,
                                        message: 'Máximo de 48 caracteres permitido.'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={
                                                fieldState.error ? true : false
                                            }
                                        >
                                            <p className={styles.form_boldText}>
                                                Código del cupón
                                            </p>

                                            <TextField
                                                type='text'
                                                error={
                                                    fieldState.error ? true : false
                                                }
                                                ref={ref}
                                                onBlur={onBlur}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    const formattedValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                                    setValue('couponID', formattedValue);
                                                }}
                                                value={value}
                                                placeholder='Ingresa código'
                                                color={
                                                    fieldState.error ? 'error' : 'success'
                                                }
                                                focused={
                                                    fieldState.isTouched || fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error ? false : true
                                                }
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>

                        {/* <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='discountValue'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido'
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={
                                                fieldState.error ? true : false
                                            }
                                        >
                                            <p className={styles.form_boldText}>
                                                Descuento (%)
                                            </p>

                                            <TextField
                                                type='text'
                                                error={
                                                    fieldState.error ? true : false
                                                }
                                                ref={ref}
                                                onBlur={onBlur}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    let formattedValue = e.target.value.replace(/[^\d,.]/g, '');
                                                    if (isNaN(formattedValue) || formattedValue < 1) {
                                                        formattedValue = 1
                                                    }
                                                    if (formattedValue > 100) {
                                                        formattedValue = 100
                                                    }
                                                    setValue('discountValue', `${formattedValue}%`);
                                                }}
                                                value={value}
                                                placeholder='Ingresa monto'
                                                color={
                                                    fieldState.error ? 'error' : 'success'
                                                }
                                                focused={
                                                    fieldState.isTouched || fieldState.isDirty
                                                }
                                                fullWidth
                                            />

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error ? false : true
                                                }
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid> */}

                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='beginDate'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido'
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer
                                                    components={[
                                                        'Fecha de inicio'
                                                    ]}
                                                >
                                                    <DemoItem label={<Label componentName="Fecha de inicio" valueType="date" />}>
                                                        <DatePicker
                                                            minDate={today}
                                                            slots={{ openPickerIcon: openIcon }}
                                                            onChange={e => {
                                                                onChange(e);
                                                                setValue('beginDate', e.$d);
                                                            }}
                                                            value={value}
                                                            format='DD/MM/YYYY'
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error ? false : true
                                                }
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name='expDate'
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido'
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            {/* <p className={styles.form_boldText}>
                                                Descuento (%)
                                            </p> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer
                                                    components={[
                                                        'Fecha de expiración'
                                                    ]}
                                                >
                                                    <DemoItem label={<Label componentName="Fecha de expiración" valueType="date" />}>
                                                        <DatePicker
                                                            minDate={today}
                                                            slots={{ openPickerIcon: openIcon }}
                                                            onChange={e => {
                                                                onChange(e);
                                                                setValue('expDate', e.$d);
                                                            }}
                                                            value={value}
                                                            format='DD/MM/YYYY'
                                                        />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider>

                                            <FormHelperText
                                                hidden={
                                                    fieldState.error ? false : true
                                                }
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} className={styles.form_box}>
                            <Controller
                                name="status"
                                control={control}
                                rules={{
                                    required: 'Este campo es requerido',
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState
                                }) => (
                                    <FormControl
                                        fullWidth
                                        error={!!fieldState.error}
                                    >
                                        <label htmlFor='country-customized-option-demo'>
                                            <p className={styles.form_boldText}>
                                                Estatus
                                            </p>
                                        </label>

                                        <Autocomplete
                                            id="country-customized-option-demo"
                                            options={options}
                                            getOptionLabel={(option) => option.label}
                                            value={options.find(option => option.value === value) || null}
                                            onChange={(event, newValue) => {
                                                onChange(newValue ? newValue.value : '');
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Selecciona una opción"
                                                    error={!!fieldState.error}
                                                />
                                            )}
                                        />

                                        <FormHelperText
                                            hidden={
                                                fieldState.error ? false : true
                                            }
                                        >
                                            {fieldState.error ? fieldState.error.message : null}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>

                    {
                        createCouponQuery?.status && (
                            <InfoMessage
                                type={createCouponQuery.type}
                                title={createCouponQuery.title}
                                message={createCouponQuery.message}
                                iconSize={createCouponQuery.iconSize}
                            />
                        )
                    }
                    {
                        getUsersListQuery?.status === 'fail' && (
                            <InfoMessage
                                type={getUsersListQuery.type}
                                title={getUsersListQuery.title}
                                message={getUsersListQuery.message}
                                iconSize={getUsersListQuery.iconSize}
                            />
                        )
                    }
                    {
                        getUser?.status && (
                            <InfoMessage
                                type={getUser.type}
                                title={getUser.title}
                                message={getUser.message}
                                iconSize={getUser.iconSize}
                            />
                        )
                    }
                    {
                        createCouponUserQuery?.status && (
                            <InfoMessage
                                type={createCouponUserQuery.type}
                                title={createCouponUserQuery.title}
                                message={createCouponUserQuery.message}
                                iconSize={createCouponUserQuery.iconSize}
                            />
                        )
                    }

                    <CustomButton type='submit'>
                        {couponsLoading ?
                            <CircularProgress color='white' size={30} /> :
                            'Guardar datos'
                        }
                    </CustomButton>
                </form>
            </Grid>

        </Box>
    )
}

export default AddCoupons