import React from "react";

import { Box } from "@mui/material";

import ModalProviderDelete from "../../molecules/modalProviderDelete";
import ModalEditProvider from "../../molecules/modalEditProvider";
import ModalAtachTypeContractToProvider from "../../molecules/modalAtachTypeContractToProvider";
import ModalAssocDocs from "../../molecules/modalAssocDocs";

const RenderActions = ({ row }) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                alignContent: "center",
                justifyContent: "space-around",
            }}
            className="flex--1 flex--center"
        >
            <ModalAtachTypeContractToProvider proveedor={row} />

            <ModalEditProvider provider={row} />

            <ModalProviderDelete provider={row} />

            <ModalAssocDocs provider={row} />
        </Box>
    );
};

export default RenderActions;
