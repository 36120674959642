import axios from "axios";
// import { api } from "../../../../utils/enums";
// const uri = api.BASE_URI;


export const mergeDocumentsOptions2 = async (body) => {
    try {
        const response = await axios.post(
            `https://apis.firmavirtual.legal/doc-consolidator-api/merge?fileInfos=[]`,
            body,
            {
                headers: {
                    Uuid: '591d49dd-5987-4038-9f05-f67d1f32c482'
                },
                responseType: 'blob'
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const mergeDocuments = async (userToken, body) => {
    try {
        const response = await axios.post(
            `https://merge.firmavirtual.com/pdf/sort`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};