import React, { useEffect, useState } from 'react';

import { default as AutocompleteMui } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



const Autocomplete = ({ sx, customClass, id, onChangeValue, onSeledted, name, arrayValue, freeSolo, placeholder, indexLabel, onChangeAutoComplete, typeInput }) => {

  const [inputValue, setInputValue] = useState(null);
  const [newInputValue, setNewInputValue] = useState('');


  useEffect(() => {// selectedValue

    const findItem = arrayValue.find((item) => item[indexLabel] === inputValue);

    if (findItem) {
      onSeledted(findItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    onChangeValue(newInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newInputValue]);

  return (

    <AutocompleteMui
      id={id}
      disableClearable={freeSolo === true ? freeSolo : undefined}
      disablePortal
      freeSolo={freeSolo === true ? freeSolo : undefined}
      value={inputValue}
      onSelect={onChangeAutoComplete}
      onChange={(event, newValue, reason, details) => {
        setInputValue(newValue);
      }}
      inputValue={newInputValue}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') onSeledted({ value: undefined });
        setNewInputValue(newInputValue);
      }}
      // isOptionEqualToValue={(option, value) => option === value}
      options={arrayValue.map((option) => option[indexLabel])}
      renderInput={(params) => <TextField {...params} sx={sx} placeholder={placeholder} className={customClass} name={name} />}
    />

  );

};

export default Autocomplete;