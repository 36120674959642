import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';


export const ContractDetailReviewStyles = makeStyles((theme) => {

    return createStyles({
        container: {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                flexDirection: 'row-reverse',
            }
        },
        contractDetails_container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                padding: '1rem',
                flexDirection: 'row-reverse'
            }
        },
        contractDetailsHeader: {
            backgroundColor: "transparent !important",
            borderRadius: "0px !important",
            marginBottom: "0px !important",
        },
        contenItem: {
            backgroundColor: theme.palette.white.main,
            width: '100%',
            padding: '1rem 0'
        },
        titleFV: {
            fontFamily: "PoppinsBold",
            textAlign: "center",
            "& > span": {
                fontFamily: "PoppinsBold",
                fontStyle: "italic",
                fontSize: "1.5rem",
                color: theme.palette.primary.light,
            },
        },
        userInfo: {
            '& h6': {
                color: theme.palette.gray.dark
            }
        },
        userTitleInfo: {
            marginRight: '55px',
            '& h6': {
                color: theme.palette.black.main,
                fontWeight: '700'
            }
        },
        infoDocsContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
        },
        userIcon: {
            marginRight: '22px'
        },
        userInfoContent: {
            width: "100%",
            display: "flex",
            alignItems: 'center',
            flexDirection: "row",
            backgroundColor: theme.palette.white.main,
            padding: '1rem',
            borderRadius: '7px',
        },
        tramitInfoContent: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.white.main,
            padding: '1rem',
            borderRadius: '7px',
            "& div.text-content": {
                marginBottom: "10px !important",
                marginTop: "10px !important",
                display: "flex",

                "& h6": {
                    color: "black",
                    fontSize: '.8rem',
                    fontFamily: 'PoppinsBold'
                },

                "& h6.MuiTypography-h6": {
                    flex: "0 0 55%",
                    maxWidth: "55%"
                },

                "& h6.MuiTypography-subtitle1": {
                    flex: "0 0 45%",
                    maxWidth: "45%",
                    fontFamily: 'PoppinsRegular'
                }

            }
        },
        ownerData_Info_textBox: {
            margin: '.75rem 0 0 0 !important',
            display: 'flex',
            flexDirection: 'column',

            '& > p': {
                color: theme.palette.black.secondary,
                fontSize: '.867rem'
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row'
            }
        },
        ownerData_Info_Box_MainText: {
            maxWidth: '100%',
            fontSize: '.867rem',
            color: theme.palette.black.secondary,
            margin: '.1rem 0 0 0 !important'
        },
        dataTramit_Info: {
            margin: '0 !important',
            marginRight: '1rem',
            //width: '6rem',
            textAlign: 'start',
            width: '100% !important',
            '& > h6': {
                color: theme.palette.black.secondary,
                fontFamily: 'PoppinsBold',
                fontSize: '.867rem',
                width: '50%',
                [theme.breakpoints.up('md')]: {
                    whiteSpace: 'nowrap'
                }
            },
            '& > p': {
                fontFamily: 'PoppinsRegular',
                fontSize: '.867rem',
                maxWidth: '100%',
                overflowWrap: 'anywhere',
                [theme.breakpoints.up('md')]: {
                    margin: '0 0 0 3rem',
                    whiteSpace: 'nowrap',
                    overflowWrap: 'normal'
                }
            },
            [theme.breakpoints.up('sm')]: {
                width: '8rem !important'
            }
        },
        optionsContent: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                flexWrap: "wrap"
            }
        },
        titleNumber: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.white.main,
            width: '38px !important',
            height: '38px !important',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '7px',
            marginRight: '1rem'
        },
        contentTitle: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: '55px 0 25px 0'
        },
        container_infoDoc: {
            width: '100%',
            backgroundColor: theme.palette.white.main,
            padding: '1rem',
            borderRadius: '7px',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            }
        },
        infoDocs: {
            maxWidth: '722px',
            width: '100%',
            height: 'auto',
            marginRight: '0px',
            paddingTop: '40px',
            paddingLeft: '29px',
            paddingBottom: '40px',
            background: '#FFFFFF',
            boxShadow: '0px 4px 22px 1px rgba(0, 79, 159, 0.03)',
            borderRadius: '6px'
        },
        fileIcon: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            '& svg': {
                marginRight: '7px'
            }
        },
        optionDocs: {
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '722px',
            width: '100%',
            maxHeight: '146px',
            height: 'auto',
            marginTop: '18px',
            '& div': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '42.94px',
                height: '40.82px',
                background: '#FFFFFF',
                borderRadius: '10px',
                cursor: 'pointer',
                borderStyle: 'solid',
                borderColor: theme.palette.primary.light,
                padding: '7px'
            },
            '& div:nth-child(1), & div:nth-child(2)': {
                marginRight: '31px'
            }
        },
        fourtSectionContent: {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.white.main,
            padding: '1rem',
            borderRadius: '6px',
            [theme.breakpoints.up('sm')]: {
                padding: '0',
            }
        },
        payTitleInfo: {
            marginTop: '60px',
            marginBottom: '20px',
            width: '100%'
        },
        payInfoMainContent: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '20px',
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '6px',
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            '& div:nth-child(2)': {
                '& h6': {
                    textAlign: 'right',
                }
            }
        },
        payInfoContent: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        payTotalInfo: {
            display: 'flex',
            justifyContent: 'flex-end',
            '& :first-child': {
                marginRight: '60px'
            }
        },
        folderIconContainer: {
            width: '158px',
            height: '229px',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: '20px'
        },
        FilePreview: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '244px',
            height: 'auto',
            background: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '244px !important',
                height: '354px !important',

                '& object:first-child, img:first-child': {
                    height: '332px !important'
                }
            },

            '& embed': {
                width: '244px !important',
                height: '332px !important'
            }

        },
        uploadButtomContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        fileButomsContainer: {
            width: '100% !important',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap'
        },
        fileMergePreview: {
            width: '158px',
            height: '229px',
            backgroundColor: '#EAF7FB',
            border: '1px dashed #00ADEA',
            borderRadius: '7px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '30px',
            marginTop: '20px',

            '& div:nth-child(3)': {
                width: '158px !important',
                height: '229px !important',
                backgroundColor: '#EAF7FB !important',

                '& object:first-child, img:first-child': {
                    height: '135px !important'
                }
            }

        },
        conatinerCloseButtomPreview: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between'
        },
        buttomClose: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '35px'
        },
        pdfViewer: {
            background: '#ccc',
            position: 'relative',
            margin: '2em auto'
        },
        canvasContainer: {
            width: '100%',
            height: 'auto',
            overflow: 'auto',
            background: '#333',
            textAlign: 'center',
            border: 'solid 3px'
        },
        mainContainerPdf: {
            overflowY: 'auto',
            width: 'auto',
            background: theme.palette.white.main,
            boxShadow: '0px 4px 11px 5px rgba(0, 79, 159, 0.04)',
            borderRadius: '7px',
            padding: '30px'
        },
        navigationControls: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        actionControlContainer: {
            background: theme.palette.white.main,
            height: 'auto',
            width: '357px',
            borderRadius: '7px',
            marginLeft: '20px',
            padding: '20px'
        },
        actionsContainer: {
            '& > div:first-child': {
                marginBottom: '50px'
            }
        },
        actionButtom: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '15px'
        },
        formParticipant: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            '& label': {
                justifyContent: 'center'
            },

            '& div.addParticipant': {
                display: 'flex',
                justifyContent: 'center',
                '& button': {
                    marginTop: '25px'
                }
            }
        },
        dragableBox: ({ layerX, layerY }) => ({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            alignItems: 'baseline',
            position: 'absolute',
            left: `${layerX}px`,
            bottom: `${layerY}px`,
            background: 'transparent',
            cursor: 'move',
            '& p': {
                fontSize: '2rem'
            }
        }),
        boxIcon: {
            position: 'absolute',
            bottom: '43px',
            fill: 'red',
            left: '60px',
            zIndex: 999,
            cursor: 'pointer'
        },
        textIcon: ({ iconColor }) => ({
            fill: iconColor
        }),
        contentParticipant: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
        userInfoContentModal: {
            flexDirection: 'column',
            marginTop: '0px',
            marginRight: '15px'
        },
        userIconModal: {
            marginRight: '0px'
        },
        userTitleInfoModal: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginRight: '0px',
            marginTop: '15px',
            '& :first-child': {
                color: theme.palette.primary.light,
                lineHeight: '0'
            }
        },
        bodyContentModal: {
            display: 'flex',
            alignItems: 'center'
        },
        mainCommentContent: {
            display: 'flex',
            flexDirection: 'column',
            '& div:last-child': {
                marginBottom: '0px !important',
            }
        },
        CommentContent: {
            borderRadius: '15px',
            padding: '20px',
            minWidth: '350px',
            marginBottom: '15px',
            backgroundColor: theme.palette.gray.light,
            '& :first-child': {
                fontSize: '1.1rem'
            }
        },
        buttomCloseCommentModal: {
            marginBottom: '20px'
        },
        info_container: {
            paddingBottom: '0 !important',
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                backgroundColor: theme.palette.white.main,
                padding: '1.333rem !important',
            }
        },
        mainContainerDialog: {
            '& div.MuiPaper-root': {
                borderRadius: '10px'
            }
        },
        detailTextNameDoc: {
            marginLeft: "0px",
            fontSize: "0.70rem",
            color: theme.palette.primary.main
        },
        infoDoc: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: 'column',
            alignItems: "center",
            border: `1px solid ${theme.palette.primary.xlight}`,
            backgroundColor: theme.palette.primary.xxxxlight,
            borderRadius: '10px',
            margin: '1.34rem .8rem',

            [theme.breakpoints.not('xs')]: {
                padding: '1rem 0',
                margin: '0',
            },

            [theme.breakpoints.only('xs')]: {
                padding: '1rem 1.3rem',
            },

            "& div:first-child": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                [theme.breakpoints.not('xs')]: {
                    marginLeft: "50px",
                },
            },
            "& div:first-child svg": {

                [theme.breakpoints.not('xs')]: {
                    marginRight: "5px",
                },

                [theme.breakpoints.only('xs')]: {
                    flex: "0 0 20%",
                    maxWidth: "20%",
                    zoom: 1.5
                }

            },

            "& div:first-child h6": {

                [theme.breakpoints.down('sm')]: {
                    textAlign: "justify",
                    fontSize: ".7rem",
                    fontFamily: "PoppinsRegular",
                    flex: "0 0 80%",
                    maxWidth: "80%",
                }

            }
        },
        infoDoc_buttons: {
            width: '100%',
            display: 'flex',
            marginTop: '.5rem',

            [theme.breakpoints.not('xs')]: {
                justifyContent: 'space-evenly',
                alignItems: 'center',
            },

            [theme.breakpoints.only('xs')]: {
                justifyContent: 'flex-end',

                "& svg": {
                    cursor: "pointer",
                    margin: "5px !important"
                }
            }

        },
        buttonTramit: {
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `1px solid ${theme.palette.primary.xlight}`,
            borderRadius: "5px",
            height: '2.25rem',
            width: '11.5rem',
            backgroundColor: theme.palette.white.main
        },
        buttonTramit2: {
            fontFamily: "PoppinsBold",
            fontSize: "0.65rem",
            textTransform: "unset !important",
            border: `none`,
            borderRadius: "5px",
            backgroundColor: theme.palette.primary.light,
            height: '2.25rem',
            width: '11.5rem',
            color: theme.palette.white.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            }
        },
        contentFooterXs: {
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& :not(span)": {
                fontFamily: "PoppinsBold",
            },
            "& h6:first-child": {
                fontSize: "1.15rem",
                color: theme.palette.primary.main,
                marginBottom: "10px",
            },
            "& :nth-child(2)": {
                color: theme.palette.black.main,
                fontSize: "0.8rem",
            },
        },
    });
});