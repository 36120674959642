import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "../../theme/theme";

export const ModalAlertStyles = makeStyles(() => {
    return createStyles({
        modalContent: {
            minHeight: 353,
            display: 'flex',
            flexDirection: 'column',
            '& h2,p': {
                color: theme.palette.primary.main,
                textAlign: "center",
            },
            '& p': {
                marginTop: 35,
                padding: '0px 23px 0px 23px',
            },
            '& h2.title': {
                marginTop: 50,
                fontFamily: "PoppinsBold",
            }
        },
        headerContent: {
            height: '57px',
            width: '100%',
            backgroundColor: theme.palette.primary.light,
            '& svg.modal-icon': {
                position: 'fixed',
                left: '50%',
                top: '50px',
                transform: 'translate(-50%, -50%)'
            },
            '& svg.close-icon': {
                marginRight: 20,
                marginTop: 15,
                cursor: 'pointer',
                color: theme.palette.white.main,
                alignSelf: 'flex-end'
            }
        },
        modalMessage_Container: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            margin: '3rem 0 7rem 0'
        },
        modalMessage_Box: {
            backgroundColor: theme.palette.error.light,
            border: `1px solid ${theme.palette.error.dark}`,
            borderRadius: '10px',
            width: '294px',
            minHeight: '37px',
            margin: '.3rem 0',
            padding: '0 .5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            '& p': {
                color: theme.palette.error.main,
                padding: '.2rem',
                margin: 0,
                fontFamily: 'PoppinsRegular'
            }
        },
        footerContent: {
            position: 'fixed',
            bottom: 0,
            height: '67px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F2F5F8',
            borderRadius: '0px 0px 7px 7px',
            alignItems: 'center',
            '& button': {
                width: 117
            }
        },
    });
});