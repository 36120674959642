import { Fragment, useContext, useEffect, useMemo, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { verifiVariable } from "../../../utils/enums";
import Rows from "./molecules/rows";
//import TablePaginationActions from "./molecules/tablePagination";
import EnhancedTableHead from "./molecules/tableHead";
import { Context } from "../../../context/utilsContext";
import ModalActions from "../../modalAction";
import TablePaginationActions from "./molecules/tablePaginationActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const createData = (
    autoId,
    cuponName,
    code,
    beginDate,
    expireDate,
    status
) => {

    return {
        autoId,
        cuponName,
        code,
        beginDate,
        expireDate,
        status,
        action: "Acciones"
    };
};

const defaultValueFilterBy = "Filtrar por";

const GeneralListCupons = ({ dataGrid, updateList }) => {
    // eslint-disable-next-line
    const { show } = useContext(Context);
    const [filterValue, setFilterValue] = useState("");
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("ID");
    const [rows, setRows] = useState([]);
    // eslint-disable-next-line
    const [idContract, setIdContract] = useState("");
    // eslint-disable-next-line
    const [openModalDelete, setOpenModalDelete] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterBy, setFilterBy] = useState();
    const [countRows, setCountRows] = useState(0); //sProcess
    // eslint-disable-next-line
    const [sProcess, setSProcess] = useState("");

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };

    // eslint-disable-next-line
    const handleChange = (event) => {
        setFilterBy(event.target.value);
        // if(event.target.value !== "sStatus")
        setFilterValue("");
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // eslint-disable-next-line
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }

            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }

        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    };

    const getComparator = (order, orderBy) => {
        let pattern = "";

        if (orderBy === "cuponName") {
            pattern = "cuponName";
        } else if (orderBy === "useNumber") {
            pattern = "useNumber";
        } else if (orderBy === "discountValue") {
            pattern = "discountValue";
        } else if (orderBy === "discountTypeValue") {
            pattern = "discountTypeValue";
        } else if (orderBy === "status") {
            pattern = "status";
        } else {
            pattern = "Acciones";
        }

        return order === "desc"
            ? (a, b) => descendingComparator(a, b, pattern)
            : (a, b) => -descendingComparator(a, b, pattern);
    };

    // eslint-disable-next-line
    const FilterSelectStatus = () => {
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label-1">
                    Estados
                </InputLabel>
                <Select
                    disabled={filterBy === defaultValueFilterBy}
                    value={filterValue}
                    onChange={handleFilterChange}
                    autoWidth
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Estados" />}
                >
                    <MenuItem value={"CREATED"}>Creados</MenuItem>
                    <MenuItem value={"REVIEW"}>En revisión</MenuItem>
                    <MenuItem value={"REJECTED"}>Rechazados</MenuItem>
                    <MenuItem value={"APPROVED"}>Pendientes de pago</MenuItem>
                    <MenuItem value={"IN_NOTARY"}>En notaría</MenuItem>
                    <MenuItem value={"SIGNED_PENDING"}>
                        Pendientes de firma
                    </MenuItem>
                    <MenuItem value={"FINISHED"}>Entregados</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const visibleRows = useMemo(() => {
        const filterData = rows.filter((row) => {
            if (["sTipoContrato", "sTipoFirma"].includes(filterBy)) {
                return !verifiVariable(row["tramit"][filterBy])
                    ? row["tramit"][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else if (["dContractDT"].includes(filterBy)) {
                return !verifiVariable(row["information"][0][filterBy])
                    ? row["information"][0][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            }/*  else if (["sStatus"].includes(filterBy)) {
                return !verifiVariable(row["sStatus"][filterBy])
                    ? row["sStatus"][filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } */ else if (filterBy !== defaultValueFilterBy) {
                return !verifiVariable(row[filterBy])
                    ? row[filterBy]
                        .toLowerCase()
                        .includes(filterValue.toLowerCase())
                    : row;
            } else {
                return row;
            }
        });

        const arrayData = stableSort(
            filterData,
            getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        setCountRows(filterData.length);

        return arrayData;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, orderBy, page, rowsPerPage, rows, filterValue, filterBy]);

    useEffect(() => {
        if (!verifiVariable(dataGrid) && dataGrid.length > 0) {
            const arrayData = [];
            console.log({ dataGrid }, 231)
            dataGrid.forEach((element) => {
                arrayData.push(
                    createData(
                        element.autoId,
                        element.cuponName,
                        element.code,
                        element.beginDate,
                        element.expireDate,
                        element.status,
                    )
                );
            });

            setRows(arrayData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    return (
        <Fragment>
            <ModalActions
                openModalErrors={openModalDelete}
                setOpenModalErrors={setOpenModalDelete}
                modalIcon={'warnIcon'}
                title={'Eliminar cupón'}
                subTitle={'¿Estás seguro de que deseas eliminar este cupón?'}
            />

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />

                    <TableBody>
                        {visibleRows.map((row) => (
                            <Rows
                                sProcess={sProcess}
                                key={row["autoId"]}
                                deleteContrat={(e) => {
                                    setOpenModalDelete((value) => e + value);
                                    // setIdContract(e["sContractID"]);
                                    // setSProcess(e["sProcess"]);
                                }}
                                row={row}
                            />
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                count={countRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage="Resultados por página"
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Fragment>
    );
};

export default GeneralListCupons;
