import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "formUach",
    initialState: {
        fullName: undefined,
        rut: undefined,
        email: undefined,
        phone: undefined,
        tramitPdf: undefined,
        fileName: undefined,
        errorStep: false,
        addSing: false
    },
    reducers: {

        FORM_UACH_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },

        FORM_UACH_PURGE_STATE: (state, action) => {
            state.fullName = undefined;
            state.rut = undefined;
            state.email = undefined;
            state.phone = undefined;
            state.pdfDJI = undefined;
            state.pdfCaraFrontal = undefined;
            state.pdfContraCara = undefined;
            state.tramitPdf = undefined;
            state.fileName = undefined;
            state.errorStep = false;
            state.addSing = false;
        },

        FORM_UACH_ERROR_STEP: (state, action) => {
            state.errorStep = action.payload;
        }

    }
});

export const {
    FORM_UACH_ERROR_STEP,
    FORM_UACH_SAVE_INFO,
    FORM_UACH_PURGE_STATE
} = slice.actions;

export const errorStepForm = (data) => {
    return { type: FORM_UACH_ERROR_STEP.type, payload: data };
};

export const saveFormInfo = (data) => {
    return { type: FORM_UACH_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: FORM_UACH_PURGE_STATE.type, payload: null };
};

export default slice.reducer;
