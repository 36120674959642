import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from "react-icons/bi";

const Gobackbutton = (props) => {
    const {gobackTitle} = props;
    const navigation = useNavigate();

    return (
        <div className="flex--start icon-pointer" onClick={() => navigation(-1)}>
            <BiChevronLeft className="icon--md icon--grey-dark-light" />
            <strong className="font-color--primary-light">{gobackTitle}</strong>
        </div>
    );
}

export default Gobackbutton;
