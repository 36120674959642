import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { FormularioUachtyles } from "../../index.css";
import { errorStepForm, saveFormInfo } from "../../../../store/formUach/formUach";


const StepOne = ({ control, watch, trigger, setValue, formState }) => {
    const styles = FormularioUachtyles();
    const {
        formUach: {
            fullName
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const nameInput = 'fullName';
    const watchFullName = watch(nameInput);

    useEffect(() => {
        if ([undefined, null, ''].includes(formState['errors'][nameInput])) {
            dispatch(errorStepForm(false));
        } else {
            dispatch(errorStepForm(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState]);

    useEffect(() => {
        setValue(nameInput, fullName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullName]);

    useEffect(() => {

        trigger();

        if (!['', null, undefined].includes(watchFullName) && watchFullName !== fullName) dispatch(saveFormInfo({ indexe: nameInput, value: watchFullName }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchFullName]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}>

                <Grid container xs={12}>

                    <Grid item={true} xs={1} className={styles.boxIndetify}>

                        1<BiRightArrowAlt color="rgb(0, 79, 159)" />

                    </Grid>

                    <Grid item={true} xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            Nombres y Apellidos *
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            ¿Cuál es tu nombre y apellido completo?
                        </Typography>

                        <Controller
                            name={nameInput}
                            control={control}
                            rules={{
                                required: "Este campo es requerido"
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <TextField
                                            type="text"
                                            error={fieldState.error ? true : false}
                                            ref={ref}
                                            onBlur={onBlur}
                                            variant={'standard'}
                                            onChange={onChange}
                                            value={value}
                                            helperText={
                                                fieldState.error
                                                    ? fieldState.error.message
                                                    : null
                                            }
                                            placeholder={"Escribe aqui tu respuesta..."}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepOne;