import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const GetFromCertXml = () => {
    const {
        fvavanzada: {
            esignerWSDescCertificadoConfPinFirma
        },
    } = useSelector((state) => state);
    const [rest, setRest] = useState(undefined);


    useEffect(() => {

        if (typeof esignerWSDescCertificadoConfPinFirma === 'string' && ![null, undefined].includes(esignerWSDescCertificadoConfPinFirma)) {

            const restEstado = esignerWSDescCertificadoConfPinFirma.match(/<estado>.+?<\/estado>/g).map(e => e.replace(/<estado>|<\/estado>/g, ""));

            const restComentarios = esignerWSDescCertificadoConfPinFirma.match(/<comentarios>.+?<\/comentarios>/g).map(e => e.replace(/<comentarios>|<\/comentarios>/g, ""));

            const restNombreConfiguracion = esignerWSDescCertificadoConfPinFirma.match(/<nombreConfiguracion>.+?<\/nombreConfiguracion>/g).map(e => e.replace(/<nombreConfiguracion>|<\/nombreConfiguracion>/g, ""));
            
            setRest({
                restEstado: restEstado[0],
                restComentarios: restComentarios[0],
                restNombreConfiguracion: restNombreConfiguracion[0]
            });

        }

    }, [esignerWSDescCertificadoConfPinFirma]);

    return rest;
};

export default GetFromCertXml;