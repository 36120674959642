import React from 'react';
import StatusHook from '../../hooks/StatusHook';

const RenderStatus = ({ status, paymentStatus }) => {

    const restStatus = StatusHook(status, paymentStatus);

    return (
        <div className={ restStatus !== undefined ? restStatus.class : null }>{ restStatus !== undefined ? restStatus.text : '' }</div>
    );

};

export default RenderStatus;