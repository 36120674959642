import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: 'couponsUser',

    initialState: {
        allCouponsUser: '',
        couponUser: '',
        loading: false,
        message: {},
        status: ''
    },

    reducers: {
        PURGE_COUPONSUSER_STATE: (state, action) => {
            state.allCouponsUser = '';
            state.couponUser = '';
            state.loading = false;
            state.message = {};
            state.status = ''
        },

        COUPONSUSER_CREATE_REQUESTED: (state, action) => {
            state.allCouponsUser = '';
            state.couponUser = '';
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONSUSER_CREATE_REQUESTED_FAILED: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.status = action.payload.status
        },

        COUPONSUSER_CREATE_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.couponUser = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        COUPONSUSER_GETTALL_REQUESTED: (state, action) => {
            state.allCouponsUser = '';
            state.loading = true;
            state.message = {};
            state.status = ''
        },

        COUPONSUSER_GETTALL_REQUESTED_SUCCESS: (state, action) => {
            if (action.payload.status === 'success') {
                state.allCouponsUser = action.payload.message;
                state.status = action.payload.status
            } else {
                state.message = action.payload.message;
                state.status = action.payload.status
            };
            state.loading = false;
        },

        COUPONSUSER_GETTALL_REQUESTED_FAILED: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.status = action.payload.status
        },

        COUPONSUSER_SAVE_INFO: (state, action) => {
            state[action.payload['indexe']] = action.payload['value'];
        },
    }
});

export const {
    PURGE_COUPONSUSER_STATE,
    COUPONSUSER_CREATE_REQUESTED,
    COUPONSUSER_CREATE_REQUESTED_SUCCESS,
    COUPONSUSER_CREATE_REQUESTED_FAILED,
    COUPONSUSER_GETTALL_REQUESTED,
    COUPONSUSER_GETTALL_REQUESTED_SUCCESS,
    COUPONSUSER_GETTALL_REQUESTED_FAILED,
    COUPONSUSER_SAVE_INFO
} = slice.actions;

export const cleanCouponsUserForm = () => {
    return { type: PURGE_COUPONSUSER_STATE.type };
};

export const couponsUserSaveInfo = (data) => {
    return { type: COUPONSUSER_SAVE_INFO, payload: data }
}

export const createCouponUser = (data, token) => {
    return apiCallStart({
        url: `/api/v2/couponuser/create`,
        method: 'POST',
        data,
        onStart: COUPONSUSER_CREATE_REQUESTED.type,
        onSuccess: COUPONSUSER_CREATE_REQUESTED_SUCCESS.type,
        onError: COUPONSUSER_CREATE_REQUESTED_FAILED.type,
        authorization: token
    });
};

export const getAllCoupons = (token) => {
    return apiCallStart({
        url: `/api/v2/couponuser/getall`,
        method: 'GET',
        onStart: COUPONSUSER_GETTALL_REQUESTED.type,
        onSuccess: COUPONSUSER_GETTALL_REQUESTED_SUCCESS.type,
        onError: COUPONSUSER_GETTALL_REQUESTED_FAILED.type,
        authorization: token
    });
};

export default slice.reducer;