import { combineReducers } from "redux";
import contractDetailReducer from "./contractDetail";
import contractListFiltersReducer from "./contactListFilters";
import signatoriesReducer from "./signatoriesList";
import tramitesReducer from "./tramites";

export default combineReducers({
    contractCreator: contractDetailReducer,
    contractListFilters: contractListFiltersReducer,
    signatories: signatoriesReducer,
    tramites: tramitesReducer
});
