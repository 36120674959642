import { createRef, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { AddSignaturesStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";
// import $ from 'jquery';

const DraggableBox = ({
    positions,
    refPdfViewer,
    signatory,
    deleteBox,
    zoom,
    docPdf,
    refCanvas,
}) => {
    const [width, setWidth] = useState(185);
    const [height, setHeight] = useState(52);

    const styles = AddSignaturesStyles({
        width,
        height,
        layerX: signatory["positionBoxX"],
        layerY: signatory["positionBoxY"],
        iconColor: signatory["textColor"],
    });
    const [textBox, setTextBox] = useState("");
    const boxRef = createRef();

    const coordinates = async (element, { startX, startY, clientX, clientY }) => {
        const parsetCurrentPage = parseInt(signatory["page"]);
        const page = await docPdf.getPage(parsetCurrentPage);
        const viewport = page.getViewport({ scale: zoom });

        const elementLeft = element.position().left;
        const elementTop = element.position().top;

        const canvas = refCanvas.current;
        const rect = canvas.getBoundingClientRect();

        const offsetX = (clientX - rect.left);
        const offsetY = (clientY - rect.top);

        const scaledX = offsetX * (canvas.width / rect.width);
        const scaledY = offsetY * (canvas.height / rect.height);

        const boxIsInsideCanvas =
            scaledX >= 0 &&
            scaledX <= canvas.width &&
            scaledY >= 0 &&
            scaledY <= canvas.height;

        if (!boxIsInsideCanvas) {
            element.css({
                left: startX,
                top: startY
            });
            return;
        }

        const CoordenadaX = (scaledX + 20) / viewport.scale;
        const CoordenadaY = (scaledY + 45) / viewport.scale;

        positions(
            Object.assign(
                { ...signatory },
                {
                    x: CoordenadaX - 60,
                    y: CoordenadaY - 50,
                    positionBoxX: elementLeft,
                    positionBoxY: elementTop,
                }
            )
        );
    };
    // On top layout
    // eslint-disable-next-line
    const onResize = (event, { element, size, handle }) => {
        console.log("onResize: ", element, size, handle);
        setWidth(size.width);
        setHeight(size.height);
    };

    useEffect(() => {

        console.log({zoom})

        let newText = "";

        if (!verifiVariable(signatory)) {
            const splitText = signatory["textTootip"].split(" ");

            const countWords = splitText.length;

            if (countWords === 1) {
                setTextBox(splitText[0]);
            } else {
                splitText.forEach((texto, index) => {
                    if (index === 0) {
                        newText += texto;
                    } else {
                        newText += `${index > 1 ? "" : " "}${texto
                            .substring(0, 1)
                            .toUpperCase()}`;
                    }
                });

                setTextBox(newText);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatory]);

    useEffect(() => {
        const element = window["jQuery"](boxRef.current);
        let startX = 0;
        let startY = 0;

        element.draggable({
            start: () => {
                startX = element.position().left;
                startY = element.position().top;
            },
            stop: (event) => {
                const clientX = event.clientX;
                const clientY = event.clientY;

                coordinates(element, { startX, startY, clientX, clientY });
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip
            title={`Firmante ${signatory["numbSigned"]}`}
            arrow
            placement="bottom-start"
        >
            <div
                id="box"
                className={styles.dragableBox}
                ref={boxRef}
                style={{ height: `${height}px`, width: `${width}px` }}
            >
                <FaTrashAlt
                    className={styles.boxIcon}
                    onClick={() => deleteBox()}
                />

                <Typography variant="h6" color={[signatory["textColor"]]}>
                    {textBox}{" "}
                    <AiFillCheckCircle size={20} className={styles.textIcon} />
                </Typography>
            </div>
        </Tooltip>
    );
};

export default DraggableBox;