import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { RecoverAccountForm } from "../../components";
import { ReactComponent as Logo } from "../../assets/logo/logo.svg";
import ScreenSizeHook from "../../hooks/ScreenSizeHook";
import ModalAlert from "../../components/floats/ModalAlert";
// import WhatsappIconSpeedDial from "../../components/whatsappIconSpeedDial";
// import HelpIconSpeedDial from "../../components/helpIconSpeedDial";

export default function RecoverAccount() {
    const screenSizeHook = ScreenSizeHook();
    const [showModal, setShowModal] = useState(false);

    return (
        <Fragment>

            <ModalAlert
                buttonText={"Aceptar"}
                modalIcon={"mailIcon"}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={"Revisa tu correo y continua con los siguientes pasos"}
                title={"Correo enviado"}
            />

            <div className="login-wrapper">

                <div className="start-session__container">

                    <div className="start-session-content__container flex--center">

                        <Grid container>

                            <Grid item xs={12} sx={{ marginRight: 2, marginLeft: 2 }}>

                                <Link to="/">
                                    <Logo className="logo-size--md" style={{ width: '100%' }} />
                                </Link>

                            </Grid>

                        </Grid>

                        <h1 className="title--head text-align--center title">
                            ¿Has olvidado tu contraseña?
                        </h1>

                        <RecoverAccountForm />

                    </div>
                </div>

                {!['xs', 'sm'].includes(screenSizeHook) && (<div className="showcase__container login-bg" />)}

            </div>

            {/* <WhatsappIconSpeedDial /> */}

            {/* <HelpIconSpeedDial /> */}

        </Fragment>
    );
}
