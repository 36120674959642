import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ErrorModalIcon } from '../../assets/icons/error-modal-icon.svg';
//import { ReactComponent as WarningModalIcon } from '../../assets/icons/icon-warning-red-outlined.svg';
import { ModalActionsStyles } from './index.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    boxShadow: 24
};

const AcceptCustomButton = styled(Button)((props) => ({
    textTransform: "none",
    color: props.theme.palette.white.main,
    fontFamily: "PoppinsBold",
}));

/* const CancelCustomButton = styled(Button)((props) => ({
    textTransform: "none",
    color: props.theme.palette.primary.main,
    fontFamily: "PoppinsBold",
})); */

const ModalActions = ({ openModalErrors, setOpenModalErrors, modalIcon, title, subTitle }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpenModalErrors(false);
        setOpen(false);
    }

    const styles = ModalActionsStyles();

    useEffect(() => {
        if (openModalErrors) handleOpen();
    }, [openModalErrors]);

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={style} className={styles.modalContent}>
                    <Stack className={styles.headerContent}>
                        {/* {modalIcon === 'successIcon' && (<CheckModalIcon className='modal-icon' />)} */}
                        {modalIcon === 'warnIcon' && (<ErrorModalIcon className='modal-icon' />)}
                        {/* {modalIcon === 'emailIcon' && (<MailModalIcon className='modal-icon' />)} */}
                        <CloseIcon className='close-icon' onClick={handleClose} />
                    </Stack>

                    <Box className={styles.modalMessage_Container}>
                        <Typography className={styles.modalMessage_Title}>{title}</Typography>
                        <Typography className={styles.modalMessage_subTitle}>{subTitle}</Typography>
                    </Box>

                    <Stack className={styles.footerContent} spacing={4} direction={'row'}>
                        {/* {cancelButtom === true && (<CancelCustomButton variant="outlined" color={'customBlueLight'} onClick={handleClose}>Cancelar</CancelCustomButton>)} */}
                        <AcceptCustomButton
                            variant="contained"
                            color={'customBlueLight'}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Aceptar
                        </AcceptCustomButton>
                    </Stack>

                </Box>
            </Modal >
        </Box>
    )
}

export default ModalActions