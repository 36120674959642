import { motion } from "framer-motion";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { FormularioUachtyles } from "../../index.css";

const StepSix = () => {
    const styles = FormularioUachtyles();

    return (
        <Grid className={styles.textContent} container>

            <br />

            <br />

            <br />

            <br />

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}>

                <Typography className={styles.texto2} variant="body2" gutterBottom>
                    Casi llegaste al final. Antes de poder firmar, sigue las instrucciones que llegan a tu correo.
                </Typography>

            </motion.div>

        </Grid>
    );
}

export default StepSix;