import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

import { ReactComponent as DeleteSuccessIcon } from "./img/Firmante_eliminado_con_exito.svg";
import { ReactComponent as MissingPayMers } from "./img/aun_no_se_han_agregado_pagadores.svg";
import { ReactComponent as MissingSignatories } from "./img/aun_no_se_han_agregado_firmantes.svg";
import { gridColumns, localizedTextsMap } from "./constants";
import Text from "../../heading/Text";
import { styles } from "../../../helpers";
import { Context } from "../../../context/utilsContext";
import AddParticipant from "./molecules/addParticipant";
import { to, verifiVariable } from "../../../utils/enums";

const CustomSnackBar = styled(Snackbar)(({ theme }) => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none'
    }
}));

const ScheduleSignatories = () => {
    const { nextPage, setNextPage, deleteParticipant, setDeleteParticipant, toResumen, setToResumen } = useContext(Context);
    const {
        scheduling: {
            secondStep
        }
    } = useSelector((state) => state);
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [key, setKey] = useState("");
    const [dataGrid, setDataGrid] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarIcon, setSnackbarIcon] = useState(<DeleteSuccessIcon />);

    const handleClickOpenSnack = () => {
        setSnackbarOpen(true);

        setTimeout(() => {
            setSnackbarOpen(false);
        }, 2500);
    }

    const handleCloseSnack = () => {
        setSnackbarOpen(false);
    }

    const saveData = (url) => {

        let rolData = [];
        let payData = [];

        [...secondStep].forEach(element => {
            rolData = rolData.concat(element['rol']);
        });

        [...secondStep].forEach(element => {
            payData = payData.concat(element['pay']);
        });

        const total = payData.reduce((a, b) => parseInt(a) + parseInt(b), 0);

        if (!rolData.includes('Firmante')) {
            setSnackbarIcon(<MissingSignatories />);
            handleClickOpenSnack();
        } else if (!rolData.includes('Pagador')) {
            setSnackbarIcon(<MissingPayMers style={{ zoom: 1.5 }} />);
            handleClickOpenSnack();
        } else if (total < 100) {
            setSnackbarIcon(<MissingPayMers style={{ zoom: 1.5 }} />);
            handleClickOpenSnack();
        } else {
            navigate(url);
        }

    }

    useEffect(() => {

        if (nextPage !== 0) saveData(to.SCHEDULE_CREATION_DOCUMENTS);

        return () => {
            setNextPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {

        if (!verifiVariable(toResumen)) saveData(toResumen);

        return () => {
            setToResumen(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {

        if (deleteParticipant > 0 && !snackbarOpen) {
            setSnackbarIcon(<DeleteSuccessIcon />);
            handleClickOpenSnack();
        }

        return () => {
            setDeleteParticipant(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteParticipant]);

    useEffect(() => {
        setDataGrid(secondStep);
    }, [secondStep]);

    const renderList = useCallback(() => {
        const arrayList = dataGrid;

        return (
            <div
                className="container--relative contracts-status-list__container"
                style={{ height: "auto", width: "100%" }}
            >

                <div className="title-menu__status">
                    <Text sx={styles.titleStyles} variant="h6">
                        Datos de los firmantesss
                    </Text>
                    <br />
                    <br />
                </div>

                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={arrayList}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            getRowId={(row) => row.rutId}
                            getCellClassName={null}
                            rowHeight={56}
                            // loading={spinner || loading}
                            // sx={styles.gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                /* Toolbar: () => (
                                    <CustomToolbar
                                        genKey={genKey}
                                    // updateData={() => updateData()}
                                    />
                                ), */
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        {/* <AddSignatoriesIcon className={style.svgIconAddSignatories} /> */}
                                        No hay registros
                                    </div>
                                ),
                            }}
                            sortingOrder={["desc", "asc"]}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        {
                                            field: "nombre",
                                            sort: "desc",
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                </div>

            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);


    return (
        <div className="content-detail__container" key={key}>

            <CustomSnackBar
                style={{
                    '& .MuiSnackbarContent-root': {
                        all: 'initial',
                        padding: '10px'
                    }
                }}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                open={snackbarOpen}
                onClose={handleCloseSnack}
                message={snackbarIcon}
            />

            <AddParticipant />
            {renderList()}
        </div>
    );
}

export default ScheduleSignatories;