import axios from "axios";
import { api } from "../../../../utils/enums";

const uri = api.BASE_SCHEDULE_URI;

export const saveDirecction = async (data, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.SAVE_SCHEDULE_DIRECTION}`,
            data,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const createTramitSchedule = async (data, ownerId, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.CREATE_TRAMIT_SCHEDULE}/${ownerId}`,
            data,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const createSchedule = async (data, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.CREATE_SCHEDULE}`,
            data,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};