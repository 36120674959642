import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { theme } from "../../../../theme/theme";

export const TermConditionsStyles = makeStyles(() => {
    return createStyles({
        mainContent: {
            width: '98vw',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '6vh',

            '& div.content-grid': {
                width: '90vw'
            }
        },

        headerGrid: {
            display: 'flex',
            flexDirection: 'row !important',
            alignItems: 'center',

            '& svg': {
                cursor: 'pointer',
                marginRight: '15px',
            },

            '& h5': {
                cursor: 'pointer',
                textDecoration: 'underline',
                marginBottom: '0px'
            }
        },
        breadcrumbsGrid: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column !important'
            },

            '& h6': {
                marginRight: '15px',
                marginBottom: '0px',
                color: theme.palette.black.secondary,
            }
        },
        contentTextGrid: {
            '& h5': {
                color: theme.palette.black.secondary,
                fontFamily: 'PoppinsBold',
            },
            '& h6': {
                color: theme.palette.black.secondary,
                fontFamily: 'PoppinsRegular',
            }
        },
        tabButtomsGrid: {
            [theme.breakpoints.only('xs')]: {
                display: 'flex !important',
                flexDirection: 'column !important',
                justifyContent: 'center !important',
                alignItems: 'center !important'
            },
        }

    });
});
