import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { ReactComponent as SuccessSignAuthIcon } from "../icons/success-sign-auth-icon.svg";

import { SignAuthorizationStyles } from "../index.css";
import ContactUs from "../molecules/contacUs";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
}));

const VerifiedIdentity = () => {
    const styles = SignAuthorizationStyles();

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Item className={styles.itemContent}>
                        <SuccessSignAuthIcon className="rest-icon rest-success-icon" />

                        <Typography
                            className={`${styles.mainTitle} ${styles.mainTitleError} ${styles.mainTitleSuccess}`}
                            variant="h5"
                            gutterBottom
                        >
                            Validación de identidad verificada con éxito
                        </Typography>

                        <Divider variant="fullWidth" />

                        <Typography
                            className={`${styles.errorInfoText} ${styles.successInfoText}`}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Ha validado su identidad con éxito. Si hay otros
                            participantes (firmantes) en este trámite, deberá
                            esperar a que todos validen su identidad. Una vez
                            que todos validen su identidad, se te enviará un
                            correo con los pasos siguientes.
                        </Typography>

                        {/* <Typography
                            className={`${styles.errorInfoText} ${styles.successInfoText}`}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Si su proceso de firma incluye certificación o
                            protocolización notarial, el documento será
                            protocolizado/certificado por la notaría en un plazo
                            máximo de 48-72 horas hábiles.
                        </Typography> */}

                        {/* <CustomButton
                            // onClick={() => navigate(to.LOGIN)}
                            onClick={() => window.close()}
                            variant="contained"
                            color="primary"
                        >
                            Cerrar
                        </CustomButton> */}

                        <Typography
                            className={styles.closeText}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Ya puede cerrar esta ventana.
                        </Typography>

                        <Divider variant="fullWidth" />

                        <ContactUs contactMarginTop={"30px"} />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default VerifiedIdentity;
