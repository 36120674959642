import { useContext } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import Grid from '@mui/material/Grid';

import { to, verifiVariable } from "../../../utils/enums";
import Steptracker from "../../dashboard/StepTracker";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import ContainerIndicator from "./molecules/containerIndicator";
import { Context } from "../../../context/utilsContext";

const SheduleHeader = () => {
    const { setToResumen } = useContext(Context);
    const screenSizeHook = ScreenSizeHook();
    const {
        scheduling: {
            fourthStep,
            thirdStep: {
                fileName
            },
            firstStep: {
                contractTypeFee
            },
            secondStep
        }
    } = useSelector((state) => state);
    const { pathname } = useLocation();
    const step1 = to.CREATE_SCHEDULE;
    const step2 = to.SCHEDULE_CREATION_SIGNATORIES;
    const step3 = to.SCHEDULE_CREATION_DOCUMENTS;
    const step4 = to.SCHEDULING_CREATION;
    const step5 = to.SCHEDULE_CREATION_PAY;

    return (
        <div className="content-detail__container">

            <Grid container spacing={4}>

                <Grid item xs={12} md={12} lg={8} xl={9}>

                    <br />

                    <div style={{justifyContent: (!verifiVariable(screenSizeHook) && screenSizeHook !== 'xs' ? 'center' : 'space-between') }} className="procedure-creation__track-container">

                        <Steptracker
                            cursor={(!verifiVariable(contractTypeFee) && pathname !== step1) ? 'pointer' : undefined}
                            onClick={() => {
                                if (!verifiVariable(contractTypeFee) && pathname !== step1) setToResumen(step1)
                            }}
                            step="1"
                            current={
                                pathname === step1
                                    ? true
                                    : false
                            }
                            title="Datos del trámite"
                        // subtitle="Tipo de documento de trámite"
                        />

                        <Steptracker
                            cursor={(secondStep.length !== 0 && pathname !== step2) ? 'pointer' : undefined}
                            onClick={() => {
                                if (secondStep.length !== 0 && pathname !== step2) setToResumen(step2)
                            }}
                            step="2"
                            current={
                                pathname === step2
                                    ? true
                                    : false
                            }
                            title="Participantes"
                        // subtitle="Datos de los solicitantes"
                        />

                        <Steptracker
                            cursor={(!verifiVariable(fileName) && pathname !== step3) ? 'pointer' : undefined}
                            onClick={() => {
                                if (!verifiVariable(fileName) && pathname !== step3) setToResumen(step3)
                            }}
                            step="3"
                            current={
                                pathname === step3
                                    ? true
                                    : false
                            }
                            title="Documentos"
                        // subtitle="Documentos a firmar"
                        />

                        <Steptracker
                            cursor={(!verifiVariable(fourthStep) && pathname !== step4) ? 'pointer' : undefined}
                            onClick={() => {
                                if (!verifiVariable(fourthStep) && pathname !== step4) setToResumen(step4)
                            }}
                            step="4"
                            current={
                                pathname === step4
                                    ? true
                                    : false
                            }
                            title="Agendamiento"
                        // subtitle="Agendamiento"
                        />

                        <Steptracker
                            step="5"
                            cursor={(!verifiVariable(fourthStep) && !verifiVariable(fileName) && secondStep.length !== 0 && !verifiVariable(contractTypeFee) && pathname !== step5) ? 'pointer' : undefined}
                            onClick={() => {
                                if (!verifiVariable(fourthStep) && !verifiVariable(fileName) && secondStep.length !== 0 && !verifiVariable(contractTypeFee) && pathname !== step5) setToResumen(step5)
                            }}
                            current={
                                pathname === step5
                                    ? true
                                    : false
                            }
                            title="Pago"
                        // subtitle="Confirmación de datos"
                        />

                    </div>

                    {!['xl', 'lg'].includes(screenSizeHook) && (<Grid item xs={12} md={12} lg={4} xl={3}>

                        <br />
                        <br />

                        <ContainerIndicator />

                    </Grid>)}

                    <br />
                    <br />

                    <div className="procedure-creation__container">

                        <Outlet />

                    </div>

                </Grid>

                {['xl', 'lg'].includes(screenSizeHook) && (<Grid item xs={12} md={12} lg={4} xl={3}>

                    <br />
                    <br />

                    <ContainerIndicator />

                </Grid>)}

            </Grid>

        </div>
    );
}

export default SheduleHeader;