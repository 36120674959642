import React from "react";
import { Outlet } from "react-router-dom";
import Steptracker from "../dashboard/StepTracker";

const HeaderContracFileUpload = () => {
    /* const { pathname } = useLocation();
    const step1 = to.CONTRACT_CREATION;
    const step2 = to.CONTRACT_CREATOR_DOCS; */

    return (
        <div className="content-detail__container">
            <div className="procedure-creation__container">
                <div className="procedure-creation__track-container">
                    <Steptracker
                        step="1"
                        /* current={
                            pathname === step1
                                ? true
                                : false
                        } */
                        title="Adjunta tus documentos"
                        subtitle="Documentos solicitados a firmar"
                    />
                    <Steptracker
                        step="2"
                        /* current={
                            pathname === step2
                                ? true
                                : false
                        } */
                        title="Agregar firmas"
                        subtitle="Participantes solicitados a firmar"
                    />

                    <Steptracker
                        step="3"
                        /* current={
                            pathname === step2
                                ? true
                                : false
                        } */
                        title="Guardar cambios"
                        subtitle="Confirmar cambios para enviar el documento"
                    />
                </div>
                <Outlet />
            </div>
        </div>
    );
};

export default HeaderContracFileUpload;
