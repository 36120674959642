import { Box } from "@mui/system";
import React from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { Text } from "../../../..";
import { styles } from "../../../../../helpers";

const RenderContractDetail = ({
    data: { sContractID, sContractTypeName},
}) => {
    const customBox = {
        display: "flex",
    };
    const customSub = {
        fontSize: "12px",
        color: "quinary.dark",
    };

    return (
        <Box sx={[styles.userAvatarContainerStyles, customBox]}>
            <FaRegFileAlt
                className="icon--xs icon--blue-light icon-pointer"
                style={{ marginRight: "10px" }}
            />
            <Box sx={styles.userNameContainerStyles}>
                <Text sx={[styles.subTitleStyles, customSub]} variant="h6">
                    {sContractID}
                </Text>
                <Text sx={styles.userNameStyles} variant="h6">
                    {sContractTypeName}
                </Text>
            </Box>
        </Box>
    );
};

export default RenderContractDetail;
