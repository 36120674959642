import { useState, useEffect } from 'react';

const useDeviceType = () => {
    const [isMobile, setIsMobile] = useState(null);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Verificamos el dispositivo utilizando patrones comunes en los user agents de móviles
        const isMobileDevice = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

        setIsMobile(isMobileDevice);
    }, []);

    return isMobile;
};

export default useDeviceType;