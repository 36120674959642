import { forwardRef, Fragment } from 'react';
import { useForm } from 'react-hook-form';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import Btn from '../../../../buttons/Button';
import { AddSignaturesStyles } from '../../index.css';



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AddSign = ({ setOpenSigModal, openSigModal, signatory, signatories }) => {
    const styles = AddSignaturesStyles();

    const { getValues, register, reset } = useForm({
        defaultValues: {
            selectedSign: ''
        }
    });

    const handleClose = () => {
        signatory(getValues('selectedSign'));
        setOpenSigModal(false);
        reset();
    };

    return (
        <Fragment>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={openSigModal}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    ¿Quién debe firmar aquí?
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>
                        Selecciona el firmante que quieres agregar al documento.
                    </DialogContentText>

                    <List dense className={styles.signatoriesList}>

                        <RadioGroup>

                            {signatories.map((item, index) => Object.assign({ ...item, sign: index })).map((item) => {
                                return (
                                    <ListItem
                                        key={item['emai']}
                                        secondaryAction={
                                            <FormControlLabel value={JSON.stringify(item)} control={<Radio {...register("selectedSign")} />} />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemText
                                                disableTypography
                                                id={`checkbox-list-secondary-label-${item['emai']}`}
                                                primary={<Typography variant='h5' color={[item['textColor']]}>
                                                    {item['full_name'].length >= 16 ? item['full_name'].substr(0, 15) + '...' : item['full_name']}
                                                </Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}

                        </RadioGroup>

                    </List>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={() => setOpenSigModal(false)}
                        sx={{
                            height: '39px',
                            borderRadius: '20px'
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Agregar"
                        color="primary"
                        onClick={() => handleClose()}
                        sx={{
                            height: '39px',
                            borderRadius: '20px',
                            marginLeft: '30px !important'
                        }}
                    />

                </DialogActions>

            </Dialog>

        </Fragment>
    );
}

export default AddSign;