import React, { useState } from "react";

import { Outlet } from "react-router-dom";


import { FaBars } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ReactComponent as Logo } from "../../../src/assets/logo/logo.svg";


import MenuAside from "./MenuAside";
import MenuTop from "./MenuTop";
import AppBarMenu from "./appBarMenu/AppBarMenu";
import { theme } from "../../theme/theme";
// import { useTheme } from '@mui/material/styles';


const drawerWidth = 317;
// const drawerWidth = 0;
const appBarHeight = 100;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        maxWidth: '100%',
        flexGrow: 1,
        backgroundColor: '#f8faff',
        paddingTop: theme.spacing(5) + " !important",
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            })
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: 'white',
    boxShadow: '0 10px 20px 0 rgb(160 170 199 / 10%)',
    height: appBarHeight,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const ContentWrapper = (props) => {
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const toggleDrawer = () => setOpen(false);

    if (isMobile) {

        return (

            <div className="dashboard-wrapper">

                <Box sx={{ display: 'flex' }}>

                    <CssBaseline />

                    <AppBar position="fixed" open={open}>

                        <Toolbar>
                            <IconButton
                                color="black.main"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2 }}
                            >
                                <FaBars />
                            </IconButton>

                            <AppBarMenu appBarHeight={appBarHeight} />

                        </Toolbar>

                    </AppBar>

                    <Drawer
                        anchor={"left"}
                        open={open}
                        onClose={toggleDrawer}
                    >

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                height: 110
                            }}
                        >
                            <Logo className="logo-size--xs" />
                        </div>

                        <Divider />

                        <div className="content__container">

                            <MenuAside {...props} />

                        </div>

                    </Drawer>

                    <Main open={open}>

                        <DrawerHeader />

                        <Outlet />

                    </Main>

                </Box>

            </div>

        );

    } else {

        return (
            <div className="dashboard-wrapper">

                <MenuTop />

                <div className="content__container">

                    <MenuAside {...props} />

                    <Outlet />

                </div>

            </div>
        );

    }

};

export default ContentWrapper;
