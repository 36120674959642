import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ContractListHeader from "../ContractListHeader";
import AddProvider from "./molecules/AddProvider";
import { getContractsCreated } from "../../../store/contract/contractDetail";
import { Box } from "@mui/system";
import { CustomToobarTableList, GeneralListProced, Spinner } from "../..";
import { setearFecha, to, verifiVariable } from "../../../utils/enums";

const DocManagement = () => {
    const {
        session: {
            login: { user },
        },
        contract: {
            contractCreator: { contractListActive, loading },
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [openForm, setOpenForm] = useState(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [sProcess, setSProcess] = useState("");

    useEffect(() => {
        const { userId, token, profileId } = user;

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        const { status, message } = contractListActive;

        if (["success"].includes(status)) {
            if (
                !verifiVariable(state) &&
                !verifiVariable(state["list"]) &&
                state["list"] === "EXPRESS"
            ) {
                setSProcess("EXPRESS");
                setDataGrid(
                    message.filter(
                        (item) =>
                            item["withDocument"] === 1 &&
                            item.sProcess === "EXPRESS"
                    )
                );
            } else {
                setSProcess("NORMAL");
                setDataGrid(
                    message.filter(
                        (item) =>
                            item["withDocument"] === 1 &&
                            item.sProcess === "NORMAL"
                    )
                );
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractListActive]);

    const updateData = () => {
        const { userId, token, profileId } = user;

        setSpinner(true);

        if (![null, undefined].includes(userId)) {
            dispatch(getContractsCreated(userId, token, profileId));
        }

        setTimeout(() => {
            setSpinner(false);
        }, 5000);
    };

    /* useEffect(()=>{
       console.log("window.history: ", window.history.replaceState({}, "", undefined)) 
    },[]); */

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarTableList
                    title={
                        sProcess !== "EXPRESS"
                            ? "Documentos por tramite."
                            : "Trámites  expressss"
                    }
                    subTitle={
                        "Aqui podrás visualizar todos los trámites que tienen un documento adjunto."
                    }
                    onUpdate={() => updateData()}
                    onCreate={() =>
                        navigate(
                            sProcess !== "EXPRESS"
                                ? to.CONTRACT_CREATION
                                : to.EXPRESS_CONTRACT_CREATION
                        )
                    }
                />
                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListProced
                                dataGrid={[...dataGrid].map((item) => {
                                    return {
                                        sProcess: item["sProcess"],
                                        sContractID: item["sContractID"],
                                        autoId: item["autoId"],
                                        sContractName: item["sContractName"],
                                        sTipoContrato: item["sTipoContrato"],
                                        sTipoFirma: item["sTipoFirma"],
                                        sStatus: item["sStatus"],
                                        sPaymentStatus: item["sPaymentStatus"],
                                        dContractDT: setearFecha(item["dContractDT"]),
                                        withDocument: item["withDocument"],
                                        iSignedCount: item["iSignedCount"]
                                    };
                                })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid, loading, state]);

    return (
        <div className="content-detail__container">
            <AddProvider
                openForm={openForm}
                closeForm={(e) => setOpenForm(e)}
            />
            <ContractListHeader />

            {spinner || loading ? (
                <Box
                    sx={{ height: "150px" }}
                    className="container--relative contracts-status-list__container"
                >
                    <Spinner loading={true} type="bar" opacity={true} />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    );
};

export default DocManagement;
