import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMemo } from 'react';

const ScreenSizeHook = () => {

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.only('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    const md = useMediaQuery(theme.breakpoints.only('md'));
    const lg = useMediaQuery(theme.breakpoints.only('lg'));
    const xl = useMediaQuery(theme.breakpoints.only('xl'));

    const screen = useMemo(() => {

        if(xs) return 'xs';

        if(sm) return 'sm';

        if(md) return 'md';

        if(lg) return 'lg';

        if(xl) return 'xl';
      
    }, [xs, sm, md, lg, xl]);

    return screen;
};

export default ScreenSizeHook;