import React from "react";
import { useNavigate } from "react-router-dom";

import { to, verifiVariable } from "../../../../../utils/enums";
import { StatusTrackerItem, Gobackbutton } from "../../../..";
import { ExpressProcessStyles } from "../../index.css";

const Tracker = ({ location, status, showGoback, paymentStatus }) => {
    const styles = ExpressProcessStyles();
    const navigation = useNavigate();

    const setLink = () => {
        if (!verifiVariable(location)) {
            switch (location) {
                case to.CONTRACT_DETAIL:
                    return navigation(to.CONTRACT_STATUS);
                case to.CONTRACTS_PAYMENT:
                    return navigation(to.HOME);
                case to.CONTRACTS_SIGNATURE:
                    return navigation(to.HOME);
                case to.CONTRACTS_NOTARY:
                    return navigation(to.HOME);
                case to.CONTRACTS_DELIVERY:
                    return navigation(to.HOME);
                default:
                    return navigation(to.HOME);
            }
        }
    };

    /* const statusReview = () => {
        if (
            status === "REJECTED" ||
            status === "APPROVED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED" ||
            status === "PAIDOUT" ||
            status === "PENDING" ||
            status === "PARTIALLY_PAID" ||
            status === "SIGNED_PENDING" ||
            status === "DELIVERED"
        ) {
            return true;
        }
        return false;
    }; */

    const statusPayment = () => {
        if (
            status === "PAIDOUT" ||
            status === "SIGNED_PENDING" ||
            status === "DELIVERED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED"
        ) {
            return true;
        } else if (["PENDING", "PARTIALLY_PAID"].includes(paymentStatus)) {
            return false;
        }

        return "";
    };

    const statusSignature = () => {
        if (
            status === "PAIDOUT" ||
            status === "DELIVERED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED"
        ) {
            return true;
        } else if (["SIGNED_PENDING", "NOTHING"].includes(status)) {
            return false;
        }

        return "";
    };

    const statusNotary = () => {
        if (status === "FINISHED") {
            return true;
        }
        return "";
    };

    return (
        <div
            className={`contract-details__header ${styles.contractDetailsHeader}`}
        >
            {showGoback && (
                <div className="breadcrumbs-navigation">
                    <Gobackbutton gobackTitle="Volver" />
                </div>
            )}
            <ul className="status-tracker">
                {/* <StatusTrackerItem
                    sStatus={status}
                    title="Revisión"
                    focused={statusReview()}
                    onClick={setLink}
                /> */}
                <StatusTrackerItem
                    sStatus={status}
                    title="Pago"
                    focused={statusPayment()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    sStatus={status}
                    title="Firma"
                    focused={statusSignature()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    from={'express'}
                    sStatus={status}
                    title="Notaria"
                    subTile="(48 - 72 hrs)"
                    focused={statusNotary()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    sStatus={status}
                    title="Entrega"
                    focused={status === "FINISHED" ? true : ""}
                    onClick={setLink}
                />
            </ul>
        </div>
    );
};

export default Tracker;
