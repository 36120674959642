import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import { SignAuthorizationStyles } from "../../index.css";

const ContactUs = ({ contactMarginTop }) => {
    const styles = SignAuthorizationStyles({ contactMarginTop });

    return (
        <Box className={styles.contacUsContent}>
            <Typography variant="subtitle1" gutterBottom>
                Para más información comuníquese con nuestro equipo de soporte:
            </Typography>

            <div className="icons">
                <div
                    className="icon-contact"
                    onClick={() =>
                        window.open(
                            "https://api.whatsapp.com/send?phone=56945227304",
                            "_blank",
                            "noopener,noreferrer"
                        )
                    }
                >
                    <WhatsAppIcon />
                </div>
                <div
                    className="icon-contact"
                    onClick={() => (window.location.href = "tel:+56945227304")}
                >
                    <PhoneIcon />
                </div>
                <div
                    className="icon-contact"
                    onClick={() =>
                        (window.location.href =
                            "mailto:contacto@firmavirtual.legal")
                    }
                >
                    <MailIcon />
                </div>
            </div>

            <div className="num-contact">
                <a
                    href="tel:+56945227304"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    +56 9 4522 7304
                </a>{" "}
                /
                <a
                    href="tel:+56975635393"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    +56 9 7563 5393
                </a>{" "}
                /
                <a
                    href="tel:+56997862790"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    +56 9 9786 2790
                </a>
            </div>

            <Typography variant="subtitle1" gutterBottom>
                contacto@firmavirtual.legal
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
                ¡Gracias por usar nuestros servicios!
            </Typography>
        </Box>
    );
};

export default ContactUs;
