import { createStyles, makeStyles } from "@mui/styles";
import { theme } from "../../theme/theme";

export const ModalAlertStyles = makeStyles(() => {
    return createStyles({
        modalContent: {
            minHeight: 353,
            '& h2,p': {
                color: theme.palette.primary.main,
                textAlign: "center",
            },
            '& p': {
                padding: '0 23px',
            },
            '& h2.title': {
                marginTop: 50,
                fontFamily: "PoppinsBold",
            },
            '& .subtitle_box_container': {
                with: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0 0 7rem 0'
            },
            '& .subtitle_box': {
                position: 'relative',
                backgroundColor: theme.palette.error.xxlight,
                border: `1px solid ${theme.palette.error.xlight}`,
                borderLeft: `3px solid ${theme.palette.error.xlight}`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '.5rem 0',
                width: '90%',
                minHeight: '2.5rem'
            },
            '& .subtitle_icon': {
                position: 'absolute',
                left: '0',
                marginLeft: '.2rem'
            },
        },
        headerContent: {
            height: '57px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.light,

            '& svg.modal-icon': {
                position: 'fixed',
                left: '50%',
                top: '50px',
                transform: 'translate(-50%, -50%)'
            },

            '& .close-icon_Box': {
                alignSelf: 'flex-end',
                marginRight: 10
            },

            '& svg.close-icon': {
                cursor: 'pointer',
                color: theme.palette.white.main,
                alignSelf: 'center',
            }
        },
        footerContent: {
            position: 'fixed',
            bottom: 0,
            height: '67px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#F2F5F8',
            borderRadius: '0px 0px 7px 7px',
            alignItems: 'center',
            '& button': {
                width: 117
            }
        },
    });
});