import React, { useState, useEffect } from "react";
// import { to } from "../../utils/enums";
import { Text, Btn } from "..";
import { AiOutlineReload } from "react-icons/ai";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { BiDownload } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';
import { styles } from "../../helpers";

const ManageDiscountCoupons = () => {
    // const navigate = useNavigate();
    const loading = false;
    const [users, setUsers] = useState([]);
    const [key, setKey] = useState("");

    useEffect(() => {
        let run = true;
        if (run) {
            genKey();

            setUsers([
                {
                    codigo: "codigo1",
                    tipoCupon: "tipoCupon1",
                    importCupon: "importCupon1",
                    descripcion: "descripcion1",
                    aplicCupon: "aplicCupon1",
                    useLimit: "useLimit1",
                    create: "create1",
                    userClient: "userClient1",
                    id: "id1"
                },
                {
                    codigo: "codigo2",
                    tipoCupon: "tipoCupon2",
                    importCupon: "importCupon2",
                    descripcion: "descripcion2",
                    aplicCupon: "aplicCupon2",
                    useLimit: "useLimit2",
                    create: "create2",
                    userClient: "userClient2",
                    id: "id2"
                }
            ]);

        }
        return () => run = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const genKey = () => {
        setKey(Math.random().toString().split(".").reduce((a, b) => a + b));
    }

    const userActionTrigger = (id) => {

        return (
            <Box sx={{
                position: "relative",
                display: "flex",
                alignContent: 'center'
            }} className="flex--1 flex--center">

                <Box sx={actionButtonsStyles} onClick={() => console.log('click')}>{/* navigate(to.CONTRACT_DETAIL, { state: id } */}
                    <BsPencilSquare className="icon--xs icon-pointer icon--blue-light" />
                </Box>

                <Box sx={actionButtonsStyles} onClick={() => console.log('click')}>{/* navigate(to.CONTRACT_DETAIL, { state: id } */}
                    <BsTrash className="icon--xs icon--blue-light icon-pointer" />
                </Box>

            </Box>
        )
    }

    const RenderCell = ({ text }) => {

        return (
            <div className="flex--1 text-align--center">
                <Text> {text} </Text>
            </div>
        )
    }

    const gridColumns = [
        {
            field: "codigo",
            headerAlign: "center",
            headerName: "Codigo",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item text--center",
            width: 100,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "tipoCupon",
            headerAlign: "center",
            headerName: "Tipo Cupón",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 140,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "importCupon",
            headerAlign: "center",
            headerName: "Impor. Cupón",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 140,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "descripcion",
            headerAlign: "center",
            headerName: "Descripción",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item text--center",
            width: 300,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "aplicCupon",
            headerAlign: "center",
            headerName: "Aplic. Cupón",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 180,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "useLimit",
            headerAlign: "center",
            headerName: "Limite Uso",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 150,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "create",
            headerAlign: "center",
            headerName: "Creación",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 140,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "userClient",
            headerAlign: "center",
            headerName: "Cliente/Usuario",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 200,
            renderCell: (params) => <RenderCell text={params.value} />
        },
        {
            field: "id",
            headerAlign: "center",
            headerName: "Acciones",
            headerClassName: "grid__header-item",
            rowClassName: "grid__row-item",
            width: 120,
            disableColumnMenu: true,
            renderCell: (params) => userActionTrigger(params.value),
        },
    ];

    const buttonStatusMenu = {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
        }
    };

    const buttonGridStatusMenu = {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
            backgroundColor: "primary.dark",
        }
    };

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    };

    const subTitleStyles = {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
    };

    const gridContainerStyles = {
        border: "none",
    };

    const actionButtonsStyles = {
        padding: "6px",
    }

    const localizedTextsMap = {
        columnMenuUnsort: "Sin ordenar",
        columnMenuSortAsc: "De menor a mayor",
        columnMenuSortDesc: "De mayor a menor",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas",
        toolbarExport: "Descargar",
        toolbarFilters: "Filtros",
        filterOperatorContains: "Contiene",
        filterOperatorEquals: "Igual a",
        filterOperatorStartsWith: "Empieza con",
        filterOperatorEndsWith: "Termina con",
        filterOperatorIsEmpty: "Esta vacio",
        filterOperatorIsNotEmpty: "No esta vacio",
        filterPanelInputLabel: "Valor",
        filterPanelInputPlaceholder: "Filtrar por valor",
    };

    const customToolbar = () => {
        return (
            <div className="contracts-list__top-menu">
                <div className="title-menu__status">
                    <Text sx={titleStyles} variant="h6">
                        Cupones de descuento
                    </Text>
                    <Text sx={subTitleStyles}>
                        Resumen de cupones
                    </Text>
                </div>
                <GridToolbarContainer className="buttons-menu__status">
                    <GridToolbarFilterButton
                        title="Filtros"
                        // translate={true}
                        lang="es"
                        arrow={false}
                        className="grid-toolbar"
                        sx={buttonGridStatusMenu} />
                    <GridToolbarExport
                        title="Descargar"
                        // translate={true}
                        lang="es"
                        sx={buttonGridStatusMenu}
                        startIcon={<BiDownload className="icon--xs icon--blue-light" />}
                        csvOptions={{
                            allColumns: true,
                            fields: [
                                "codigo",
                                "tipoCupon",
                                "importCupon",
                                "descripcion",
                                "aplicCupon",
                                "useLimit",
                                "create",
                                "userClient",
                                "id"
                            ]
                        }} />
                    <Btn
                        variant="contained"
                        text="Actualizar"
                        sx={buttonStatusMenu}
                        onClick={genKey}
                        startIcon={
                            <AiOutlineReload className="icon--xs icon--blue-light" />
                        }
                    />
                    <Btn
                        variant="contained"
                        text="Crear Cupón"
                        sx={styles.buttonGoToCreatorMenu}
                    />
                </GridToolbarContainer>
            </div>
        );
    }

    return (
        <div className="content-detail__container" key={key}>

            <div className="contracts-status-list__container" style={{ height: 'auto', width: '100%' }}>

                <div style={{ display: 'flex', height: '100%' }}>

                    <div style={{ flexGrow: 1 }}>

                        <DataGrid
                            rows={users}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            loading={loading}
                            getRowId={(row) => row.id}
                            rowHeight={80}
                            sx={gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: customToolbar,
                                NoRowsOverlay: () => <div className="data-grid__no-rows">No hay registros</div>,
                            }}
                        />

                    </div>

                </div>

            </div>

        </div>
    );
};

export default ManageDiscountCoupons;
