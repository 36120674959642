import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Text from '../../../../heading/Text';

import { validation } from "../../../../../utils/validations";



const AddProvider = ({ openForm, closeForm }) => {

    const labelStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        fontSize: "0.95rem",
    };

    const [open, setOpen] = useState(false);
    const fullWidth = true;
    const maxWidth = 'xl';
    const { control, handleSubmit } = useForm({
        defaultValues: {
            id: 0,
            nombreNotaria: '',
            responsable: '',
            correo: '',
            telefono: '',
            jurisdiccion: ''
        }
    });

    useEffect(() => {
        setOpen(openForm);
    }, [openForm]);

    const handleClose = () => {
        closeForm(false);
    };

    const onSubmit = (data) => {
        console.log('onSubmit', data);
        handleClose();
    };

    return (

        <Fragment>

            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle id="responsive-dialog-title" align='center'>
                    Crear Nuevo Proveedor
                </DialogTitle>

                <div className="procedure-creation__form-container">

                    <div className="form__container">

                        <form onSubmit={handleSubmit(onSubmit)}>

                            <DialogContent>

                                <DialogContentText /* color={'error'} */>
                                    Ingrese los datos solicitados.
                                </DialogContentText>

                                <div className="input-container">
                                    <label>
                                        <Text
                                            sx={labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Nombre Notaria
                                        </Text>
                                    </label>

                                    <Controller
                                        name="nombreNotaria"
                                        control={control}
                                        rules={{
                                            required: 'Nombre Notaria es requerido'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (

                                                <Fragment>

                                                    <input
                                                        type="text"
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={'Nombre Notaria'}
                                                    />

                                                    {fieldState.error && (
                                                        <span className="input--error">
                                                            {fieldState.error.message}
                                                        </span>
                                                    )}

                                                </Fragment>

                                            );
                                        }}
                                    />

                                </div>

                                <div className="input-container">
                                    <label>
                                        <Text
                                            sx={labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Responsable
                                        </Text>
                                    </label>

                                    <Controller
                                        name="responsable"
                                        control={control}
                                        rules={{
                                            required: 'El Responsable es requerido'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (

                                                <Fragment>

                                                    <input
                                                        type="text"
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={'Responsable'}
                                                    />

                                                    {fieldState.error && (
                                                        <span className="input--error">
                                                            {fieldState.error.message}
                                                        </span>
                                                    )}

                                                </Fragment>

                                            );
                                        }}
                                    />

                                </div>

                                <div className="input-container">
                                    <label>
                                        <Text
                                            sx={labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Correo
                                        </Text>
                                    </label>

                                    <Controller
                                        name="correo"
                                        control={control}
                                        rules={{
                                            required: 'El Correo es requerido',
                                            pattern: {
                                                value: validation.email,
                                                message: 'Correo invalido'
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (

                                                <Fragment>

                                                    <input
                                                        type="text"
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={'correo'}
                                                    />

                                                    {fieldState.error && (
                                                        <span className="input--error">
                                                            {fieldState.error.message}
                                                        </span>
                                                    )}

                                                </Fragment>

                                            );
                                        }}
                                    />

                                </div>

                                <div className="input-container">
                                    <label>
                                        <Text
                                            sx={labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Teléfono
                                        </Text>
                                    </label>

                                    <Controller
                                        name="telefono"
                                        control={control}
                                        rules={{
                                            required: 'El numero de Teléfono es requerido',
                                            pattern: {
                                                value: validation.phone,
                                                message: 'Numero invalido'
                                            }
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (

                                                <Fragment>

                                                    <input
                                                        type="text"
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={'+56912345478'}
                                                    />

                                                    {fieldState.error && (
                                                        <span className="input--error">
                                                            {fieldState.error.message}
                                                        </span>
                                                    )}

                                                </Fragment>

                                            );
                                        }}
                                    />

                                </div>

                                <div className="input-container">
                                    <label>
                                        <Text
                                            sx={labelStyles}
                                            align="left"
                                            mgButton
                                            variant="h6"
                                        >
                                            Jurisdicción
                                        </Text>
                                    </label>

                                    <Controller
                                        name="jurisdiccion"
                                        control={control}
                                        rules={{
                                            required: 'La Jurisdicción es requerido'
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                                            return (

                                                <Fragment>

                                                    <input
                                                        type="text"
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        value={value}
                                                        placeholder={'Jurisdicción'}
                                                    />

                                                    {fieldState.error && (
                                                        <span className="input--error">
                                                            {fieldState.error.message}
                                                        </span>
                                                    )}

                                                </Fragment>

                                            );
                                        }}
                                    />

                                </div>


                            </DialogContent>

                            <DialogActions>

                                <Button autoFocus onClick={handleClose}>
                                    Cancelar
                                </Button>

                                <Button type='submit' autoFocus>
                                    Add
                                </Button>

                            </DialogActions>

                        </form>

                    </div>

                </div>

            </Dialog>

        </Fragment>

    );

};

export default AddProvider;