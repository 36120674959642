import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { FormularioUachtyles } from "./index.css";
import Logo from "../../assets/logo/uach-logo.png";
import { clearCreateContract, getContractsTypes, resetContract } from "../../store/contract/contractDetail";
import Stepsper from "./molecules/stepsper";
import { endSession, logSession } from "../../store/auth/userLogin";
import { clearForm } from "../../store/formUach/formUach";
import { api } from "../../utils/enums";


const FormularioUach = () => {
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const styles = FormularioUachtyles();
    const [showSteps, setShowSteps] = useState(false);


    useEffect(() => {
        endSession();
        dispatch(clearForm());
        dispatch(resetContract());
        dispatch(clearCreateContract());

        dispatch(
            logSession({
                login: api.USUARIO_UACH,
                password: api.PASSWORD_UACH
            })
        );
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { token } = user;

        if (![null, undefined, ''].includes(token)) dispatch(getContractsTypes(token));
        // eslint-disable-next-line
    }, [user]);

    return (
        <Fragment>

            {!showSteps && (<Grid className={styles.textContent} container>

                <img src={Logo} className={styles.logo} alt='' />

                <Typography className={styles.texto2} variant="body2" gutterBottom>

                    Gracias por iniciar esta solicitud de firma de declaración jurada de ingresos para la <strong>Universidad Austral de Chile (UACh).</strong>
                    Una vez completada será procesada a la brevedad en <strong>horario hábil de las notarías (sin contar sábados, domingos ni festivos).</strong> La notaría certificará el documento dentro de <strong>máximo 12 horas hábiles.</strong>

                </Typography>


                <br />


                <Typography className={styles.texto1} variant="body2" gutterBottom>
                    Si aún tienes dudas, por favor llámanos antes de completar esta solicitud:
                </Typography>

                <br />

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="mailto:contacto@firmavirtual.legal" rel="noopener noreferrer" target='_blank'>contacto@firmavirtual.legal</a>
                </Typography>

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="https://wa.me/56945227304" rel="noopener noreferrer" target='_blank'>+56 9 4522 7304</a>
                </Typography>

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="https://wa.me/56975635393" rel="noopener noreferrer" target='_blank'>+56 9 7563 5393</a>
                </Typography>

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="https://wa.me/56997862790" rel="noopener noreferrer" target='_blank'>+56 9 9786 2790</a>
                </Typography>

                <br />

                <Typography className={styles.texto1} variant="body2" gutterBottom>
                    (el horario de soporte es de Lunes a Viernes de 9 -19hrs, exceptuando feriados)
                </Typography>

                <br />

                <Typography className={styles.texto1} variant="body2" gutterBottom>
                    Gracias.
                </Typography>

                <br />

                <Typography className={styles.texto1} variant="body2" gutterBottom>
                    Saludos cordiales,
                </Typography>

                <br />

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="https://firmavirtual.legal/" rel="noopener noreferrer" target='_blank'>Equipo FirmaVirtual</a>
                </Typography>

                <br />

                <Typography className={styles.texto3} variant="body2" gutterBottom>
                    <a href="https://firmavirtual.legal/terminos-y-condiciones-politica-de-privacidad/" rel="noopener noreferrer" target='_blank'>Términos y Condiciones & Política de Privacidad</a>
                </Typography>

                <br />

                <br />

                <Grid container className={styles.buttomBox} style={{ justifyContent: 'center' }}>

                    <Button
                        variant="contained"
                        color="customFormButtomSolicitud"
                        size="large"
                        onClick={() => setShowSteps(true)}
                    >
                        COMENZAR
                    </Button>

                    <Typography id="textPulsar" variant="body2" gutterBottom>
                        Pulsa <strong>Enter</strong>
                    </Typography>

                    {/* <BiSubdirectoryLeft /> */}

                </Grid>

                <br />

                <br />

            </Grid>)}

            {showSteps && (<Stepsper />)}

        </Fragment>
    );
}

export default FormularioUach;