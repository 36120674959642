import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

import { ExpressProcessStyles } from "../../index.css";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as CardIcon } from "../../../../../assets/icons/icon-rolPayer-initialState.svg";
import { ReactComponent as CardIconDisabled } from "../../../../../assets/icons/icon-rolPayer-disableState.svg";
import { ReactComponent as CardIconHover } from "../../../../../assets/icons/icon-rolPayer-hoverState.svg";
import { ReactComponent as CardSelectedIcon } from "../../../../../assets/icons/icon-rolPayer-selectedState.svg";
import { ReactComponent as Close } from "../../../../../assets/icons/icon-close-gray-light.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/icons/icon-rolCopy-initialState.svg"
//import { ReactComponent as MailIconDisabled } from "../../../../../assets/icons/icon-rolCopy-disabledState.svg"
import { ReactComponent as MailIconHover } from "../../../../../assets/icons/icon-rolCopy-hoverState.svg"
import { ReactComponent as MailIconSelected } from "../../../../../assets/icons/icon-rolCopy-selectedState.svg"
import { ReactComponent as PendIcon } from "../../../../../assets/icons/icon-rolSigner-initialState.svg";
import { ReactComponent as PendIconDisabled } from "../../../../../assets/icons/icon-rolSigner-disabledState.svg";
import { ReactComponent as PenIconHover } from "../../../../../assets/icons/icon-rolSigner-hoverState.svg";
import { ReactComponent as PenSelectedIcon } from "../../../../../assets/icons/icon-rolSigner-selectedState.svg";
import { ReactComponent as SigPayHover } from "../../../../../assets/icons/icon-rolPayerSigner-hoverState.svg";
import { ReactComponent as SigPayIcon } from "../../../../../assets/icons/icon-rolPayerSigner-initialState.svg";
import { ReactComponent as SigPayIconDisabled } from "../../../../../assets/icons/icon-rolPayerSigner-disabledState.svg";
import { ReactComponent as SigPaySeletedIcon } from "../../../../../assets/icons/icon-rolPayerSigner-selectedState.svg";

import {
    checkRut,
    formaRut,
    validatePhoneNumber,
    validation,
} from "../../../../../utils/validations";
import { saveTramitExpressInfo } from "../../../../../store/formExpressTramit";
import { randomRgba, verifiVariable } from "../../../../../utils/enums";
import { theme } from "../../../../../theme/theme";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import { Box, CircularProgress, IconButton } from "@mui/material";
import InfoMessage from "../../../contentWelcome/molecules/infoMessage";
//import { mailValidate } from "../../../../common/services";

const CustomAddButton = styled(Button)((props) => ({
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.813rem",
    padding: "15px 26px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const AddParticipants = ({ openAddDiag }) => {
    const screenSizeHook = ScreenSizeHook();
    const {
        formExpressTramit: { participants, editSigner },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const styles = ExpressProcessStyles();
    const [cardIcon, setCardIcon] = useState(<CardIcon style={{ width: '3.5rem' }} />);
    const [penIcon, setPenIcon] = useState(<PendIcon style={{ width: '3.5rem' }} />);
    const [cardPenIcon, setCardPenIcon] = useState(<SigPayIcon style={{ width: '3.5rem' }} />);
    const [emailIcon, setEmailIcon] = useState(<MailIcon style={{ width: '3.5rem' }} />);
    const [validEmail, setValidEmail] = useState(false);
    const [disabledCheckSigner, setDisabledCheckSigner] = useState(false);
    const [isPayDisabled, setIsPayDisabled] = useState(false);
    //const [formValidated, setFormValidated] = useState(false);
    const [addParticipantLoader, setAddParticipantLoader] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
        watch,
        clearErrors,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            rol: "",
            rut: "",
            fullName: "",
            phone: "",
            pay: "0",
            signerOrder: 1,
            documentIDType: "Rut"
        },
    });
    const [open, setOpen] = useState(false);

    const watchRol = watch("rol");
    const watchForm = watch();
    const watchDocumentIDType = watch('documentIDType');

    const handleClose = () => {
        setValue("rol", "");
        setValue("pay", "0");
        clearErrors();
        setValidEmail(false)
        if (!verifiVariable(editSigner)) {

            reset({
                email: "",
                rol: "",
                rut: "",
                fullName: "",
                phone: "",
                pay: "0",
                signerOrder: 1,
                documentIDType: "Rut"
            });

            const formInfo = { ...watchForm };

            delete formInfo.rol;
            delete formInfo.pay;

            dispatch(
                saveTramitExpressInfo({
                    indexe: "formParticipants",
                    value: formInfo,
                })

            );
            dispatch(
                saveTramitExpressInfo({
                    indexe: "editSigner",
                    value: undefined,
                }),
            );
        }

        setOpen(false);
    };

    const onsubmit = async (data) => {
        setAddParticipantLoader(true);
        const formData = {
            ...data,
            rol: data["rol"].split(","),
            signerOrder: parseInt(data["signerOrder"]) || 1,
            documentIDType: data['documentIDType'],
            rut: data['documentIDType'] === 'Rut' ? data['rut'] : '',
            passport: data['documentIDType'] === 'Passport' ? data['passport'] : '',
        };

        const arrayData = structuredClone(participants);

        if (!verifiVariable(editSigner)) {
            for (let index = 0; index < arrayData.length; index++) {
                const element = arrayData[index];
                if (element["email"] === editSigner["email"]) {
                    arrayData[index] = { ...element, ...formData }/* Object.assign({ ...element }, formData); */
                }
            }
        } else {
            arrayData.push(
                Object.assign({ textColor: randomRgba() }, { ...formData })
            );
        }
        /* const isValidEmail = async (email) => {
            const response = await mailValidate(email);
            return response.status;
        }

        const status = await isValidEmail(data.email);
        if (status === 'fail' || status === 'warning') {
            setValidEmail(true)
            setFormValidated(true);
            setAddParticipantLoader(false)
            if (!formValidated) {
                return
            }
        } */
        dispatch(
            saveTramitExpressInfo({ indexe: "participants", value: arrayData })
        );
        reset();
        handleClose();
        //setFormValidated(false)
        setAddParticipantLoader(false)
    };

    const blockRadio100Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...participants].filter(
                (item) =>
                    item["rol"].includes("Pagador") &&
                    item["rut"] !== editSigner["rut"]
            )
            : [...participants].filter((item) =>
                item["rol"].includes("Pagador")
            );

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay !== 0;
    }, [participants, editSigner]);

    const blockRadio50Pay = useCallback(() => {
        let pay = 0;

        const signPart = !verifiVariable(editSigner)
            ? [...participants].filter(
                (item) =>
                    item["rol"].includes("Pagador") &&
                    item["rut"] !== editSigner["rut"]
            )
            : [...participants].filter((item) =>
                item["rol"].includes("Pagador")
            );

        signPart.forEach((element) => {
            pay += parseInt(element["pay"]);
        });

        return pay > 50;
    }, [participants, editSigner]);

    const disabledPayCheck = useCallback(() => {
        const payInfo = [...participants]
            .filter((participant) => {
                return verifiVariable(editSigner)
                    ? participant
                    : participant["rut"] !== editSigner["rut"];
            })
            .map((participant) => {
                return participant["pay"];
            })
            .filter((participant) => {
                return ["50", "100"].includes(participant);
            });

        return payInfo.includes("100") ||
            (payInfo.length === 2 && payInfo.includes("50"))
            ? setIsPayDisabled(true)
            : setIsPayDisabled(false);
    }, [participants, editSigner]);

    const disableSignerParticipants = () => {
        if (!verifiVariable(participants)) {
            const participantList = [...participants]
                .filter(participant => participant.rut !== editSigner?.rut)
                .filter((participant) => participant["rol"].includes("Firmante"));

            participantList.length >= 10 ? setDisabledCheckSigner(true) : setDisabledCheckSigner(false);
        }
    };

    useEffect(() => {
        if (openAddDiag > 0) setOpen(true);
        disabledPayCheck();
        disableSignerParticipants();
        // eslint-disable-next-line
    }, [openAddDiag]);

    useEffect(() => {
        if (!verifiVariable(editSigner) && Object.keys(editSigner).length !== 0) {
            setValue("fullName", editSigner["fullName"]);
            setValue("email", editSigner["email"]);
            setValue("phone", editSigner["phone"]);
            setValue("pay", editSigner["pay"]);
            setValue("rol", editSigner["rol"].toString());
            setValue("signerOrder", editSigner["signerOrder"]);
            setValue("documentIDType", editSigner["documentIDType"]);
            if (!verifiVariable(editSigner["rut"])) setValue("rut", editSigner["rut"]);
            if (!verifiVariable(editSigner["passport"])) setValue("passport", editSigner["passport"]);
        };

        // eslint-disable-next-line
    }, [editSigner]);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <form
                onSubmit={handleSubmit(onsubmit)}
                className={styles.contentFomAddpart}
            >
                <Box className={styles.modalHeader}>

                    <Typography
                        variant="h6"
                        className={styles.titleAddDiag}
                    >
                        {editSigner
                            ? `Editando participante ${participants.findIndex(ele => ele.email === editSigner.email) + 1}`
                            : `Participante ${participants.length + 1}`}
                    </Typography>

                    <IconButton
                        size="medium"
                        className="closeIcon"
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>

                </Box>


                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) && screenSizeHook === "xs" ? 12 : 6
                        }
                    >
                        <Grid item xs={12}>
                            <Controller
                                name={`fullName`}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    maxLength: {
                                        value: 50,
                                        message: 'La longitud máxima es de 50 caracteres'
                                    },
                                    pattern: {
                                        value: validation.nombre,
                                        message: 'No está permitido utilizar ciertos caracteres especiales. Carcateres especiales permitidos "-_#()@:?$+= áéíóúÁÉÍÓÚñÑüÜ.,!"'
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label
                                                htmlFor="fullName"
                                                className={styles.titleFormAddDiag}
                                            >
                                                <Typography
                                                    variant="h6"
                                                >
                                                    Nombre completo
                                                </Typography>
                                            </label>

                                            <TextField
                                                id="fullName"
                                                autoComplete="name"
                                                type="text"
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                className={styles.emailInput}
                                                onChange={onChange}
                                                value={value}
                                                placeholder={"Nombre completo"}
                                                color={fieldState.error ? "error" : "success"}
                                                focused={fieldState.isTouched || fieldState.isDirty}
                                                fullWidth
                                            />

                                            <FormHelperText
                                                className={styles.errorText}
                                                hidden={fieldState.error ? false : true}
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>

                                            <br />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid>
                            <Box>
                                <Grid item xs={12}>

                                    <Controller
                                        name={`documentIDType`}
                                        control={control}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label className={styles.titleFormAddDiag}>
                                                        <Typography variant="h6">
                                                            Selecciona el tipo de documento de identificación
                                                        </Typography>
                                                    </label>

                                                    <RadioGroup
                                                        defaultValue={(!verifiVariable(editSigner) && !verifiVariable(editSigner["documentID"])) ? editSigner["documentID"] : "Rut"}
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="Rut"
                                                                    onChange={onChange}
                                                                />
                                                            }
                                                            label="Rut"
                                                        />

                                                        <FormControlLabel
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="Passport"
                                                                    onChange={onChange}
                                                                />
                                                            }
                                                            label="Pasaporte"
                                                        />
                                                    </RadioGroup>

                                                    <FormHelperText
                                                        hidden={fieldState.error ? false : true}
                                                    >
                                                        {fieldState.error ? fieldState.error.message : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Box>
                        </Grid>

                        {!verifiVariable(watchDocumentIDType) && watchDocumentIDType === 'Rut' && (

                            <Grid item xs={12}>
                                <Controller
                                    name={`rut`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {
                                            isPassPortOrRut: (value) => {
                                                if (
                                                    [checkRut(value)].includes(true)
                                                ) {
                                                    return true;
                                                } else {
                                                    return "RUT inválido";
                                                }
                                            },
                                            uniqueRut: (value) => {
                                                const list = [
                                                    ...participants.filter(
                                                        (item) =>
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(item["rut"])
                                                    ),
                                                ];

                                                const rutList = [];

                                                for (
                                                    let index = 0;
                                                    index < list.length;
                                                    index++
                                                ) {
                                                    const element = list[index];

                                                    if (editSigner) {
                                                        // Verifica si va a editar
                                                        if (
                                                            editSigner["rut"] !==
                                                            element["rut"]
                                                        )
                                                            rutList.push(
                                                                element["rut"]
                                                            );
                                                    } else {
                                                        rutList.push(
                                                            element["rut"]
                                                        );
                                                    }
                                                }

                                                return rutList.includes(value)
                                                    ? "RUT duplicado"
                                                    : true;
                                            },
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={!!fieldState.error}
                                            >
                                                <label
                                                    htmlFor="rut"
                                                    className={styles.titleFormAddDiag}
                                                >
                                                    <Typography variant="h6">
                                                        RUT
                                                    </Typography>
                                                </label>

                                                <TextField
                                                    id="rut"
                                                    autoComplete="on"
                                                    type="text"
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.emailInput}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        const value = e.target.value;
                                                        const formattedRut = formaRut(value);
                                                        setValue('rut', formattedRut);
                                                    }}
                                                    value={value}
                                                    placeholder={"Ejemplo: 11111111-1"}
                                                    color={fieldState.error ? "error" : "success"}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    className={styles.errorText}
                                                    hidden={fieldState.error ? false : true}
                                                >
                                                    {fieldState.error ? fieldState.error.message : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>

                        )}

                        {!verifiVariable(watchDocumentIDType) && watchDocumentIDType === 'Passport' && (
                            <Grid item xs={12}>
                                <Controller
                                    name={`passport`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        pattern: {
                                            value: validation.passport,
                                            message: 'Pasaporte inválido'
                                        },
                                        validate: {
                                            uniquePassport: (value) => {
                                                const list = [
                                                    ...participants.filter(
                                                        (item) =>
                                                            ![
                                                                null,
                                                                undefined,
                                                                "",
                                                            ].includes(item["rut"])
                                                    ),
                                                ];

                                                const passportList = [];

                                                for (
                                                    let index = 0;
                                                    index < list.length;
                                                    index++
                                                ) {
                                                    const element = list[index];

                                                    if (editSigner) {
                                                        // Verifica si va a editar
                                                        if (
                                                            editSigner["passport"] !==
                                                            element["passport"]
                                                        )
                                                            passportList.push(
                                                                element["passport"]
                                                            );
                                                    } else {
                                                        passportList.push(
                                                            element["passport"]
                                                        );
                                                    }
                                                }

                                                return passportList.includes(value)
                                                    ? "Pasaporte duplicado"
                                                    : true;
                                            },
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl
                                                fullWidth
                                                error={!!fieldState.error}
                                            >
                                                <label
                                                    htmlFor="passport"
                                                    className={styles.titleFormAddDiag}
                                                >
                                                    <Typography variant="h6">
                                                        Pasaporte
                                                    </Typography>
                                                </label>

                                                <TextField
                                                    id="passport"
                                                    autoComplete="on"
                                                    type="text"
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.emailInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={"Ejemplo: AA123456"}
                                                    color={fieldState.error ? "error" : "success"}
                                                    focused={fieldState.isTouched || fieldState.isDirty}
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    className={styles.errorText}
                                                    hidden={fieldState.error ? false : true}
                                                >
                                                    {fieldState.error ? fieldState.error.message : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        )}

                        {
                            !verifiVariable(watchRol) &&
                            ![...watchRol.split(",")].includes("Copia") &&
                            (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`email`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            pattern: {
                                                message: "Formato de correo inválido",
                                                value: validation.email,
                                            },
                                            validate: {
                                                uniqueEmail: (value) => {
                                                    const list = [
                                                        ...participants.filter(
                                                            (item) =>
                                                                ![
                                                                    null,
                                                                    undefined,
                                                                    "",
                                                                ].includes(
                                                                    item["email"]
                                                                )
                                                        ),
                                                    ];

                                                    const emailList = [];

                                                    for (
                                                        let index = 0;
                                                        index < list.length;
                                                        index++
                                                    ) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // verifica si va a aeditar
                                                            if (
                                                                editSigner["email"] !==
                                                                element["email"]
                                                            )
                                                                emailList.push(
                                                                    element["email"]
                                                                );
                                                        } else {
                                                            emailList.push(
                                                                element["email"]
                                                            );
                                                        }
                                                    }

                                                    return emailList.includes(value)
                                                        ? "Email duplicado"
                                                        : true;
                                                },
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label
                                                        htmlFor="email"
                                                        className={styles.titleFormAddDiag}
                                                    >
                                                        <Typography variant="h6">
                                                            Correo electrónico
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="email"
                                                        autoComplete="email"
                                                        type="text"
                                                        error={!!fieldState.error}
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.emailInput}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            setValidEmail(false);
                                                            const deleteSpaces = e.target.value.replace(/\s/g, '');
                                                            setValue('email', deleteSpaces);
                                                        }}
                                                        value={value}
                                                        placeholder={"tucorreo@mail.com"}
                                                        color={fieldState.error ? "error" : validEmail ? "info" : "success"}
                                                        focused={fieldState.isTouched || fieldState.isDirty}
                                                        fullWidth
                                                    />

                                                    {validEmail &&
                                                        <InfoMessage
                                                            icon={'warning'}
                                                            text={"Nuestro sistema detectó un problema con este correo. Puedes modificarlo, o si estás seguro de que el correo es correcto y deseas continuar, haz clic en 'Agregar participante'."}
                                                        />
                                                    }

                                                    <FormHelperText
                                                        className={styles.errorText}
                                                        hidden={fieldState.error ? false : true}
                                                    >
                                                        {fieldState.error ? fieldState.error.message : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )
                        }

                    </Grid>

                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) &&
                                screenSizeHook === "xs"
                                ? 12
                                : 6
                        }
                    >
                        {
                            !verifiVariable(watchRol) &&
                            [...watchRol.split(",")].includes("Copia") &&
                            (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`email`}
                                        control={control}
                                        rules={{
                                            required: "Este campo es requerido",
                                            pattern: {
                                                message: "Formato de correo inválido",
                                                value: validation.email,
                                            },
                                            validate: {
                                                uniqueEmail: (value) => {
                                                    const list = [...participants.filter((item) => ![null, undefined, "",].includes(item["email"]))];

                                                    const emailList = [];

                                                    for (let index = 0; index < list.length; index++) {
                                                        const element = list[index];

                                                        if (editSigner) {
                                                            // verifica si va a aeditar
                                                            if (editSigner["email"] !== element["email"]) emailList.push(element["email"]);
                                                        } else {
                                                            emailList.push(element["email"]);
                                                        }
                                                    }

                                                    return emailList.includes(value) ? "Email duplicado" : true;
                                                },
                                            },
                                        }}
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label
                                                        htmlFor="email"
                                                        className={styles.titleFormAddDiag}
                                                    >
                                                        <Typography variant="h6">
                                                            Correo electrónico
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="email"
                                                        autoComplete="email"
                                                        type="text"
                                                        error={!!fieldState.error}
                                                        ref={ref}
                                                        onBlur={onBlur}
                                                        className={styles.emailInput}
                                                        onChange={(e) => {
                                                            onChange(e);
                                                            setValidEmail(false);
                                                            const deleteSpaces = e.target.value.replace(/\s/g, '');
                                                            setValue('email', deleteSpaces);
                                                        }}
                                                        value={value}
                                                        placeholder={"tucorreo@mail.com"}
                                                        color={fieldState.error ? "error" : validEmail ? "info" : "success"}
                                                        focused={fieldState.isTouched || fieldState.isDirty}
                                                        fullWidth
                                                    />

                                                    {validEmail &&
                                                        <InfoMessage
                                                            icon={'warning'}
                                                            text={"Nuestro sistema detectó un problema con este correo. Puedes modificarlo, o si estás seguro de que el correo es correcto y deseas continuar, haz clic en 'Agregar participante'."}
                                                        />
                                                    }

                                                    <FormHelperText
                                                        className={styles.errorText}
                                                        hidden={fieldState.error ? false : true}
                                                    >
                                                        {fieldState.error ? fieldState.error.message : null}
                                                    </FormHelperText>

                                                    <br />
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )
                        }

                        <Grid item xs={12}>
                            <Controller
                                name={`phone`}
                                control={control}
                                rules={{
                                    required: "Este campo es requerido",
                                    validate: {
                                        validPhoneNumber: (value) => validatePhoneNumber(value) || "Formato de numero telefonico invalido",
                                        validateBlanks: (value) => !/\s/g.test(value) || "No estan permitidos los espacios en blanco",
                                        uniquePhone: (value) => {
                                            const list = [...participants.filter((item) => ![null, undefined, "",].includes(item["phone"]))];

                                            const phonelList = [];

                                            for (let index = 0; index < list.length; index++) {
                                                const element = list[index];

                                                if (editSigner) {
                                                    // verifica si va a aeditar
                                                    if (editSigner["phone"] !== element["phone"]) phonelList.push(element["phone"]);
                                                } else {
                                                    phonelList.push(element["phone"]);
                                                }
                                            }

                                            return phonelList.includes(value) ? "Nro de teléfono duplicado" : true;
                                        }
                                    }
                                }}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                    fieldState,
                                }) => {
                                    return (
                                        <FormControl
                                            fullWidth
                                            error={!!fieldState.error}
                                        >
                                            <label
                                                htmlFor="phone"
                                                className={styles.titleFormAddDiag}
                                            >
                                                <Typography variant="h6">
                                                    Número de teléfono (WhatsApp)
                                                </Typography>
                                            </label>

                                            <TextField
                                                id="phone"
                                                autoComplete="tel"
                                                type="text"
                                                error={!!fieldState.error}
                                                ref={ref}
                                                onBlur={onBlur}
                                                className={styles.emailInput}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    const deleteSpaces = e.target.value.replace(/[^\d+]/g, '');
                                                    setValue('phone', deleteSpaces);
                                                }}
                                                value={value}
                                                placeholder={"+56000000000"}
                                                color={fieldState.error ? "error" : "success"}
                                                focused={fieldState.isTouched || fieldState.isDirty}
                                                fullWidth
                                            />

                                            <FormHelperText
                                                className={styles.errorText}
                                                hidden={fieldState.error ? false : true}
                                            >
                                                {fieldState.error ? fieldState.error.message : null}
                                            </FormHelperText>

                                            <br />
                                        </FormControl>
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                sx={{
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    flexWrap: "wrap",
                                    marginBottom: '.6rem'
                                }}
                                fullWidth
                                error={errors.rol ? true : false}
                            >
                                <label className={styles.titleFormAddDiag}>
                                    <Typography variant="h6">
                                        ¿Qué rol desempeñará el participante?
                                    </Typography>
                                </label>

                                <RadioGroup
                                    defaultValue={
                                        disabledCheckSigner
                                            ? ''
                                            : editSigner
                                                ? editSigner["rol"].toString()
                                                : "Firmante"
                                    }
                                    row
                                    className={styles.buttomsRol}
                                >
                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona participará pagando
                                                    el trámite.
                                                </Typography>
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        onMouseEnter={() => setCardIcon(<CardIconHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setCardIcon(<CardIcon style={{ width: '3.5rem' }} />)}
                                                        disabled={isPayDisabled}
                                                        icon={
                                                            isPayDisabled
                                                                ? <CardIconDisabled style={{ width: '3.5rem' }} />
                                                                : cardIcon
                                                        }
                                                        checkedIcon={<CardSelectedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Pagador"}
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                !disabledCheckSigner ? (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona participará
                                                    únicamente como firmante.
                                                </Typography>) : (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Solo esta permitido un maximo de 10 firmantes por trámites.
                                                </Typography>)
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={disabledCheckSigner}
                                                        onMouseEnter={() => setPenIcon(<PenIconHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setPenIcon(<PendIcon style={{ width: '3.5rem' }} />)}
                                                        icon={
                                                            disabledCheckSigner
                                                                ? <PendIconDisabled style={{ width: '3.5rem' }} />
                                                                : penIcon
                                                        }
                                                        checkedIcon={<PenSelectedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Firmante"}
                                                        onClick={() =>
                                                            setValue("pay", "0")
                                                        }
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                !disabledCheckSigner ? (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona participará
                                                    firmando y pagando el documento.
                                                </Typography>) : (<Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Solo esta permitido un maximo de 10 firmantes por trámites.
                                                </Typography>)
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        disabled={isPayDisabled || disabledCheckSigner}
                                                        onMouseEnter={() => setCardPenIcon(<SigPayHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setCardPenIcon(<SigPayIcon style={{ width: '3.5rem' }} />)}
                                                        icon={
                                                            (isPayDisabled || disabledCheckSigner)
                                                                ? <SigPayIconDisabled style={{ width: '3.5rem' }} />
                                                                : cardPenIcon
                                                        }
                                                        checkedIcon={<SigPaySeletedIcon style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Firmante,Pagador"}
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>

                                    <Grid className={styles.rolIcon}>
                                        <Tooltip
                                            title={
                                                <Typography
                                                    variant="subtitle1"
                                                    color={["white.main"]}
                                                >
                                                    Esta persona recibirá
                                                    una copia del documento.
                                                </Typography>
                                            }
                                            placement="top"
                                            TransitionComponent={Fade}
                                            TransitionProps={{
                                                timeout: 600,
                                            }}
                                            arrow
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        onMouseEnter={() => setEmailIcon(<MailIconHover style={{ width: '3.5rem' }} />)}
                                                        onMouseLeave={() => setEmailIcon(<MailIcon style={{ width: '3.5rem' }} />)}
                                                        icon={emailIcon}
                                                        checkedIcon={<MailIconSelected style={{ width: '3.5rem' }} />}
                                                        {...register("rol", {
                                                            required:
                                                                "Debe seleccionar al menos un rol",
                                                        })}
                                                        value={"Copia"}
                                                    />
                                                }
                                            />
                                        </Tooltip>
                                    </Grid>

                                </RadioGroup>

                                <FormHelperText
                                    className={styles.errorText}
                                    hidden={errors.rol ? false : true}
                                >
                                    {errors.rol ? errors.rol?.message : ""}
                                </FormHelperText>
                            </FormControl>
                        </Grid>

                        {
                            !verifiVariable(watchRol) &&
                            [...watchRol.split(",")].includes("Pagador") && (
                                <Grid item xs={12}>
                                    <Controller
                                        name={`pay`}
                                        control={control}
                                        rules={{
                                            validate: {
                                                verifyPay: (value) => {
                                                    const rolValidate = [...watchRol.split(",")];

                                                    return (
                                                        (parseInt(value) !==
                                                            0 &&
                                                            rolValidate.includes(
                                                                "Pagador"
                                                            )) ||
                                                        "Seleccionar porcentaje de pago."
                                                    );
                                                },
                                            },
                                        }}
                                        render={({
                                            field: {
                                                onChange,
                                                onBlur,
                                                value,
                                                ref,
                                            },
                                            fieldState,
                                        }) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    error={!!fieldState.error}
                                                >
                                                    <label
                                                        style={{ marginBottom: "0px" }}
                                                        className={styles.titleFormAddDiag}
                                                    >
                                                        <Typography variant="h6">
                                                            ¿Este participante
                                                            realizará el pago del
                                                            50% o el 100% del
                                                            servicio?{" "}
                                                        </Typography>
                                                    </label>

                                                    <RadioGroup
                                                        defaultValue={
                                                            editSigner
                                                                ? editSigner[
                                                                "pay"
                                                                ]
                                                                : "0"
                                                        }
                                                        row
                                                    >
                                                        <FormControlLabel
                                                            disabled={blockRadio50Pay()}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="50"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                />
                                                            }
                                                            label="50%"
                                                        />

                                                        <FormControlLabel
                                                            disabled={blockRadio100Pay()}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        "&.Mui-checked":
                                                                        {
                                                                            color: `${theme.palette.primary.light} !important`,
                                                                        },
                                                                    }}
                                                                    value="100"
                                                                    onChange={
                                                                        onChange
                                                                    }
                                                                />
                                                            }
                                                            label="100%"
                                                        />
                                                    </RadioGroup>

                                                    <FormHelperText
                                                        hidden={
                                                            fieldState.error
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        {fieldState.error
                                                            ? fieldState.error
                                                                .message
                                                            : null}
                                                    </FormHelperText>
                                                </FormControl>
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                        {watchRol && (watchRol === 'Firmante' || watchRol === 'Firmante,Pagador') && (
                            <Grid item xs={12}>
                                <Controller

                                    name={`signerOrder`}
                                    control={control}
                                    rules={{
                                        required: "Este campo es requerido",
                                        validate: {
                                            minValue: (value) => value >= 1 || "Debe ser 1 o mayor que 1",
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState,
                                    }) => {
                                        return (
                                            <FormControl

                                                fullWidth
                                                error={
                                                    fieldState.error ? true : false
                                                }
                                            >
                                                <label className={styles.titleFormAddDiag}>
                                                    <Typography variant="h6">
                                                        Orden de firma del firmante
                                                    </Typography>
                                                </label>

                                                <TextField
                                                    type="number"
                                                    error={!!fieldState.error}
                                                    ref={ref}
                                                    onBlur={onBlur}
                                                    className={styles.emailInput}
                                                    onChange={onChange}
                                                    value={value}
                                                    placeholder={""}
                                                    color={
                                                        fieldState.error
                                                            ? "error"
                                                            : "success"
                                                    }
                                                    focused={
                                                        fieldState.isTouched ||
                                                        fieldState.isDirty
                                                    }
                                                    fullWidth
                                                />

                                                <FormHelperText
                                                    className={styles.errorText}
                                                    hidden={
                                                        fieldState.error
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {fieldState.error
                                                        ? fieldState.error.message
                                                        : null}
                                                </FormHelperText>

                                                <br />
                                            </FormControl>
                                        );
                                    }}
                                />
                            </Grid>
                        )}

                    </Grid>

                    <Grid item xs={12}>
                        <CustomAddButton
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {addParticipantLoader
                                ? <CircularProgress size={20} color="white" />
                                : 'Agregar participante'
                            }
                        </CustomAddButton>
                    </Grid>

                </Grid>

            </form>

        </Dialog>
    );
};

export default AddParticipants;