import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    MdCloudUpload,
    MdModeEdit,
    MdOutlineCancelPresentation,
} from "react-icons/md";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import FilePreview from "react-file-preview-latest";

import Text from "../../heading/Text";
import { DocumentsStyles } from "./index.css";
import {
    asyncBase64ToFile,
    asyncBlobToBase64,
    asyncFileToBase64,
    to,
    verifiVariable,
} from "../../../utils/enums";

import { ReactComponent as FolderIcon } from "./img/folder.svg";
import Spinner from "../../spinners/Spinner";
import { Context } from "../../../context/utilsContext";
import { saveSchedulingInfo } from "../../../store/scheduling";
import { validation } from "../../../utils/validations";
import { mergeDocumentsOptions2 } from "../../paperworkProcess/documents/services";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 81px",
    marginTop: "11px",
    marginBottom: "20px",
    border: `1px solid ${props.theme.palette.primary.light}`,
    "&:hover": {
        border: `1px solid ${props.theme.palette.primary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const MergeCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    padding: "11px 32px",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.primary.light}`,
    "&:hover": {
        border: `1px solid ${props.theme.palette.primary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const CancelMergeCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    color: props.theme.palette.secondary.main,
    fontSize: 16,
    padding: "11px 32px",
    marginTop: "11px",
    border: `1px solid ${props.theme.palette.secondary.light}`,
    "&:hover": {
        border: `1px solid ${props.theme.palette.secondary.light}`,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const TextIconfolder = styled(Typography)((props) => ({
    color: props.theme.palette.gray.dark,
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const ScheduleDocuments = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } =
        useContext(Context);
    const navigate = useNavigate();
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const inputMergeRef = useRef(null);
    const inputFileRef = useRef(null);
    const {
        scheduling: {
            loading: loadingRequest,
            uploadProgress,
            thirdStep: { fileName, docPdfBase64 },
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const styles = DocumentsStyles();
    const [showMergeContainer, setShowMergeContainer] = useState(false);
    const [arrayMerge, setArrayMerge] = useState([]);
    const [fileTramit, setFileTramit] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [textLoading, setTextLoading] = useState("");
    const [showMaxSizeText, setShowMaxSizeText] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleSort = () => {
        //duplicate items
        let _arrayMerge = [...arrayMerge];

        //remove and save the dragged item content
        const draggedItemContent = _arrayMerge.splice(dragItem.current, 1)[0];

        //switch the position
        _arrayMerge.splice(dragOverItem.current, 0, draggedItemContent);

        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;

        //update the actual array
        setArrayMerge(_arrayMerge);
    };

    const handleFileClick = () => {
        inputFileRef.current.click();
    };

    const handleMergeClick = () => {
        inputMergeRef.current.click();
    };

    const addData = (array) => {
        return new Promise((resolve) => {

            const formData = new FormData();
            
            array.forEach((element) => {
                formData.append('files', element["file"]);
            });

            resolve(formData);

        });
    };

    const mergeDocs = async () => {
        try {
            setLoading(true);

            setTextLoading(
                "Uniendo documento este proceso puede durar un par de minutos por favor espere..."
            );

            const formData = await addData(arrayMerge);

            const response = await mergeDocumentsOptions2(formData);

            const fileBase64 = await asyncBlobToBase64(response);

            setLoading(false);

            setShowMergeContainer((value) => !value);
            setArrayMerge([]);

            setFileTramit({
                name: "tramite",
            });

            dispatch(
                saveSchedulingInfo({
                    indexe: "thirdStep",
                    value: {
                        fileName: "tramite",
                        docPdfBase64: fileBase64.replace(
                            "data:application/pdf;base64,",
                            ""
                        ),
                    },
                })
            );
        } catch (error) {
            setLoading(false);
            handleClick();
            console.log(error);
        }
    };

    const handleFileChange = async (event) => {
        try {
            const fileObj = event.target.files && event.target.files[0];

            if (!fileObj) {
                return;
            }

            if (fileObj.size > validation.fileMaxSize) {
                setShowMaxSizeText(true);
                return;
            }

            setShowMaxSizeText(false);

            event.target.value = null;

            const docPdfBase64 = await asyncFileToBase64(fileObj);

            dispatch(
                saveSchedulingInfo({
                    indexe: "thirdStep",
                    value: {
                        fileName: fileObj.name,
                        docPdfBase64: docPdfBase64.replace(
                            "data:application/pdf;base64,",
                            ""
                        ),
                    },
                })
            );

            /* dispatch(
                repairDoc(
                    {
                        document: docPdfBase64.replace(
                            "data:application/pdf;base64,",
                            ""
                        ),
                    },
                    token,
                    false
                )
            ); */

            setFileTramit({
                name: fileObj.name,
            });
        } catch (error) {
            console.log("error: ", error);
        }
    };

    const handleFileMergeChange = async (event) => {
        const arrayData = [...arrayMerge];

        for (let index = 0; index < event.target.files.length; index++) {
            const element = event.target.files[index];

            const restDocPdfBase64 = await asyncFileToBase64(element);

            const docPdfBase64 = restDocPdfBase64.split("base64,");

            arrayData.push({
                file: element,
                name: element.name,
                docPdfBase64: docPdfBase64[1],
            });
        }

        event.target.value = null;

        setArrayMerge(arrayData);
    };

    const removeFileFromArray = (name) => {
        const arrayData = [...arrayMerge].filter(
            (item) => item["name"] !== name
        );
        setArrayMerge(arrayData);
    };

    useEffect(() => {
        if (nextPage !== 0) navigate(to.SCHEDULING_CREATION);

        return () => {
            setNextPage(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {
        if (!verifiVariable(toResumen)) navigate(toResumen);

        return () => {
            setToResumen(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {
        if (docPdfBase64)
            asyncBase64ToFile(
                "data:application/pdf;base64," + docPdfBase64,
                fileName
            ).then((file) =>
                setFileTramit({ file, docPdfBase64, name: fileName })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docPdfBase64]);

    useEffect(() => {
        setTextLoading("Limpiando archivo " + uploadProgress + "%");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadProgress]);

    return (
        <div
            className={`${styles.procedureCreationFormContainer} procedure-creation__form-container`}
        >
            {(loading || loadingRequest) && (
                <Spinner
                    loading={true}
                    type="bar"
                    opacity={true}
                    text={textLoading}
                />
            )}

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Ha habido un error durante la previsualización del archivo
                </Alert>
            </Snackbar>

            <div className={`${styles.formContainer} form__container`}>
                <div className={styles.titleContainer}>
                    <Text className={styles.titleStyles} align="left">
                        Adjuntar documentos
                    </Text>

                    <br />

                    <Text
                        className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                        align="left"
                    >
                        Adjunta los documentos necesarios para continuar con el
                        trámite.
                    </Text>

                    <Text className={styles.hintStyles} align="left">
                        Recuerda adjuntar como mínimo contrato a firmar y
                        cédulas de los participantes o firmantes.
                    </Text>

                    <br />

                    <Text
                        className={`${styles.bottomTitleStyles} ${styles.subTitleStyles} ${styles.titleStyles}`}
                        align="left"
                    >
                        Recuerda que todos los documentos deben de estar unidos
                        para ser parte de un (1) archivo PDF.
                    </Text>

                    {showMaxSizeText && (
                        <Typography
                            variant="body1"
                            gutterBottom
                            color={["error"]}
                            textAlign={"center"}
                        >
                            El archivo que seleccionaste es demasiado grande.
                            Por favor, selecciona un archivo que sea menor a
                            10MB.
                        </Typography>
                    )}
                </div>
            </div>

            {!showMergeContainer && !fileTramit && (
                <div
                    className={`${styles.fileButomsContainer} form__container`}
                >
                    <div className={styles.uploadButtomContainer}>
                        <input
                            hidden
                            accept="application/pdf"
                            style={{ display: "none" }}
                            ref={inputFileRef}
                            type="file"
                            onChange={handleFileChange}
                        />

                        <Text
                            className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                            align="center"
                        >
                            ¿Ya cuentas con todos tus archivos unidos en (1)
                            sólo PDF?
                        </Text>

                        <UploadCustomButtom
                            onClick={() => handleFileClick()}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdCloudUpload className="icon--blue icon--lg" />
                            }
                        >
                            Subir archivo
                        </UploadCustomButtom>

                        <div>o</div>

                        <Text
                            className={`${styles.subTitleStyles} ${styles.titleStyles}`}
                            sx={{ marginTop: "11px" }}
                            align="center"
                        >
                            ¿Necesitas unir tus documentos en un solo archivo
                            PDF?
                        </Text>

                        <MergeCustomButtom
                            onClick={() => {
                                setShowMergeContainer((validate) => !validate);
                                /* urlUnificarDocs(); */
                            }}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdModeEdit
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    className="icon--white icon--md icon-back-ground-color-blue"
                                />
                            }
                        >
                            Unir PDF con mis archivos
                        </MergeCustomButtom>
                    </div>
                </div>
            )}

            {docPdfBase64 && fileTramit && (
                <div
                    className={`${styles.fileButomsContainer} form__container`}
                >
                    <div className={styles.FilePreview}>
                        <div className={styles.conatinerCloseButtomPreview}>
                            <DisabledByDefaultIcon
                                className="icon--red icon-pointer"
                                onClick={() => {
                                    dispatch(
                                        saveSchedulingInfo({
                                            indexe: "thirdStep",
                                            value: {},
                                        })
                                    );
                                    setFileTramit(undefined);
                                }}
                            />
                        </div>

                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            align="center"
                            color={"gray.dark"}
                        >
                            {fileName.length < 19
                                ? fileName
                                : fileName.substring(0, 15) + "..."}
                        </Typography>

                        <FilePreview
                            type={"file"}
                            file={fileTramit["file"]}
                            onError={() => console.log("error")}
                        />
                    </div>
                </div>
            )}

            {showMergeContainer && (
                <div
                    className={`${styles.fileButomsContainer} form__container`}
                >
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {arrayMerge.length >= 2 && (
                            <MergeCustomButtom
                                onClick={() => mergeDocs()}
                                variant="outlined"
                                color="primary"
                                startIcon={
                                    <MdModeEdit
                                        style={{
                                            borderRadius: "5px",
                                        }}
                                        className="icon--white icon--md icon-back-ground-color-blue"
                                    />
                                }
                            >
                                Unir archivos
                            </MergeCustomButtom>
                        )}

                        <CancelMergeCustomButtom
                            style={{
                                marginLeft:
                                    arrayMerge.length >= 2 ? "20px" : "0px",
                            }}
                            onClick={() => {
                                setShowMergeContainer((value) => !value);
                                setArrayMerge([]);
                            }}
                            variant="outlined"
                            color="primary"
                            startIcon={
                                <MdOutlineCancelPresentation
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    className="icon--white icon--md icon-back-ground-color-red"
                                />
                            }
                        >
                            Cancelar
                        </CancelMergeCustomButtom>
                    </div>

                    {arrayMerge.map((item, index) => {
                        return (
                            <div
                                className={styles.fileMergePreview}
                                key={item["name"] + index}
                                draggable
                                onDragStart={(e) => (dragItem.current = index)}
                                onDragEnter={(e) =>
                                    (dragOverItem.current = index)
                                }
                                onDragEnd={handleSort}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div
                                    className={
                                        styles.conatinerCloseButtomPreview
                                    }
                                >
                                    <ControlCameraIcon className="icon--blue-light icon-move" />

                                    <DisabledByDefaultIcon
                                        className="icon--red icon-pointer"
                                        onClick={() =>
                                            removeFileFromArray(item["name"])
                                        }
                                    />
                                </div>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    align="center"
                                    color={"gray.dark"}
                                >
                                    {item["name"].length < 19
                                        ? item["name"]
                                        : item["name"].substring(0, 15) + "..."}
                                </Typography>

                                <FilePreview
                                    type={"file"}
                                    file={item["file"]}
                                    onError={() => console.log("error")}
                                />
                            </div>
                        );
                    })}

                    <div
                        className={styles.folderIconContainer}
                        onClick={handleMergeClick}
                    >
                        <input
                            hidden
                            accept="image/png, image/jpeg, image/jpg, application/pdf, .docx, .docm, .doc, .pptx, .pptm, .potx, .ppsx"
                            style={{ display: "none" }}
                            ref={inputMergeRef}
                            type="file"
                            multiple
                            onChange={handleFileMergeChange}
                        />

                        <FolderIcon />

                        <TextIconfolder variant="subtitle1" gutterBottom>
                            Añadir archivo
                        </TextIconfolder>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScheduleDocuments;
