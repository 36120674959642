import { Fragment, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRedo } from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
// import { MdFindReplace } from "react-icons/md";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';

import NumberFormat from 'react-number-format';

import { BreadCrumbTracker, Btn, ModalUploadNotaryDoc, Notificationbanner, Spinner } from "../..";
import { ReactComponent as UserIcon } from "./img/user-icon.svg";
import { getContractDetail, saveDetailsContract } from "../../../store/contract/contractDetail";
import { ContractDetailReviewStyles } from "./index.css";
import { to, typeUser, verifiVariable } from "../../../utils/enums";
import DetailSignatories from "./molecules/detailSignatories";
import { Context } from "../../../context/utilsContext";
import DeleteContractAlert from "./molecules/deleteContractAlert";
import { resetContractApi, sendContractPriotity, sendEmailSign } from "../../../utils/api";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import { getUserToken } from "../../storeSelector/storeSelector";
import ResetContractModal from "./molecules/resetContractModal";
import { Box, Container } from "@mui/material";

import { ReactComponent as DownloadOutlineDetailIcon } from "../../../assets/icons/icon_download_outline_detail_tramit.svg";

import ViewModalDocs from "../signAuthorization/molecules/viewModalDocs";
import ErrorAlert from "../expressProcess/molecules/errorAlert";
import Contact from "../expressProcess/molecules/contact";
import TramitDetail from "../../tramitDetailData/TramitDetail/TramitDetail";
import DocumentInfo from "../../tramitDetailData/documentInfo/DocumentInfo";
import { theme } from "../../../theme/theme";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const button = {
    marginTop: `${["xs"].includes(ScreenSizeHook) ? "15px" : "0px"}`,
    fontSize: "0.8rem",
    textTransform: "none",
    backgroundColor: "quinary.light",
    color: "primary.light",
    "&:hover": {
        backgroundColor: "primary.xlight",
    },
};

const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    margin: 5,
    width: '15rem',
    padding: '4px 20px',
    fontSize: '0.78rem',
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const CustomTitle = styled(Typography)((props) => ({
    color: props.theme.palette.black.main,
    fontSize: '1rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomSubTitle = styled(Typography)((props) => ({
    lineHeight: '0.75',
    color: props.theme.palette.gray.dark,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));

const CustomPayInfo = styled(Typography)((props) => ({
    lineHeight: '3',
    color: props.theme.palette.black.main,
    fontSize: '0.75rem',
    fontFamily: [
        '"PoppinsBold"'
    ].join(',')
}));


const ContractDetailReview = () => {
    const styles = ContractDetailReviewStyles();
    const userToken = getUserToken();
    const { loading, setLoading, setTimer, timer } = useContext(Context);
    const {
        contract: {
            contractCreator: {
                loading: loadingTramit,
                contractCreatedDetails,
                //tramitAproversCreated
            }
        },
        session: {
            login: {
                user,
                userInfo
            }
        }
    } = useSelector((state) => state);
    const invalidContract = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { state, pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [detailContract, setDetailContract] = useState(undefined);
    const [payInfo, setPayInfo] = useState(undefined);
    const [textLoading, setTextLoading] = useState('');
    const [showReminderMessage, setShowReminderMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [textReenvio, setTextReenvio] = useState("");
    const [reSentContract, setReSentContract] = useState(false);
    // eslint-disable-next-line
    const [approveSigner, setApproveSigner] = useState([]);
    const [openModalDocument, setOpenModalDocument] = useState({
        openModal: false,
        tramitType: '',
        download: false
    });
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsn, setAlertMsn] = useState("");
    const [showError, setShowError] = useState(0);
    const [docLog, setDocLog] = useState(null);

    /* const grillaDatosSolici = useCallback(() => {

        if (
            !verifiVariable(detailContract) &&
            !verifiVariable(detailContract['contrato']) &&
            !verifiVariable(detailContract['contrato'][0]) &&
            ['CREATED', 'REJECTED'].includes(detailContract['contrato'][0]['sStatus']) &&
            ['sm', 'xs'].includes(screenSizeHook)
        ) {
            return 12;
        } else {
            return 6;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailContract]); */

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const getTramit = () => {
        const { token } = user;

        if (typeof state === 'object') {
            dispatch(getContractDetail(state.sContractID, token));
        } else {
            dispatch(getContractDetail(state, token));
        };
    };

    const resetContract = async () => {

        try {

            setLoading(true);

            const { token } = user;

            const { status, message } = await resetContractApi(state, token);

            setLoading(false);

            if (status === 'success') {

                setShowReminderMessage(true);
                setAlertType("success");
                setAlertMessage(
                    "Se ha reiniciado el contrato"
                );

                getTramit();

            } else {
                setShowReminderMessage(true);
                setAlertType("error");
                setAlertMessage(message['msg']);
            }

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 4000);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const sendReminder = async () => {

        try {

            setLoading(true);

            const { token } = user;

            if (reSentContract === false) await sendContractPriotity(state, token);

            if (reSentContract === true) await sendEmailSign(state, token);

            setLoading(false);
            setShowReminderMessage(true);
            setAlertType("success");
            setAlertMessage(
                "Se ha enviado un recordatorio a todos los firmantes"
            );
            getTramit();

            setTimeout(() => {
                setShowReminderMessage(false);
                setAlertType("");
                setAlertMessage("");
            }, 4000);

            setTimer();

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleShowDocument = (open, tramitType, download) => {
        setOpenModalDocument({
            openModal: open,
            tramitType: tramitType,
            download: download
        });
    };

    useEffect(() => {

        if (!verifiVariable(user) && !verifiVariable(state)) {
            getTramit();
        } else {
            navigate(to.DASHBOARD_WELCOME);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, state]);

    useEffect(() => {

        if (!verifiVariable(contractCreatedDetails)) {

            let total = 0;
            let iva = 0;
            let notary = 0;
            let signature = 0;

            setDetailContract(contractCreatedDetails);

            setApproveSigner(contractCreatedDetails['firmantes'].map((item) => item['agree_draft']));

            contractCreatedDetails['firmantes'].forEach((firmante) => {
                if (firmante.portion !== 0) {
                    total += parseInt(firmante.totalPay);
                    iva += parseInt(firmante.iva);
                    notary += parseInt(firmante.notaryPay);
                    signature += parseInt(firmante.amount);
                }
            });

            setPayInfo({
                total,
                iva,
                notary,
                signature,
            });

        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractCreatedDetails]);

    useEffect(() => {

        if (!verifiVariable(contractCreatedDetails)) {

            const { contrato } = contractCreatedDetails;

            if (verifiVariable(contrato) || contrato.length === 0) {
                invalidContract.current = true;
                setAlertTitle("Trámite no encontrado");
                setAlertMsn("El trámite ha sido eliminado o no existe.");
                setShowError((value) => 1 + value);
            }

        }

        /* return () => {
            dispatch(saveDetailsContract({ indexe: "contractCreatedDetails", value: null }));
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractCreatedDetails]);

    return (
        <Container maxWidth={'xl'} className={styles.container}>
            {
                !isMobile && (
                    <Grid item xs={12} sm={3}>
                        {
                            !verifiVariable(detailContract) &&
                            !verifiVariable(detailContract['contrato']) &&
                            !verifiVariable(detailContract['contrato'][0]) &&
                            (<div className={`info-body padding-y--xxs flex--evenly ${styles.contentParticipant}`}>

                                {detailContract['firmantes'].map((member, index) => {
                                    return (
                                        <DetailSignatories
                                            textLoading={(e) => setTextLoading(e)}
                                            loading={(e) => setLoading(e)}
                                            updateContract={() => getTramit()}
                                            member={member}
                                            index={index}
                                            comentarios={detailContract['comentarios']}
                                            contractDetail={detailContract['contrato']}
                                            contractMembers={detailContract['firmantes']}
                                            contractFiles={detailContract['archivos']}
                                            removeSignner={false}
                                            deleteSigner={() => console.log('displayDeleteSignnerWarning')}
                                        />
                                    );
                                })}

                            </div>)
                        }
                    </Grid>
                )
            }

            <Box className="content-detail__container" sx={{ padding: '0 !important' }}>

                <ErrorAlert
                    msn={alertMsn}
                    title={alertTitle}
                    errors={showError}
                    onClose={() => {
                        if (invalidContract.current === true) {
                            navigate(-1);
                            dispatch(saveDetailsContract({ indexe: "contractCreatedDetails", value: null }));
                        }
                    }}
                />

                <div className="contract-details__main">

                    {
                        !verifiVariable(detailContract) &&
                        !verifiVariable(detailContract['contrato']) &&
                        !verifiVariable(detailContract['contrato'][0]) && (
                            <BreadCrumbTracker
                                location={pathname}
                                status={detailContract['contrato'][0]['sStatus']}
                                paymentStatus={detailContract['contrato'][0]["sPaymentStatus"]}
                                showGoback={true}
                            />
                        )
                    }

                    <div className={styles.contractDetails_container}>

                        {isMobile && (
                            <Grid
                                item
                                xs={12}
                                className={styles.contenItem}
                            >
                                <Box>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        className={styles.titleFV}
                                    >
                                        Bienvenido a <br /> FirmaVirtual
                                        <br />
                                        <span>Trámite con aprobadores</span>
                                    </Typography>
                                </Box>
                            </Grid>
                        )}

                        {
                            isMobile && (
                                <Grid item xs={12} sm={12} md={4}>
                                    {
                                        !verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        (<div className={`info-body padding-y--xxs flex--evenly ${styles.contentParticipant}`}>

                                            {detailContract['firmantes'].map((member, index) => {
                                                return (
                                                    <DetailSignatories
                                                        textLoading={(e) => setTextLoading(e)}
                                                        loading={(e) => setLoading(e)}
                                                        updateContract={() => getTramit()}
                                                        member={member}
                                                        index={index}
                                                        comentarios={detailContract['comentarios']}
                                                        contractDetail={detailContract['contrato']}
                                                        contractMembers={detailContract['firmantes']}
                                                        contractFiles={detailContract['archivos']}
                                                        removeSignner={false}
                                                        deleteSigner={() => console.log('displayDeleteSignnerWarning')}
                                                    />
                                                );
                                            })}

                                        </div>)
                                    }
                                </Grid>
                            )
                        }

                        <section className={`contract-detail__info-container ${styles.info_container}`}>

                            <Notificationbanner
                                display={showReminderMessage}
                                notificationType={alertType}
                            >
                                <strong>{alertMessage}</strong>
                            </Notificationbanner>

                            {(loading || loadingTramit) && (
                                <Spinner
                                    loading={true}
                                    type="bar"
                                    text={textLoading}
                                    opacity={true}
                                />
                            )}

                            <Grid container sx={{ margin: { sm: '0 1rem' } }}>

                                <Dialog
                                    fullWidth={true}
                                    TransitionComponent={Transition}
                                    open={open}
                                    onClose={handleClose}
                                    maxWidth='xs'
                                    className={styles.mainContainerDialog}
                                >

                                    <DialogTitle sx={{ textAlign: 'center' }}>
                                        ¿Estás seguro que deseas realizar esta acción?
                                    </DialogTitle>

                                    <DialogContent>

                                        <DialogContentText>
                                            {textReenvio}
                                        </DialogContentText>

                                    </DialogContent>

                                    <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                                        <Btn
                                            variant="outlined"
                                            text="Cancelar"
                                            color="error"
                                            onClick={() => handleClose()}
                                            sx={{
                                                height: '39px',
                                                borderRadius: '20px'
                                            }}
                                        />

                                        <Btn
                                            variant="outlined"
                                            text="Aceptar"
                                            color="primary"
                                            onClick={() => { sendReminder(); handleClose() }}
                                            sx={{
                                                height: '39px',
                                                borderRadius: '20px',
                                                marginLeft: '30px !important'
                                            }}
                                        />

                                    </DialogActions>

                                </Dialog>

                                <Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                                    {!isMobile && (
                                        <Grid item xs={12} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                                            <Grid
                                                item
                                                xs={12}
                                                className={styles.contenItem}
                                            >
                                                <Box>
                                                    <Typography
                                                        variant="h5"
                                                        gutterBottom
                                                        className={styles.titleFV}
                                                    >
                                                        Bienvenido a <br /> FirmaVirtual
                                                        <br />
                                                        <span>Trámite con aprobadores</span>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {!isMobile && (
                                        <Grid item xs={12}>

                                            <div className={styles.optionsContent}>

                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['contrato']) &&
                                                    !verifiVariable(detailContract['contrato'][0]) &&
                                                    ['REVIEW', 'CREATED'].includes(detailContract['contrato'][0]['sStatus']) &&
                                                    detailContract['contrato'][0]['withDocument'] === 1 &&
                                                    (
                                                        <Grid
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >

                                                            {isMobile && <br />}

                                                            <div>

                                                                <Tooltip
                                                                    title={
                                                                        <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                                            Opción para reenviar el contrato a todos los participantes.
                                                                        </Typography>
                                                                    }
                                                                    TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 600 }}
                                                                    arrow
                                                                >

                                                                    <DeleteAndUpdateCustomButtom
                                                                        onClick={() => {
                                                                            setReSentContract(false);
                                                                            setTextReenvio('Si reenvías el contrato a los participantes, todos los firmantes deben realizar el proceso de revisión del documento nuevamente.');
                                                                            handleClickOpen();
                                                                        }}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        disabled={timer !== '00:00:00' ? true : false}
                                                                        startIcon={
                                                                            <BsEnvelope className='icon--blue icon--xs' />
                                                                        }
                                                                    >
                                                                        Reenviar contrato{timer !== '00:00:00' && timer}
                                                                    </DeleteAndUpdateCustomButtom>

                                                                </Tooltip>

                                                            </div>

                                                        </Grid>
                                                    )
                                                }

                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['contrato']) &&
                                                    !verifiVariable(detailContract['contrato'][0]) &&
                                                    ["SIGNED_PENDING"].includes(detailContract['contrato'][0]['sStatus']) &&
                                                    detailContract['contrato'][0]['withDocument'] === 1 && (!verifiVariable(userInfo) && userInfo.iProfileID === typeUser.ADMIN) &&
                                                    (
                                                        <Grid
                                                            item
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'end'
                                                                }}
                                                            >

                                                                <Tooltip
                                                                    title={
                                                                        <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                                            Reenvia correo de firmas, los participantes deberán realizar el proceso de firma nuevamente.
                                                                        </Typography>
                                                                    }
                                                                    TransitionComponent={Fade}
                                                                    TransitionProps={{ timeout: 600 }}
                                                                    arrow
                                                                >

                                                                    <DeleteAndUpdateCustomButtom
                                                                        onClick={() => {
                                                                            setReSentContract(true);
                                                                            setTextReenvio('Todos los firmantes recibiran nuevamente el correo de firma.');
                                                                            handleClickOpen();
                                                                        }}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        disabled={timer !== '00:00:00' ? true : false}
                                                                        startIcon={
                                                                            <BsEnvelope className='icon--blue icon--xs' />
                                                                        }
                                                                    >
                                                                        Reenviar firmas {timer !== '00:00:00' && timer}
                                                                    </DeleteAndUpdateCustomButtom>

                                                                </Tooltip>

                                                            </div>

                                                        </Grid>
                                                    )
                                                }

                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['contrato']) &&
                                                    !verifiVariable(detailContract['contrato'][0]) &&
                                                    ['REVIEW', 'CREATED'].includes(detailContract['contrato'][0]['sStatus']) &&
                                                    (
                                                        <Fragment>

                                                            <ResetContractModal aceptar={() => resetContract()} />

                                                            <DeleteContractAlert />
                                                            {/* {
                                                                !approveSigner.includes('SUCCESS') &&
                                                                (
                                                                )
                                                            } */}

                                                        </Fragment>
                                                    )
                                                }

                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['contrato']) &&
                                                    !verifiVariable(detailContract['contrato'][0]) &&
                                                    ['IN_NOTARY'].includes(detailContract['contrato'][0]['sStatus']) &&
                                                    userInfo.iProfileID === typeUser.ADMIN &&
                                                    (
                                                        <ModalUploadNotaryDoc
                                                            styledButton={button}
                                                            contract={contractCreatedDetails}
                                                            userToken={userToken}
                                                            inList={false}
                                                        />
                                                    )
                                                }

                                                <Tooltip
                                                    title={
                                                        <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                            Actualizar el seguimiento permite actualizar el estado de cada firmante.
                                                        </Typography>
                                                    }
                                                    TransitionComponent={Fade}
                                                    TransitionProps={{ timeout: 600 }}
                                                    arrow
                                                >

                                                    <DeleteAndUpdateCustomButtom variant="outlined" startIcon={<FaRedo className="icon--blue" />} onClick={() => getTramit()}>
                                                        Actualizar seguimiento
                                                    </DeleteAndUpdateCustomButtom>

                                                </Tooltip>

                                                {
                                                    !verifiVariable(detailContract) &&
                                                    !verifiVariable(detailContract['contrato']) &&
                                                    !verifiVariable(detailContract['contrato'][0]) &&
                                                    ['FINISHED'].includes(detailContract['contrato'][0]['sStatus']) &&
                                                    /* userInfo.iProfileID === typeUser.ADMIN && */
                                                    (
                                                        <Tooltip
                                                            title={
                                                                <Typography variant="subtitle1" color={['white.main']} gutterBottom>
                                                                    Descargar documento notariado.
                                                                </Typography>
                                                            }
                                                            TransitionComponent={Fade}
                                                            TransitionProps={{ timeout: 600 }}
                                                            arrow
                                                        >

                                                            <DeleteAndUpdateCustomButtom
                                                                variant="outlined"
                                                                startIcon={<DownloadOutlineDetailIcon />}
                                                                onClick={() => {
                                                                    setDocLog(null);
                                                                    handleShowDocument(true, 'approvers', true);
                                                                }}
                                                            >
                                                                Documento Notariado
                                                            </DeleteAndUpdateCustomButtom>

                                                        </Tooltip>
                                                    )
                                                }

                                            </div>

                                        </Grid>
                                    )}

                                    {/* Inicio Primera Seccion */}

                                    <div className={styles.contentTitle}>

                                        <div className={styles.titleNumber}>1</div>

                                        <div>

                                            <CustomTitle variant="h6" gutterBottom>
                                                Datos personales  del solicitante
                                            </CustomTitle>

                                            <CustomSubTitle variant="subtitle1" gutterBottom>
                                                Mis datos personales
                                            </CustomSubTitle>

                                        </div>

                                    </div>

                                </Grid>

                                <Grid item xs={12}>

                                    <div className={styles.userInfoContent}>

                                        <div className={styles.userIcon}>
                                            <UserIcon />
                                        </div>

                                        <Box className={styles.ownerData_Info_Box}>

                                            <Box className={styles.ownerData_Info_textBox}>
                                                <Box className={styles.dataTramit_Info}>
                                                    <Typography variant="h6">
                                                        Nombre
                                                    </Typography>
                                                </Box>

                                                <Box className={styles.dataTramit_Info}>
                                                    <Typography variant="body1">
                                                        {detailContract?.['owner']?.['full_name']}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className={styles.ownerData_Info_textBox}>
                                                <Box className={styles.dataTramit_Info}>
                                                    <Typography variant="h6">
                                                        Correo electrónico
                                                    </Typography>
                                                </Box>

                                                <Box className={styles.dataTramit_Info}>
                                                    <Typography variant="body1">
                                                        {detailContract?.['owner']?.['email']}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className={styles.ownerData_Info_textBox}>
                                                <Box className={styles.dataTramit_Info}>

                                                    <Typography variant="h6">
                                                        Teléfono
                                                    </Typography>
                                                </Box>

                                                <Box className={styles.dataTramit_Info}>
                                                    <Typography variant="body1">
                                                        {detailContract?.['owner']?.['phone'] || 'No especificado'}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>

                                        <div className={styles.userTitleInfo}>
                                        </div>

                                        {
                                            !verifiVariable(detailContract) &&
                                            !verifiVariable(detailContract['contrato']) &&
                                            !verifiVariable(detailContract['contrato'][0]) &&
                                            (<div className={styles.userInfo}>

                                            </div>)}

                                    </div>

                                </Grid>

                                {/* Fin Primera Secciοn e Inicio Segunda Session */}

                                <TramitDetail
                                    tramitID={detailContract?.['contrato']?.[0]?.['autoId']}
                                    tramitType={detailContract?.['contrato']?.[0]?.['sTipoFirma'] || detailContract?.['contrato']?.[0]?.['sTipoContrato']}
                                    participantsNumber={detailContract?.['contrato']?.[0]?.['iSignerCount']}
                                    tramitName={detailContract?.['contrato']?.[0]?.['sContractName'] || 'No especificado'}
                                />

                                <ViewModalDocs
                                    docLog={docLog}
                                    openModal={openModalDocument.openModal}
                                    setOpenModal={setOpenModalDocument}
                                    download={openModalDocument.download}
                                    tramitType={openModalDocument.tramitType}
                                />

                                <DocumentInfo
                                    signLog={
                                        detailContract?.['contrato']?.[0] &&
                                        ["FINISHED", "IN_NOTARY", "SIGNED"].includes(detailContract["contrato"][0]["sStatus"])
                                    }
                                    sectionTitle={detailContract?.contrato?.[0]?.sStatus === 'FINISHED'}
                                    isReadyToView={detailContract?.['archivos']?.[0]}
                                    isReadyToDownload={detailContract?.['contrato']?.[0]}
                                    isReplaceable={
                                        detailContract?.['contrato']?.[0] &&
                                        (
                                            ["CREATED", "REVIEW"].includes(detailContract["contrato"][0]["sStatus"]) ||
                                            userInfo.iProfileID === typeUser.ADMIN
                                        )
                                    }
                                    viewDocument={() => {
                                        setDocLog(null);
                                        handleShowDocument(true, 'approvers', true);
                                    }}
                                    downloadDocument={() => {
                                        setDocLog(null);
                                        handleShowDocument(true, 'approvers', true);
                                    }}
                                    downloadLogDocument={() => {
                                        setDocLog(true);
                                        handleShowDocument(true, 'approvers', true);
                                    }}
                                    replaceDocument={() => navigate(to.CONTRACT_REPLACE_DOCUMENT)}
                                    documentName={detailContract?.archivos?.[0]?.file_name || 'No especificado'}
                                />

                                {/* Fin Tercera Secciοn e Inicio Cuarta Session */}

                                <Grid item xs={12}>

                                    <div className={styles.contentTitle}>

                                        <div className={styles.titleNumber}>4</div>

                                        <div>

                                            <CustomTitle variant="h6" gutterBottom>
                                                Resúmen del pedido
                                            </CustomTitle>

                                            <CustomSubTitle variant="subtitle1" gutterBottom>
                                                Total a pagar
                                            </CustomSubTitle>

                                        </div>

                                    </div>

                                    {
                                        !verifiVariable(detailContract) &&
                                        !verifiVariable(detailContract['contrato']) &&
                                        !verifiVariable(detailContract['contrato'][0]) &&
                                        <Grid item xs={12} className={styles.fourtSectionContent}>

                                            <div className={styles.payInfoMainContent}>

                                                <div className={styles.payInfoContent}>

                                                    <div>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            Servicio de notaría
                                                        </CustomPayInfo>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            Servicio de firma electrónica
                                                        </CustomPayInfo>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            IVA
                                                        </CustomPayInfo>

                                                    </div>

                                                    <div>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            <NumberFormat
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                prefix={"$"}
                                                                value={payInfo['notary']}
                                                                displayType={"text"}
                                                                renderText={(value, props) => value}
                                                            />
                                                        </CustomPayInfo>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            <NumberFormat
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                prefix={"$"}
                                                                value={payInfo['signature']}
                                                                displayType={"text"}
                                                                renderText={(value, props) => value}
                                                            />
                                                        </CustomPayInfo>

                                                        <CustomPayInfo variant="subtitle1" gutterBottom>
                                                            <NumberFormat
                                                                thousandSeparator={"."}
                                                                decimalSeparator={","}
                                                                prefix={"$"}
                                                                value={payInfo['iva']}
                                                                displayType={"text"}
                                                                renderText={(value, props) => value}
                                                            />
                                                        </CustomPayInfo>

                                                    </div>

                                                </div>

                                                <div className={styles.payTotalInfo}>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        Total
                                                    </CustomPayInfo>

                                                    <CustomPayInfo variant="subtitle1" gutterBottom>
                                                        <NumberFormat
                                                            thousandSeparator={"."}
                                                            decimalSeparator={","}
                                                            prefix={"$"}
                                                            value={payInfo['total']}
                                                            displayType={"text"}
                                                            renderText={(value, props) => value}
                                                        />
                                                    </CustomPayInfo>

                                                </div>

                                            </div>

                                        </Grid>}

                                </Grid>

                                {/* Fin Cuarta Seccion */}

                            </Grid>

                        </section>

                    </div>
                </div>
                <Grid item xs={12}>
                    <div className={styles.contentFooterXs}>
                        <Typography variant="h6" gutterBottom>
                            Gracias por usar nuestros servicios
                        </Typography>

                        <Contact showMsnInfo={true} />
                    </div>
                </Grid>
            </Box >
        </Container>
    );
}

export default ContractDetailReview;