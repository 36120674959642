import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const PaperworkHeaderStyles = makeStyles(() => {

    return createStyles({
        procedureCreationContainer: {
            backgroundColor: theme.palette.white.main,
            borderRadius: '10px',
            height: 'min-content',
            paddingBottom: '10px !important'
        },
        procedureCreationTrackContainer: ({ screenSizeHook }) => ({
            justifyContent: ['xs', 'sm', 'md'].includes(screenSizeHook) ? 'center !important' : 'flex-start !important',
            borderBottom: 'unset !important',
            paddingLeft: '19px',
            paddingTop: '10px'
        })
    });

});