import React from 'react'

import { Grid } from '@mui/material'

import HeaderBoxCouponsStats from './molecules/headerBox';

const container = {
    display: 'flex'
};

const CouponsStatsHeader = ({ data }) => {

    const colorsStyle = [
        'primary',
        'success',
        'warning',
        'senary',
        'secondary'
    ];

    return (
        <Grid sx={container}>
            {data.map((element, index) => (
                <HeaderBoxCouponsStats
                    text={element.text}
                    number={element.count}
                    color={colorsStyle[index]}
                />
            ))}
        </Grid>
    )
}

export default CouponsStatsHeader