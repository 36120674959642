import React from "react";
import { LoadingButton } from "@mui/lab";

export default function Btn(props) {
    const {
        text,
        variant,
        disabled,
        size,
        fullWidth,
        color,
        type,
        onClick,
        buttonClass,
        startIcon,
        endIcon,
        loading,
        sx
    } = props;

    const sizeType = () => {
        switch (size) {
            case "xs":
                return "small";
            case "md":
                return "medium";
            case "lg":
                return "large";
            default:
                return "medium";
        }
    };

    const styleOverrideConfig = {
        textTransform: "none",
        fontSize: "1.3rem",
        borderRadius: "7px",
        padding: size === "lg" ? "9px 10px" : "6px 10px",
    };

    return (
        <LoadingButton
            size={sizeType()}
            variant={variant}
            onClick={onClick}
            type={type}
            color={color}
            disabled={disabled}
            fullWidth={fullWidth}
            sx={sx === undefined ? styleOverrideConfig : sx}
            className={buttonClass}
            startIcon={startIcon}
            endIcon={endIcon}
            loading={loading}
            disableElevation
        >
            {text}
        </LoadingButton>
    );
}
