import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { BiRightArrowAlt } from "react-icons/bi";

import { motion } from "framer-motion";

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { errorStepFormSolicitud, saveInfoDatosPersonalesFormSolicitud } from "../../../../store/formSolicitud/formSolicitud";
import { validation } from "../../../../utils/validations";
import { FormularioSolicitudStyles } from "../../index.css";

const StepFour = ({ control, watch, trigger, setValue }) => {
    const styles = FormularioSolicitudStyles();
    const {
        formSolicitud: {
            datosPersonales: {
                sEmailInvited
            }
        }
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const refFirstLoad = React.useRef({});
    const [errorInput, setErrorInput] = React.useState(undefined);

    const watchEmail = watch("sEmailInvited");

    React.useEffect(() => {
        if (![undefined].includes(errorInput)) {
            dispatch(errorStepFormSolicitud(true));
        } else {
            dispatch(errorStepFormSolicitud(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInput]);

    React.useEffect(() => {
        setValue("sEmailInvited", sEmailInvited);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sEmailInvited]);

    refFirstLoad.current = false;

    React.useEffect(() => {

        trigger();

        if (!['', null, undefined].includes(watchEmail) && watchEmail !== sEmailInvited) dispatch(saveInfoDatosPersonalesFormSolicitud({ indexe: 'sEmailInvited', value: watchEmail }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchEmail]);

    return (
        <div className={styles.stepBox}>

            <motion.div
                initial={{ y: "50vh" }}
                animate={{ y: 0 }}
                transition={{ stiffness: 150 }}
            >

                <Grid container xs={12}>

                    <Grid item xs={1} className={styles.boxIndetify}>

                        4<BiRightArrowAlt />

                    </Grid>

                    <Grid item xs={11}>

                        <Typography variant="h4" className={styles.cssTexto1} gutterBottom>
                            Correo electrónico *
                        </Typography>

                        <Typography variant="h5" className={styles.cssTexto2} gutterBottom>
                            ¿Cuál es tu mail?
                        </Typography>

                        <Controller
                            name="sEmailInvited"
                            control={control}
                            rules={{
                                required: 'Este campo es requerido',
                                pattern: {
                                    message: 'Formato email invalido',
                                    value: validation.email
                                }
                            }}
                            render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState,
                            }) => {
                                setErrorInput(fieldState.error);
                                return (
                                    <FormControl
                                        fullWidth
                                        error={fieldState.error ? true : false}
                                    >
                                        <TextField
                                            type="text"
                                            error={fieldState.error ? true : false}
                                            ref={ref}
                                            onBlur={onBlur}
                                            variant={'standard'}
                                            onChange={onChange}
                                            value={value}
                                            helperText={
                                                fieldState.error
                                                    ? fieldState.error.message
                                                    : null
                                            }
                                            placeholder={"nombre@ejemplo.com"}
                                            color={
                                                fieldState.error
                                                    ? "error"
                                                    : "success"
                                            }
                                            focused={
                                                fieldState.isTouched ||
                                                fieldState.isDirty
                                            }
                                            fullWidth
                                        />

                                    </FormControl>
                                );
                            }}
                        />

                    </Grid>

                </Grid>

            </motion.div>

        </div>
    );
}

export default StepFour;