import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";

import { SignAuthorizationStyles } from "../../index.css";
import { verifiVariable } from "../../../../../utils/enums";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";

const SignatureType = () => {
    const {
        formANFTramit: { signatureType },
        contract: {
            tramites: { allTramitAgreements, contracTypesNames },
        },
    } = useSelector((state) => state);
    const refInputControl = useRef();
    const styles = SignAuthorizationStyles();
    const dispatch = useDispatch();
    const { control, watch } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            typeFirm: "",
        },
    });

    const typeFirmWatch = watch("typeFirm");

    const getOptionLabel = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }
        return option.name;
    };

    useEffect(() => {

        const arrayData = [];
        
        if (signatureType) {
            const data = structuredClone(allTramitAgreements).filter(
                ele =>
                    ele.sContractTypeName === signatureType.value &&
                    ele.sModal === 'SIGNATURE_ANF'
            );
            if (data.length > 0) {
                arrayData.push(...data);
            };
        } else {
            if (!verifiVariable(typeFirmWatch)) {
                const data = [...allTramitAgreements].filter(
                    (item) =>
                        item["sModal"] === "SIGNATURE_ANF" &&
                        item["sContractType"] === typeFirmWatch["procedureType"] &&
                        item["sContractTypeName"] === typeFirmWatch["value"]
                );

                if (data.length > 0) {
                    arrayData.push(...data);
                };
            };
        };

        if (arrayData) {
            dispatch(
                saveTramitANFInfo({
                    indexe: "signatureType",
                    value: typeFirmWatch || signatureType,
                })
            );

            dispatch(
                saveTramitANFInfo({
                    indexe: "signatureTypeList",
                    value: arrayData,
                })
            );
        };

        // eslint-disable-next-line
    }, [typeFirmWatch, allTramitAgreements, signatureType]);

    return (
        <Box className={styles.spacinContent}>
            <Controller
                name={"typeFirm"}
                control={control}
                render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState,
                }) => {
                    return (
                        <FormControl
                            fullWidth
                            error={fieldState.error ? true : false}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                className={styles.title}
                                color={["black.main"]}
                            >
                                ¿Qué trámite necesitas gestionar?
                            </Typography>

                            <AutocompleteMui
                                value={signatureType}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                multiple={false}
                                options={contracTypesNames}
                                getOptionLabel={getOptionLabel}
                                onChange={(e, options, reason) => {
                                    if (reason === "clear") {
                                        dispatch(
                                            saveTramitANFInfo({
                                                indexe: "signatureType",
                                                value: null,
                                            })
                                        );

                                        dispatch(
                                            saveTramitANFInfo({
                                                indexe: "signatureTypeList",
                                                value: [],
                                            })
                                        );
                                    }
                                    onChange(options);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        ref={refInputControl}
                                        {...params}
                                        placeholder="¿Qué trámite necesitas gestionar?"
                                        className={styles.autocompleteInput}
                                    />
                                )}
                            />

                            <FormHelperText
                                hidden={fieldState.error ? false : true}
                            >
                                {fieldState.error
                                    ? fieldState.error.message
                                    : null}
                            </FormHelperText>
                        </FormControl>
                    );
                }}
            />
        </Box>
    );
};

export default SignatureType;
