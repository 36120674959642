import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AiFillFilePdf } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import { ReactComponent as UploadIcon } from "../icons/upload-icon.svg";
import { asyncFileToBase64, verifiVariable } from "../../../../../utils/enums";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import UnirDocsModal from "../unirDocsModal";
import { validation } from "../../../../../utils/validations";
// import { ExpressProcessStyles } from "../../../../dashboard/expressProcess/index.css";
import { saveFormInfo } from "../../../../../store/formTramit";
import { ReplaceRolDocumentStyles } from "../../index.css";

const AddDocuments = ({ loadingModaldocs, textLoadingModaldocs }) => {
    const {
        fourthStep: document
    } = useSelector((state) => state.formTramit);
    const styles = ReplaceRolDocumentStyles();
    const screenSizeHook = ScreenSizeHook();
    const dispatch = useDispatch();
    const inputFileRef = useRef(null);
    // eslint-disable-next-line
    const [openModal, setOpenModal] = useState(0);
    const [showMaxSizeText, setShowMaxSizeText] = useState(false);

    const UploadButton = styled(Button)((props) => ({
        fontStyle: "normal",
        boxShadow: "none",
        fontWeight: "bolder",
        textTransform: "none",
        fontSize: "0.80rem",
        padding: "11px 28px",
        border: "1px solid",
        borderRadius: "7px",
        lineHeight: 1.5,
        backgroundColor: !verifiVariable(document)
            ? props.theme.palette.error.main
            : props.theme.palette.primary.light,
        borderColor: !verifiVariable(document)
            ? props.theme.palette.error.main
            : props.theme.palette.primary.light,
        alignSelf: "center",
        fontFamily: ['"PoppinsBold"'].join(","),
        "&:hover": {
            backgroundColor: !verifiVariable(document)
                ? props.theme.palette.error.main
                : props.theme.palette.primary.xlight,
            borderColor: !verifiVariable(document)
                ? props.theme.palette.error.main
                : props.theme.palette.primary.xlight,
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#0062cc",
            borderColor: "#005cbf",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    }));

    const clearDoc = () => {
        saveFormInfo({
            indexe: "firms",
            value: {},
        });

        dispatch(
            saveFormInfo({
                indexe: "fourthStep",
                value: null,
            })
        );
    };

    const handleFileChange = async (event) => {
        try {
            const fileObj = event.target.files && event.target.files[0];

            if (!fileObj) {
                return;
            }

            if (fileObj.size > validation.fileMaxSize) {
                setShowMaxSizeText(true);
                return;
            }

            setShowMaxSizeText(false);

            // 👇️ reset file input
            event.target.value = null;

            // 👇️ is now empty
            // console.log(event.target.files);

            // 👇️ can still access file object here
            // console.log(fileObj);
            // console.log(fileObj.name);

            const fileBase64 = await asyncFileToBase64(fileObj);

            dispatch(
                saveFormInfo({
                    indexe: "fourthStep",
                    value: fileBase64,
                })
            );

            dispatch(
                saveFormInfo({
                    indexe: "fileName",
                    value: fileObj.name,
                })
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box className={`${styles.contentDocuments} ${styles.spacinContent}`}>
            <UnirDocsModal
                openModalDocs={openModal}
                loadingModaldocs={(e) => loadingModaldocs(e)}
                textLoadingModaldocs={(e) => textLoadingModaldocs(e)}
            />

            <input
                hidden
                accept="application/pdf"
                style={{ display: "none" }}
                ref={inputFileRef}
                type="file"
                onChange={handleFileChange}
            />

            <Typography
                variant="h6"
                className={styles.title}
                marginTop={"10px"}
                marginBottom={"10px"}
            >
                Adjuntar documentos
            </Typography>

            <Typography
                variant="caption"
                textAlign={'justify'}
                marginTop={"5px"}
                marginBottom={"10px"}
            >
                Para proteger tu contrato bajo la nueva ley N° 21.461 "Devuélveme mi Casa", incluye el certificado de dominio vigente de la propiedad (con menos de 30 días de antigüedad) en la documentación.
                Si aún no lo tienes, te sugerimos crear el tramite nuevamente incluyendo el dominio vigente de la propiedad.
                Sin el certificado de dominio vigente, el contrato de arriendo no queda protegido bajo la nueva ley de arriendo.
            </Typography>

            {!verifiVariable(document) && (
                <Grid container spacing={2} className="actions-buttoms">
                    <Grid item xs={12}>
                        <UploadButton
                            onClick={() => clearDoc()}
                            fullWidth
                            startIcon={<FaTrashAlt />}
                            variant="contained"
                            color="primary"
                        >
                            Quitar Documento
                        </UploadButton>
                    </Grid>
                </Grid>
            )}

            {verifiVariable(document) && (
                <Grid container spacing={2} className="actions-buttoms">
                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) &&
                                screenSizeHook === "xs"
                                ? 12
                                : 6
                        }
                    >
                        <UploadButton
                            onClick={() => inputFileRef.current.click()}
                            fullWidth
                            startIcon={<UploadIcon />}
                            variant="contained"
                            color="primary"
                        >
                            Subir archivo
                        </UploadButton>
                    </Grid>

                    <Grid
                        item
                        xs={
                            !verifiVariable(screenSizeHook) &&
                                screenSizeHook === "xs"
                                ? 12
                                : 6
                        }
                    >
                        <UploadButton
                            onClick={() => setOpenModal((value) => 1 + value)}
                            fullWidth
                            startIcon={<AiFillFilePdf />}
                            variant="contained"
                            color="primary"
                        >
                            Unir archivos
                        </UploadButton>
                    </Grid>
                </Grid>
            )}
            {showMaxSizeText && (
                <Typography
                    variant="body1"
                    gutterBottom
                    color={["error"]}
                    textAlign={"center"}
                >
                    El archivo que seleccionaste es demasiado grande. Por favor,
                    selecciona un archivo que sea menor a 10MB.
                </Typography>
            )}
        </Box>
    );
};

export default AddDocuments;
