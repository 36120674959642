import { Fragment, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import { ReactComponent as CloseIcon } from "../../img/close-icon.svg";
import { ReactComponent as MsnIcon } from "../../img/msn-icon.svg";
import { ReactComponent as UserIcon } from "../../img/user-icon.svg";

import { ContractDetailReviewStyles } from "../../index.css";
import dayjs from "dayjs";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 11px 5px rgba(0, 79, 159, 0.04)",
    borderRadius: "7px",
    p: 4,
};

const RejectCommentsModal = ({ index, comenmts, member }) => {
    const styles = ContractDetailReviewStyles();
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line
    const [rejectes, setRejectes] = useState(comenmts);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Tooltip
                title={
                    <Typography
                        variant="subtitle1"
                        color={["white.main"]}
                        gutterBottom
                    >
                        Revisa los comentarios de los participantes
                    </Typography>
                }
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
            >
                <MsnIcon onClick={() => handleOpen()} />
            </Tooltip>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div
                            className={`${styles.buttomClose} ${styles.buttomCloseCommentModal}`}
                        >
                            <CloseIcon onClick={handleClose} />
                        </div>

                        <Grid item xs={12} className={styles.bodyContentModal}>
                            <div
                                className={`${styles.userInfoContent} ${styles.userInfoContentModal}`}
                            >
                                <div
                                    className={`${styles.userIcon} ${styles.userIconModal}`}
                                >
                                    <UserIcon />
                                </div>

                                <div
                                    className={`${styles.userTitleInfo} ${styles.userTitleInfoModal}`}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        Participante {index + 1}
                                    </Typography>

                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        {member["full_name"]}
                                    </Typography>
                                </div>
                            </div>

                            <div className={styles.mainCommentContent}>
                                {rejectes.map((comment, index) => {
                                    console.log("comment: ", comment);

                                    return (
                                        <div className={styles.CommentContent}>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                            >
                                                {`${index + 1}- ${
                                                    comment["comments"]
                                                }: Publicado el ${dayjs(
                                                    comment["date"]
                                                ).format("DD")} de ${dayjs(
                                                    comment["date"]
                                                ).format("MMMM")} del ${dayjs(
                                                    comment["date"]
                                                ).format("YYYY")}.`}
                                            </Typography>
                                        </div>
                                    );
                                })}
                            </div>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default RejectCommentsModal;
