import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/apiExpress";
import { apiCallStart as apiCallStartMerge } from "../actions/apiMerge";
import { apiCallStart as apiCallStartAPI } from "../actions/api";
import { api } from "../../utils/enums";

const slice = createSlice({
    name: "formExpressTramit",
    initialState: {
        agrement: null,
        allTramitExpress: [],
        attachedCDV: null,
        code: null,
        contractName: '',
        detailContract: undefined,
        document: null,
        editSigner: undefined,
        fileName: null,
        firms: {},
        formParticipants: null,
        loading: false,
        message: null,
        optionSignature: [],
        participants: [],
        requestTramit: undefined,
        signatureType: null,
        status: null,
        statusMergeDoc: null,
        tramitType: null,
        uploadProgress: 0,
        tramitExpressCreated: null
    },
    reducers: {
        TRAMIT_EXPRESS_MERGE_SUCCESS: (state, action) => {
            state.loading = false;
            state.uploadProgress = 0;

            if (action.payload["status"] === "fail") {
                state.message = null;
                state.statusMergeDoc = action.payload["status"];
            } else {
                state.document =
                    "data:application/pdf;base64," +
                    action.payload["data"]["file"];
                state.message = null;
                state.statusMergeDoc = null;
            }
        },

        TRAMIT_EXPRESS_UPLOAD_PROGRESS: (state, action) => {
            state.uploadProgress = Math.floor(action.payload);
        },

        TRAMIT_EXPRESS_SAVE_INFO: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },

        TRAMIT_EXPRESS_CLEANED_DETAIL_CONTRACT: (state, action) => {
            state.detailContract = undefined;
        },

        TRAMIT_EXPRESS_PURGE_STATE: (state, action) => {
            state.uploadProgress = 0;
            state.signatureType = null;
            state.participants = [];
            state.document = null;
            state.editSigner = undefined;
            state.fileName = null;
            state.requestTramit = undefined;
            state.status = null;
            state.statusMergeDoc = null;
            state.code = null;
            state.message = null;
            state.loading = false;
            state.optionSignature = [];
            state.firms = {};
            state.agrement = null;
            state.formParticipants = null;
            state.attachedCDV = null;
            state.contractName = ''
        },

        TRAMIT_EXPRESS_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.statusMergeDoc = null;
            state.message = null;
        },

        TRAMIT_EXPRESS_CREATE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.message = action.payload["message"];
            state.loading = false;
            state.tramitType = null;
            state.attachedCDV = null;
            state.tramitExpressCreated = true
        },

        TRAMIT_EXPRESS_RESET_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.message = action.payload["message"];
            state.loading = false;
        },

        TRAMIT_EXPRESS_DELETE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.message = action.payload["message"];
            state.loading = false;
        },

        TRAMIT_EXPRESS_DETAIL_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.detailContract = action.payload["message"];
            state.loading = false;
        },

        TRAMIT_EXPRESS_LIST_SUCCESS: (state, action) => {
            state.uploadProgress = 0;
            state.status = action.payload["status"];
            state.code = action.payload["code"];
            state.allTramitExpress = action.payload["message"];
            state.loading = false;
        },

        TRAMIT_EXPRESS_FAIL: (state, action) => {
            state.uploadProgress = 0;
            state.message = action.payload["message"] || 'Error interno en el servidor';
            state.status = action.payload["status"] || 'fail';
            state.code = action.payload["code"] || '500';
            state.loading = false;
        },
        TRAMIT_EXPRESS_DEMO: (state, action) => { },
    },
});

export const {
    TRAMIT_EXPRESS_SAVE_INFO,
    TRAMIT_EXPRESS_PURGE_STATE,
    TRAMIT_EXPRESS_REQUEST,
    TRAMIT_EXPRESS_CREATE_SUCCESS,
    TRAMIT_EXPRESS_RESET_SUCCESS,
    TRAMIT_EXPRESS_DELETE_SUCCESS,
    TRAMIT_EXPRESS_DETAIL_SUCCESS,
    TRAMIT_EXPRESS_LIST_SUCCESS,
    TRAMIT_EXPRESS_CLEANED_DETAIL_CONTRACT,
    TRAMIT_EXPRESS_MERGE_SUCCESS,
    TRAMIT_EXPRESS_UPLOAD_PROGRESS,
    TRAMIT_EXPRESS_DEMO,
    TRAMIT_EXPRESS_FAIL,
} = slice.actions;

export const getALLTramitExpress = (token) => {
    return apiCallStart({
        url: `/api/v1/contract`,
        method: "GET",
        data: {},
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_LIST_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        authorization: token,
    });
};

export const getDetailTramit = (token, sContractID) => {
    return apiCallStart({
        url: `/api/v1/contract/detail/${sContractID}`,
        method: "GET",
        data: {},
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_DETAIL_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        authorization: token,
    });
};

export const mergeDoc = (data, token) => {
    return apiCallStartMerge({
        url: `/pdf/repair`,
        method: "POST",
        data,
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_MERGE_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        onUploadProgress: TRAMIT_EXPRESS_UPLOAD_PROGRESS.type,
        authorization: token,
    });
};

export const createTramit = (token, data) => {
    return apiCallStart({
        url: `/api/v1/contract/create-contract-express`,
        method: "POST",
        data,
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_CREATE_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        authorization: token,
    });
};

export const deleteTramit = (token, idContract) => {
    return apiCallStartAPI({
        url: `${api.DELETE_CONTRACT}/${idContract}`,
        method: "POST",
        data: {},
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_DELETE_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        authorization: token,
    });
};

export const resetTramit = (token, idContract) => {
    return apiCallStartAPI({
        url: `${api.RESET_CONTRACT(idContract)}`,
        method: "POST",
        data: {},
        onStart: TRAMIT_EXPRESS_REQUEST.type,
        onSuccess: TRAMIT_EXPRESS_RESET_SUCCESS.type,
        onError: TRAMIT_EXPRESS_FAIL.type,
        authorization: token,
    });
};

export const saveTramitExpressInfo = (data) => {
    return { type: TRAMIT_EXPRESS_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: TRAMIT_EXPRESS_PURGE_STATE.type, payload: null };
};

export const clearDetailContract = () => {
    return { type: TRAMIT_EXPRESS_CLEANED_DETAIL_CONTRACT.type, payload: null };
};

export default slice.reducer;
