import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";

import { to, verifiVariable } from "../../../../../utils/enums";
import StepTrackerIndicator from "../../atoms/stepTrackerIndicator";
import { PaperworkHeaderStyles } from "../../index.css";

const ContainerIndicator = () => {
    const screenSizeHook = ScreenSizeHook();
    const styles = PaperworkHeaderStyles({ screenSizeHook });
    const {
        scheduling: {
            // fourthStep,
            thirdStep: {
                fileName
            },
            firstStep: {
                contractTypeFee
            },
            secondStep
        }
    } = useSelector((state) => state);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const step1 = to.CREATE_SCHEDULE;
    const step2 = to.SCHEDULE_CREATION_SIGNATORIES;
    const step3 = to.SCHEDULE_CREATION_DOCUMENTS;
    const step4 = to.SCHEDULING_CREATION;
    const step5 = to.SCHEDULE_CREATION_PAY;


    return (
        <div className={styles.procedureCreationContainer}>

            <div className={`${styles.procedureCreationTrackContainer} procedure-creation__track-container`}>

                {pathname === step1 && (<StepTrackerIndicator
                    disableBackButtom={true}
                    cursor={(!verifiVariable(contractTypeFee) && pathname !== step1) ? 'pointer' : undefined}
                    onClick={() => {
                        if (!verifiVariable(contractTypeFee) && pathname !== step1) navigate(step1)
                    }}
                    step="1"
                    current={
                        pathname === step1
                            ? true
                            : false
                    }
                    title="Datos del trámite"
                    subtitle="Tipo de documento de trámite"
                />)}

                {pathname === step2 && (<StepTrackerIndicator
                    goBack={() => navigate(step1)}
                    disableNextButtoms={secondStep.length ? false : true}
                    cursor={(!verifiVariable(contractTypeFee) && pathname !== step2) ? 'pointer' : undefined}
                    onClick={() => {
                        if (!verifiVariable(contractTypeFee) && pathname !== step2) navigate(step2)
                    }}
                    step="2"
                    current={
                        pathname === step2
                            ? true
                            : false
                    }
                    title="Datos de participantes"
                    subtitle="Datos de las personas participantes"
                />)}

                {pathname === step3 && (<StepTrackerIndicator
                    goBack={() => navigate(step2)}
                    disableNextButtoms={fileName ? false : true}
                    cursor={(!verifiVariable(contractTypeFee) && pathname !== step3) ? 'pointer' : undefined}
                    onClick={() => {
                        if (!verifiVariable(contractTypeFee) && pathname !== step3) navigate(step3)
                    }}
                    step="3"
                    current={
                        pathname === step3
                            ? true
                            : false
                    }
                    title="Adjuntar documentos"
                    subtitle="Documentos solicitados a firmar"
                />)}

                {pathname === step4 && (<StepTrackerIndicator
                    goBack={() => navigate(step3)}
                    disableNextButtoms={fileName ? false : true}
                    cursor={(!verifiVariable(contractTypeFee) && pathname !== step4) ? 'pointer' : undefined}
                    onClick={() => {
                        if (!verifiVariable(contractTypeFee) && pathname !== step4) navigate(step4)
                    }}
                    step="4"
                    current={
                        pathname === step4
                            ? true
                            : false
                    }
                    title="Agendamiento"
                    subtitle="Agenda la fecha para realizar el trámite"
                />)}

                {pathname === step5 && (<StepTrackerIndicator
                    goBack={() => navigate(step4)}
                    disableNextButtoms={fileName ? false : true}
                    cursor={(!verifiVariable(contractTypeFee) && pathname !== step5) ? 'pointer' : undefined}
                    onClick={() => {
                        if (!verifiVariable(contractTypeFee) && pathname !== step5) navigate(step5)
                    }}
                    step="5"
                    current={
                        pathname === step5
                            ? true
                            : false
                    }
                    title="Pago"
                    subtitle="Informe de pago"// seguir con
                />)}

            </div>

        </div>
    );
}

export default ContainerIndicator;