import axios from "axios";
import { api } from "../../../../utils/enums";

const uri = api.BASE_SCHEDULE_URI;


export const getNotary = async (notaryId, userToken) => {
    try {
        const response = await axios.get(
            `${uri}${api.GET_NOTARY}${notaryId}`,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const blackList = async (date, userToken) => {
    try {
        const response = await axios.get(
            `${uri}${api.GET_BLACK_LIST(date)}`,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};