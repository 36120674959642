import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";

import Grid from "@mui/material/Grid";

import { Btn } from "../../components";
import { resetPassword } from "../../utils/api";
import { to } from "../../utils/enums";
import { validation } from "../../utils/validations";
import ModalAlert from "../floats/ModalAlert";

export default function RecoverAccountForm() {
    const navigate = useNavigate();
    const [recoverSuccess, setRecoverSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState({});

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const resErrorCatch = (email) => {
        setRecoverSuccess(false);
        setShowModal(true);
        setModalStatus({
            title: "¡Vaya!, ha ocurrido un error",
            message: `Verifica tu correo electrónico ${email} y vuelve a intentarlo`,
            buttonText: "Volver a intentarlo",
            modalIcon: "warnIcon"
        });
    };

    const onSubmit = async (data) => {
        try {
            const response = await resetPassword(data.recover);

            if (response?.status !== undefined) {
                if (response?.status === "success") {
                    setRecoverSuccess(true);
                    setShowModal(true);
                    setModalStatus({
                        title: "¡Excelente!",
                        message: `Hemos enviado un correo a ${data.recover} con las instrucciones para recuperar tu cuenta`,
                        buttonText: "Entendido",
                        modalIcon: "successIcon"
                    });
                } else {
                    resErrorCatch(data.recover);
                }
            } else {
                resErrorCatch(data.recover);
            }
        } catch (error) {
            resErrorCatch(data.recover);

            console.log(error);
        }
    };

    const handleModal = () => {
        if (recoverSuccess) {
            setShowModal(false);
            navigate(to.RESET);
        } else {
            setShowModal(false);
        }
    };


    return (
        <>

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent={"center"}>
                    <Grid
                        item
                        xs={12}
                        sm={9}
                        md={8}
                        sx={{ marginRight: 2, marginLeft: 2 }}
                    >
                        <div className="input-container">
                            <label>Correo electrónico</label>
                            <input
                                type="text"
                                className={
                                    errors.recover && "input-container--error"
                                }
                                placeholder="tucorreo@mail.com"
                                {...register("recover", {
                                    required: true,
                                    pattern: validation.email,
                                })}
                            />
                            {errors.recover?.type === "required" && (
                                <span className="input--error">
                                    Este campo es obligatorio
                                </span>
                            )}
                            {errors.recover?.type === "pattern" && (
                                <span className="input--error">
                                    Ingresa un correo electrónico valido
                                </span>
                            )}
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={9}
                        md={8}
                        sx={{ marginRight: 2, marginLeft: 2 }}
                    >
                        <Btn
                            variant="contained"
                            text="Enviar"
                            size="lg"
                            color="primary"
                            type="submit"
                            buttonClass="button-submit"
                            fullWidth
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={9}
                        md={8}
                        sx={{ marginRight: 2, marginLeft: 2 }}
                    >
                        <div className="flex--center" style={{ marginBottom: 30 }}>
                            <Btn
                                variant="text"
                                text="Atrás para iniciar sesión"
                                buttonClass="button-link"
                                onClick={() => navigate("/")}
                                startIcon={
                                    <MdKeyboardArrowLeft className="icon--md icon--blue" />
                                }
                            />
                        </div>

                        <div className="flex--center signup-link">
                            <span className="whitespace--right-md">
                                ¿Nuevo en nuestra plataforma?
                            </span>
                            <Link to="/signup-account">Crea una cuenta</Link>
                        </div>
                    </Grid>
                </Grid>

            </form>
        </>
    );
}
