import axios from "axios";
import { api as Api } from "../utils/enums";

const uriClvUnica = Api.BASE_URI_CLV_UNI;
// const uriChalle = Api.BASE_URI_CHALLE;
const uriRa = Api.BASE_URI_RA;
const uriEsigner = Api.BASE_URI_ESIGNER;
const urlChalleJose = 'https://esignapi.alvexltda.cl';

const version = 'v1';

const api = 'api';

const headersClvUnica = {
    'X-API-APP': Api.X_API_APP,
    'X-API-KEY': Api.X_API_KEY
};

const headersChalle = {
    'XAPIAPP': Api.X_API_APP,
    'XAPIKEY': Api.X_API_KEY
};

const urlsFva = {
    claveUnicaToken: `${uriClvUnica}/ClaveUnica/${api}/${version}/tokens/request`,
    claveUnicaUrlAuthentication: `${uriClvUnica}/ClaveUnica/${api}/${version}/tokens/:code/authorize`,
    claveUnicaAccessToken: `${uriClvUnica}/ClaveUnica/${api}/${version}/tokens`,
    claveUnicaDatosUsuario: `${uriClvUnica}/ClaveUnica/${api}/${version}/users/info`,
    // challengeGenerarToken: `${uriChalle}/esign-api-mvn/rest-services/tokens/generarToken`
    challengeGenerarToken: `${urlChalleJose}/generarToken.php`,
    challengeIngresValidacion: `${urlChalleJose}/ingresoValidacionChallenge.php`,
    challengeRestValidacion: `${urlChalleJose}/respuestaValidacionChallenge.php`,
    raWSIngresoSolicitud: `${uriRa}/esign-ra/WSIngresoSolicitud`,
    raCertPinFirma: `${uriEsigner}/SignServerEsign/WSDescargaCertificadoConfPinFirma`,
    sigDocUrl: `${uriEsigner}/SignServerEsign/WSIntercambiaDocSoap`
};

export const sigDocWithCert = async (data) => {
    try {

        const response = await axios.post(urlsFva.sigDocUrl, data, {
            headers: {
                'Content-Type': 'text/xml;charset=ISO-8859-1',
                "Access-Control-Allow-Origin": "*"
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const wSDescargaCertificadoConfPinFirma = async (data) => {
    try {

        const response = await axios.post(urlsFva.raCertPinFirma, data, {
            headers: {
                'Content-Type': 'text/xml',
                "Access-Control-Allow-Origin": "*"
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const raWSIngresoSolicitud = async (data) => {
    try {

        const response = await axios.post(urlsFva.raWSIngresoSolicitud, data, {
            headers: {
                //'Content-Type': 'application/xml',
                "Access-Control-Allow-Origin": "*",
                // "Access-Control-Allow-Headers" : "Content-Type",
                //"Access-Control-Allow-Methods": "OPTIONS,POST,GET,PUT,DELETE",
                //"Accept": "*/*",
                //"Content-Length": "458"
            }
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const challengeRestValidacion = async (token, data) => {
    try {

        const response = await axios.post(urlsFva.challengeRestValidacion, data, {
            headers: Object.assign(headersChalle, { 'Authorization': `${token}` })
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const challengeIngressValidacion = async (token, data) => {
    try {

        const response = await axios.post(urlsFva.challengeIngresValidacion, data, {
            headers: Object.assign(headersChalle, { 'Authorization': `${token}` })
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const challengeGenerarToken = async () => {
    try {

        const response = await axios.get(urlsFva.challengeGenerarToken, {
            headers: headersChalle
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const claveUnicaDatosUsuario = async (data) => {
    try {
        const response = await axios.post(urlsFva.claveUnicaDatosUsuario, data, {
            headers: headersClvUnica
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const claveUnicaAccessToken = async (code) => {
    try {
        const response = await axios.get(urlsFva.claveUnicaAccessToken + '/' + code, {
            headers: headersClvUnica
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const claveUnicaToken = async () => {
    try {
        const response = await axios.post(urlsFva.claveUnicaToken, {
            failedRedirect: "https://firmavirtual.legal/",
            successRedirect: "https://firmavirtual.legal/"
        }, {
            headers: headersClvUnica
        });

        return response.data;
    } catch (error) {
        return error;
    }
}

export const urlAuthentication = async (code) => {
    try {
        const response = await axios.post(urlsFva.claveUnicaUrlAuthentication, {}, {
            params: {
                code
            },
            headers: headersClvUnica
        });

        return response.data;
    } catch (error) {
        return error;
    }
}