import { createRef, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { verifiVariable } from "../../../../../utils/enums";
import { ReplaceRolDocumentStyles } from "../../index.css";

const DraggableBox = ({
    viewport,
    positions,
    signatory,
    deleteBox,
    refCanvas,
}) => {
    // eslint-disable-next-line
    const [width, setWidth] = useState(185);
    // eslint-disable-next-line
    const [height, setHeight] = useState(52);
    const styles = ReplaceRolDocumentStyles({
        width,
        height,
        layerX: signatory["positionBoxX"],
        layerY: signatory["positionBoxY"],
        iconColor: signatory["textColor"],
    });
    const [textBox, setTextBox] = useState("");
    const boxRef = createRef();


    useEffect(() => {
        let newText = "";

        if (!verifiVariable(signatory)) {
            const splitText = signatory["textTootip"].split(" ");

            const countWords = splitText.length;

            if (countWords === 1) {
                setTextBox(splitText[0]);
            } else {
                splitText.forEach((texto, index) => {
                    if (index === 0) {
                        newText += texto;
                    } else {
                        newText += `${index > 1 ? "" : " "}${texto
                            .substring(0, 1)
                            .toUpperCase()}`;
                    }
                });

                setTextBox(newText);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signatory]);

    useEffect(() => {

        (async () => {

            const element = window["jQuery"](boxRef.current);

            let startOffsetX = 0;
            let startOffsetY = 0;

            element.draggable({
                start: (event) => {
                    startOffsetX = event.originalEvent.offsetX;
                    startOffsetY = event.originalEvent.offsetY;
                },
                stop: (event) => {
                    const canvas = refCanvas.current;
                    const rect = canvas.getBoundingClientRect();

                    const scaleX = canvas.width / rect.width;
                    const scaleY = canvas.height / rect.height;
                    const offsetX = event.clientX - rect.left;
                    const offsetY = event.clientY - rect.top;

                    const startX = parseFloat(startOffsetX);
                    const startY = parseFloat(startOffsetY);
                    const deltaX = offsetX - startX;
                    const deltaY = offsetY - startY;

                    const CoordenadaX = (deltaX * scaleX - 2) / viewport.scale;
                    const CoordenadaY = (deltaY * scaleY + 20) / viewport.scale;

                    positions(
                        Object.assign(
                            { ...signatory },
                            {
                                x: CoordenadaX,
                                y: CoordenadaY,
                                positionBoxX: element.position().left,
                                positionBoxY: element.position().top,
                            }
                        )
                    );
                },
            });
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tooltip
            title={`Firmante ${signatory["numbSigned"]}`}
            arrow
            placement="bottom-start"
        >
            <div
                className={styles.dragableBox}
                ref={boxRef}
                style={{ height: `${height}px`, width: `${width}px` }}
            >
                <FaTrashAlt
                    className={styles.boxIcon}
                    onClick={() => deleteBox()}
                />

                <Typography variant="h6" color={[signatory["textColor"]]}>
                    {textBox}{" "}
                    <AiFillCheckCircle size={20} className={styles.textIcon} />
                </Typography>
            </div>
        </Tooltip>
    );
};

export default DraggableBox;
