import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const HelpTramitsStyles = makeStyles(() => {

    return createStyles({
        divIcon: {
            width: '100%',
            position: 'absolute',
            top: '5px',            
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
            alignContent: 'center',
            '& svg': {
                cursor: 'pointer',
                marginRight: '0px'
            }
        },
        popover: {            
            backgroundColor: 'transparent',
            '& div.MuiPaper-root': {
                top: '10vw',
                left: '30vw',
                transformOrigin: '152px 200px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '40% !important',
                '& iframe': {
                    height: "400px",
                    width: '100%'
                }
            }
        }
    })

});

