import React, { useState, useEffect } from "react";
import { to } from "../../utils/enums";
import { Text, Btn } from "..";
import { useNavigate } from "react-router-dom";
import { Box, Divider, Grid } from "@mui/material";
import {
    DataGrid
} from "@mui/x-data-grid";
import BasicTabs from "../common/tabs/BasicTabs";
import { BsTrash } from "react-icons/bs";

const ManageIntegration = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [key, setKey] = useState("");

    useEffect(() => {
        let run = true;
        if (run) {
            genKey();

            setUsers([
                {
                    id: 1,
                    version: "v3",
                    apiKey: "wtxb23625762341sdfdljksf03",
                    info: "API v3",
                    name: "HeavenWard",
                    avancedApi: "28-01-2021",
                },
                {
                    id: 2,
                    version: "v3",
                    apiKey: "wtxb23625762341sdfdljksf03",
                    info: "API v3",
                    name: "HeavenWard",
                    avancedApi: "28-01-2021",
                },
                {
                    id: 3,
                    version: "v3",
                    apiKey: "wtxb23625762341sdfdljksf03",
                    info: "API v3",
                    name: "HeavenWard",
                    avancedApi: "28-01-2021",
                },
                {
                    id: 4,
                    version: "v3",
                    apiKey: "wtxb23625762341sdfdljksf03",
                    info: "API v3",
                    name: "HeavenWard",
                    avancedApi: "28-01-2021",
                },
            ]);

            setLoading(false);
            // TODO: Dispatching an action to get the users of account owner.
            // setLoading(true);
            // if(userInfo.userId !== undefined) {
            //     store.dispatch(getContractsCreated(userInfo.userId, userToken));
            // }

            //TODO: Subscribing to the store and getting the users from the store.
            // const unsubscribe = store.subscribe(() => {
            //     const { contractListActive } = store.getState().contract.contractCreator;

            //     if (!_.isEmpty(contractListActive)) {
            //         const filterContracs = contractListActive.message.filter(contract => contract.sStatus === "CREATED" && contract.sPaymentStatus === "PENDING");

            //         setLoading(false);
            //         setUsers(filterContracs);
            //     }
            // })

            // return () => unsubscribe();
        }
        return () => (run = false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const genKey = () => {
        setLoading(true);
        setKey(
            Math.random()
                .toString()
                .split(".")
                .reduce((a, b) => a + b)
        );
    };

    const userActionTrigger = (id) => {
        return (
            <Box
                sx={actionButtonsContainerStyles}
                className="flex--1 flex--center"
            >
                <Box
                    sx={actionButtonsStyles}
                    onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}
                >
                    <BsTrash className="icon--xs icon--grey-dark icon-pointer" />
                </Box>
            </Box>
        );
    };

    const gridColumns = [
        {
            field: "version",
            headerAlign: "center",
            headerName: "Versión",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item text--uppercase text--left",
            width: 80
        },
        {
            field: "apiKey",
            headerAlign: "center",
            headerName: "Claves API",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 240
        },
        {
            field: "info",
            headerAlign: "center",
            headerName: "Información",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 160
        },
        {
            field: "name",
            headerAlign: "center",
            headerName: "Nombre",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 300
        },
        {
            field: "avancedApi",
            headerAlign: "center",
            headerName: "Avanced API 140 LABEL",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 240
        },
        {
            field: "id",
            headerAlign: "center",
            headerName: "Acciones",
            headerClassName: "grid__header-item",
            rowClassName: "grid__row-item",
            width: 100,
            disableColumnMenu: true,
            renderCell: (params) => userActionTrigger(params.value),
        },
    ];

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    };

    const title1Styles = {
        color: "quinary.xdark",
        fontWeight: "500",
        marginBottom: "0",
    };

    const subTitleStyles = {
        color: "quinary.dark",
        fontWeight: "400",
        fontSize: '0.9em',
        padding: '10px 10px 10px 0'
    };

    const gridContainerStyles = {
        border: "none",
    };

    const actionButtonsContainerStyles = {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    };

    const actionButtonsStyles = {
        padding: "6px",
    };

    const localizedTextsMap = {
        columnMenuUnsort: "Sin ordenar",
        columnMenuSortAsc: "De menor a mayor",
        columnMenuSortDesc: "De mayor a menor",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas",
        toolbarExport: "Descargar",
        toolbarFilters: "Filtros",
        filterOperatorContains: "Contiene",
        filterOperatorEquals: "Igual a",
        filterOperatorStartsWith: "Empieza con",
        filterOperatorEndsWith: "Termina con",
        filterOperatorIsEmpty: "Esta vacio",
        filterOperatorIsNotEmpty: "No esta vacio",
        filterPanelInputLabel: "Valor",
        filterPanelInputPlaceholder: "Filtrar por valor",
    };

    const buttonApiStyles = {
        backgroundColor: "transparent",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: "600",
        textTransform: "none",
        color: "primary.light",
        marginBottom: "5px",
        "&:hover": {
            backgroundColor: "#ddf1f8",
        },
    };

    const buttonKeyStyles = {
        backgroundColor: "#87e483",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontWeight: "600",
        textTransform: "none",
        color: "white",
        marginBottom: "5px",
        "&:hover": {
            backgroundColor: "#5cc858",
        },
    };

    const customToolbar = () => {
        return (
            <div
                className="contracts-list__top-menu"
                style={{ display: "block" }}
            >
                <div className="title-menu__status">
                    <Text sx={titleStyles} variant="h6">
                        Administración e integraciones
                    </Text>
                    <Text sx={subTitleStyles}>
                        Resumen de administración de integraciones
                    </Text>
                </div>
                <Divider style={{ marginTop: "27px", marginBottom: "27px" }} />
                <Box component="div">
                    <Grid container>
                        <Grid item xs={4} style={{ textAlign: "center" }}>
                            <Btn
                                variant="contained"
                                text="Claves de API"
                                size="lg"
                                type="buttom"
                                sx={buttonApiStyles}
                            />
                            <Divider
                                sx={{
                                    background: "#00adea",
                                    height: "3px",
                                    marginTop: "10px",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: "right" }}>
                            <Btn
                                variant="contained"
                                text="Generar key"
                                size="lg"
                                type="buttom"
                                sx={buttonKeyStyles}
                            />
                            <Divider sx={{ marginTop: "10px" }} />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    };

    const customFooterbar = () => {
        return (
            <Box component="div" sx={{ marginTop: "27px" }}>

                <Grid container>

                    <Grid item xs={6} sm={4} style={{ textAlign: "center" }}>

                        <Btn
                            variant="contained"
                            text="¿ Necesitas la clave API v2?"
                            size="lg"
                            type="buttom"
                            sx={buttonApiStyles}
                        />

                        <Divider
                            sx={{
                                background: "#00adea",
                                height: "3px",
                                marginTop: "10px",
                            }}
                        />

                    </Grid>

                    <Grid item xs={6} sm={8}>

                        <Box component="div" sx={{ height: "56px" }}></Box>

                        <Divider />

                    </Grid>

                </Grid>

                <Grid container spacing={2} sx={{ marginTop: "27px" }}>

                    <Grid item xs={12} lg={6}>

                        <Text sx={title1Styles}>Sobre la API</Text>

                        <Text sx={subTitleStyles}>
                            La API de SendinBlue Permmite la integración de la
                            funcionalidades de sendinBlue en otras aplicaciones
                            ¿Deseas más información?
                        </Text>

                        <Btn
                            text="Leer la documentación API"
                            size="lg"
                            sx={buttonApiStyles}
                        />

                    </Grid>

                    <Grid item xs={12} lg={6} sx={{ background: "#f6f8fa", height: '310px' }}>
                        <BasicTabs />
                    </Grid>

                </Grid>
                
            </Box>
        );
    };

    return (
        <div className="content-detail__container" key={key}>

            <div className="contracts-status-list__container" style={{ height: 'auto', width: '100%' }}>

                <div style={{ display: 'flex', height: '100%' }}>

                    <div style={{ flexGrow: 1 }}>

                        <DataGrid
                            rows={users}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            loading={loading}
                            getRowId={(row) => row.id}
                            rowHeight={80}
                            sx={gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: customToolbar,
                                NoRowsOverlay: () => (
                                    <div className="data-grid__no-rows">
                                        No hay registros
                                    </div>
                                ),
                                Footer: customFooterbar,
                            }}
                        />

                    </div>

                </div>

            </div>

        </div>
    );
};

export default ManageIntegration;
