import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContractsCreatedSuccessStyles } from '../../index.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ScreenSizeHook from '../../../../hooks/ScreenSizeHook';
import MainProgresBar from '../../../modalContractCreatedAndContractSteps/molecules/mainProgressBar';
import SliderDots from '../sliderDots/sliderDots';
import ResponsiveText from '../../../modalContractCreatedAndContractSteps/molecules/responsiveText';

const ImgSlider = ({ images, stepsNumber, text, stepsTitle }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepActive, setStepActive] = useState(0);
    const [visibleImages, setVisibleImages] = useState([]);
    const styles = ContractsCreatedSuccessStyles();
    const screenSize = ScreenSizeHook();

    const prevSlide = () => {
        let active = 0;
        const firstIndex = currentIndex - 1;
        if (currentIndex >= 1) active = 1;
        if (firstIndex === 0) active = 0;
        setStepActive(value => value - 1);
        setCurrentIndex(firstIndex);
        setActiveIndex(active);
    };


    const nextSlide = () => {
        let active = 0;
        let firstIndex = currentIndex;
        if (currentIndex < images.length - 1) firstIndex++
        if (currentIndex >= 0 && currentIndex <= images.length - 3) active = 1;
        if (currentIndex === images.length - 2) active = 2
        setStepActive(value => value + 1);
        setCurrentIndex(firstIndex);
        setActiveIndex(active);
    };

    const getVisibleImages = () => {

        if (['xs', 'sm', 'md'].includes(screenSize)) {
            setVisibleImages([
                images[currentIndex]
            ]);
        } else {
            if (currentIndex === 0) {
                setVisibleImages([
                    images[currentIndex],
                    images[currentIndex + 1],
                    images[currentIndex + 2],
                ]);
            };

            if (currentIndex > 0 && currentIndex < images.length - 1) {
                setVisibleImages([
                    images[currentIndex - 1],
                    images[currentIndex],
                    images[currentIndex + 1],
                ]);
            };
        };
    };

    useEffect(() => {
        getVisibleImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, screenSize])

    return (
        <Container sx={{ margin: '1.667rem 0 0 0' }}>

            <MainProgresBar
                steps={text.length}
                active={stepActive}
                stepsTitle={stepsTitle}
            />

            <ResponsiveText
                text={text}
                active={stepActive}
            />

            <div className={styles.slider}>

                <ArrowBackIosIcon
                    sx={currentIndex <= 0 ? { visibility: 'hidden' } : {}}
                    disabled={currentIndex < 1}
                    onClick={prevSlide}
                >Prev
                </ArrowBackIosIcon>

                <div className={styles.image_container}>
                    {visibleImages.map((image, index) => (
                        <img
                            key={index}
                            src={image.src}
                            alt={image.alt}
                            className={`
                                ${styles.slider_image} 
                                ${['xs', 'sm', 'md'].includes(screenSize) ?
                                    styles.slider_image__active : {}}
                                ${!['xs', 'sm', 'md'].includes(screenSize) &&
                                    index === activeIndex ?
                                    styles.slider_image__active : {}} 
                        `} />
                    ))}
                </div>

                <ArrowForwardIosIcon
                    sx={currentIndex >= images.length - 1 ? { visibility: 'hidden' } : {}}
                    disabled={currentIndex >= images.length - 1}
                    onClick={nextSlide}
                >Next
                </ArrowForwardIosIcon>

            </div>

            <SliderDots
                dotsNumber={text.length}
                dotActive={stepActive}
            />

        </Container>
    )
}

export default ImgSlider