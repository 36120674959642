import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme();

export const AppBarMenuStyles = makeStyles(() => {

    return createStyles({
        userName: {
            fontSize: '1rem',
            display: 'block',
            color: "black"
        },
        userRol: {
            fontSize: '0.85rem',
            display: 'block',
            color: theme.palette.grey[600]
        },
        iconsBlock: {
            display: 'flex',
            alignItems: 'center',
            marginRight: 22
        },
        rightBlock: {
            display: 'flex'
        },
        personInfoContent: {
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 1.2,
            alignItems: 'flex-end',
            marginRight: 10
        },
        userInfoContent: {
            display: 'flex',
            alignItems: 'center'
        },
        mainContent: {
            width: '100%',
        },
        topUserContent: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    })
});

