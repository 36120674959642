import { forwardRef, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";

import Btn from "../../../../buttons/Button";
import {
    clearStatusAnf,
    deleteAnfTramit,
    deleteExpressTramit,
    saveTramitANFInfo,
} from "../../../../../store/formANFTramit";
import { theme } from "../../../../../theme/theme";
import Spinner from "../../../../spinners/Spinner";
import { deleteContract } from "../../../../../utils/api";
import { verifiVariable } from "../../../../../utils/enums";
import { saveDetailsContract } from "../../../../../store/contract/contractDetail";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteContractAlert = ({
    contractID,
    openModalDelete,
    sProcess,
    updateData,
}) => {
    const dispatch = useDispatch();
    const { login: { user } } = useSelector(state => state.session);
    const {
        contractCreator: { contractListActive }
    } = useSelector((state) => state.contract);
    const { allAnfTramit, status, requestTramit } = useSelector((state) => state.formANFTramit);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        dispatch(clearStatusAnf());
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteCurrentContract = async () => {
        const { token } = user;

        dispatch(deleteAnfTramit(token, contractID));

        dispatch(deleteExpressTramit(token, contractID));

        setTimeout(() => {
            updateData();
        }, 1000);
    };

    const deleteDocs = async () => {
        try {
            setLoading(true);

            const { token } = user;

            const { message } = contractListActive;
            const findContract2 = [...allAnfTramit].find((item) => item["contractID"] === contractID);

            await deleteContract(contractID, token);

            if (!verifiVariable(message) && typeof message === "object" && [...message].length !== 0) {
                const findContract1 = [...message].find((item) => item["sContractID"] === contractID);
                if (!verifiVariable(findContract1)) dispatch(saveDetailsContract({ indexe: "contractListActive", value: { ...contractListActive, message: [...message].filter((item) => item["sContractID"] !== contractID) } }));
            }

            if (!verifiVariable(findContract2)) dispatch(saveTramitANFInfo({ indexe: "allAnfTramit", value: [...allAnfTramit].filter((item) => item["contractID"] !== contractID) }));

            handleClose();

            setLoading(false);
            updateData();
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (["success", "fail"].includes(status)) {
            handleClose();
        }

        setLoading(requestTramit);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, requestTramit]);

    useEffect(() => {
        if (openModalDelete > 0) handleClickOpen();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModalDelete]);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                sx={{
                    "& div.MuiDialogContent-root": {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    },
                    "& div.MuiPaper-root": {
                        borderRadius: "10px",
                    },
                    "& h6:nth-child(2)": {
                        fontFamily: "PoppinsBold",
                        fontSize: "1.1rem",
                    },
                    "& h6:nth-child(3)": {
                        fontFamily: "PoppinsRegular",
                        fontSize: ".9rem",
                        color: theme.palette.black.main,
                        "& span": {
                            color: theme.palette.primary.light,
                        },
                    },
                }}
            >
                {loading && (
                    <Spinner loading={true} type="bar" opacity={true} />
                )}

                <DialogTitle sx={{ textAlign: "center" }}>
                    ¿Deseas eliminar este contrato?
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            Si eliminas este contrato, no podrás recuperarlo.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions
                    style={{ justifyContent: "center", paddingBottom: "20px" }}
                >
                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() =>
                            sProcess === "ANF"
                                ? deleteCurrentContract()
                                : deleteDocs()
                        }
                        sx={{
                            height: "39px",
                            borderRadius: "20px",
                            marginLeft: "30px !important",
                        }}
                    />
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DeleteContractAlert;
