import { Box } from "@mui/system";
import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { Text } from "../../../..";
import { styles } from "../../../../../helpers";

const RenderCountSigner = ({ data: { iSignedCount } }) => {
    
    const customBox = {
        display: "flex",
    }

    const customCount = {
        display: "flex",
        justifyContent: "center"
    }

    return (
        <Box sx={[styles.userAvatarContainerStyles, customBox]}>
            <FaUserAlt
                className="icon--xs icon--blue-light icon-pointer"
                style={{ marginRight: "10px", height: "14px", width: "14px" }}
            />
            <Text sx={[styles.userNameStyles,customCount]} variant="h6">
                {iSignedCount}
            </Text>
        </Box>
    );
};

export default RenderCountSigner;
