import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const DataPreviewStyles = makeStyles(() => {

    return createStyles({
        formContainer: {
            width: '93% !important'
        },
        subTitles: {
            display: 'flex',
            flexDirection: 'column',
            wordWrap: 'break-word'
        },
        titles: {
            display: 'flex',
            // justifyContent: 'flex-start',
            alignItems: 'end',
            flexDirection: 'row',
            marginBottom: '13px'
        },
        floatButtom: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        textoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            fontSize: '1.1rem'
        },
        hintStyles: {
            color: theme.palette.black.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleStyles: {
            color: theme.palette.black.main,
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '44px'
        },
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        numberTitle: {
            display: 'flex',
            alignItems: 'baseline'
        }
    });

});