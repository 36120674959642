import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Link, Typography } from '@mui/material';

import { ProceduresHistoryStyles } from '../../index.css';
import { ReactComponent as IconMaxSecurity } from '../../../../assets/icons/icon-max-security.svg';
import { ReactComponent as IconHighSecurity } from '../../../../assets/icons/icon-high-security.svg';
import { ReactComponent as IconMidSecurity } from '../../../../assets/icons/icon-more-security.svg';
import { ReactComponent as IconGoodSecurity } from '../../../../assets/icons/icon-good-security.svg';
import { ReactComponent as IconRepeat } from '../../../../assets/icons/icon-repeat-outlined-gray.svg';

import { useDispatch } from 'react-redux';
import { saveTramitExpressInfo } from '../../../../store/formExpressTramit';
import { to } from '../../../../utils/enums';
import { saveTramitANFInfo } from '../../../../store/formANFTramit';
import { saveFormInfo } from '../../../../store/formTramit';
import { theme } from '../../../../theme/theme';

const FrequentProceduresCard = ({
    contractType,
    iContractTypeFeeID,
    iSignerCount,
    processType,
    tramitType,
}) => {

    const [tramitTypeStyle, setTramitType] = useState({});
    const navegate = useNavigate();
    const dispatch = useDispatch();

    const styles = ProceduresHistoryStyles();

    const handleClickNavigate = () => {

        let signatureTypeNewValue = '';

        if (tramitType === 'FIRMA') {
            signatureTypeNewValue = 'SIGNATURE'
        } else if (tramitType === 'FIRMA CON PROTOCOLIZACION NOTARIAL') {
            signatureTypeNewValue = 'SIGNATURE_PROT_NOTARIAL'
        } else {
            signatureTypeNewValue = 'SIGNATURE_CERT_NOTARIAL'
        };

        if (processType === 'EXPRESS') {
            dispatch(saveTramitExpressInfo({
                indexe: 'tramitType',
                value: {
                    name: contractType,
                    value: contractType.toUpperCase()
                }
            }));
            dispatch(saveTramitExpressInfo({
                indexe: 'signatureType',
                value: signatureTypeNewValue
            }));
            dispatch(saveTramitExpressInfo({
                indexe: 'optionTypeTramit',
                value: [{
                    iContractTypeFeeID,
                    iSignCount: iSignerCount
                }]
            }));

            navegate(to.EXPRESS_CONTRACT_CREATION);
        } else if (processType === 'NORMAL') {
            dispatch(saveFormInfo({
                indexe: 'firstStep',
                value: {
                    procedureid: {
                        name: contractType,
                        value: contractType.toUpperCase()
                    },
                    iSignerCount: iSignerCount,
                    contractTypeFee: {
                        sModal: signatureTypeNewValue
                    },
                    proceduresignature: iContractTypeFeeID.toString()
                }
            }));
            dispatch(saveFormInfo({
                indexe: 'procedureid',
                value: {
                    name: contractType,
                    value: contractType.toUpperCase()
                }
            }));

            navegate(to.CONTRACT_CREATION);
        } else {
            dispatch(saveTramitANFInfo({
                indexe: 'signatureType',
                value: {
                    name: contractType,
                    value: contractType.toUpperCase()
                }
            }))

            navegate(to.ANF_CREATE);
        };
    };

    useEffect(() => {
        if (tramitType === 'FIRMA') {
            setTramitType({
                borderLeft: '2px solid #DE7717',
                icon: <IconGoodSecurity />,
                procedureType: 'FES',
                to: ''
            });
        } else if (tramitType === 'FIRMA CON CERTIFICACION NOTARIAL') {
            setTramitType({
                borderLeft: {
                    borderLeft: "2px solid transparent",
                    background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(0deg, #dde13f, #DC8558) border-box",
                },
                icon: <IconMidSecurity />,
                procedureType: 'Certificación notarial',
                to: ''
            });
        } else if (tramitType === 'FIRMA CON PROTOCOLIZACION NOTARIAL') {
            setTramitType({
                borderLeft: {
                    borderLeft: "2px solid transparent",
                    background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(0deg, #7EDBAE, #dde13f) border-box",
                },
                icon: <IconHighSecurity />,
                procedureType: 'Protocolización notarial',
                to: ''
            });
        } else if (tramitType === 'ANF') {
            setTramitType({
                borderLeft: '2px solid #7EDBAE',
                icon: <IconMaxSecurity />,
                procedureType: 'ANF',
                to: ''
            });
        } else if (tramitType === 'FIRMA ELECTRONICA AVANZADA') {
            setTramitType({
                borderLeft: `2px solid ${theme.palette.primary.light}`,
                icon: <IconMaxSecurity />,
                procedureType: 'Firma electrónica avanzada',
                to: ''
            });
        }
    }, [tramitType]);

    return (
        <Grid item>
            <Box
                className={styles.frequentProcedures_Box}
                sx={{ borderLeft: tramitTypeStyle.borderLeft }}
            >
                <Box className={styles.frequentProcedures_Box_tramitType_Box}>
                    <Box className={styles.frequentProcedures_Box_tramitType}>
                        {tramitTypeStyle.icon}

                        <Typography className='text'>
                            {tramitTypeStyle.procedureType}
                        </Typography>
                    </Box>

                    <IconRepeat />
                </Box>

                <Typography className={styles.frequentProcedures_Box_tramitType_Text}>
                    {contractType.charAt(0) + contractType.slice(1).toLowerCase()}
                </Typography>

                <Link
                    component="button"
                    variant="body2"
                    className={styles.frequentProcedures_Box_tramitType_link}
                    onClick={handleClickNavigate}
                >
                    Volver a realizar
                </Link>
            </Box>
        </Grid>
    )
}

export default FrequentProceduresCard