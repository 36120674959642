import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import spy from 'redux-monitor-spy';
import rootReducer from "./reducer";
import logger from "./middleware/logger";
import api from "./middleware/api";
import apiExpress from "./middleware/apiExpress";
import apiAnf from "./middleware/apiAnf";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage1 from "redux-persist/lib/storage";
import storage from 'redux-persist-indexeddb-storage';
import apiMerge from "./middleware/apiMerge";

/* const persistConfig = {
    key: "root",
    version: 1,
    storage,
}; */


storage1.removeItem("persist:root");
storage1.removeItem("session");

const persistConfig = {
    key: "root",
    version: 1,
    storage: storage('firmaVirtual1'),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
    spy,
    ...getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [
                FLUSH,
                REHYDRATE,
                PAUSE,
                PERSIST,
                PURGE,
                REGISTER,
            ],
        },
    }),
    logger({ destination: "console" }),
    api,
    apiExpress,
    apiMerge,
    apiAnf,
];

const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: true, // process.env.REACT_APP_PROD
    /* middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
        logger({ destination: "console" }),
        api,
        apiExpress,
        apiMerge,
        apiAnf,
    ], */
});

let persistor = persistStore(store);

export { store, persistor };
