import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const PaperworkStyles = makeStyles(() => {

    return createStyles({
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "right",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        autocompleteInput: {
            background: "#f8faff",
            border: "2px solid #b4bacc",
            borderRadius: "7px",
        },
        labelStyles: {
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "0.95rem"
        },
        formBox: {
            width: 'inherit'
        },
        stepsperContent: {
            display: 'flex'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '44px'
        },
        hintStyles: {
            color: theme.palette.quinary.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleStyles: {
            color: theme.palette.black.main,
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        }
    });

});