import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: 'contract list filter',
    initialState: {
        filterBy: null,
        filters: null
    },
    reducers: {
        FILTER_BY: (state, action) => {
            state.filterBy = action.payload.filterBy;
            state.filters = action.payload.filters;
        },
        PURGE_STATE: (state, action) => {
            state.filterBy = null;
            state.filters = null;
        }
    }
});

export const {
    FILTER_BY,
    PURGE_STATE
} = slice.actions;

export const setFilters = (filterData) => {
    return { type: FILTER_BY.type, payload: filterData }
};

export const deleteFilters = () => {
    return { type: PURGE_STATE.type }
};

export default slice.reducer;