import React, { useState, useEffect } from "react";
// import { store } from "../../store/storeConfig";
// import { getUserToken, getUserInfo } from "../storeSelector/storeSelector";
// import { getContractsCreated } from "../../store/contract/contractDetail";
import { to } from "../../utils/enums";
import { Text, Btn } from "..";
import { AiOutlineReload } from "react-icons/ai";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Avatar, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';

const ManageClients = () => {
    const navigate = useNavigate();
    // const userToken = getUserToken();
    // const userInfo = getUserInfo();
    // const [loading, setLoading] = useState(false);
    const loading = false;
    const [users, setUsers] = useState([]);
    const [key, setKey] = useState("");

    useEffect(() => {
        let run = true;
        if (run) {
            genKey();

            setUsers([
                {
                    id: 1,
                    oAvatar: {
                        sName: "El Portal",
                        sPicture: "https://placeimg.com/100/100/people",
                        sUserName: "portal7635",
                    },
                    sEmail: "jdoe@portal.com",
                    sUserName: "portal7635",
                },
                {
                    id: 2,
                    oAvatar: {
                        sName: "Balance",
                        sPicture: "https://placeimg.com/100/100/people",
                        sUserName: "balance1234",
                    },
                    sEmail: "jdoe@balance.com",
                    sUserName: "balance1234",
                },
                {
                    id: 3,
                    oAvatar: {
                        sName: "Pett Happy",
                        sPicture: "https://placeimg.com/100/100/people",
                        sUserName: "petthappy1234",
                    },
                    sEmail: "jdoe@petthappy.com",
                    sUserName: "petthappy1234",
                },
                {
                    id: 4,
                    oAvatar: {
                        sName: "Fuente Alemana",
                        sPicture: "https://placeimg.com/100/100/people",
                        sUserName: "fuentealemana1234",
                    },
                    sEmail: "jdoe@fuentealemana.com",
                    sUserName: "fuentealemana1234",
                }
            ]);

            // TODO: Dispatching an action to get the users of account owner.
            // setLoading(true);
            // if(userInfo.userId !== undefined) {
            //     store.dispatch(getContractsCreated(userInfo.userId, userToken));
            // }

            //TODO: Subscribing to the store and getting the users from the store.
            // const unsubscribe = store.subscribe(() => {
            //     const { contractListActive } = store.getState().contract.contractCreator;

            //     if (!_.isEmpty(contractListActive)) {
            //         const filterContracs = contractListActive.message.filter(contract => contract.sStatus === "CREATED" && contract.sPaymentStatus === "PENDING");

            //         setLoading(false);
            //         setUsers(filterContracs);
            //     }
            // })

            // return () => unsubscribe();


        }
        return () => run = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // TODO: Dispatching an action to get the users of account owner when generating a new key.
    // useEffect(() => {
    //     let run = true;
    //     if (run) {
    //         setLoading(true);
    //         store.dispatch(getContractsCreated(userInfo.userId, userToken));

    //         const unsubscribe = store.subscribe(() => {
    //             const { contractListActive } = store.getState().contract.contractCreator;

    //             if (!_.isEmpty(contractListActive)) {
    //                 const filterContracs = contractListActive.message.filter(contract => (contract.sStatus === "CREATED" || contract.sStatus === "REVIEW") && contract.sPaymentStatus === "PENDING");

    //                 setLoading(false);
    //                 setUsers(filterContracs);
    //             }
    //         })

    //         return () => unsubscribe();
    //     }
    //     return () => run = false;
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [key]);

    const genKey = () => {
        // setLoading(true);
        setKey(Math.random().toString().split(".").reduce((a, b) => a + b));
    }

    const userActionTrigger = (id) => {

        return (
            <Box sx={actionButtonsContainerStyles} className="flex--1 flex--center">
                <Box sx={actionButtonsStyles} onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}>
                    <BsPencilSquare className="icon--xs icon--grey-dark icon-pointer" />
                </Box>
                <Box sx={actionButtonsStyles} onClick={() => navigate(to.CONTRACT_DETAIL, { state: id })}>
                    <BsTrash className="icon--xs icon--grey-dark icon-pointer" />
                </Box>
            </Box>
        )
    }

    const gridColumns = [
        {
            field: "oAvatar",
            headerAlign: "center",
            headerName: "Nombre cliente",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item text--uppercase text--left",
            width: 300,
            renderCell: (params) => renderUserName(params.value)
        },
        {
            field: "sEmail",
            headerAlign: "center",
            headerName: "Correo electrónico",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 300
        },
        {
            field: "sUserName",
            headerAlign: "center",
            headerName: "ID de usuario",
            headerClassName: "grid__header-item",
            cellClassName: "grid__row-item",
            width: 200,
            disableColumnMenu: true
        },
        {
            field: "id",
            headerAlign: "center",
            headerName: "Acciones",
            headerClassName: "grid__header-item",
            rowClassName: "grid__row-item",
            width: 100,
            disableColumnMenu: true,
            renderCell: (params) => userActionTrigger(params.value),
        },
    ];

    const renderUserName = (data) => {
        const { sName, sPicture, sUserName } = data;

        return (
            <Box sx={userAvatarContainerStyles}>
                <Avatar sx={userAvatarPictureStyles} variant="square" src={sPicture} name={`${sName}_picture`} alt={`${sName} picture`} />
                <Box sx={userNameContainerStyles}>
                    <Text sx={userNameStyles} variant="h6">{sName}</Text>
                    <Text sx={userNickNameStyles} variant="h6">{sUserName}</Text>
                </Box>
            </Box>
        );
    }

    const buttonStatusMenu = {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
        }
    };

    const buttonGridStatusMenu = {
        backgroundColor: "quinary.light",
        color: "primary.light",
        textTransform: "none",
        fontSize: "0.9rem",
        borderRadius: "5px",
        padding: "6px 10px",
        fontWeight: "500",
        "&:hover": {
            color: "white.main",
            backgroundColor: "primary.dark",
        }
    };

    const buttonGoToCreatorMenu = {
        backgroundColor: "quaternary.light",
        color: "white.main",
        textTransform: "none",
        fontWeight: "500",
    };

    const titleStyles = {
        color: "black.main",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "24px",
    };

    const userNameStyles = {
        color: "black.main",
        textTransform: "capitalize",
        lineHeight: 1.1,
    }

    const subTitleStyles = {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
    };

    const gridContainerStyles = {
        border: "none",
    };

    const userAvatarContainerStyles = {
        display: "flex",
        flexDirection: "row",
    }

    const userAvatarPictureStyles = {
        overflow: "hidden",
        borderRadius: "10%",
    }

    const userNickNameStyles = {
        color: "quinary.main",
        fontWeight: "300",
        marginBottom: "0",
        fontSize: "0.9rem",
    }

    const userNameContainerStyles = {
        marginLeft: "10px",
    }

    const actionButtonsContainerStyles = {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    }

    const actionButtonsStyles = {
        padding: "6px",
    }

    const localizedTextsMap = {
        columnMenuUnsort: "Sin ordenar",
        columnMenuSortAsc: "De menor a mayor",
        columnMenuSortDesc: "De mayor a menor",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas",
        toolbarExport: "Descargar",
        toolbarFilters: "Filtros",
        filterOperatorContains: "Contiene",
        filterOperatorEquals: "Igual a",
        filterOperatorStartsWith: "Empieza con",
        filterOperatorEndsWith: "Termina con",
        filterOperatorIsEmpty: "Esta vacio",
        filterOperatorIsNotEmpty: "No esta vacio",
        filterPanelInputLabel: "Valor",
        filterPanelInputPlaceholder: "Filtrar por valor",
    };

    const customToolbar = () => {
        return (
            <div className="contracts-list__top-menu">
                <div className="title-menu__status">
                    <Text sx={titleStyles} variant="h6">
                        Administración de Clientes
                    </Text>
                    <Text sx={subTitleStyles}>
                        Resumen de administración de clientes
                    </Text>
                </div>
                <GridToolbarContainer className="buttons-menu__status">
                    <GridToolbarFilterButton
                        title="Filtros"
                        translate={true}
                        lang="es"
                        arrow={false}
                        className="grid-toolbar"
                        sx={buttonGridStatusMenu} />
                    <Btn
                        variant="contained"
                        text="Actualizar"
                        sx={buttonStatusMenu}
                        onClick={genKey}
                        startIcon={
                            <AiOutlineReload className="icon--xs icon--blue-light" />
                        }
                    />
                    <Btn
                        variant="contained"
                        text="Añadir nuevo cliente"
                        sx={buttonGoToCreatorMenu}
                        onClick={() => navigate(to.CONTRACT_CREATION)}
                    />
                </GridToolbarContainer>
            </div>
        );
    }

    return (
        <div className="content-detail__container" key={key}>

            <div className="contracts-status-list__container" style={{ height: 'auto', width: '100%' }}>

                <div style={{ display: 'flex', height: '100%' }}>

                    <div style={{ flexGrow: 1 }}>

                        <DataGrid
                            rows={users}
                            columns={gridColumns}
                            pageSize={8}
                            rowsPerPageOptions={[8]}
                            autoHeight={true}
                            autoPageSize={true}
                            loading={loading}
                            getRowId={(row) => row.id}
                            rowHeight={80}
                            sx={gridContainerStyles}
                            localeText={localizedTextsMap}
                            disableSelectionOnClick={true}
                            components={{
                                Toolbar: customToolbar,
                                NoRowsOverlay: () => <div className="data-grid__no-rows">No hay registros</div>,
                            }}
                        // initialState={{
                        //     sorting: {
                        //         sortModel: [
                        //             { 
                        //                 field: "dContractDT",
                        //                 sort: "desc"
                        //             }
                        //         ]
                        //     }
                        // }}
                        />

                    </div>

                </div>

            </div>

        </div>
    );
};

export default ManageClients;
