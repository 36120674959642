import React, { useEffect, useRef, useState } from 'react'
import { ExpressProcessStyles } from '../../index.css';
import { Box, Typography } from '@mui/material';
import FilePreview from "react-file-preview-latest";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import LogoRepair from "../../../../../assets/img/logo_repair.png"

const RenderedPDF = ({ index, item, removeFileFromArray, dragItem, handleSort }) => {

    const pdfRef = useRef(null);
    const [isVisibledPDF, setIsVisibledPDF] = useState({});
    const styles = ExpressProcessStyles();

    useEffect(() => {

        const checkPDFRender = () => {

            if (pdfRef.current) {
                const element = pdfRef.current.querySelector('object');
                const isPDFRendered = element?.contentDocument?.children?.[0]?.children?.[1]?.children;

                console.log({ element })
                console.log({ isPDFRendered })

                if (isPDFRendered === undefined) {
                    console.log(typeof isPDFRendered)
                    return {
                        documentType: 'nopdf',
                    }
                };

                if (isPDFRendered?.length > 0) {
                    return {
                        documentType: 'pdf',
                        visible: 'yes'
                    }
                }

                return {
                    documentType: 'pdf',
                    visible: 'no'
                };
            };
        };

        const timer = setTimeout(() => {
            const isRendered = checkPDFRender();
            setIsVisibledPDF(isRendered);
        }, 500);

        return () => clearTimeout(timer);

    }, [pdfRef, item]);

    return (
        <div
            className={styles.fileMergePreview}
            ref={pdfRef}
        >
            <div className={styles.conatinerCloseButtomPreview}>
                <ControlCameraIcon className="icon--blue-light icon-move" />

                <DisabledByDefaultIcon
                    className="icon--red icon-pointer"
                    onClick={() =>
                        removeFileFromArray(
                            item["name"]
                        )
                    }
                />
            </div>

            <Typography
                variant="subtitle1"
                gutterBottom
                align="center"
                color={"gray.dark"}
            >
                {item["name"].length < 19
                    ? item["name"]
                    : item["name"].substring(
                        0,
                        15
                    ) + "..."}
            </Typography>

            {isVisibledPDF?.documentType === 'pdf' && isVisibledPDF?.visible === 'no' ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Typography>Vista previa no disponible</Typography>
                    <img src={LogoRepair} alt='imagen-novisible' style={{ width: '5rem', height: '5rem' }} />
                </Box>
            ) : (
                <FilePreview
                    type={"file"}
                    file={item["file"]}
                    onError={() => console.log("error")}
                />
            )}

        </div>
    )
}

export default RenderedPDF