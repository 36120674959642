import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const DetailSignatoriesStyles = makeStyles(() => {

    return createStyles({
        statusHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        cardHeader: {
            padding: '9px !important'
        },
        cardContainer: {
            cursor: 'initial !important',
            margin: '3px',
            padding: '15px 20px !important'
        }
    })

});