import React from "react";
import Typography from "@mui/material/Typography";

export default function Text({sx, align, children, classes, mgButton, variant, paragraph, className, color}) {

    return (
        <Typography
            sx={sx}
            color={color ? color : 'palette.black.main'}
            align={align}
            className={className}
            classes={classes}
            variant={variant}
            gutterBottom={mgButton}
            paragraph={paragraph}
            component={"span"}
        >
            {children}
        </Typography>
    );
}
