import axios from "axios";
import { api } from "../../../../utils/enums";

const uri = api.BASE_URI;

const scheduleUri = api.BASE_SCHEDULE_URI;

export const updateSchedule = async (scheduleId, notarioID, data, userToken) => {
    try {
        const response = await axios.put(
            `${scheduleUri}${api.UPDATE_SCHEDULE}/${scheduleId}/${notarioID}`,
            data,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const getNotary = async (notaryId, userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_NOTARY}${notaryId}`,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const blackList = async (date, userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.GET_BLACK_LIST(date)}`,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};


export const deleteSchedule = async (id, userToken) => {
    try {
        const response = await axios.delete(
            `${scheduleUri}${api.DELETE_SCHEDULE}${id}`,
            {
                headers: {
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const downloadCompiledTramitDoc = async (contract, userToken, view) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.DOWNLOAD_COMPILED_TRAMIT_FILE}/${contract}`,
            {
                responseType: "blob",
                headers: {
                    Authorization: userToken,
                },
            }
        );

        if (view) {
            const resultFile = new File([response.data], 'tramit', { type: "application/pdf" });
            return resultFile;
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `contrato_${contract}.pdf`);
            document.body.appendChild(link);
            link.click();

            return response.data;
        }


    } catch (error) {
        return error;
    }
};

export const getOneUser = async (userId, userToken) => {
    try {
        const response = await axios.get(
            `${uri}${api.GET_ONE_USER}${userId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const scheduleDetail = async (idSchedule, userToken) => {
    try {
        const response = await axios.get(
            `${scheduleUri}${api.SCHEDULE_DETAIL}${idSchedule}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const scheduleContractDetail = async (idContract, userToken) => {
    try {
        const response = await axios.post(
            `${scheduleUri}${api.SCHEDULE_CONTARCT_DETAIL}${idContract}`,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateAllSignerByRUT = async (userToken, idContract, body) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_ALL_SIGNER_BY_RUT(idContract)}`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const updateAllSignerByEmail = async (userToken, idContract, body) => {
    try {
        const response = await axios.post(
            `${uri}${api.UPDATE_ALL_SIGNER_BY_EMAIL(idContract)}`,
            body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: userToken
                }
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};