import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '../../../theme/theme';

export const PaperworkStyles = makeStyles(() => {

    return createStyles({
        gooBackButton: {
            backgroundColor: theme.palette.quinary.light,
            color: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "left",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        buttonSubmitStyles: {
            backgroundColor: theme.palette.primary.light,
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "500",
            textTransform: "none",
            float: "right",
            fontSize: '0.9375rem',
            padding: '8px 10px'
        },
        autocompleteInput: {
            background: "#f8faff",
            border: "2px solid #b4bacc",
            borderRadius: "7px",
        },
        labelStyles: {
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "0.95rem"
        },
        formBox: {
            width: 'inherit'
        },
        stepsperContent: {
            display: 'flex'
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '44px'
        },
        hintStyles: {
            color: theme.palette.quinary.main,
            fontWeight: "300",
            marginBottom: "0"
        },
        titleStyles: {
            color: theme.palette.black.main,
            fontWeight: "500",
            marginBottom: "0",
            fontSize: "1.2rem"
        },
        stepContainerActionsButtoms: {
            width: `100%`,
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "0", //80px
            flexDirection: "row",
            paddingLeft: "45px",
            paddingRight: "45px",
        },
        managProc: {
            paddingBottom: "0", //45px
            flexDirection: "column",
            alignItems: "center",
            "& h6": {
                textAlign: "center",
                fontFamily: "PoppinsBold",
                fontSize: "0.9rem",
            },
        },
        contenItem: {
            paddingTop: "60px",
        },
        formItem: {
            boxShadow: "none",//12px 0px 39px rgba(156, 174, 191, 0.2)
            borderRadius: "none",//7px
        },
        container: {
            marginTop: "20px",
            padding: "15px 0",
            backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            color: theme.palette.text.secondary,
        },
        userManual_box: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '& > p': {
                color: theme.palette.primary.main,
                textDecoration: 'underline',
                marginRight: '.25rem',
                '&:hover': {
                    color: theme.palette.primary.xlight
                }
            }
        }
    });

});