import React from 'react';
import { Box } from '@mui/material';
import DetailAgreement from '../../molecules/DetailAgreement';
// import ModalAgreementDelete from '../../molecules/ModalAgreementDelete';


const RenderActions = ({ row }) => {

    return (

        <Box sx={{
            position: "relative",
            display: "flex",
            alignContent: 'center'
        }} className="flex--1 flex--center">

            <DetailAgreement agreement={row} key={`detail-${row.rut}`} />

            {/* <ModalAgreementDelete organization={row.rut} key={`delete-${row.rut}`}  /> */}

        </Box>
    );

};

export default RenderActions;