import React from "react";
import { StatusTrackerItem } from "..";
import { useNavigate } from "react-router-dom";
import { to } from "../../utils/enums";
import { ContractDetailReviewStyles } from "./contractDetailReview/index.css";

// eslint-disable-next-line
const Breadcrumbtracker = ({ location, status, showGoback, paymentStatus }) => {
    
    const navigation = useNavigate();
    const styles = ContractDetailReviewStyles();

    const setLink = () => {
        switch (location) {
            case to.CONTRACT_DETAIL:
                return navigation(to.CONTRACT_STATUS);
            case to.CONTRACTS_PAYMENT:
                return navigation(to.HOME);
            case to.CONTRACTS_SIGNATURE:
                return navigation(to.HOME);
            case to.CONTRACTS_NOTARY:
                return navigation(to.HOME);
            case to.CONTRACTS_DELIVERY:
                return navigation(to.HOME);
            default:
                return navigation(to.HOME);
        }
    };

    const statusReview = () => {
        if (
            status === "REJECTED" ||
            status === "APPROVED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED" ||
            status === "PAIDOUT" ||
            status === "PENDING" ||
            status === "PARTIALLY_PAID" ||
            status === "SIGNED_PENDING" ||
            status === "DELIVERED"
        ) {
            return true;
        }
        return false;
    };

    const statusPayment = () => {
        if (
            status === "PAIDOUT" ||
            status === "SIGNED_PENDING" ||
            status === "DELIVERED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED"
        ) {
            return true;
        } else if (["PENDING", "PARTIALLY_PAID"].includes(paymentStatus)) {
            return false;
        }

        return "";
    };

    const statusSignature = () => {
        if (
            status === "PAIDOUT" ||
            status === "DELIVERED" ||
            status === "IN_NOTARY" ||
            status === "FINISHED"
        ) {
            return true;
        } else if (status === "SIGNED_PENDING") {
            return false;
        }

        return "";
    }

    const statusNotary = () => {
        if (status === "FINISHED") {
            return true;
        }
        return "";
    };


    return (
        <div className={`contract-details__header ${styles.contractDetailsHeader}`}>
            {/* {showGoback && (
                <div className="breadcrumbs-navigation">
                    <Gobackbutton gobackTitle="Volver" />
                </div>
            )} */}
            <ul className="status-tracker">
                <StatusTrackerItem
                    sStatus={status}
                    title="Revisión"
                    focused={statusReview()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    sStatus={status}
                    title="Pago"
                    focused={statusPayment()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    sStatus={status}
                    title="Firma"
                    focused={statusSignature()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    from={'ROL'}
                    sStatus={status}
                    title="Notaria"
                    subTile="(48 - 72 hrs)"
                    focused={statusNotary()}
                    onClick={setLink}
                />
                <StatusTrackerItem
                    sStatus={status}
                    title="Entrega"
                    focused={status === "FINISHED" ? true : ""}
                    onClick={setLink}
                />
            </ul>
        </div>
    );
};

export default Breadcrumbtracker;
