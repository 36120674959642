import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Box } from '@mui/material';

import { to } from '../../../utils/enums';
import AddProvider from "./molecules/AddProvider";
import { couponsSaveInfo, getAllCoupons } from '../../../store/coupons/coupons';
import CustomToobarCuponsList from './molecules/customToobarCuponsList';
import GeneralListCupons from '../general-list-cupon';
import ErrorAlert from '../expressProcess/molecules/errorAlert';
import CouponsStatsHeader from '../../couponsStatsHeader';
import Spinner from '../../spinners/Spinner';


const ManageCoupons = () => {

    const {
        session: {
            login: { user },
        },
        coupons: {
            coupons: {
                allCoupons,
                loading: couponsLoading,
                status: couponsStatus
                //message: couponsTypeMessage,
            }
        }
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState({});
    //const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const [dataGrid, setDataGrid] = useState([]);
    //const [spinner, setSpinner] = useState(false);
    const [headerData, setHeaderData] = useState([]);

    const getHeaderData = (data) => {

        const newHeaderData = [
            {
                text: 'Todos los cupones',
                count: data.length || 0
            },
            {
                text: 'Cupones activos',
                count: data.filter(element => element.status === 'ACTIVE').length || 0
            },
            {
                text: 'Cupones inactivos',
                count: data.filter(element => element.status === 'INACTIVE').length || 0
            },
        ];

        setHeaderData(newHeaderData);
    };

    useEffect(() => {

        const { token } = user;

        dispatch(getAllCoupons(token));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const clearCouponsState = () => {
            dispatch(couponsSaveInfo({
                indexe: 'status',
                value: ''
            }));
        };

        if (couponsStatus === 'success') {

            if (allCoupons?.length > 0) {
                const newData = [];
                allCoupons.forEach(element => {
                    newData.push({
                        autoId: element.coupon_id,
                        cuponName: element.name,
                        code: element.code,
                        beginDate: element.begin_date,
                        expireDate: element.expire_date,
                        status: element.status
                    });
                });

                setDataGrid(newData);
                getHeaderData(allCoupons);
            };

            clearCouponsState();
        } else if (couponsStatus === 'fail') {
            setOpenModal({
                open: 1,
                title: 'Ha surgido un problema consultando los tipos de cupones',
                message: 'Podrías intentar nuevamente o comunícate con un administrador del sistema.'
            });
        };

        const timer = setTimeout(() => {
            clearCouponsState();
        }, 5000);

        return () => {
            if (timer) {
                clearTimeout(timer);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [couponsStatus]);

    const renderList = useCallback(() => {
        return (
            <Fragment>
                <br />

                <CustomToobarCuponsList
                    title={"Gestión de tipo de cupones"}
                    subTitle={
                        "Aquí podrás visualizar el registro de los cupones ingresados"
                    }
                    onUpdate={() => console.log("Update") /* updateData() */}
                    onCreate={() => navigate(to.COUPONS_ADD)}
                />

                <div
                    className="container--relative contracts-status-list__container"
                    style={{ height: "auto", width: "100%" }}
                >
                    <div style={{ display: "flex", height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <GeneralListCupons
                                //updateList={() => updateData()}
                                dataGrid={
                                    dataGrid.map((item) => {
                                        return {
                                            autoId: item.autoId,
                                            cuponName: item.cuponName,
                                            code: item.code,
                                            beginDate: item.beginDate,
                                            expireDate: item.expireDate,
                                            status: item.status
                                        };
                                    })}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    return (

        <div className="content-detail__container">
            <ErrorAlert msn={openModal.message} title={openModal.title} errors={openModal.openModal} />
            <AddProvider
                openForm={openForm}
                closeForm={(e) => setOpenForm(e)}
            />
            <CouponsStatsHeader data={headerData} />

            {/* spinner || */ couponsLoading ? (
                <Box
                    sx={{ height: "150px" }}
                    className="container--relative contracts-status-list__container"
                >
                    <Spinner loading={true} type="bar" opacity={true} />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    )
}

export default ManageCoupons;