import React from "react";

import { ReactComponent as ActiveBar } from "../../../../../assets/icons/active-bar.svg";
import { ReactComponent as DisabledBar } from "../../../../../assets/icons/disabled-bar.svg";

import ScreenSizeHook from "../../../../../hooks/ScreenSizeHook";
import Text from "../../../../heading/Text";

const Steptracker = ({ current, step, title, subtitle, onClick, cursor, lastTrack, marginText, thereIsData }) => {
    const screenSizeHook = ScreenSizeHook();

    const stepNumberStyles = {
        // color: current ? "white.main" : "primary.light",
        color: "white.main",
        fontSize: "1.3rem",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
    };

    const titleStyles = {
        color: (current || cursor) ? "black.main" : "gray.dark",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
        marginLeft: marginText,
        fontSize: screenSizeHook === 'xs' ? '0.8rem' : '15px'
    };

    const subTitleStyles = {
        color: "quinary.dark",
        fontWeight: "300",
        marginBottom: "0",
    };

    const trackItem = {
        paddingTop: '15px',
        width: screenSizeHook === 'xs' ? '15px' : (lastTrack ? '80px' : '120px'),
        flexDirection: 'column',
        justifyContent: screenSizeHook === 'xs' ? 'start' : 'center',
        alignContent: 'center',
        cursor
    };

    return (
        <div className="procedure-creation__track-item" onClick={onClick} style={trackItem}>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: lastTrack ? (screenSizeHook === 'xs' ? 'center' : 'baseline') : 'unset',
                }}
            >
                <div
                    className={`procedure-creation-step ${(current || cursor) ? "step--active" : ""
                        }`}
                >
                    <Text sx={stepNumberStyles} align="left" mgButton variant="h7">
                        {step}
                    </Text>
                </div>

                {
                    (screenSizeHook !== 'xs' && !lastTrack) && ((thereIsData) ? <ActiveBar /> : <DisabledBar />)
                }

            </div>

            <div className="procedure-creation-description" style={{ alignSelf: (screenSizeHook === 'xs' ? 'center' : 'flex-start') }}>
                <Text sx={titleStyles} mgButton variant="h7">
                    {title}
                </Text>
                <Text sx={subTitleStyles}>{subtitle}</Text>
            </div>

        </div>
    );
};

export default Steptracker;
