import { Fragment, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import { PDFDocument } from "pdf-lib";
import FilePreview from "react-file-preview-latest";

import { ReactComponent as EditIconDataPreview } from "../../../assets/icons/edit-preview-data.svg";

import { DataPreviewStyles } from "./index.css";
import {
    asyncBase64ToFile,
    asyncFileToBase64,
    getErrorContractCreation,
    to,
    typeUser,
    verifiVariable,
} from "../../../utils/enums";
import {
    contractStoreFilesRequest,
    mergeDocs,
    sendContractPriotity,
} from "../../../utils/api";
import {
    addMemeber,
    createContract,
    clearCreateContract,
} from "../../../store/contract/contractDetail";
import { clearForm } from "../../../store/formTramit";
import Spinner from "../../spinners/Spinner";
import { Context } from "../../../context/utilsContext";
import ScreenSizeHook from "../../../hooks/ScreenSizeHook";
import Text from "../../heading/Text";
import ErrorDocumentAlert from "../../errorDocumentAlert";
import ErrorAlert from "../../dashboard/expressProcess/molecules/errorAlert";

const stepNumberStyles = {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00adea",
    height: "30px",
    width: "30px",
    borderRadius: "5px",
    color: "white.main",
    fontSize: "1.3rem",
    fontWeight: "500",
    marginBottom: "0",
    marginTop: "0",
    marginRight: "10px",
};

const CustomTextNumber = styled(Typography)((props) => ({
    marginRight: "10px",
}));

const CustomSubtitle1 = styled(Typography)((props) => ({
    fontSize: "0.9rem",
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const EditCustomButtom = styled(Button)((props) => ({
    borderRadius: "7px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: 16,
    whiteSpace: 'nowrap',
    background: props.theme.palette.primary.light,
    "&:hover": {
        background: props.theme.palette.primary.light,
    },
    fontFamily: ['"PoppinsBold"'].join(","),
}));

const DataPreview = () => {
    const { nextPage, setNextPage, toResumen, setToResumen } =
        useContext(Context);
    const navigate = useNavigate();
    const styles = DataPreviewStyles();
    const dispatch = useDispatch();
    const screenSizeHook = ScreenSizeHook();
    const {
        session: {
            login: { user, userInfo },
        },
        contract: {
            contractCreator: {
                contract: { detail, status },
                message,
                contract
            },
        },
        formTramit: {
            attachedCDV,
            contractName,
            firstStep: { procedureid, contractTypeFee, typeAgrement, signatureOptions },
            fourthStep,
            previewPdfSigned,
            secondStep,
            thirdStep: { fileName },
        },
    } = useSelector((state) => state);
    // eslint-disable-next-line
    const [firmantes, setFirmante] = useState(
        ![null, undefined, ""].includes(secondStep) ? secondStep : []
    );
    const [loading, setLoading] = useState(false);
    const [filePreview, setFilePreview] = useState(undefined);
    const [documentFail, setDocumentFail] = useState(0);
    const [queryStatusInfo, setQueryStatusInfo] = useState({
        title: '',
        errors: []
    });
    const [isOpenModal, setIsOpenModal] = useState(false);

    const CustomTextTitle = styled(Typography)((props) => ({
        whiteSpace: 'nowrap',
        margin: '0 1rem 0 0',
        color: props.theme.palette.black.main,
        fontSize: screenSizeHook === "xs" ? "1rem" : "1.2rem",
        marginBottom: screenSizeHook === "xs" ? "0px" : "0.35em",
    }));

    const verifyRol = (rol) => {
        if (rol.length === 1) {
            if (rol.includes("Firmante")) {
                return 0;
            } else if (rol.includes("Pagador")) {
                return 3;
            } else if (rol.includes("Pre Aprobador")) {
                return 2;
            } else if (rol.includes("Copia")) {
                return 5;
            }
        } else {
            if (rol.includes("Firmante") && rol.includes("Pagador")) {
                return 0;
            } else if (
                rol.includes("Pagador") &&
                rol.includes("Pre Aprobador")
            ) {
                return 4;
            }
        }
    };

    const createTramit = () => {

        setLoading(true);

        const iContractTypeFeeID = signatureOptions.filter(element =>
            element.sModal === contractTypeFee.sModal &&
            element.iSignCount === (secondStep.filter(element =>
                element.rol.includes('Firmante')
            ).length > 1 ? 2 : 1)
        );

        const { token } = user;

        const contractRequestPayload = {
            sOwnerType: "NATURAL",
            sPaymentStatus: "PENDING",
            iPaymentService: 0,
            iContractTypeFeeID: iContractTypeFeeID[0].iContractTypeFeeID,
            iSignedCount: firmantes.filter((item) => item['rol'].includes('Firmante')).length,
            callback: "",
            iAgreementId: 0,
            iPriceId: 0,
            attachedCDV,
            ...(contractTypeFee.sModal === 'SIGNATURE_ADVANCE' && { provider: 'FEA' }),
            sContractName: contractName,
            signers: firmantes.map((item) => {
                const firmante = {
                    ...item,
                    order: 1,
                    rol: verifyRol(item["rol"]),
                    payment: "0.00",
                    type: "NATURAL",
                    portion: item["pay"],
                };

                delete firmante["pay"];

                return firmante;
            }),
        };

        dispatch(addMemeber(contractRequestPayload));
        dispatch(
            createContract(
                contractRequestPayload,
                token,
                !verifiVariable(userInfo) &&
                    userInfo["iProfileID"] === typeUser.ADMIN
                    ? typeAgrement["agreementId"]
                    : false
            )
        );
    };

    useEffect(() => {
        if (previewPdfSigned)
            asyncBase64ToFile(previewPdfSigned, "preview").then((file) =>
                setFilePreview(file)
            );
    }, [previewPdfSigned]);

    useEffect(() => {

        if (nextPage !== 0 && !loading) createTramit();

        return () => {
            setNextPage(0);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextPage]);

    useEffect(() => {
        if (!verifiVariable(toResumen)) navigate(toResumen);

        return () => {
            setToResumen(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toResumen]);

    useEffect(() => {
        (async () => {
            try {
                if (!verifiVariable(status) && !verifiVariable(detail) && ("sContractID" in detail)) {
                    setLoading(true);

                    const { token } = user;
                    const pdfDoc = await PDFDocument.load(fourthStep);
                    const { sContractID: contractID } = detail;

                    const pdfBytes = await pdfDoc.save();

                    const filePdf = new File([pdfBytes], fileName, {
                        type: "application/pdf",
                    });

                    const fileBase64 = await asyncFileToBase64(filePdf);

                    await contractStoreFilesRequest(
                        contractID,
                        fileBase64.replace("data:application/pdf;base64,", ""),
                        token
                    );

                    await mergeDocs(contractID, token);

                    await sendContractPriotity(contractID, token);

                    dispatch(clearCreateContract());
                    dispatch(clearForm());


                }

                if (verifiVariable(status) && !verifiVariable(detail) && verifiVariable(detail["sContractID"])) {
                    setDocumentFail((e) => e + 1);
                    setLoading(false);
                }

            } catch (error) {
                setDocumentFail((e) => e + 1);
                console.error(error);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail, status]);

    useEffect(() => {

        if (status === 'success' && detail) {
            navigate(to.CONTRACTS_CREATED_SUCCESS, {
                state: {
                    sContractID: detail.sContractID,
                    tramitType: 'approvers'
                },
            });

            setLoading(false);
        };

        if (message) {

            const errors = getErrorContractCreation(message[0], 'approvers');

            if (errors.length === 0) {
                setQueryStatusInfo({
                    title: 'Ha surgido un problema al crear el trámite.',
                    errors: ['Por favor, verifica los datos, inténtalo nuevamente y, si el error persiste, comunícate con el administrador del sistema.']
                })
                setIsOpenModal(true);
            };

            if (errors.length > 1) {
                setQueryStatusInfo({
                    title: 'Ha surgido un problema al crear el trámite.',
                    errors: errors
                });
                setIsOpenModal(true);
            }

            setLoading(false);
        };

    }, [message, contract, detail, navigate, status]);

    return (
        <div className="procedure-creation__form-container">
            <ErrorDocumentAlert errors={documentFail} />
            <ErrorAlert
                msn={queryStatusInfo.errors}
                title={queryStatusInfo.title}
                isOpenModal={isOpenModal}
                setIsOpenModal={setIsOpenModal}
            />


            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={"Creando Trámite, por favor espere"}
                    opacity={true}
                />
            )}

            <div className={`${styles.formContainer} form__container`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <CustomTextTitle
                            variant="h6"
                            gutterBottom
                            textAlign={
                                screenSizeHook === "xs" ? "center" : "unset"
                            }
                        >
                            Resumen de la solicitud
                        </CustomTextTitle>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                            textAlign={
                                screenSizeHook === "xs" ? "center" : "unset"
                            }
                        >
                            confirme los datos para seguir con la solicitud
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={9}
                        xl={5}
                        className={styles.titles}
                        sx={{
                            alignItems:
                                screenSizeHook === "xs"
                                    ? "center !important"
                                    : "end",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className={styles.numberTitle}>
                            {screenSizeHook === "xs" && (
                                <div
                                    className={`procedure-creation-step step--active`}
                                >
                                    <Text
                                        sx={stepNumberStyles}
                                        align="left"
                                        mgButton
                                        variant="h6"
                                    >
                                        1
                                    </Text>
                                </div>
                            )}

                            {screenSizeHook !== "xs" && (
                                <CustomTextNumber
                                    variant="h5"
                                    gutterBottom
                                    color={["primary.light"]}
                                >
                                    1
                                </CustomTextNumber>
                            )}

                            <CustomTextTitle variant="h6" gutterBottom>
                                Datos del trámite
                            </CustomTextTitle>
                        </div>

                        {screenSizeHook !== "xs" && (
                            <EditCustomButtom
                                variant="contained"
                                onClick={() => navigate(to.CONTRACT_CREATION)}
                            >
                                Editar respuestas
                            </EditCustomButtom>
                        )}

                        {screenSizeHook === "xs" && (
                            <EditIconDataPreview
                                onClick={() => navigate(to.CONTRACT_CREATION)}
                            />
                        )}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        /* sm={4} */ className={styles.subTitles}
                    >
                        <CustomSubtitle1
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            ¿Qué trámite necesitas gestionar?
                        </CustomSubtitle1>

                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            {![null, undefined, ""].includes(procedureid)
                                ? procedureid.name
                                : ""}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        /* sm={8} */ className={styles.subTitles}
                    >
                        <CustomSubtitle1
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            ¿Qué tipo de firma necesitas?
                        </CustomSubtitle1>

                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            {![null, undefined, ""].includes(contractTypeFee)
                                ? contractTypeFee.label.slice(0, 92) + "..."
                                : ""}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        /* sm={8} */ className={styles.subTitles}
                    >
                        <CustomSubtitle1
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            Nombre del trámite
                        </CustomSubtitle1>

                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            color={["gray.main"]}
                        >
                            {contractName
                                ? contractName
                                : "No especificado"}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        className={styles.titles}
                        sx={{
                            alignItems:
                                screenSizeHook === "xs"
                                    ? "center !important"
                                    : "end",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className={styles.numberTitle}>
                            {screenSizeHook === "xs" && (
                                <div
                                    className={`procedure-creation-step step--active`}
                                >
                                    <Text
                                        sx={stepNumberStyles}
                                        align="left"
                                        mgButton
                                        variant="h6"
                                    >
                                        2
                                    </Text>
                                </div>
                            )}

                            {screenSizeHook !== "xs" && (
                                <CustomTextNumber
                                    variant="h5"
                                    gutterBottom
                                    color={["primary.light"]}
                                >
                                    2
                                </CustomTextNumber>
                            )}

                            <CustomTextTitle variant="h6" gutterBottom>
                                Datos participantes
                            </CustomTextTitle>
                        </div>

                        {screenSizeHook === "xs" && (
                            <EditIconDataPreview
                                onClick={() =>
                                    navigate(to.CONTRACT_CREATION_SIGNATORIES)
                                }
                            />
                        )}

                        {screenSizeHook !== "xs" && (
                            <EditCustomButtom
                                variant="contained"
                                onClick={() =>
                                    navigate(to.CONTRACT_CREATION_SIGNATORIES)
                                }
                            >
                                Editar respuestas
                            </EditCustomButtom>
                        )}
                    </Grid>

                    {firmantes.map((item, index) => (
                        <Fragment key={item.rutId}>
                            <Grid item xs={12} className={styles.subTitles}>
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["primary.light"]}
                                >
                                    Participante {index + 1}
                                </CustomSubtitle1>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className={styles.subTitles}
                            >
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    Nombre
                                </CustomSubtitle1>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    {item.full_name}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                className={styles.subTitles}
                            >
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    RUT (sin puntos y con guión) o Pasaporte
                                    (solo números)
                                </CustomSubtitle1>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    {item.rutId}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={4}
                                className={styles.subTitles}
                            >
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    Correo electrónico
                                </CustomSubtitle1>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    {item.email}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                className={styles.subTitles}
                            >
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    Número de celular
                                </CustomSubtitle1>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    {item.phone}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                className={styles.subTitles}
                            >
                                <CustomSubtitle1
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    Rol del participante
                                </CustomSubtitle1>

                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    color={["gray.main"]}
                                >
                                    {item.rol.toString()}
                                </Typography>
                            </Grid>
                        </Fragment>
                    ))}

                    <Grid
                        item
                        xs={12}
                        sm={8}
                        lg={9}
                        xl={5}
                        className={styles.titles}
                        sx={{
                            alignItems:
                                screenSizeHook === "xs"
                                    ? "center !important"
                                    : "end",
                            justifyContent: "space-between",
                        }}
                    >
                        <div className={styles.numberTitle}>
                            {screenSizeHook === "xs" && (
                                <div
                                    className={`procedure-creation-step step--active`}
                                >
                                    <Text
                                        sx={stepNumberStyles}
                                        align="left"
                                        mgButton
                                        variant="h6"
                                    >
                                        3
                                    </Text>
                                </div>
                            )}

                            {screenSizeHook !== "xs" && (
                                <CustomTextNumber
                                    variant="h5"
                                    gutterBottom
                                    color={["primary.light"]}
                                >
                                    3
                                </CustomTextNumber>
                            )}

                            <CustomTextTitle
                                variant="h6"
                                gutterBottom
                                textAlign={"left"}
                            >
                                Documentos
                            </CustomTextTitle>
                        </div>

                        {screenSizeHook === "xs" && (
                            <EditIconDataPreview
                                onClick={() =>
                                    navigate(to.CONTRACT_CREATION_DOCUMENTS)
                                }
                            />
                        )}

                        {screenSizeHook !== "xs" && (
                            <EditCustomButtom
                                variant="contained"
                                onClick={() =>
                                    navigate(to.CONTRACT_CREATION_DOCUMENTS)
                                }
                            >
                                {" "}
                                Cambiar documento{" "}
                            </EditCustomButtom>
                        )}
                    </Grid>

                    {filePreview && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                "& > div": {
                                    width:
                                        screenSizeHook === "xs"
                                            ? "300px !important"
                                            : "424px !important",
                                },
                            }}
                        >
                            <FilePreview
                                type={"file"}
                                file={filePreview}
                                onError={() => console.log("error")}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default DataPreview;
