import { forwardRef, Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegTrashAlt } from "react-icons/fa";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { ContractDetailReviewStyles } from '../../index.css';
import Btn from '../../../../buttons/Button';
import { Context } from '../../../../../context/utilsContext';
import { to } from '../../../../../utils/enums';
import { deleteSchedule } from '../../services';


const DeleteAndUpdateCustomButtom = styled(Button)((props) => ({
    border: `1.5px solid ${props.theme.palette.primary.light}`
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteContractAlert = () => {
    const styles = ContractDetailReviewStyles();
    const {
        session: {
            login: {
                user
            }
        }
    } = useSelector((state) => state);
    const { state } = useLocation();
    const navigation = useNavigate();
    const { setLoading } = useContext(Context);
    const [open, setOpen] = useState(false);


    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const deleteCurrentContract = async () => {
        try {
            const { token } = user;

            setLoading(true);
            await deleteSchedule(state, token);
            setLoading(false);
            handleClose();
            navigation(to.MANAGE_SCHEDULE_HISTORY);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (

        <Fragment>

            <DeleteAndUpdateCustomButtom onClick={handleClickOpen} variant="outlined" style={{ marginRight: '20px' }} startIcon={<FaRegTrashAlt className="icon--blue" />}>
                Eliminar trámite
            </DeleteAndUpdateCustomButtom>

            <Dialog
                fullWidth={true}
                TransitionComponent={Transition}
                open={open}
                onClose={handleClose}
                maxWidth='xs'
                className={styles.mainContainerDialog}
            >

                <DialogTitle sx={{ textAlign: 'center' }}>
                    ¿Deseas eliminar este contrato?
                </DialogTitle>

                <DialogContent>

                    <DialogContentText>

                        <Typography>
                            Si eliminas este contrato, no podrás recuperarlo.
                        </Typography>

                    </DialogContentText>

                </DialogContent>

                <DialogActions style={{ justifyContent: 'center', paddingBottom: '20px' }}>

                    <Btn
                        variant="outlined"
                        text="Cancelar"
                        color="error"
                        onClick={handleClose}
                        sx={{
                            height: '39px',
                            borderRadius: '20px'
                        }}
                    />

                    <Btn
                        variant="outlined"
                        text="Aceptar"
                        color="primary"
                        onClick={() => deleteCurrentContract()}
                        sx={{
                            height: '39px',
                            borderRadius: '20px',
                            marginLeft: '30px !important'
                        }}
                    />

                </DialogActions>

            </Dialog>

        </Fragment>

    );
}

export default DeleteContractAlert;