import React from "react";

export const CustomixedYAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
                {payload.value} h
            </text>
        </g>
    );
};
