import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { to } from "../../utils/enums";
import Steptracker from "../dashboard/StepTracker";

const Procedurecreationform = () => {
    const { pathname } = useLocation();
    const step1 = to.CONTRACT_CREATION;
    const step2 = to.CONTRACT_CREATOR_DOCS;

    return (
        <div className="content-detail__container">
            <div className="procedure-creation__container">
                <div className="procedure-creation__track-container">
                    <Steptracker
                        step="1"
                        current={
                            pathname === step1
                                ? true
                                : false
                        }
                        title="Datos del trámite"
                        subtitle="Tipo de documento de trámite"
                    />
                    <Steptracker
                        step="2"
                        current={
                            pathname === step2
                                ? true
                                : false
                        }
                        title="Adjunta tus documentos"
                        subtitle="Documentos solicitados a firmar"
                    />
                </div>
                <Outlet />
            </div>
        </div>
    );
};

export default Procedurecreationform;
