import React from 'react'
import { ModalContractCreatedAndContractStepsStyles } from '../../index.css'

const ResponsiveText = ({ text, active }) => {

    const styles = ModalContractCreatedAndContractStepsStyles();

    return (
        <div className={styles.stepperResponsiveText}>
            <p>{text[active]}</p>
        </div>
    )
}

export default ResponsiveText