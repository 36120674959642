import React from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid } from '@mui/material';

import Text from '../../../heading/Text';
import { ModalContractCreatedAndContractStepsStyles } from '../../index.css';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/icon-check-white-outlined.svg';

const Steps = ({ className, children, active, index, steps }) => {

    const styles = ModalContractCreatedAndContractStepsStyles();

    let current = active === index ? true : false;

    const stepNumberStyles = {
        color: "white.main",
        fontSize: "1.3rem",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
        display: 'flex',
        alignItems: 'center'
    };

    const titleStyles = {
        color: current ? "black.main" : "gray.dark",
        fontWeight: "500",
        marginBottom: "0",
        marginTop: "0",
        fontSize: '.9rem'
    };

    return (
        <Grid className={styles.stepperProgress_mobileBox}>
            <div className={styles.stepperProgress_mobileBox_Number}>
                <div className={className}>
                    <Text sx={stepNumberStyles} align="left" mgButton variant="h7">
                        {children.index <= active ? <CheckIcon /> : children.index + 1}
                    </Text>
                </div>
                {index + 1 === steps ? null :
                    <div className={styles.stepperProgress_MobileArrow}>
                        <ArrowForwardIosIcon />
                    </div>}
            </div>
            <div className={styles.stepperProgress_stepText}>
                <Text sx={titleStyles} align="left" mgButton variant="h7">
                    Paso Nº {index + 1}<br />{children.text}
                </Text>
            </div>
        </Grid>
    )
}

export default Steps