import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const FormularioSolicitudStyles = makeStyles((theme) => {

    const fontSize = 'calc(1.5rem + 1vw) !important';

    return createStyles({
        boxIndetify: {
            display: 'flex',
            flexDirection: ' !important',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
            marginTop: '3px !important',
            paddingRight: '10px',
            zoom: 1.5,
            '& svg': {
            }

        },
        cssTexto1: {
            fontSize: 'calc(10px + 1vw) !important',
            color: 'black',
            '& strong, em': {
                fontSize: 'calc(10px + 1vw)',
            },
            '& a': {
                fontSize: 'calc(10px + 1vw)',
                color: 'black',
                fontWeight: 'normal',
                textDecoration: 'underline'
            }
        },
        cssTexto2: {
            fontSize: 'calc(7px + 1vw) !important',
            color: 'grey',
            '& strong, em': {
                fontSize: 'calc(7px + 1vw)',
            },
            '& em': {
                color: 'grey'
            }
        },
        cssTexto: {
            fontSize: '30px !important'
        },
        stepBox: {
            width: "100%",
            "& > *": {
                margin: "4rem auto 0rem"
            }
        },
        formBox: {
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                margin: theme.spacing(1)
            }
        },
        stepsperContent: {
            display: 'flex',
            flexDirection: 'column !important',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: '100vh'
        },
        textContent: {
            display: 'flex',
            flexDirection: 'column !important',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        buttomBox: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            alignContent: 'center',
            color: 'white !important',
            '& button': {
                fontWeight: '900 !important',
                fontSize: '1.2rem !important',
                '& span': {
                    color: 'white !important'
                }
            },
            '& svg': {
                marginBottom: '9px'
            },
            '& #textPulsar': {
                marginLeft: '10px',

                '& strong': {
                    fontWeight: '500'
                }
            }
        },
        logo: {
            height: '132px',
            width: '320px'
        },
        texto1: {
            fontSize,
            textAlign: 'center'
        },
        texto2: {
            fontSize,
            textAlign: 'center',
            fontStyle: 'italic',

            '& strong': {
                fontSize
            },

            '& a': {
                fontSize,
                color: 'black',
                fontWeight: 'normal',
                textDecoration: 'underline'
            }
        },
        texto3: {
            textAlign: 'center',

            '& a': {
                fontSize,
                color: 'black',
                fontWeight: 'normal',
                textAlign: 'center',
                textDecoration: 'underline'
            }
        }
    });

});