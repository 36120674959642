import { forwardRef, useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import Btn from "../buttons/Button";
import { AddSignaturesStyles } from "../paperworkProcess/addSignatures/index.css";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDocumentAlert = ({ errors }) => {
    const styles = AddSignaturesStyles();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (errors > 0) handleClickOpen();
    }, [errors]);

    return (
        <Dialog
            fullWidth={true}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            className={styles.mainContainerDialog}
        >
            <DialogTitle
                sx={{ textAlign: "center" }}
                color={["secondary.main"]}
            >
                Han surgido errores en el documento.
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    Se ha producido un error al intentar subir su documento. Si
                    está tratando de cargar un documento con un dominio vigente,
                    le solicitamos que lo envíe por separado a
                    contacto@firmavirtual.legal, indicando el ID del trámite que
                    está creando. Por favor, suba el contrato sin incluir el
                    dominio vigente en esta ocasión. ¡Gracias por su
                    comprensión!
                </DialogContentText>
            </DialogContent>

            <DialogActions
                style={{ justifyContent: "center", paddingBottom: "20px" }}
            >
                <Btn
                    variant="outlined"
                    text="OK"
                    color="primary"
                    onClick={handleClose}
                    sx={{
                        height: "39px",
                        borderRadius: "20px",
                        marginLeft: "30px !important",
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDocumentAlert;
