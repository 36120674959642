import RenderContractDetail from "../atoms/renderContractDetail";
import RenderUserName from "../atoms/renderUserName";
import RenderCountSigner from "../atoms/renderCountSigner";
import RenderStatus from "../atoms/renderStatus";
import RenderCreatedAt from "../atoms/renderCreatedAt";
import RenderActions from "../atoms/renderActions";

const gridColumns = [
    {
        field: "autoId",
        headerAlign: "center",
        headerName: "ID",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 80,
    },
    {
        field: "sTipoContrato",
        headerAlign: "center",
        headerName: "Contrato",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase",
        width: 280,
        renderCell: (params) => <RenderContractDetail data={params.row} />,
    },
    {
        field: "user",
        headerAlign: "center",
        headerName: "Usuario creador",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--uppercase",
        width: 210,
        renderCell: (params) => <RenderUserName data={params.row} />,
    },
    {
        field: "iSignedCount",
        headerAlign: "center",
        headerName: "Firmantes",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item text--center",
        width: 130,
        renderCell: (params) => <RenderCountSigner data={params.row} />,
    },
    {
        field: "dContractDT",
        headerAlign: "center",
        headerName: "Fecha de creación",
        headerClassName: "grid__header-item",
        cellClassName: "grid__row-item",
        width: 150,
        renderCell: (params) => <RenderCreatedAt item={params.row} />,
    },
    {
        field: "sStatus",
        headerAlign: "center",
        headerName: "Estado",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        disableColumnMenu: true,
        width: 240,
        renderCell: (params) => (
            <RenderStatus
                status={params.value}
                paymentStatus={params.row["sPaymentStatus"]}
            />
        ),
    },
    {
        field: "sContractID",
        headerAlign: "center",
        headerName: "Acciones",
        headerClassName: "grid__header-item",
        rowClassName: "grid__row-item",
        width: 100,
        disableColumnMenu: true,
        renderCell: (params) => (
            <RenderActions
                id={params.value}
                status={params["row"]["sStatus"]}
            />
        ),
    },
];

const localizedTextsMap = {
    columnMenuUnsort: "Sin ordenar",
    columnMenuSortAsc: "De menor a mayor",
    columnMenuSortDesc: "De mayor a menor",
    columnMenuFilter: "Filtro",
    columnMenuHideColumn: "Ocultar",
    columnMenuShowColumns: "Mostrar columnas",
    toolbarExport: "Descargar",
    toolbarFilters: "Filtros",
    filterOperatorContains: "Contiene",
    filterOperatorEquals: "Igual a",
    filterOperatorStartsWith: "Empieza con",
    filterOperatorEndsWith: "Termina con",
    filterOperatorIsEmpty: "Esta vacio",
    filterOperatorIsNotEmpty: "No esta vacio",
    filterPanelInputLabel: "Valor",
    filterPanelInputPlaceholder: "Filtrar por valor",
};

export { gridColumns, localizedTextsMap };
