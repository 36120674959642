import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FaTrashAlt } from "react-icons/fa";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import * as PdfjsLib from "pdfjs-dist";

import { ReactComponent as UploadIcon } from "../../../../../assets/icons/icon-upload-outlined-blue.svg";
import { ReactComponent as MergeIcon } from "../../../../../assets/icons/icon-merge-outlined-blue.svg";
import { asyncFileToBase64, verifiVariable } from "../../../../../utils/enums";
import { saveTramitANFInfo } from "../../../../../store/formANFTramit";
import { SignAuthorizationStyles } from "../../index.css";
import UnirDocsModal from "../unirDocsModal";
import { validation } from "../../../../../utils/validations";
import ModalContractUploadError from "../../../../modalContractUploadError";

const AddDocuments = ({ loadingModaldocs, textLoadingModaldocs }) => {
    const {
        formANFTramit: { document },
    } = useSelector((state) => state);
    const styles = SignAuthorizationStyles();
    const dispatch = useDispatch();
    const inputFileRef = useRef(null);
    // eslint-disable-next-line
    const [openModal, setOpenModal] = useState(0);
    const [showMaxSizeText, setShowMaxSizeText] = useState(false);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);

    const UploadButton = styled(Button)((props) => ({
        fontStyle: "normal",
        boxShadow: "none",
        fontWeight: "bolder",
        textTransform: "none",
        fontSize: "0.80rem",
        padding: "11px 28px",
        border: "1px solid",
        borderRadius: "7px",
        lineHeight: 1.5,
        color: !verifiVariable(document)
            ? props.theme.palette.white.main
            : props.theme.palette.primary.main,
        backgroundColor: !verifiVariable(document)
            ? props.theme.palette.error.main
            : props.theme.palette.white.main,
        borderColor: !verifiVariable(document)
            ? props.theme.palette.error.main
            : props.theme.palette.primary.light,
        alignSelf: "center",
        fontFamily: ['"PoppinsBold"'].join(","),
        "&:hover": {
            backgroundColor: !verifiVariable(document)
                ? props.theme.palette.error.main
                : props.theme.palette.primary.xlight,
            borderColor: !verifiVariable(document)
                ? props.theme.palette.error.main
                : props.theme.palette.primary.main,
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#0062cc",
            borderColor: "#005cbf",
        },
        "&:focus": {
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
    }));

    const clearDoc = () => {
        saveTramitANFInfo({
            indexe: "firms",
            value: {},
        });

        dispatch(
            saveTramitANFInfo({
                indexe: "document",
                value: null,
            })
        );
    };

    const handleFileChange = async (event) => {
        try {
            const fileObj = event.target.files && event.target.files[0];

            if (!fileObj) {
                return;
            }

            if (fileObj.type !== 'application/pdf') {
                setErrorsUpload(["Solo se permiten archivos PDF."]);
                setOpenModalErrors(true);
                return;
            }


            if (fileObj.size > validation.fileMaxSize) {
                setShowMaxSizeText(true);
                return;
            }

            const arrayBuffer = await fileObj.arrayBuffer();

            await PdfjsLib.getDocument({ data: arrayBuffer }).promise;

            setShowMaxSizeText(false);

            event.target.value = null;

            const fileBase64 = await asyncFileToBase64(fileObj);

            dispatch(
                saveTramitANFInfo({
                    indexe: "document",
                    value: fileBase64,
                })
            );

            dispatch(
                saveTramitANFInfo({
                    indexe: "fileName",
                    value: fileObj.name,
                })
            );
        } catch (error) {
            setErrorsUpload(['El archivo PDF está corrupto o tiene una estructura inválida.']);
            setOpenModalErrors(true);
            console.log(error);
        }
    };

    return (
        <Box className={`${styles.contentDocuments} ${styles.spacinContent}`}>

            <ModalContractUploadError
                openModalErrors={openModalErrors}
                setOpenModalErrors={setOpenModalErrors}
                data={errorsUpload}
                modalIcon={'warnIcon'}
            />

            <UnirDocsModal
                openModalDocs={openModal}
                loadingModaldocs={(e) => loadingModaldocs(e)}
                textLoadingModaldocs={(e) => textLoadingModaldocs(e)}
            />
            <input
                hidden
                accept="application/pdf"
                style={{ display: "none" }}
                ref={inputFileRef}
                type="file"
                onChange={handleFileChange}
            />

            <Typography
                variant="h6"
                className={styles.title}
                marginTop={"10px"}
                marginBottom={"10px"}
            >
                Adjuntar documentos
            </Typography>

            <Typography
                variant="caption"
                className={styles.subTitle}
                textAlign={'justify'}
                marginTop={"5px"}
                marginBottom={"10px"}
            >
                Para todos los documentos notariados, se deben subir los siguientes elementos:
                el contrato a firmar, las cédulas de los firmantes y, si corresponde,
                el certificado de representante legal (e-RUT).
            </Typography>

            <Typography
                variant="caption"
                textAlign={'justify'}
                marginTop={"5px"}
                marginBottom={"10px"}
            >
                Para proteger tu contrato bajo la nueva ley N° 21.461 "Devuélveme mi Casa", incluye el certificado de dominio vigente de la propiedad (con menos de 30 días de antigüedad) en la documentación.
                Si aún no lo tienes, te sugerimos crear el tramite nuevamente incluyendo el dominio vigente de la propiedad.
                Sin el certificado de dominio vigente, el contrato de arriendo no queda protegido bajo la nueva ley de arriendo.
            </Typography>

            {!verifiVariable(document) && (
                <Grid container spacing={2} className="actions-buttoms">
                    <Grid item xs={12}>
                        <UploadButton
                            onClick={() => clearDoc()}
                            fullWidth
                            startIcon={<FaTrashAlt />}
                            variant="contained"
                            color="primary"
                        >
                            Quitar Documento
                        </UploadButton>
                    </Grid>
                </Grid>
            )}

            {verifiVariable(document) && (
                <Grid container spacing={2} className="actions-buttoms">

                    <Grid
                        item
                        xs={12}
                    >
                        <UploadButton
                            onClick={() => setOpenModal((value) => 1 + value)}
                            fullWidth
                            startIcon={<MergeIcon />}
                            variant="contained"
                            color="primary"
                        >
                            Unir archivos
                        </UploadButton>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                    >
                        <UploadButton
                            onClick={() => inputFileRef.current.click()}
                            fullWidth
                            startIcon={<UploadIcon />}
                            variant="contained"
                            color="primary"
                        >
                            Subir archivo
                        </UploadButton>
                    </Grid>

                </Grid>
            )}

            {showMaxSizeText && (
                <Typography
                    variant="body1"
                    gutterBottom
                    color={["error"]}
                    textAlign={"center"}
                >
                    El archivo que seleccionaste es demasiado grande. Por favor,
                    selecciona un archivo que sea menor a 10MB.
                </Typography>
            )}
        </Box>
    );
};

export default AddDocuments;
