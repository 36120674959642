import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart as apiCallStartMerge } from "../actions/apiMerge";

const slice = createSlice({
    name: "scheduling",
    initialState: {
        previewPdfSigned: undefined,
        firstStep: {},
        secondStep: [],
        thirdStep: {},
        fourthStep: {},
        firms: {},
        editSigner: undefined,
        uploadProgress: 0,
        loading: false,
        docPdfBase64Detail: null,
        communeList: [],
        juridictionList: [],
        notaryList: [],
        notarieList: [],
    },
    reducers: {
        FORM_SCHEDULE_UPLOAD_PROGRESS: (state, action) => {
            state.uploadProgress = Math.floor(action.payload);
        },

        FORM_SCHEDULE_FAIL: (state, action) => {
            state.uploadProgress = 0;
            state.loading = false;
        },

        FORM_SCHEDULE_MERGE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;

            state.thirdStep = Object.assign(state.thirdStep, {
                docPdfBase64: action.payload["data"]["file"],
            });

            state.loading = false;
        },

        FORM_SCHEDULE_EDIT_MERGE_SUCCESS: (state, action) => {
            state.uploadProgress = 0;

            state.docPdfBase64Detail = action.payload["data"]["file"];

            state.loading = false;
        },

        FORM_SCHEDULE_REQUEST: (state, action) => {
            state.docPdfBase64Detail = null;
            state.loading = true;
        },

        FORM_SCHEDULE_SAVE_INFO: (state, action) => {
            state[action.payload["indexe"]] = action.payload["value"];
        },

        FORM_GET_EDIT_SCHEDULE_SIGNER: (state, action) => {
            state.editSigner = action.payload;
        },

        FORM_CLEAR_EDIT_SCHEDULE_SIGNER: (state, action) => {
            state.editSigner = undefined;
        },

        FORM_SCHEDULE_PURGE_STATE: (state, action) => {
            state.firstStep = {};
            state.secondStep = [];
            state.thirdStep = {};
            state.fourthStep = {};
            state.previewPdfSigned = undefined;
            state.firms = {};
            state.editSigner = undefined;
            state.uploadProgress = 0;
            state.loading = false;
            state.docPdfBase64Detail = null;
        },
    },
});

export const {
    FORM_SCHEDULE_UPLOAD_PROGRESS,
    FORM_SCHEDULE_FAIL,
    FORM_SCHEDULE_MERGE_SUCCESS,
    FORM_SCHEDULE_EDIT_MERGE_SUCCESS,
    FORM_SCHEDULE_REQUEST,
    FORM_CLEAR_EDIT_SCHEDULE_SIGNER,
    FORM_GET_EDIT_SCHEDULE_SIGNER,
    FORM_SCHEDULE_SAVE_INFO,
    FORM_SCHEDULE_PURGE_STATE,
} = slice.actions;

export const repairDoc = (data, token, edit) => {
    return apiCallStartMerge({
        url: `/pdf/repair`,
        method: "POST",
        data,
        onStart: FORM_SCHEDULE_REQUEST.type,
        onSuccess: edit
            ? FORM_SCHEDULE_EDIT_MERGE_SUCCESS.type
            : FORM_SCHEDULE_MERGE_SUCCESS.type,
        onError: FORM_SCHEDULE_FAIL.type,
        onUploadProgress: FORM_SCHEDULE_UPLOAD_PROGRESS.type,
        authorization: token,
    });
};

export const getEditSigner = (data) => {
    return { type: FORM_GET_EDIT_SCHEDULE_SIGNER.type, payload: data };
};

export const clearEditSigner = () => {
    return { type: FORM_CLEAR_EDIT_SCHEDULE_SIGNER.type, payload: undefined };
};

export const saveSchedulingInfo = (data) => {
    return { type: FORM_SCHEDULE_SAVE_INFO.type, payload: data };
};

export const clearForm = () => {
    return { type: FORM_SCHEDULE_PURGE_STATE.type, payload: null };
};

export default slice.reducer;
