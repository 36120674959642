import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ListAsocDocsHook = (docsProvider) => {

    const {
        contract: {
            tramites: {
                providersTramList
            }
        }
    } = useSelector((state) => state);
    const [list, setList] = useState([]);


    useEffect(() => {

        if (![null, undefined, ''].includes(docsProvider)) {

            const arrayList = [...docsProvider['contractTypeList']];

            const contractTypeList = [];

            arrayList.forEach((item) => {

                const contracType = providersTramList.find((providersTram) => item['iContractTypeFeeID'] === providersTram['iContractTypeFeeID']);

                if (![undefined, null, ''].includes(contracType)) contractTypeList.push(contracType);

            });

            setList(contractTypeList);

        } else {
            setList([]);
        }

    }, [docsProvider, providersTramList]);

    return list;
};

export default ListAsocDocsHook;