import { createStyles, makeStyles } from "@mui/styles"

export const TramitDetailDataStyles = makeStyles((theme) => {
    return createStyles({
        mainInfo_box: {
            backgroundColor: theme.palette.white.main,
            padding: '1rem',
            [theme.breakpoints.up('sm')]: {
                padding: '0'
            }
        },
        numberTitle: {
            display: "flex",
            alignItems: "center",
            margin: "55px 0 25px 0",
            "& h6": {
                lineHeight: "1.2",
                "& span": {
                    color: "#A5A5A5",
                    fontFamily: "PoppinsRegular",
                },
            },
        },
        stepNumbers: {
            display: "flex",
            width: "35px",
            height: "35px",
            justifyContent: "center",
            borderRadius: "7px",
            alignItems: "center",
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.light,
            fontSize: "1.2rem",
            fontFamily: "PoppinsBold",
        },
        detailTextTitle: {
            fontFamily: "PoppinsBold",
            color: theme.palette.black.secondary,
            fontSize: "0.90rem",
            marginLeft: "15px",
        },
        infoItem: {
            paddingRight: "15px",
            paddingLeft: "15px",
            boxShadow: "unset",
            borderRadius: "0px 7px 7px 0px",
            "& > div": {
                marginBottom: "35px",
            },
        },
        dataTramit_Container: {
            margin: '0',
            padding: '1rem'
        },
        dataTramit: {
            margin: ".75rem !important",
            display: 'flex',
            //justifyContent: 'space-between'
        },
        dataTramit_Info: {
            margin: '0 !important',
            width: '50%',
            textAlign: 'start'
        },
        ownerData_Info_Box_mainText: {
            width: '50%',
            fontSize: '.867rem',
            color: theme.palette.black.secondary,
            margin: '.1rem 0 0 0 !important',
            fontFamily: 'PoppinsBold'
        },
        ownerData_Info_Box_secondaryText: {
            fontSize: '.867rem',
            color: theme.palette.black.secondary,
            margin: '.1rem 0 0 0 !important'
        },

        blueBox: {
            backgroundColor: theme.palette.primary.xxxxlight,
            border: `1px solid ${theme.palette.primary.xxxlight}`,
            borderRadius: '.5rem',
            padding: '1rem',
            [theme.breakpoints.up('lg')]: {
                width: '100%'
            }
        },

        documentName_box: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },

        documentName: {
            fontSize: '.8rem',
            color: theme.palette.primary.main
        },

        document_actionButtons: {
            margin: '1rem 0 0 0',
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'space-evenly'
            }
        }
    });
});