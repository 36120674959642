import { createSlice } from "@reduxjs/toolkit";
import { apiCallStart } from "../actions/api";

const slice = createSlice({
    name: "providers",
    initialState: {
        provider: undefined,
        providers: [],
        loading: false,
        message: null,
        code: null,
        status: null
    },

    reducers: {

        CLEAR_ALL: (state, action) => {
            state.providers = [];
            state.provider = undefined;
        },

        SAVE_LIST_PROVIDERS: (state, action) => {
            state.providers = action.payload;
        },

        PROVIDERS_LIST_REQUEST: (state, action) => {
            state.loading = true;
            state.status = null;
            state.code = null;
            state.message = null;
        },

        PROVIDERS_GET_SUCCESS: (state, action) => {
            state.provider = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        PROVIDERS_LIST_SUCCESS: (state, action) => {
            state.providers = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
            state.message = null;
        },

        PROVIDERS_LIST_FAIL: (state, action) => {
            state.message = action.payload['message'];
            state.status = action.payload['status'];
            state.code = action.payload['code'];
            state.loading = false;
        }

    }
});

export const {
    CLEAR_ALL,
    SAVE_LIST_PROVIDERS,
    PROVIDERS_LIST_REQUEST,
    PROVIDERS_LIST_SUCCESS,
    PROVIDERS_GET_SUCCESS,
    PROVIDERS_LIST_FAIL
} = slice.actions;

export const clearAll = () => {
    return { type: CLEAR_ALL.type, payload: null };
};

export const addProviders = (rest) => {
    return { type: SAVE_LIST_PROVIDERS.type, payload: rest };
};

export const getProvider = (rut, token) => {
    return apiCallStart({
        url: "/api/v1/notary/find-rut",
        method: "POST",
        data: {rut},
        onStart: PROVIDERS_LIST_REQUEST.type,
        onSuccess: PROVIDERS_GET_SUCCESS.type,
        onError: PROVIDERS_LIST_FAIL.type,
        authorization: token
    });
};

export const getProvidersStorage = (token) => {
    return apiCallStart({
        url: `/api/v1/notary/find-list`,
        method: "POST",
        data: {},
        onStart: PROVIDERS_LIST_REQUEST.type,
        onSuccess: PROVIDERS_LIST_SUCCESS.type,
        onError: PROVIDERS_LIST_FAIL.type,
        authorization: token
    });
};

export default slice.reducer;