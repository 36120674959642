import axios from "axios";
import { api } from "../../../../utils/enums";

const uri = api.BASE_URI;

export const downloadMemberContractDoc = async (memberToken, userToken, contract) => {
    try {
        const response = await axios.get(
            `${uri}${api.DOWNLOAD_MEMBER_CONTRACT_FILE}/${memberToken}`,
            {
                responseType: "blob",
                headers: {
                    Authorization: userToken,
                },
            }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `contrato_${contract}.pdf`);
        document.body.appendChild(link);
        link.click();

        return response.data;
    } catch (error) {
        return error;
    }
};

export const aprovedMemberContract = async (memberToken, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.APROVED_MEMBER_CONTRACT}/${memberToken}`,
            {},
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};

export const unAprovedMemberContract = async (memberToken, userToken) => {
    try {
        const response = await axios.post(
            `${uri}${api.UNAPROVED_MEMBER_CONTRACT}/${memberToken}`,
            {},
            {
                headers: {
                    Authorization: userToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        return error;
    }
};