import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';

import { addChalleGenToken, addChalleIngresValidacion, addChalleRestValidacion } from '../../../../../store/fvavanzada/fvavanzada';
import { challengeGenerarToken, challengeIngressValidacion, challengeRestValidacion } from "../../../../../utils/api-fva";
import { getNombres, nextStep, textErrorAsync } from '../../../../../utils/validations';
import { FirmaVirtualAvanzadaStyles } from '../../FirmaVirtualAvanzadaStyles.css';
import Spinner from '../../../../spinners/Spinner';
import ModalAlert from '../../../../floats/ModalAlert';

const Step2 = () => {
    const styles = FirmaVirtualAvanzadaStyles();
    const {
        fvavanzada: {
            clvUnicadAtosUsuario,
            challeIngresValidacion,
            challeGenToken,
            challeRespuestaValidacion
        },
    } = useSelector((state) => state);
    const dispatch = useDispatch();
    const { control, handleSubmit } = useForm();
    const [preguntas, setPreguntas] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [textLoading, setTextLoading] = React.useState("");
    const [showModal, setShowModal] = React.useState(false);
    const [modalStatus, setModalStatus] = React.useState({});

    const onSubmit = async (data) => {
        try {

            setTextLoading("Procesando sus respuestas.");

            setLoading(true);

            const rest = Object.values(data);

            const datos = {
                idChallenge: challeIngresValidacion['idChallenge'],
                respuestas: [

                ]
            };

            // eslint-disable-next-line
            rest.map((item) => {
                datos.respuestas.push(JSON.parse(item));
            });

            const restValidation = await challengeRestValidacion(challeGenToken['token'], datos);

            if (restValidation !== 'FAIL') {

                dispatch(addChalleRestValidacion(restValidation));

                setModalStatus({
                    title: "Validación exitosa!",
                    message: restValidation['message'] + '. ' + nextStep,
                    buttonText: "Cerrar",
                    modalIcon: "successIcon"
                });
                setShowModal(true);

            } else {

                dispatch(addChalleRestValidacion(null));

                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: restValidation['message'],
                    buttonText: "Cerrar",
                    modalIcon: "warnIcon"
                });
                setShowModal(true);

            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
            dispatch(addChalleRestValidacion(null));
            console.log('error', error);
        }
    }

    const handleModal = () => {
        setShowModal(!showModal);
        setModalStatus({
            title: "",
            message: "",
            buttonText: "",
            modalIcon: ""
        });
    };

    React.useEffect(() => {

        (async () => {

            try {

                if (challeRespuestaValidacion === null) {

                    setTextLoading("Cargando preguntas de seguridad.");

                    setLoading(true);

                    const data = {
                        rut: `${clvUnicadAtosUsuario['RolUnico']['numero']}-${clvUnicadAtosUsuario['RolUnico']['DV']}`,
                        numeroDocumento: clvUnicadAtosUsuario['formData']['numeroDocumento'],
                        nombres: clvUnicadAtosUsuario['name']['nombres'][0],
                        apellidoPaterno: getNombres(clvUnicadAtosUsuario['name']['apellidos']),
                        apellidoMaterno: getNombres(clvUnicadAtosUsuario['name']['apellidos']),
                        email: clvUnicadAtosUsuario['formData']['email'],
                        fechaNacimiento: clvUnicadAtosUsuario['formData']['fechaNacimiento'],
                        estadoCivil: clvUnicadAtosUsuario['formData']['estadoCivil'],
                        telefono: clvUnicadAtosUsuario['formData']['telefono']
                    }

                    const restGetChalleToken = await challengeGenerarToken();

                    dispatch(addChalleGenToken(restGetChalleToken));

                    const restChalleIngressValid = await challengeIngressValidacion(restGetChalleToken['token'], data);

                    if (restChalleIngressValid['status'] !== 'FAIL') {
                        dispatch(addChalleIngresValidacion(restChalleIngressValid));
                        setModalStatus({
                            title: "Validación exitosa!",
                            message: "Información cargada correctamente",
                            buttonText: "successIcon",
                            modalIcon: "Cerrar"
                        });
                        setShowModal(true);
                    } else {
                        dispatch(addChalleGenToken(null));
                        dispatch(addChalleIngresValidacion(null));

                        setModalStatus({
                            title: "¡Ups!, Algo ha pasado",
                            message: restChalleIngressValid['message'],
                            buttonText: "Cerrar",
                            modalIcon: "warnIcon"
                        });
                        setShowModal(true);
                    }

                    setLoading(false);

                }

            } catch (error) {
                dispatch(addChalleGenToken(null));
                dispatch(addChalleIngresValidacion(null));
                setLoading(false);
                setModalStatus({
                    title: "¡Ups!, Algo ha pasado",
                    message: textErrorAsync,
                    buttonText: "Cerrar",
                    modalIcon: "warnIcon"
                });
                setShowModal(true);
                console.log('error', error);
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {

        if (![null, undefined].includes(challeIngresValidacion) && challeIngresValidacion['status'] === 'OK') {
            setPreguntas(challeIngresValidacion['preguntas']);
        }

    }, [challeIngresValidacion]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.formRestChalle}>

            <ModalAlert
                buttonText={modalStatus.buttonText}
                modalIcon={modalStatus.modalIcon}
                onAcept={handleModal}
                openModal={showModal}
                setOpenModal={setShowModal}
                subTitle={modalStatus.message}
                title={modalStatus.title}
            />

            {loading && (
                <Spinner
                    loading={true}
                    type="bar"
                    text={textLoading}
                    opacity={true}
                />
            )}

            {![null, undefined].includes(preguntas) && preguntas.map((field, index) => {
                return (
                    <Controller
                        key={field['descripcion'] + (index + 1)}
                        name={`pregunta${field['id']}`}
                        control={control}
                        rules={{
                            required: 'Esta respuesta es requerida.'
                        }}
                        render={({ field: { onChange, onBlur, value, ref }, fieldState }) => {
                            return (
                                <FormControl
                                    fullWidth
                                    error={fieldState.error ? true : false}
                                >
                                    <InputLabel id="demo-simple-select-label">{field['descripcion']}</InputLabel>
                                    <Select
                                        ref={ref}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={undefined}
                                        label={field['descripcion']}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        defaultValue={''}
                                    >
                                        {![null, undefined].includes(field['alternativas']) && field['alternativas'].map((opcion, index) => {
                                            return (
                                                <MenuItem
                                                    key={opcion['descripcion'] + index}
                                                    value={JSON.stringify({ pregunta: field['id'], respuesta: opcion['id'] })}
                                                >
                                                    {opcion['descripcion']}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    <FormHelperText hidden={fieldState.error ? false : true}>{fieldState.error ? fieldState.error.message : null}</FormHelperText>
                                    <br />
                                </FormControl>
                            );
                        }}
                    />
                )
            })}

            <Button type='submit' disabled={(preguntas.length === 0 ? true : false) || challeRespuestaValidacion !== null} variant="contained">Responder</Button>

        </form>
    );
}

export default Step2;