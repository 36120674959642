import React from 'react'

import { Grid, Typography } from '@mui/material';

import { theme } from '../../../../theme/theme';

const styles = {
    width: '13.5rem',
    height: '7.5rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem',
    "> *": {
        color: theme.palette.white.main
    },
    "> .numberStyle": {
        fontSize: '1.7rem',
        fontFamily: 'PoppinsBold'
    },
    "> .textStyle": {
        fontSize: '.85rem',
        fontFamily: 'PoppinsBold'
    },
};
const HeaderBoxCouponsStats = ({ text, number, color }) => {

    return (
        <Grid sx={{ ...styles, backgroundColor: theme.palette[color].main }}>
            <Typography className='numberStyle'>{number}</Typography>
            <Typography className='textStyle'>{text}</Typography>
        </Grid>
    )
}

export default HeaderBoxCouponsStats