import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const GetFromXmlRestWSIngresoSolicitud = () => {
    const {
        fvavanzada: {
            raWSIngresoSolicitud
        },
    } = useSelector((state) => state);
    const [rest, setRest] = useState(undefined);


    useEffect(() => {

        if (typeof raWSIngresoSolicitud === 'string' && ![null, undefined].includes(raWSIngresoSolicitud)) {

            const restEstado = raWSIngresoSolicitud.match(/<estado>.+?<\/estado>/g).map(e => e.replace(/<estado>|<\/estado>/g, ""));

            const restEstadoSolicitud = raWSIngresoSolicitud.match(/<estadoSolicitud>.+?<\/estadoSolicitud>/g).map(e => e.replace(/<estadoSolicitud>|<\/estadoSolicitud>/g, ""));

            const restNumSolicitud = raWSIngresoSolicitud.match(/<numeroSolicitud>.+?<\/numeroSolicitud>/g).map(e => e.replace(/<numeroSolicitud>|<\/numeroSolicitud>/g, ""));

            const restDescrip = raWSIngresoSolicitud.match(/<descripcion>.+?<\/descripcion>/g).map(e => e.replace(/<descripcion>|<\/descripcion>/g, ""));
            
            const restPin = raWSIngresoSolicitud.match(/<pin>.+?<\/pin>/g).map(e => e.replace(/<pin>|<\/pin>/g, ""));

            setRest({
                restEstado: restEstado[0],
                restDescrip: restDescrip[0],
                restEstadoSolicitud: restEstadoSolicitud[0],
                restNumSolicitud: restNumSolicitud[0],
                restPin: restPin[0]
            });

        }

    }, [raWSIngresoSolicitud]);

    return rest;
};

export default GetFromXmlRestWSIngresoSolicitud;